/**
 * A ServicePriority definition
 * @typedef {Object} ServicePriority
 * @property {number} id -
 * @property {string} name -
 * @property {string} code -
 * @property {string} symbol -
 */

export default {
  servicePriorities: [],
  servicePrioritiesState: null,

  filter: {},

  meta: {},

  colors: [
    // Green
    '#1f9d55',
    '#38c172',
    '#a2f5bf',
    // Blue
    '#2779bd',
    '#3490dc',
    '#bcdefa',
    // Red
    '#cc1f1a',
    '#e3342f',
    '#f9acaa',
    // Orange
    '#de751f',
    '#f6993f',
    '#fcd9b6',
    // Purple
    '#794acf',
    '#9561e2',
    '#d6bbfc',
    // Grey
    '#8795a1',
    '#b8c2cc',
    '#f1f5f8',
  ],

  activeServicePriority: null,
  activeServicePriorityState: null,
  activeServicePriorityErrors: [],
  activeServicePriorityValid: false,
  activeServicePriorityModifiedFields: {},
};
