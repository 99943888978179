<template>
  <div>
    <div v-for="e in this.$enums.CONTRACTOR_HOURS_DAYS.enumValues" :key="e.value">
      <div class="mt-2">
        <strong :key="e.display">{{ e.display }}</strong>
      </div>
      <div v-if="isFullyClosed(hours[e.value])">
        {{ $t('views.availableServices.workingHours.closed') }}
      </div>
      <div v-else>
        <div
            v-if="hours[e.value].start && hours[e.value].finish"
            :key="hours[e.value].start + hours[e.value].finish"
        >
          {{
            $t('views.availableServices.workingHours.standardHours', {
              start: hours[e.value].start,
              finish: hours[e.value].finish,
            })
          }}
        </div>
        <div v-else>
          {{ $t('views.availableServices.workingHours.standardHoursClosed') }}
        </div>

        <div v-if="hours[e.value].out_of_hours">
          {{ $t('views.availableServices.workingHours.outofHoursAvailable') }}
        </div>
        <div v-else>
          {{ $t('views.availableServices.workingHours.outofHoursUnvailable') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContractorWorkingHoursDisplay',

  props: {
    hours: {
      type: Object,
      required: true,
    },
  },

  methods: {
    isFullyClosed(hours) {
      if (!hours) {
        return true;
      }
      if (hours.out_of_hours) {
        return false;
      }
      return !hours.start || !hours.finish;
    },
  },
};
</script>
