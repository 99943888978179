import types from '@/store/types/documentType.js';
import DocumentTypeAPI from '@/services/DocumentTypeAPI.js';
import API from '@/enums/API.js';
import APIError from '@/errors/APIError.js';

const a = types.actions;
const m = types.mutations;

export default {
  /**
   * Loads all DocumentTypes
   * @param {Object} context
   * @param {number} page The page to load. If not supplied will default to 1
   * @return {DocumentType[]} An array of {@link DocumentType} objects.
   */
  async [a.GET_DOCUMENT_TYPES](
    { commit, state },
    { sort = 'name', direction = 'asc', filter = {} },
  ) {
    commit(m.SET_DOCUMENT_TYPES_STATUS, API.LOADING);

    try {
      const documentTypes = await DocumentTypeAPI.getDocumentTypes('all', sort, direction, filter);

      commit(m.SET_DOCUMENT_TYPES_STATUS, API.SUCCESS);
      commit(m.SET_DOCUMENT_TYPES, documentTypes);
    } catch (error) {
      commit(m.SET_DOCUMENT_TYPES_STATUS, API.FAILURE);

      let message = 'An error occurred while fetching these Document Types. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return state.documentTypes;
  },

  /**
   * Creates a new DocumentType
   * @param {Object} context
   * @param {object} documentType A {@link DocumentType} object
   * @return {DocumentType} A {@link DocumentType} object.
   */
  async [a.CREATE_DOCUMENT_TYPE]({ commit, state }, { documentType }) {
    try {
      commit(m.SET_ACTIVE_DOCUMENT_TYPE_ERRORS, []);
      commit(m.SET_ACTIVE_DOCUMENT_TYPE_STATE, API.SAVING);

      const finalDocumentType = { ...documentType };
      if (documentType.restrict_by_role === false) {
        finalDocumentType.role_ids = [];
      }

      const documentTypeResponse = await DocumentTypeAPI.createDocumentType(finalDocumentType);
      commit(m.SET_ACTIVE_DOCUMENT_TYPE_STATE, API.SUCCESS);
      commit(m.SET_ACTIVE_DOCUMENT_TYPE, documentTypeResponse);
      commit(m.ADD_LOCAL_DOCUMENT_TYPE, documentTypeResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_DOCUMENT_TYPE_STATE, API.FAILURE);

      let message = 'An error occurred while creating this Document Type. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeDocumentType;
  },

  /**
   * Updates an existing DocumentType
   * @param {Object} context
   * @param {object} documentType The {@link DocumentType} object to update
   * @param {object} modifiedFields An object with the key/values for the fields to update.
   * @return {DocumentType} A {@link DocumentType} object.
   */
  async [a.UPDATE_DOCUMENT_TYPE]({ commit, state }, { documentType, modifiedFields }) {
    try {
      commit(m.SET_ACTIVE_DOCUMENT_TYPE_ERRORS, []);
      commit(m.SET_ACTIVE_DOCUMENT_TYPE_STATE, API.SAVING);

      const finalDocumentType = { ...modifiedFields };
      if (modifiedFields.restrict_by_role === false) {
        finalDocumentType.role_ids = [];
      }

      const documentTypeResponse = await DocumentTypeAPI.updateDocumentType(
        documentType.id,
        finalDocumentType,
      );

      commit(m.SET_ACTIVE_DOCUMENT_TYPE_STATE, API.SUCCESS);
      commit(m.SET_ACTIVE_DOCUMENT_TYPE, documentTypeResponse);
      commit(m.UPDATE_LOCAL_DOCUMENT_TYPE, documentTypeResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_DOCUMENT_TYPE_STATE, API.FAILURE);

      let message = 'An error occurred while updating this Document Type. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeDocumentType;
  },

  /**
   * Deletes a DocumentType
   * @param {Object} context
   * @param {object} documentType The {@link DocumentType} object to delete
   * @return {DocumentType} A {@link DocumentType} object.
   */
  async [a.DELETE_DOCUMENT_TYPE]({ commit }, { documentType }) {
    try {
      commit(m.SET_ACTIVE_DOCUMENT_TYPE_ERRORS, []);
      commit(m.SET_ACTIVE_DOCUMENT_TYPE_STATE, API.DELETING);

      await DocumentTypeAPI.deleteDocumentType(documentType.id);

      commit(m.SET_ACTIVE_DOCUMENT_TYPE_STATE, API.SUCCESS);
      commit(m.SET_ACTIVE_DOCUMENT_TYPE, null);

      commit(m.REMOVE_LOCAL_DOCUMENT_TYPE, documentType);
    } catch (error) {
      commit(m.SET_ACTIVE_DOCUMENT_TYPE_STATE, API.FAILURE);

      let message = 'An error occurred while deleting this Document Type. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return true;
  },
};
