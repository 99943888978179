import types from '../../types/financialPeriod.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_FINANCIAL_PERIODS
   * @summary Updates the `FinancialPeriods` property.
   * @method
   * @param {Object} state Current state
   * @param {FinancialPeriods[]} financialPeriod The loaded FinancialPeriods array
   */
  [m.SET_FINANCIAL_PERIODS](state, financialPeriods) {
    state.financialPeriods = financialPeriods;
  },

  [m.SET_FINANCIAL_PERIODS_STATUS](state, status) {
    state.financialPeriodsState = status;
  },

  /**
   * @name m SET_FINANCIAL_PERIODS_META
   * @summary Updates the `meta` property which holds the meta data for the `FINANCIAL_PERIODS` collection.
   * For example, total rows, current page, number rows per page etc
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The meta data to store
   */
  [m.SET_FINANCIAL_PERIODS_META](state, meta) {
    state.meta = meta;
  },

  /**
   * @name m SET_FINANCIAL_PERIODS_FILTER
   * @summary Updates the `filter` property which holds the collection of filters currently active
   * on the FinancialPeriods collection
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The filters to be applied
   */
  [m.SET_FINANCIAL_PERIODS_FILTER](state, filter) {
    state.filter = filter;
  },

  /**
   * @name m SET_ACTIVE_FINANCIAL_PERIOD
   * @summary Updates the `activeFinancialPeriod` and resets the
   * `activeFinancialPeriodModifiedFields` & `activeFinancialPeriodErrors` properties
   * @method
   * @param {Object} state Current state
   * @param {FinancialPeriod} financialPeriod The new Active {@link FinancialPeriod}
   */
  [m.SET_ACTIVE_FINANCIAL_PERIOD](state, financialPeriod) {
    state.activeFinancialPeriod = financialPeriod;
    state.activeFinancialPeriodModifiedFields =
      !financialPeriod || financialPeriod.id ? {} : { ...financialPeriod };
    state.activeFinancialPeriodErrors = [];
  },

  /**
   * @name m SET_ACTIVE_FINANCIAL_PERIOD_STATE
   * @summary Updates the `activeFinancialPeriodState` property which holds the current status of the
   * based on the API enum
   * @method
   * @param {Object} state Current state
   * @param {enums/API} activeFinancialPeriodState The current activeFinancialPeriodState
   * (e.g. loading, success, failure)
   */
  [m.SET_ACTIVE_FINANCIAL_PERIOD_STATE](state, activeFinancialPeriodState) {
    state.activeFinancialPeriodState = activeFinancialPeriodState;
  },

  /**
   * @name m SET_ACTIVE_FINANCIAL_PERIOD_ERRORS
   * @summary Updates the `activeFinancialPeriodErrors` property which holds any
   * errors related to activeFinancialPeriod
   * @method
   * @param {Object} state Current state
   * @param {string[]} errors An array of error messages
   */
  [m.SET_ACTIVE_FINANCIAL_PERIOD_ERRORS](state, errors) {
    state.activeFinancialPeriodErrors = errors;
  },

  /**
   * @name m SET_ACTIVE_FINANCIAL_PERIOD_VALID
   * @summary Updates the `activeFinancialPeriodValid` property which holds
   * a boolean for the validation status of the `activeFinancialPeriod`.
   * @method
   * @param {Object} state Current state
   * @param {boolean} valid True if the `activeFinancialPeriod` is valid.
   */
  [m.SET_ACTIVE_FINANCIAL_PERIOD_VALID](state, valid) {
    state.activeFinancialPeriodValid = valid;
  },

  /**
   * @name m UPDATE_ACTIVE_FINANCIAL_PERIOD_MODIFIED_FIELDS
   * @summary Adds given field/value pair to the `activeFinancialPeriodModifiedFields` collection, which
   * will be sent as a PATCH to the API. If the given `value` equals the one on the `activeFinancialPeriod`
   * object (i.e. it hasn't actually changed, or has been reverted back to it's original) then we
   * remove that field from the modifiedFields collection
   * @method
   * @param {Object} state Current state
   * @param {object} payload Object containing a `field` and `value` properties.
   */
  [m.UPDATE_ACTIVE_FINANCIAL_PERIOD_MODIFIED_FIELDS](state, { field, value }) {
    state.activeFinancialPeriodModifiedFields[field] = value;
  },

  /**
   * @name m CLEAR_ACTIVE_FINANCIAL_PERIOD_MODIFIED_FIELDS
   * @summary Empties the `activeFinancialPeriodModifiedFields` object. Used to ensure we're on a clean
   * slate after navigating to a FinancialPeriod Display screen,
   * @method
   * @param {Object} state Current state
   */
  [m.CLEAR_ACTIVE_FINANCIAL_PERIOD_MODIFIED_FIELDS](state) {
    state.activeFinancialPeriodModifiedFields = {};
  },

  /**
   * @name m REMOVE_LOCAL_FINANCIAL_PERIOD
   * @summary Removes the given FinancialPeriod from the `FinancialPeriods` array. This is used to prevent a
   * full reload being needed after deleting a FinancialPeriod.
   * @method
   * @param {Object} state Current state
   * @param {object} financialPeriod The FinancialPeriod to remove.
   */
  [m.REMOVE_LOCAL_FINANCIAL_PERIOD](state, financialPeriod) {
    const index = state.financialPeriods.findIndex((u) => u.id === financialPeriod.id);

    if (index >= 0) {
      state.financialPeriods.splice(index, 1);
    }
  },

  /**
   * @name m ADD_LOCAL_FINANCIAL_PERIOD
   * @summary Adds the given FinancialPeriod to the `financialPeriods` array. This is used to prevent a
   * full reload being needed after adding a FinancialPeriod.
   * @method
   * @param {Object} state Current state
   * @param {object} financialPeriod The FinancialPeriod to add.
   */
  [m.ADD_LOCAL_FINANCIAL_PERIOD](state, financialPeriod) {
    state.financialPeriods.push(financialPeriod);
  },

  /**
   * @name m UPDATE_LOCAL_FINANCIAL_PERIOD
   * @summary Updates the related FinancialPeriod object in the `financialPeriods` array after an update.
   * @method
   * @param {Object} state Current state
   * @param {object} financialPeriod The FinancialPeriod to update.
   */
  [m.UPDATE_LOCAL_FINANCIAL_PERIOD](state, financialPeriod) {
    const localFinancialPeriod = state.financialPeriods.find((c) => c.id === financialPeriod.id);

    if (localFinancialPeriod) {
      Object.assign(localFinancialPeriod, financialPeriod);
    }
  },
};
