import axios from 'axios';
import createAPIParams from '@/util/createAPIParams.js';

/**
 * Interface to the DocumentType API endpoints
 * @class
 * @exports src/services/DocumentTypeAPI
 */
class DocumentTypeAPI {
  /**
   * Retrieves all the DocumentTypes
   * @method
   * @static
   * @return {Promise} Promise that resolves to an array of {@link DocumentTypes} items.
   */
  static async getDocumentTypes(page, sort, direction, filter) {
    const response = await axios({
      method: 'GET',
      url: 'document_type',
      responseType: 'json',
      params: createAPIParams(page, sort, direction, filter),
    });

    return response.data.data;
  }

  /**
   * @summary Hits the create DocumentType endpoint.
   * @desc Sends the given documentType data to the create endpoint
   * @param  {DocumentType}  documentType A {@link DocumentType} object to be saved
   * @return {Promise}      Resolves to the returned full DocumentType object
   */
  static async createDocumentType(documentType) {
    const response = await axios({
      method: 'POST',
      url: 'document_type',
      responseType: 'json',
      data: documentType,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the PATCH DocumentType endpoint.
   * @desc Sends the specified _modified_ fields to the endpoint to be saved.
   * @param  {String|Number}  documentTypeId The DocumentType Id to save
   * @param  {object}  modifiedFields An object with a subset of the
   * {@link DocumentType} fields that have been modified and should be saved.
   * @return {Promise}      Resolves to the returned full DocumentType object
   */
  static async updateDocumentType(documentTypeId, modifiedFields) {
    const response = await axios({
      method: 'PATCH',
      url: `document_type/${documentTypeId}`,
      responseType: 'json',
      data: modifiedFields,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the DELETE DocumentType endpoint.
   * @param  {String|Number}  documentTypeId The DocumentType Id to delete
   * @return {Promise}
   */
  static async deleteDocumentType(documentTypeId) {
    await axios({
      method: 'DELETE',
      url: `document_type/${documentTypeId}`,
      responseType: 'json',
    });

    return true;
  }
}

export default DocumentTypeAPI;
