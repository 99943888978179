/**
 * A Audit definition
 * @typedef {Object} Audit
 */

export default {
  audits: [],
  auditsState: null,

  filter: {},
  sort: 'created_at',
  sortDirection: 'DESC',

  meta: {},

  activeAudit: null,
  activeAuditState: null,
  activeAuditErrors: [],
  activeAuditValid: false,
  activeAuditModifiedFields: {},
};
