<template>
  <ul class="list-card">
    <ListItem
      v-for="(n, index) in items"
      :key="index"
      :node="n"
      display-field="searchDisplayName"
      :children-field="() => []"
      @nodeclicked="onNodeClick"
    />

    <div v-if="items.length === 0" class="empty-text m-3">
      {{ $t('ux.form.list.ListSearchCard.emptyText') }}
    </div>
  </ul>
</template>

<script>
import ListItem from '@/ux/form/list/ListItem.vue';

/**
 * This component creates a list of ListItem components based on a simple list nodes
 * @exports src/ux/list/ListSearchCard
 */
export default {
  name: 'ListSearchCard',

  components: {
    ListItem,
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },

  methods: {
    onNodeClick(node) {
      this.$emit('nodeclicked', node, true);
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  padding: 0;
  margin: 0;
  background: #fff;
}
</style>
<style lang="scss"></style>
