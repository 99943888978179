export default {
  state: {
    /**
     * A Work Order definition
     * @typedef {Object} WorkOrder
     * @property {number} id -
     * @property {string} summary -
     * @property {number} primary_location_id -
     * @property {number} service_type_id -
     * @property {number} reporting_user_id -
     * @property {date} created_at -
     * @property {date} updated_at -
     */
    workOrder: () => ({
      id: null,
      summary: null,
      primary_location_id: null,
      service_type_id: null,
      work_order_request_type_id: null,
      reporting_user_id: null,
      requested_service_id: null,
      description: null,
      point_of_contact: null,
      external_reference: null,
      owner_id: null,
      asset_ids: [],
      watchers: [],
      work_order_priority_id: null,
      emergency: false,
    }),

    /**
     * A Work Order Types definition
     * @typedef {Object} WorkOrderType
     * @property {number} id -
     * @property {string} name -
     * @property {string} description -
     */

    /**
     * A Work Order Comment definition
     * @typedef {Object} WorkOrderComment
     * @property {number} id -
     * @property {string} message -
     * @property {number} user_id -
     * @property {number} work_order_id -
     * @property {string} visibility -
     * @property {file[]} files -
     * @property {string} created_at -
     * @property {string} updated_at -
     */
  },

  getters: {},

  actions: {
    GET_WORK_ORDER: 'GET_WORK_ORDER',
    UPDATE_WORK_ORDER: 'UPDATE_WORK_ORDER',
    CREATE_WORK_ORDER: 'CREATE_WORK_ORDER',
    DELETE_WORK_ORDER: 'DELETE_WORK_ORDER',
    GET_WORK_ORDER_TYPES: 'GET_WORK_ORDER_TYPES',
    GET_WORK_ORDER_REQUEST_TYPES: 'GET_WORK_ORDER_REQUEST_TYPES',
    GET_WORK_ORDER_STATES: 'GET_WORK_ORDER_STATES',
  },

  mutations: {
    SET_ACTIVE_WORK_ORDER: 'SET_ACTIVE_WORK_ORDER',
    SET_ACTIVE_WORK_ORDER_STATE: 'SET_ACTIVE_WORK_ORDER_STATE',
    SET_ACTIVE_WORK_ORDER_ERRORS: 'SET_ACTIVE_WORK_ORDER_ERRORS',
    SET_ACTIVE_WORK_ORDER_VALID: 'SET_ACTIVE_WORK_ORDER_VALID',
    UPDATE_ACTIVE_WORK_ORDER_MODIFIED_FIELDS: 'UPDATE_ACTIVE_WORK_ORDER_MODIFIED_FIELDS',
    CLEAR_ACTIVE_WORK_ORDER_MODIFIED_FIELDS: 'CLEAR_ACTIVE_WORK_ORDER_MODIFIED_FIELDS',

    SET_WORK_ORDERS_FILTER: 'SET_WORK_ORDERS_FILTER',
    SET_WORK_ORDERS_SORT: 'SET_WORK_ORDERS_SORT',

    SET_WORK_ORDER_TYPES: 'SET_WORK_ORDER_TYPES',
    SET_WORK_ORDER_REQUEST_TYPES: 'SET_WORK_ORDER_REQUEST_TYPES',
    SET_WORK_ORDER_STATES: 'SET_WORK_ORDER_STATES',
  },
};
