import axios from 'axios';

/**
 * A Country definition
 * @typedef {Object} Country
 * @property {number} id -
 * @property {string} name -
 */

/**
 * Interface to the Country API endpoints
 * @class
 * @exports src/services/CountryAPI
 */
class CountryAPI {
  /**
   * Retrieves all the Countries
   * @method
   * @static
   * @return {Promise}          Promise that resolves to an aray of {@link Country} items.
   */
  static async getCountries() {
    const response = await axios({
      method: 'GET',
      url: 'country',
      responseType: 'json',
    });

    return response.data.data;
  }
}

export default CountryAPI;
