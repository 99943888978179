
<template>
  <div>
    <table class="table-borderless">
      <tr>
        <td><font-awesome-icon class="text-muted" :icon="['far', 'tag']" /></td>
        <td>
          <div>
            <Chip variant="secondary">
              <div style="font-size: 0.8rem">
                <span v-if="asset.label" :key="asset.label" class="text-dark">
                  #{{ asset.label }}
                </span>
                <span v-else>Unknown</span>
              </div>
            </Chip>
          </div>
        </td>
      </tr>
      <tr>
        <td><font-awesome-icon class="text-muted" :icon="['far', 'hand-holding-box']" /></td>
        <td class="text-dark">
          <span :key="asset.assetModel.name" class="text-bold">
            {{ asset.assetModel.name }}
          </span>
        </td>
      </tr>
      <tr>
        <td><font-awesome-icon class="text-muted" :icon="['far', 'conveyor-belt-alt']" /></td>
        <td class="text-dark">
          <span
            v-if="asset.assetModel.assetManufacturer"
            :key="asset.assetModel.assetManufacturer"
            translate="no"
          >
            {{ asset.assetModel.assetManufacturer?.name }}
          </span>
          <span v-else>Unknown</span>
        </td>
      </tr>
      <tr>
        <td><font-awesome-icon class="text-muted" :icon="['far', 'layer-plus']" /></td>
        <td class="text-dark">
          <span :key="composedPath(asset)">{{ composedPath(asset) }}</span>
        </td>
      </tr>
      <tr>
        <td><font-awesome-icon class="text-muted" :icon="['far', 'map-marker-alt']" /></td>
        <td class="text-dark">
          <span :key="asset.maintainableEntity.path">{{ asset.maintainableEntity.path }}</span>
        </td>
      </tr>
    </table>

    <div class="d-flex flex-wrap mt-2">
      <small :key="asset.assetStatus.status" class="status mr-2 mt-1" :style="{ backgroundColor: asset.assetStatus.color}">
        <span class="font-weight-light mr-1">{{ $t('views.asset.fields.status')}}:</span>
        {{ asset.assetStatus.status }}
      </small>
      <small :key="asset.conditionGrade.name" class="condition mt-1" :style="{ backgroundColor: asset.conditionGrade.color}">
        <span class="font-weight-light mr-1">{{ $t('views.asset.fields.condition')}}:</span>
        {{ asset.conditionGrade.name }}
      </small>
    </div>
  </div>

</template>

<script>
import Chip from 'primevue/chip';

export default {
  name: "AssetDetailsMobile",
  components: {
    Chip
  },
  props: {
    asset: {
      required: true,
      type: Object
    },
    includeWarranty: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  computed: {
    assetUnderWarranty() {
      return this.isInLabourWarranty || this.isInPartsWarranty;
    },

    isInLabourWarranty() {
      return new Date(this.asset.warranty_labour) >= new Date();
    },

    isInPartsWarranty() {
      return new Date(this.asset.warranty_parts) >= new Date();
    },
  },
  methods: {
    composedPath(item) {
      return `${item.assetModel.assetType?.assetCategory?.name} > ${item.assetModel.assetType?.name}`;
    },
  }
}
</script>

<style lang="scss" scoped>
.condition, .status {
  padding: 0.3rem 0.5rem;
  color: #000;
  background-color: #ececec;
  border-radius: 5px;
}
</style>
