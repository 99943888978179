import types from '../../types/requestForServices.js';
import RequestForServiceAPI from '../../../services/RequestForServiceAPI.js';
import errorTypes from '../../types/error.js';
import ApiStatusCreator from '../../../enums/API.js';
const API_STATUS = ApiStatusCreator();

const a = types.actions;
const m = types.mutations;
const me = errorTypes.mutations;

export default {
  /**
   * Loads specific RequestForService details
   * @param {Object} context
   * @param {number} id The RequestForService ID to load
   * @return {RequestForService} A {@link RequestForService} object.
   */
  async [a.GET_REQUEST_FOR_SERVICE]({ commit, state }, { id }) {
    try {
      commit(m.SET_ACTIVE_REQUEST_FOR_SERVICE, null);
      commit(m.SET_ACTIVE_REQUEST_FOR_SERVICE_STATE, API_STATUS.LOADING);

      const requestForService = await RequestForServiceAPI.getRequestForService(id);

      requestForService.created_at = new Date(requestForService.created_at);
      requestForService.updated_at = new Date(requestForService.updated_at);

      commit(m.SET_ACTIVE_REQUEST_FOR_SERVICE, requestForService);
      commit(m.SET_ACTIVE_REQUEST_FOR_SERVICE_STATE, API_STATUS.SUCCESS);
    } catch (error) {
      commit(m.SET_ACTIVE_REQUEST_FOR_SERVICE_STATE, API_STATUS.FAILURE);
      let message = 'An error occurred while fetching this Request for Service. Please try again.'
      let noPermissionOrNotFoundError = '';
      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
        if (error.response.status === 404 || error.response.status === 403) {
          noPermissionOrNotFoundError = error.response.data.message;
        }
      } else {
        message = error.message || message;
      }

      commit(m.SET_ACTIVE_REQUEST_FOR_SERVICE_ERRORS, message);
      commit(me.SET_NOT_FOUND_OR_PERMISSION_ERRORS, noPermissionOrNotFoundError);
    }

    return state.requestForServices;
  },
};
