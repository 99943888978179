export default {
  state: {
    expense: (data = {}) => ({
      id: null,
      user_id: null,
      work_order: null,
      supplier_id: null,
      tax_rate_id: null,
      description: null,
      purchased_at: null,
      quantity: null,
      unit_price: null,
      tax_total: null,
      total: null,
      media: null,
      created_at: null,
      updated_at: null,
      ...data,
    }),

    expenseItem: () => ({
      id: null,
      quantity: 1,
      unit_price: 0,
      description: '',
      purchased_at: '2019-11-12',
      tax_rate: {
        id: 1,
      },
      tax_rate_id: 1,
      supplier: {
        id: 1,
      },
      supplier_id: 1,
    }),
  },

  getters: {},

  mutations: {
    SET_ACTIVE_EXPENSE: 'SET_ACTIVE_EXPENSE',
    SET_ACTIVE_EXPENSE_STATE: 'SET_ACTIVE_EXPENSE_STATE',
    SET_ACTIVE_EXPENSE_ERRORS: 'SET_ACTIVE_EXPENSE_ERRORS',

    SET_EXPENSES_FILTER: 'SET_EXPENSES_FILTER',
    SET_EXPENSES_SORT: 'SET_EXPENSES_SORT',

    SET_EXPENSES: 'SET_EXPENSES',
    SET_EXPENSES_STATUS: 'SET_EXPENSES_STATUS',
    SET_EXPENSES_META: 'SET_EXPENSES_META',

    REMOVE_LOCAL_EXPENSE: 'REMOVE_LOCAL_EXPENSE',
    ADD_LOCAL_EXPENSE: 'ADD_LOCAL_EXPENSE',
    UPDATE_LOCAL_EXPENSE: 'UPDATE_LOCAL_EXPENSE',

    SET_ACTIVE_EXPENSE_VALID: 'SET_ACTIVE_EXPENSE_VALID',
    UPDATE_ACTIVE_EXPENSE_MODIFIED_FIELDS: 'UPDATE_ACTIVE_EXPENSE_MODIFIED_FIELDS',
    CLEAR_ACTIVE_EXPENSE_MODIFIED_FIELDS: 'CLEAR_ACTIVE_EXPENSE_MODIFIED_FIELDS',
  },
};
