import Vue from 'vue';
import types from '../../types/error.js';

const m = types.mutations;

export default {
  /**
   * @name m PUSH_ERROR
   * @summary Push an error into the state
   * @method
   * @param {Object} state Current state
   * @param {int} id A globally unique ID identifying the error instance
   * @param {string[]} errors The errors to display to the user
   * @param {int} timeout A setTimeout identifier corresponding to the duration the error will be displayed
   */
  [m.PUSH_ERROR](state, { id, errors, timeout }) {
    Vue.set(state.errors, id, { id, errors, timeout });
    state.totalCount += 1;
  },

  /**
   * @name m EXPIRE_ERROR
   * @summary Expire an error currently in the state
   * @method
   * @param {Object} state Current state
   * @param {int} id The globally unique ID identifying the error instance
   */
  [m.EXPIRE_ERROR](state, id) {
    const existing = state.errors[id] ?? null;
    if (!existing) {
      return;
    }

    clearTimeout(existing.timeout);
    Vue.delete(state.errors, id);
  },

  /**
   * @name m SET_NOT_FOUND_OR_PERMISSION_ERRORS
   * @summary Updates the `noPermissionOrNotFound` property which holds any errors related to permissions and not found
   * @method
   * @param {Object} state Current state
   * @param {string[]} errors An array of error messages
   */
  [m.SET_NOT_FOUND_OR_PERMISSION_ERRORS](state, errors) {
    // eslint-disable-next-line no-unused-expressions
    Array.isArray(errors)
      ? (state.noPermissionOrNotFound = errors)
      : state.noPermissionOrNotFound.push(errors);
  },
};
