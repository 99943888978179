<template>
  <div>
    <b-modal ref="setupModal">
      <template #modal-header>
        <div class="d-flex w-100 align-items-center">
          <b-button class="cancel-close-button" variant="none" @click="hide">
            <span v-if="active !== 'BackupCodes'">{{ $t('common.forms.cancel') }}</span>
            <span v-if="active === 'BackupCodes'">{{ $t('common.forms.close') }}</span>
          </b-button>
          <span class="flex-fill text-center">
            {{ $t('components.auth.otp.setup.header') }}
          </span>
        </div>
        <div class="btn-placeholder" />
      </template>

      <QrCode v-if="active === 'QrCode' && !error" :qr-code="qrCode"></QrCode>

      <OtpForm v-if="active === 'OtpForm'" ref="otpForm" @updated="otpFormUpdated"></OtpForm>

      <BackupCodes v-if="active === 'BackupCodes'" :backup-codes="backupCodes"></BackupCodes>

      <b-alert v-if="error" class="mb-0" variant="danger" show>
        <font-awesome-icon :icon="['far', 'exclamation-triangle']" class="mr-1" />
        <span :key="error">{{ error }}</span>
      </b-alert>

      <template #modal-footer>
        <b-button v-if="active === 'OtpForm'" ref="backButton" class="mr-auto" @click="back">{{
          $t('common.back')
        }}</b-button>
        <SpinButton
          v-if="active !== 'BackupCodes' && !(active === 'QrCode' && error)"
          ref="nextButton"
          variant="primary"
          :disabled="active === 'OtpForm' && !otpValid"
          :loading="checkingOtp"
          :loading-msg="$t('common.forms.checking')"
          @click="next"
          >{{ $t('common.next') }}</SpinButton
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BAlert, BButton, BModal } from 'bootstrap-vue';
import BackupCodes from '@/components/auth/otp/BackupCodes.vue';
import OtpForm from '@/components/auth/otp/Form.vue';
import QrCode from '@/components/auth/otp/QrCode.vue';
import AuthorizationAPI from '@/services/AuthorizationAPI.js';
import types from '@/store/types/authorization.js';
import SpinButton from '@/ux/SpinButton.vue';

const COMPONENT_OTP_FORM = 'OtpForm';
const COMPONENT_QR_CODE = 'QrCode';
const COMPONENT_BACKUP_CODES = 'BackupCodes';

export default {
  name: 'SetupModal',

  components: {
    BAlert,
    BButton,
    BModal,
    QrCode,
    BackupCodes,
    OtpForm,
    SpinButton,
  },

  data() {
    return {
      active: null,
      checkingOtp: false,
      backupCodes: [],
      error: null,
      otp: '',
      otpValid: false,
      qrCode: null,
    };
  },

  methods: {
    async show() {
      this.active = COMPONENT_QR_CODE;
      this.error = null;
      this.$refs.setupModal.show();
      this.qrCode = null;

      try {
        const data = await AuthorizationAPI.getSharedSecretSetupQRCode();
        this.qrCode = data.qr_code_image;
      } catch (error) {
        this.error = error.response.data.message;
      }
    },

    hide() {
      this.$refs.setupModal.hide();
    },

    back() {
      this.error = null;
      this.active = COMPONENT_QR_CODE;
    },

    async next() {
      if (this.active === COMPONENT_OTP_FORM) {
        try {
          this.checkingOtp = true;
          this.backupCodes = await this.$store.dispatch(types.actions.CONFIRM_OTP, {
            otp: this.otp,
          });

          this.error = null;
          this.active = COMPONENT_BACKUP_CODES;
        } catch (error) {
          this.error = error.response.data.message;
        }

        this.checkingOtp = false;
      }

      if (this.active === COMPONENT_QR_CODE) {
        this.otpValid = false;
        this.active = COMPONENT_OTP_FORM;
      }
    },

    otpFormUpdated(e) {
      this.otp = e.value;
      this.otpValid = e.valid;
    },
  },
};
</script>
<style lang="scss" scoped>
.cancel-close-button {
  width: 5rem;
}
</style>
