import axios from 'axios';
import JsonApiResponseConverter from 'json-api-response-converter';
import IndexParameterBuilder from "@/services/v2/IndexParameterBuilder";
import DataPreparer from "@/services/v2/DataPreparer";
import MetaExtractor from "@/services/v2/MetaExtractor";

class PatchAPI {
  static async patch(route, config) {
    const response = await axios({
      method: 'PATCH',
      url: `v2/${ route }/${ config.id }`,
      headers: {
        'Content-Type': 'application/vnd.api+json',
      },
      responseType: 'json',
      data: {
        data: DataPreparer.prepare(route, config),
      },
      params: IndexParameterBuilder.prepare(config),
    });

    return {
      data: new JsonApiResponseConverter(response.data).formattedResponse,
      meta: {
        ...response.data.meta,
        ...(await MetaExtractor.extractMeta([response.data.data]))[0]
      },
    }
  }
}

export default PatchAPI;
