/**
 * MaintainableEntity Definition
 * @typedef {Object} MaintainableEntity
 * @property {string} name -
 * @property {string} code -
 * @property {number} parent_id -
 * @property {number} service_type_id -
 * @property {string} notes -
 * @property {string} out_of_service_since_date -
 * @property {string} disposal_date -
 * @property {Object} attributes -
 *
 */
export default {
  filter: {},
  page: 1,
  sort: 'name',
  sortDirection: 'ASC',
  meta: {},
  activeMaintainableEntity: null,
  activeMaintainableEntityState: null,
  activeMaintainableEntityErrors: [],
  activeMaintainableEntityValid: false,
  activeMaintainableEntityModifiedFields: {},
  maintainableEntities: [],
  maintainableEntitiesTree: [],
  maintainableEntitiesState: null,
  maintainableEntitiesErrors: [],
  maintainableEntitiesMeta: {},
};
