import axios from 'axios';
import createAPIParams from '@/util/createAPIParams.js';

/**
 * Interface to the ServicePriority API endpoints
 * @class
 * @exports src/services/ServicePriorityAPI
 */
class ServicePriorityAPI {
  /*
   * Retrieves all Service Priority items
   * @return {Promise} Promise that resolves to the api response.
   */
  static async getServicePriorities(page, sort, direction, filter) {
    const response = await axios({
      method: 'GET',
      url: 'service_priority',
      responseType: 'json',
      params: {
        ...createAPIParams(page || 'all', sort, direction, filter),
        include: 'roles',
      },
    });

    return response.data.data;
  }

  /**
   * @summary Hits the create ServicePriority endpoint.
   * @desc Sends the given servicePriority data to the create endpoint
   * @param  {ServicePriority}  servicePriority A {@link ServicePriority} object to be saved
   * @return {Promise}      Resolves to the returned full ServicePriority object
   */
  static async createServicePriority(servicePriority) {
    const response = await axios({
      method: 'POST',
      url: 'service_priority',
      responseType: 'json',
      data: servicePriority,
      params: {
        include: 'roles',
      },
    });

    return response.data.data;
  }

  /**
   * @summary Hits the PATCH ServicePriority endpoint.
   * @desc Sends the specified _modified_ fields to the endpoint to be saved.
   * @param  {String|Number}  servicePriorityId The ServicePriority Id to save
   * @param  {object}  modifiedFields An object with a subset of the
   * {@link ServicePriority} fields that have been modified and should be saved.
   * @return {Promise}      Resolves to the returned full ServicePriority object
   */
  static async updateServicePriority(servicePriorityId, modifiedFields) {
    const response = await axios({
      method: 'PATCH',
      url: `service_priority/${servicePriorityId}`,
      responseType: 'json',
      data: modifiedFields,
      params: {
        include: 'roles',
      },
    });

    return response.data.data;
  }

  /**
   * @summary Hits the DELETE ServicePriority endpoint.
   * @param  {String|Number}  servicePriorityId The ServicePriority Id to delete
   * @return {Promise}
   */
  static async deleteServicePriority(servicePriorityId) {
    await axios({
      method: 'DELETE',
      url: `service_priority/${servicePriorityId}`,
      responseType: 'json',
    });

    return true;
  }
}

export default ServicePriorityAPI;
