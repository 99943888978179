<template>
  <div>
    <b-input-group>
      <b-form-input
        v-bind="$attrs"
        id="password-confirmation"
        v-model.trim="passwordConfirmation"
        :disabled="disabled"
        :type="showPassword ? 'text' : 'password'"
        :state="state"
        @keydown.enter="$emit('onEnterPress')"
      />
      <b-input-group-append>
        <b-button :disabled="disabled" variant="light" @click="onShowPasswordToggle">
          <font-awesome-icon v-if="!showPassword" :icon="['far', 'eye']" />
          <font-awesome-icon v-else :icon="['far', 'eye-slash']" />
        </b-button>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import { BButton, BFormInput, BInputGroup, BInputGroupAppend } from 'bootstrap-vue';

export default {
  name: 'PasswordConfirmation',
  components: {
    BButton,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: null,
    },
  },
  emits: [
    'input',
    'onEnterPress',
  ],
  data() {
    return {
      showPassword: false,
    };
  },
  computed: {
    passwordConfirmation: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    onShowPasswordToggle() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style lang="scss">
</style>
