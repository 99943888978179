import types from '../../types/serviceType.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_SERVICE_TYPES
   * @summary Updates the `aserviceTypes` property.
   * @method
   * @param {Object} state Current state
   * @param {ServiceType[]} serviceTypes The loaded Service Types array
   */
  [m.SET_SERVICE_TYPES](state, serviceTypes) {
    state.serviceTypes = serviceTypes;
  },

  [m.SET_SERVICE_TYPES_STATUS](state, status) {
    state.serviceTypesState = status;
  },
};
