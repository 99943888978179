import Base from './Base.js';

export default function WorkOrderCostStatusCreator() {
  class WorkOrderCostStatus extends Base {}

  WorkOrderCostStatus.initEnum({
    DRAFT: {
      value: 'DRAFT',
      display: 'Draft',
    },
    PENDING: {
      value: 'PENDING',
      display: 'Pending',
    },
    APPROVED: {
      value: 'APPROVED',
      display: 'Approved',
    },
    QUERIED: {
      value: 'QUERIED',
      display: 'Queried',
    },
    REJECTED: {
      value: 'REJECTED',
      display: 'Rejected',
    },
    // CORE-1804 todo: Kind of gross, perhaps this should be a proper status?
    // Used as a pseudo-status for draft lines when WO billing status is submitted
    SUBMITTED: {
      value: 'SUBMITTED',
      display: 'Submitted',
    },
  });

  return WorkOrderCostStatus;
}
