export default {
  state: {
    /**
     * A Dashboard definition
     * @typedef {Object} Dashboard
     * @property {number} id -
     * @property {string} title -
     * @property {date} created_at -
     * @property {date} updated_at -
     */
    dashboard: () => ({
      id: null,
      title: null,
      widgets: [],
      created_at: null,
      updated_at: null,
    }),
  },

  getters: {},

  actions: {
    GET_DASHBOARD: 'GET_DASHBOARD',
    UPDATE_DASHBOARD: 'UPDATE_DASHBOARD',
    CREATE_DASHBOARD: 'CREATE_DASHBOARD',
    DELETE_DASHBOARD: 'DELETE_DASHBOARD',
  },

  mutations: {
    SET_DASHBOARDS_SORT: 'SET_DASHBOARDS_SORT',
    SET_ACTIVE_DASHBOARD: 'SET_ACTIVE_DASHBOARD',
    SET_EDIT_DASHBOARD: 'SET_EDIT_DASHBOARD',
    CLEAR_EDIT_DASHBOARD_STATE: 'CLEAR_EDIT_DASHBOARD_STATE',
    SET_ACTIVE_DASHBOARD_STATE: 'SET_ACTIVE_DASHBOARD_STATE',
    SET_ACTIVE_DASHBOARD_ERRORS: 'SET_ACTIVE_DASHBOARD_ERRORS',
    SET_ACTIVE_DASHBOARD_VALID: 'SET_ACTIVE_DASHBOARD_VALID',
    SET_ACTIVE_DASHBOARD_WIDGETS: 'SET_ACTIVE_DASHBOARD_WIDGETS',
    UPDATE_ACTIVE_DASHBOARD_MODIFIED_FIELDS: 'UPDATE_ACTIVE_DASHBOARD_MODIFIED_FIELDS',
    CLEAR_ACTIVE_DASHBOARD_MODIFIED_FIELDS: 'CLEAR_ACTIVE_DASHBOARD_MODIFIED_FIELDS',
    SET_DASHBOARDS_FILTER: 'SET_DASHBOARDS_FILTER',
  },
};
