import dayjs from "dayjs";
import Serializer from "@/services/v2/Serializer";

class DataPreparer {
  static prepare(route, config) {
    let formattedData = {};

    // reformat any dates into ISO 8601 format, which is required by our json api package
    Object.keys(config.data).forEach((value) => {
      const isDate = dayjs(config.data[value], 'YYYY-MM-DDTHH:mm:ss', true).isValid();
      formattedData[`${value}`] = isDate
        ? config.data[value].endsWith('Z')
          ? config.data[value]
          : dayjs(config.data[value]).toISOString()
        : config.data[value];
    });

    if (config.id) {
      formattedData.id = config.id;
    }

    return (new Serializer())
      .serialize(route, formattedData)
      .data;
  };
}

export default DataPreparer;
