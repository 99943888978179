import objectPluck from '@/util/objectPluck.js';
import APIError from '../../../errors/APIError.js';
import types from '../../types/maintainableEntity.js';
import errorTypes from '../../types/error.js';
import MaintainableEntityAPI from '../../../services/MaintainableEntityAPI.js';
import ApiStatusCreator from '../../../enums/API.js';
const API_STATUS = ApiStatusCreator();

const a = types.actions;
const m = types.mutations;
const me = errorTypes.mutations;

export default {
  /**
   * Loads MaintainableEntities
   * @param {Object} context
   * @param {string} id The MaintainableEntity Type to load
   * @param {string} filter
   * @param {string} page
   * @param {string} sort
   * @param {string} direction
   * @return {MaintainableEntity} A {@link MaintainableEntity} object.
   */
  async [a.GET_MAINTAINABLE_ENTITIES](
    { commit, state },
    { maintainableEntityType, filter, page, sort, direction },
  ) {
    try {
      commit(m.SET_MAINTAINABLE_ENTITIES, []);
      commit(m.SET_MAINTAINABLE_ENTITIES_STATE, API_STATUS.LOADING);
      const basicFilter = objectPluck(filter, 'value');
      const data = await MaintainableEntityAPI.getMaintainableEntities(
        maintainableEntityType,
        page || 1,
        sort,
        direction,
        basicFilter,
      );
      commit(m.SET_MAINTAINABLE_ENTITIES_STATE, API_STATUS.SUCCESS);
      commit(m.SET_MAINTAINABLE_ENTITIES, data?.data);
      commit(m.SET_MAINTAINABLE_ENTITIES_META, data?.meta);
    } catch (error) {
      commit(m.SET_MAINTAINABLE_ENTITIES_STATE, API_STATUS.FAILURE);
      let message = 'An error occurred while fetching these Maintainable Entities. Please try again.'
      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data?.message;
      } else {
        message = error.message || message;
      }
      commit(m.SET_MAINTAINABLE_ENTITIES_ERRORS, message);
    }
    return state.maintainableEntities;
  },

  /**
   * Loads MaintainableEntities Tree
   * @param {Object} context
   * @return {MaintainableEntity} A {@link MaintainableEntity} object.
   */
  async [a.GET_MAINTAINABLE_ENTITIES_TREE]({ commit, state }, context) {
    try {
      commit(m.SET_MAINTAINABLE_ENTITIES_TREE, []);
      commit(m.SET_MAINTAINABLE_ENTITIES_STATE, API_STATUS.LOADING);
      const data = await MaintainableEntityAPI.getMaintainableEntitiesTree(context);
      commit(m.SET_MAINTAINABLE_ENTITIES_STATE, API_STATUS.SUCCESS);
      commit(m.SET_MAINTAINABLE_ENTITIES_TREE, data?.data);
    } catch (error) {
      commit(m.SET_MAINTAINABLE_ENTITIES_STATE, API_STATUS.FAILURE);
      let message = 'An error occurred while fetching these Maintainable Entities. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data?.message;
      } else {
        message = error.message || message;
      }

      commit(m.SET_MAINTAINABLE_ENTITIES_ERRORS, message);
    }
    return state.maintainableEntities;
  },

  /**
   * Loads specific MaintainableEntity details
   * @param {Object} context
   * @param {number} id The MaintainableEntity ID to load
   * @param {string} id The MaintainableEntity Type to load
   * @return {MaintainableEntity} A {@link MaintainableEntity} object.
   */
  async [a.GET_MAINTAINABLE_ENTITY]({ commit, state }, { id, maintainableEntityType }) {
    try {
      commit(me.SET_NOT_FOUND_OR_PERMISSION_ERRORS, []);
      commit(m.SET_ACTIVE_MAINTAINABLE_ENTITY_STATE, API_STATUS.LOADING);
      const maintainableEntity = await MaintainableEntityAPI.getMaintainableEntity(
        maintainableEntityType,
        id,
      );
      maintainableEntity.created_at = new Date(maintainableEntity.created_at);
      maintainableEntity.updated_at = new Date(maintainableEntity.updated_at);
      commit(m.SET_ACTIVE_MAINTAINABLE_ENTITY, maintainableEntity);
      commit(m.SET_ACTIVE_MAINTAINABLE_ENTITY_STATE, API_STATUS.SUCCESS);
    } catch (error) {
      commit(m.SET_ACTIVE_MAINTAINABLE_ENTITY_STATE, API_STATUS.FAILURE);
      let message = 'An error occurred while fetching this Maintainable Entity. Please try again.'
      let noPermissionOrNotFoundError = '';
      if (error.response && error.response.data && error.response.data.message) {
        if (error.response.status === 404 || error.response.status === 403) {
          noPermissionOrNotFoundError = error.response.data.message;
        }
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      commit(me.SET_NOT_FOUND_OR_PERMISSION_ERRORS, noPermissionOrNotFoundError);
      throw new APIError(message);
    }

    return state.activeMaintainableEntity;
  },

  /**
   * Creates a new MaintainableEntity
   * @param {Object} context
   * @param {object} maintainableEntity A {@link MaintainableEntity} object
   * @return {MaintainableEntity} A {@link MaintainableEntity} object.
   */
  async [a.CREATE_MAINTAINABLE_ENTITY](
    { commit, state },
    { maintainableEntityType, maintainableEntity },
  ) {
    try {
      commit(m.SET_ACTIVE_MAINTAINABLE_ENTITY_ERRORS, []);
      commit(m.SET_ACTIVE_MAINTAINABLE_ENTITY_STATE, API_STATUS.SAVING);

      const maintainableEntityResponse = await MaintainableEntityAPI.createMaintainableEntity(
        maintainableEntityType,
        maintainableEntity,
      );

      commit(m.SET_ACTIVE_MAINTAINABLE_ENTITY_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_MAINTAINABLE_ENTITY, maintainableEntityResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_MAINTAINABLE_ENTITY_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while creating this Maintainable Entity. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeMaintainableEntity;
  },

  /**
   * Updates an existing MaintainableEntity
   * @param {Object} context
   * @param {object} maintainableEntity The {@link MaintainableEntity} object to update
   * @param {string} maintainableEntityType
   * @param {object} modifiedFields An object with the key/values for the fields to update.
   * @return {MaintainableEntity} A {@link MaintainableEntity} object.
   */
  async [a.UPDATE_MAINTAINABLE_ENTITY](
    { commit, state },
    { maintainableEntity, maintainableEntityType, modifiedFields },
  ) {
    try {
      commit(m.SET_ACTIVE_MAINTAINABLE_ENTITY_ERRORS, []);
      commit(m.SET_ACTIVE_MAINTAINABLE_ENTITY_STATE, API_STATUS.SAVING);

      const maintainableEntityResponse = await MaintainableEntityAPI.updateMaintainableEntity(
        maintainableEntityType,
        maintainableEntity.id,
        modifiedFields,
      );

      commit(m.SET_ACTIVE_MAINTAINABLE_ENTITY_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_MAINTAINABLE_ENTITY, maintainableEntityResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_MAINTAINABLE_ENTITY_STATE, API_STATUS.FAILURE);
      let message = 'An error occurred while updating this Maintainable Entity. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeMaintainableEntity;
  },

  /**
   * Deletes a MaintainableEntity
   * @param {Object} context
   * @param {object} maintainableEntity The {@link MaintainableEntity} object to delete
   * @param {string} maintainableEntityType The {@link MaintainableEntityType} object to delete
   * @return {MaintainableEntity} A {@link MaintainableEntity} object.
   */
  async [a.DELETE_MAINTAINABLE_ENTITY]({ commit }, { maintainableEntity, maintainableEntityType }) {
    try {
      commit(m.SET_ACTIVE_MAINTAINABLE_ENTITY_ERRORS, []);
      commit(m.SET_ACTIVE_MAINTAINABLE_ENTITY_STATE, API_STATUS.DELETING);

      await MaintainableEntityAPI.deleteMaintainableEntity(
        maintainableEntityType,
        maintainableEntity.id,
      );

      commit(m.SET_ACTIVE_MAINTAINABLE_ENTITY_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_MAINTAINABLE_ENTITY, null);
    } catch (error) {
      commit(m.SET_ACTIVE_MAINTAINABLE_ENTITY_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while deleting this Maintainable Entity. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new APIError(message);
    }

    return true;
  },
};
