<template>
  <b-badge
    class="contractor-service-priority-badge"
    :class="{ 'muted-badge': $featureFlag.WORK_ORDER_PRIORITY_FRONTEND }"
    :style="{ backgroundColor: backgroundColor }"
  >
    <span :key="name">
      {{ name }}
    </span>
  </b-badge>
</template>

<script>
import { BBadge } from 'bootstrap-vue';

export default {
  name: 'ContractorServicePriorityBadge',

  components: {
    'b-badge': BBadge,
  },

  props: {
    name: {
      type: String,
      required: true,
    },
    color: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    backgroundColor() {
      if (this.disabled) {
        return '';
      }

      if (this.$featureFlag.WORK_ORDER_PRIORITY_FRONTEND) {
        return '';
      }

      return this.color;
    },
  },
};
</script>

<style lang="scss" scoped>
.contractor-service-priority-badge {
  font-size: 90%;
  background-color: $gray-900;
  line-height: normal;
  white-space: normal;
}

.muted-badge {
  background: $gray-300;
  color: $secondary;
}
</style>
