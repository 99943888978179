import axios from 'axios';
import createAPIParams from '@/util/createAPIParams.js';
import Vue from 'vue';
/**
 * Interface to the FinancialPeriod API endpoints
 * @class
 * @exports src/services/FinancialPeriodAPI
 */
class FinancialPeriodAPI {
  /**
   * Retrieves all the Financial Periods
   * @method
   * @static
   * @return {Promise}          Promise that resolves to an aray of {@link FinancialPeriod} items.
   */
  static async getFinancialPeriods(page, sort, direction, filter) {
    const response = await axios({
      method: 'GET',
      url: 'financial_period',
      responseType: 'json',
      params: createAPIParams(page || 'all', sort, direction, filter),
    });

    return response.data.data;
  }

  /**
   * Retrieves all the Financial Period divisions
   * @method
   * @static
   * @return {Promise}          Promise that resolves to an array of FinancialPeriodDivision items.
   */
  static async getFinancialPeriodDivisions(page, sort, direction, filter) {
    const response = await axios({
      method: 'GET',
      url: 'financial_period_division',
      responseType: 'json',
      params: createAPIParams(page || 'all', sort, direction, filter),
    });

    return response.data.data;
  }

  /**
   * @summary Hits the create FinancialPeriod endpoint.
   * @desc Sends the given financialPeriod data to the create endpoint
   * @param  {FinancialPeriod}  financialPeriod A {@link FinancialPeriod} object to be saved
   * @return {Promise}      Resolves to the returned full FinancialPeriod object
   */
  static async createFinancialPeriod(financialPeriod) {
    const response = await axios({
      method: 'POST',
      url: 'financial_period',
      responseType: 'json',
      data: financialPeriod,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the PATCH FinancialPeriod endpoint.
   * @desc Sends the specified _modified_ fields to the endpoint to be saved.
   * @param  {String|Number}  financialPeriodId The FinancialPeriod Id to save
   * @param  {object}  modifiedFields An object with a subset of the
   * {@link FinancialPeriod} fields that have been modified and should be saved.
   * @return {Promise}      Resolves to the returned full FinancialPeriod object
   */
  static async updateFinancialPeriod(financialPeriodId, modifiedFields) {
    const response = await axios({
      method: 'PATCH',
      url: `financial_period/${financialPeriodId}`,
      responseType: 'json',
      data: modifiedFields,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the DELETE FinancialPeriod endpoint.
   * @param  {String|Number}  financialPeriodId The FinancialPeriod Id to delete
   * @return {Promise}
   */
  static async deleteFinancialPeriod(financialPeriodId) {
    await axios({
      method: 'DELETE',
      url: `financial_period/${financialPeriodId}`,
      responseType: 'json',
    });

    return true;
  }

  static getCurrentFinancialPeriod(financialPeriods, dayjs) {
    const parseDate = (d) => dayjs(d).toDate();
    const now = new Date();
    let found = null;
    let foundDivision = false;

    financialPeriods.forEach((fp) => {
      if (!found) {
        const start = parseDate(fp.start_date);
        const end = parseDate(fp.end_date);

        if (now > start && now <= end) {
          fp.current = true; // eslint-disable-line no-param-reassign
          found = fp;
        }
      }
    });

    // if we found one then narrow it down by marking the current division
    if (found) {
      (found.divisions || []).forEach((division) => {
        if (!foundDivision) {
          const start = parseDate(division.start_date);
          const end = parseDate(division.end_date);

          if (now > start && now <= end) {
            division.current = true; // eslint-disable-line no-param-reassign
            foundDivision = true;
          }
        }
      });
    }

    return found;
  }
}

export default FinancialPeriodAPI;
