export default {
  getters: {},

  actions: {
    GET_SERVICE_TYPES: 'GET_SERVICE_TYPES',
  },

  mutations: {
    SET_SERVICE_TYPES: 'SET_SERVICE_TYPES',
    SET_SERVICE_TYPES_STATUS: 'SET_SERVICE_TYPES_STATUS',
  },
};
