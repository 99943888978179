import APIError from '../../../errors/APIError.js';
import types from '../../types/servicePriority.js';
import ServicePriorityAPI from '../../../services/ServicePriorityAPI.js';
import ApiStatusCreator from '../../../enums/API.js';

const a = types.actions;
const m = types.mutations;
const API_STATUS = ApiStatusCreator();

export default {
  /**
   * Loads all ServicePriorities
   * @param {Object} context
   * @param {number} page The page to load. If not supplied will default to 1
   * @return {ServicePriority[]} An array of {@link ServicePriority} objects.
   */
  async [a.GET_SERVICE_PRIORITIES]({ commit, state }, { page, sort, direction, filter }) {
    commit(m.SET_SERVICE_PRIORITIES_STATUS, API_STATUS.LOADING);

    try {
      // eslint-disable-next-line max-len
      const servicePriorities = await ServicePriorityAPI.getServicePriorities(
        page,
        sort,
        direction,
        filter,
      );

      commit(m.SET_SERVICE_PRIORITIES_STATUS, API_STATUS.SUCCESS);
      commit(m.SET_SERVICE_PRIORITIES, servicePriorities);
      // commit(m.SET_SERVICE_PRIORITIES_META, servicePriorities.meta);
    } catch (error) {
      commit(m.SET_SERVICE_PRIORITIES_STATUS, API_STATUS.FAILURE);

      let message = 'An error occurred while fetching these Service Priorities. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.servicePriorities;
  },

  /**
   * Creates a new ServicePriority
   * @param {Object} context
   * @param {object} servicePriority A {@link ServicePriority} object
   * @return {ServicePriority} A {@link ServicePriority} object.
   */
  async [a.CREATE_SERVICE_PRIORITY]({ commit, state }, { servicePriority }) {
    try {
      commit(m.SET_ACTIVE_SERVICE_PRIORITY_ERRORS, []);
      commit(m.SET_ACTIVE_SERVICE_PRIORITY_STATE, API_STATUS.SAVING);

      // eslint-disable-next-line max-len
      const servicePriorityResponse = await ServicePriorityAPI.createServicePriority(
        servicePriority,
      );

      commit(m.SET_ACTIVE_SERVICE_PRIORITY_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_SERVICE_PRIORITY, servicePriorityResponse);
      commit(m.ADD_LOCAL_SERVICE_PRIORITY, servicePriorityResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_SERVICE_PRIORITY_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while creating this Service Priority. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeServicePriority;
  },

  /**
   * Updates an existing ServicePriority
   * @param {Object} context
   * @param {object} servicePriority The {@link ServicePriority} object to update
   * @param {object} modifiedFields An object with the key/values for the fields to update.
   * @return {ServicePriority} A {@link ServicePriority} object.
   */
  async [a.UPDATE_SERVICE_PRIORITY]({ commit, state }, { servicePriority, modifiedFields }) {
    try {
      commit(m.SET_ACTIVE_SERVICE_PRIORITY_ERRORS, []);
      commit(m.SET_ACTIVE_SERVICE_PRIORITY_STATE, API_STATUS.SAVING);

      // eslint-disable-next-line max-len
      const servicePriorityResponse = await ServicePriorityAPI.updateServicePriority(
        servicePriority.id,
        modifiedFields,
      );

      commit(m.SET_ACTIVE_SERVICE_PRIORITY_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_SERVICE_PRIORITY, servicePriorityResponse);
      commit(m.UPDATE_LOCAL_SERVICE_PRIORITY, servicePriorityResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_SERVICE_PRIORITY_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while updating this Service Priority. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeServicePriority;
  },

  /**
   * Deletes a ServicePriority
   * @param {Object} context
   * @param {ServicePriority} servicePriority The {@link ServicePriority} object to delete
   * @return {ServicePriority} A {@link ServicePriority} object.
   */
  async [a.DELETE_SERVICE_PRIORITY]({ commit }, { servicePriority }) {
    try {
      commit(m.SET_ACTIVE_SERVICE_PRIORITY_ERRORS, []);
      commit(m.SET_ACTIVE_SERVICE_PRIORITY_STATE, API_STATUS.DELETING);

      await ServicePriorityAPI.deleteServicePriority(servicePriority.id);

      commit(m.SET_ACTIVE_SERVICE_PRIORITY_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_SERVICE_PRIORITY, null);

      commit(m.REMOVE_LOCAL_SERVICE_PRIORITY, servicePriority);
    } catch (error) {
      commit(m.SET_ACTIVE_SERVICE_PRIORITY_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while deleting this Service Priority. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return true;
  },
};
