export default {
  getters: {},

  actions: {
    GET_DOCUMENTS: 'GET_DOCUMENTS',
    UPDATE_DOCUMENT: 'UPDATE_DOCUMENT',
    CREATE_DOCUMENT: 'CREATE_DOCUMENT',
    DELETE_DOCUMENT: 'DELETE_DOCUMENT',
  },

  mutations: {
    SET_DOCUMENTS: 'SET_DOCUMENTS',
    SET_DOCUMENTS_SORT: 'SET_DOCUMENTS_SORT',
    SET_DOCUMENTS_STATUS: 'SET_DOCUMENTS_STATUS',
    SET_DOCUMENTS_META: 'SET_DOCUMENTS_META',
    SET_DOCUMENTS_FILTER: 'SET_DOCUMENTS_FILTER',
    SET_ACTIVE_DOCUMENT: 'SET_ACTIVE_DOCUMENT',
    SET_ACTIVE_DOCUMENT_STATE: 'SET_ACTIVE_DOCUMENT_STATE',
    SET_ACTIVE_DOCUMENT_ERRORS: 'SET_ACTIVE_DOCUMENT_ERRORS',
    SET_ACTIVE_DOCUMENT_VALID: 'SET_ACTIVE_DOCUMENT_VALID',
    UPDATE_ACTIVE_DOCUMENT_MODIFIED_FIELDS: 'UPDATE_ACTIVE_DOCUMENT_MODIFIED_FIELDS',
    CLEAR_ACTIVE_DOCUMENT_MODIFIED_FIELDS: 'CLEAR_ACTIVE_DOCUMENT_MODIFIED_FIELDS',
  },
};
