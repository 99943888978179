/* eslint-disable no-param-reassign */
function writeValue(value, jsonData, key, formData, prefix) {
  const path = prefix === '' ? key : `${prefix}[${key}]`;
  if (Array.isArray(value)) {
    jsonData[key] = [];
    value.forEach((data, subkey) => {
      writeValue(data, jsonData[key], subkey, formData, path);
    });
  } else if (
    typeof value === 'object' &&
    !!value &&
    !(value instanceof File) &&
    !(value instanceof Blob)
  ) {
    jsonData[key] = {};
    Object.entries(value).forEach(([subkey, data]) => {
      writeValue(data, jsonData[key], subkey, formData, path);
    });
  } else if (value instanceof File || value instanceof Blob) {
    formData.append(path, value);
  } else {
    jsonData[key] = value;
  }
}

/**
 * Converts a JS object into a FormData object
 * @method
 * @static
 * @param {Object} sourceData The object to convert
 * @return {FormData} The populated form data object
 */
export default function createFormData(sourceData) {
  const formData = new FormData();
  const jsonData = {};

  Object.entries(sourceData).forEach(([key, data]) => {
    writeValue(data, jsonData, key, formData, '');
  });

  if (Object.keys(jsonData).length > 0) {
    formData.append('json_data', JSON.stringify(jsonData));
  }

  return formData;
}
