import axios from 'axios';
import createAPIParams from '@/util/createAPIParams.js';

/**
 * Interface to the Contractor API endpoints
 * @class
 * @exports src/services/ContractorAPI
 */
class ContractorAPI {
  /**
   * Retrieves the specified Contractor's details
   * @method
   * @static
   * @param  {number}  contractorId    The contractor id to load
   * @return {Promise}          Promise that resolves to the {@link Contractor} details.
   */
  static async getContractor(contractorId) {
    const response = await axios({
      method: 'GET',
      url: `contractor/${contractorId}`,
      responseType: 'json',
    });

    return response.data.data;
  }

  /**
   * Retrieves all Contractors for the given page
   * @param {Number} page The page to load
   * @param {String} sort The field to sort on
   * @param {String} direction The sort direction. Either 'ASC' or 'DESC'. Defaults to ASC.
   * @param {Object} filter An object with key value pairs of filter values.
   * @return {Promise} Promise that resolves to the api response.
   * In format: { contractor: {object[]}, meta: {object}, links: {object} }
   */
  static async getContractors(page, sort, direction, filter) {
    const response = await axios({
      method: 'GET',
      url: 'contractor',
      responseType: 'json',
      params: createAPIParams(page, sort, direction, filter),
    });

    return response.data;
  }

  /**
   * @summary Hits the create Contractor endpoint.
   * @desc Sends the given contractor data to the create endpoint
   * @param  {Contractor}  contractor A {@link Contractor} object to be saved
   * @return {Promise}      Resolves to the returned full Contractor object
   */
  static async createContractor(contractor) {
    const response = await axios({
      method: 'POST',
      url: 'contractor',
      responseType: 'json',
      data: contractor,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the PATCH Contractor endpoint.
   * @desc Sends the specified _modified_ fields to the endpoint to be saved.
   * @param  {String|Number}  contractorId The Contractor Id to save
   * @param  {object}  modifiedFields An object with a subset of the
   * {@link Contractor} fields that have been modified and should be saved.
   * @return {Promise}      Resolves to the returned full Contractor object
   */
  static async updateContractor(contractorId, modifiedFields) {
    const response = await axios({
      method: 'PATCH',
      url: `contractor/${contractorId}`,
      responseType: 'json',
      data: modifiedFields,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the DELETE Contractor endpoint.
   * @param  {String|Number}  contractorId The Contractor Id to delete
   * @return {Promise}
   */
  static async deleteContractor(contractorId) {
    await axios({
      method: 'DELETE',
      url: `contractor/${contractorId}`,
      responseType: 'json',
    });

    return true;
  }

  // invite contractors
  static async inviteContractors(contractors) {
    const response = await axios({
      method: 'POST',
      url: 'contractor/invite',
      responseType: 'json',
      data: contractors,
    });

    return response.data.data;
  }
}

export default ContractorAPI;
