<template>
  <span class="breadcrumb-item">
    <span
      v-if="route.meta.disabled"
      :key="route.meta.bcLinkText"
      class="disabled"
      :translate="route.meta.translate === false ? 'no' : 'yes'"
    >
      {{ route.meta.bcLinkText }}
    </span>

    <router-link v-if="!isBcDynamic && !route.meta.disabled" :to="to">
      <span
        :key="route.meta.bcLinkText"
        :translate="route.meta.translate === false ? 'no' : 'yes'"
      >
        {{ route.meta.bcLinkText }}
      </span>
    </router-link>

    <router-link v-if="isBcDynamic && !route.meta.disabled && hasResolvedParams" :to="to">
      <template v-if="value">
        <span
          :key="formattedValue"
          :translate="route.meta.translate === false ? 'no' : 'yes'"
        >
          {{ formattedValue }}
        </span>
      </template>
      <template v-if="!value">
        <b-spinner variant="" class="spinner align-middle" />
        <span :key="loadingText" class="sr-only">{{ loadingText }}</span>
      </template>
    </router-link>

    <router-link v-if="isBcDynamic && !route.meta.disabled && !hasResolvedParams" :to="to">
      <span>{{ formattedValue }}</span>
    </router-link>

    <font-awesome-icon v-if="!isLast" class="arrow" :icon="['far', 'chevron-right']" />
  </span>
</template>

<script>
import { BSpinner } from 'bootstrap-vue';

export default {
  name: 'BreadCrumbItem',
  components: {
    'b-spinner': BSpinner,
  },
  props: {
    route: {
      type: Object,
      default: () => {},
    },
    defaultQuery: {
      type: Object,
      default: () => {},
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    entity: {
      type: Object,
      default: () => null,
    },
  },
  computed: {
    formattedValue() {
      return typeof this.route.meta.bcLinkText == 'function' ?
        this.route.meta.bcLinkText(this.value, this.$t) :
          this.$t(this.route.meta.bcLinkText, this.value)
    },
    hasResolvedParams() {
      return Object.values(this.$route.params).some((param) => param !== undefined && param !== null && param !== '');
    },
    isBcDynamic() {
      if (typeof this.route.meta.bcDynamic === 'function') {
        return this.route.meta.bcDynamic(this.entity);
      }
      return !!this.route.meta.bcDynamic;
    },
    loadingText() {
      const loadingText = this.$t(this.route.meta.bcLoadingText, this.value);

      return loadingText || this.$t('common.forms.loading');
    },
    to() {
      let to;
      if (this.route.meta.bcLinkPathOverride) {
        const routeOverride = this.route.meta.bcLinkPathOverride(this.value);

        to = {
          name: routeOverride.name,
          query: routeOverride.query
        }
      } else if (this.route.name) {
        to = {
          name: this.route.name,
          query: {
            ...this.defaultQuery,
          },
          params: {
            id: this.$route.params.id,
          },
        };
      } else {
        to = this.route.path.replace(/:id/, this.$route.params.id);
      }

      return to;
    },
  },
  beforeCreate() {
    this.$options.computed.value = () => {
      if (this.route.meta.bcGetter) {
        return this.$store.getters[this.route.meta.bcGetter];
      }
      if (this.entity) {
        return this.entity;
      }
      return null;
    };
  },
};
</script>

<style lang="scss" scoped>
.breadcrumb-item {
  a, span.disabled {
    color: #313a46;
    font-weight: bold;
    font-size: 1rem;
  }

  .arrow {
    margin: 0 0.5rem;
    color: #b4b4b4;
  }

  .spinner {
    width: 1rem;
    height: 1rem;
    border-width: 0.1rem;
    color: #313a46;
  }
}

@include media-breakpoint-up(md) {
  .breadcrumb-item {
    a, span.disabled {
      font-size: 1.5rem;
    }
  }
}
</style>
