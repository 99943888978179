import APIError from '../../../errors/APIError.js';
import types from '../../types/contractor.js';
import ContractorAPI from '../../../services/ContractorAPI.js';
import errorTypes from '../../types/error.js';
import ApiStatusCreator from '../../../enums/API.js';
const API_STATUS = ApiStatusCreator();

const a = types.actions;
const m = types.mutations;
const me = errorTypes.mutations;

const isCustomPOEnabled = (contractorObj) =>
  contractorObj.po_number_next !== null || contractorObj.po_number_prefix !== null;

export default {
  /**
   * Loads specific Contractor details
   * @param {Object} context
   * @param {number} id The Contractor ID to load
   * @return {Contractor} A {@link Contractor} object.
   */
  async [a.GET_CONTRACTOR]({ commit, state }, { id }) {
    try {
      commit(m.SET_ACTIVE_CONTRACTOR, null);
      commit(m.SET_ACTIVE_CONTRACTOR_STATE, API_STATUS.LOADING);

      const contractor = await ContractorAPI.getContractor(id);
      contractor.custom_po_enabled = isCustomPOEnabled(contractor);
      contractor.created_at = new Date(contractor.created_at);
      contractor.updated_at = new Date(contractor.updated_at);

      commit(m.SET_ACTIVE_CONTRACTOR, contractor);
      commit(m.SET_ACTIVE_CONTRACTOR_STATE, API_STATUS.SUCCESS);
    } catch (error) {
      commit(m.SET_ACTIVE_CONTRACTOR_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while fetching this Contractor. Please try again'
      let noPermissionOrNotFoundError = '';
      if (error.response && error.response.data && error.response.data.message) {
        if (error.response.status === 404 || error.response.status === 403) {
          noPermissionOrNotFoundError = error.response.data.message;
        }
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }
      commit(m.SET_ACTIVE_CONTRACTOR_ERRORS, message);
      commit(me.SET_NOT_FOUND_OR_PERMISSION_ERRORS, noPermissionOrNotFoundError);
    }

    return state.contractors;
  },

  /**
   * Creates a new Contractor
   * @param {Object} context
   * @param {object} contractor A {@link Contractor} object
   * @return {Contractor} A {@link Contractor} object.
   */
  async [a.CREATE_CONTRACTOR]({ commit, state }, { contractor }) {
    try {
      commit(m.SET_ACTIVE_CONTRACTOR_ERRORS, []);
      commit(m.SET_ACTIVE_CONTRACTOR_STATE, API_STATUS.SAVING);

      const contractorResponse = await ContractorAPI.createContractor(contractor);

      commit(m.SET_ACTIVE_CONTRACTOR_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_CONTRACTOR, {
        ...contractorResponse,
        custom_po_enabled: isCustomPOEnabled(contractorResponse),
      });
    } catch (error) {
      commit(m.SET_ACTIVE_CONTRACTOR_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while creating this Contractor. Please try again';

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeContractor;
  },

  /**
   * Updates an existing Contractor
   * @param {Object} context
   * @param {object} contractor The {@link Contractor} object to update
   * @param {object} modifiedFields An object with the key/values for the fields to update.
   * @return {Contractor} A {@link Contractor} object.
   */
  async [a.UPDATE_CONTRACTOR]({ commit, state }, { contractor, modifiedFields }) {
    try {
      commit(m.SET_ACTIVE_CONTRACTOR_ERRORS, []);
      commit(m.SET_ACTIVE_CONTRACTOR_STATE, API_STATUS.SAVING);

      const copy = { ...modifiedFields };
      if (copy.custom_po_enabled === false) {
        copy.po_number_next = null;
        copy.po_number_prefix = null;
      }
      const contractorResponse = await ContractorAPI.updateContractor(contractor.id, copy);

      commit(m.SET_ACTIVE_CONTRACTOR_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_CONTRACTOR, {
        ...contractorResponse,
        custom_po_enabled:
          modifiedFields.custom_po_enabled || isCustomPOEnabled(contractorResponse),
      });
    } catch (error) {
      commit(m.SET_ACTIVE_CONTRACTOR_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while updating this Contractor. Please try again';

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeContractor;
  },

  /**
   * Deletes a Contractor
   * @param {Object} context
   * @param {object} contractor The {@link Contractor} object to delete
   * @return {Contractor} A {@link Contractor} object.
   */
  async [a.DELETE_CONTRACTOR]({ commit }, { contractor }) {
    try {
      commit(m.SET_ACTIVE_CONTRACTOR_ERRORS, []);
      commit(m.SET_ACTIVE_CONTRACTOR_STATE, API_STATUS.DELETING);

      await ContractorAPI.deleteContractor(contractor.id);

      commit(m.SET_ACTIVE_CONTRACTOR_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_CONTRACTOR, null);
    } catch (error) {
      commit(m.SET_ACTIVE_CONTRACTOR_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while deleting this Contractor. Please try again';

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return true;
  },
};
