<template>
  <div class="cell">
    <div class="d-flex flex-row">
      <div class="d-flex flex-column mr-2 text-muted">
        <div>Label:</div>
        <div>Model:</div>
        <div>Manufacturer:</div>
        <div>Type:</div>
        <div>Location:</div>
      </div>
      <div class="d-flex flex-column">
        <div>
          <Chip style="max-height: 1rem" variant="secondary">
            <span
              v-if="asset.label"
              :key="asset.label"
              style="font-size: 0.8rem"
              translate="no"
            >
              #{{ asset.label }}
            </span>
            <span v-else style="font-size: 0.8rem">Unknown</span>
          </Chip>
        </div>
        <div>
          <span
            :key="asset.assetModel.name"
            class="text-bold"
            translate="no"
          >
            {{ asset.assetModel.name }}
          </span>
        </div>
        <div>
          <span
            v-if="asset.assetModel.assetManufacturer"
            :key="asset.assetModel.assetManufacturer?.name"
            translate="no"
          >
            {{ asset.assetModel.assetManufacturer?.name }}
          </span>
          <span v-else>Unknown</span>
        </div>
        <div><span :key="composedPath(asset)">{{ composedPath(asset) }}</span></div>
        <div>
          <span :key="asset.maintainableEntity.path">
            {{ asset.maintainableEntity.path }}
          </span>
        </div>
        <div v-if='includeBadges' class="d-flex mt-3">
          <small :key="asset.assetStatus.status" class="status mr-2" :style="{ backgroundColor: asset.assetStatus.color}">
            <span class="font-weight-light mr-1">{{ $t('views.asset.fields.status')}}:</span>
            {{ asset.assetStatus.status }}
          </small>
          <small :key="asset.conditionGrade.name" class="condition" :style="{ backgroundColor: asset.conditionGrade.color}">
            <span class="font-weight-light mr-1">{{ $t('views.asset.fields.condition')}}:</span>
            {{ asset.conditionGrade.name }}
          </small>
          <div v-if="includeWarranty && assetUnderWarranty" class="d-inline-block">
            <font-awesome-icon
              :id="`warranty-icon${asset.id}`"
              class="ml-2 fa-lg align-middle"
              :icon="['far', 'file-certificate']"
              @click.stop
            />
            <b-popover  :target="`warranty-icon${asset.id}`" triggers="hover">
              <div style="padding: 0.5rem">
                <div v-if="isInPartsWarranty" :key="asset.warranty_parts">
                  {{ $t('views.work-orders.WorkOrderEdit.singleAsset.partsWarrantyExpires') }}:
                  {{ $formatDate(asset.warranty_parts) }}
                </div>
                <div v-if="isInLabourWarranty" :key="asset.warranty_labour">
                  {{ $t('views.work-orders.WorkOrderEdit.singleAsset.labourWarrantyExpires') }}:
                  {{ $formatDate(asset.warranty_labour) }}
                </div>
              </div>
            </b-popover>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {BPopover} from 'bootstrap-vue';
import Chip from 'primevue/chip';

export default {
  name: "AssetDetails",
  components: {
    BPopover,
    Chip
  },
  props: {
    asset: {
      required: true,
      type: Object
    },
    includeBadges: {
      type: Boolean,
      default() {
        return false
      }
    },
    includeWarranty: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  computed: {
    assetUnderWarranty() {
      return this.isInLabourWarranty || this.isInPartsWarranty;
    },

    isInLabourWarranty() {
      return new Date(this.asset.warranty_labour) >= new Date();
    },

    isInPartsWarranty() {
      return new Date(this.asset.warranty_parts) >= new Date();
    },
  },
  methods: {
    composedPath(item) {
      return `${item.assetModel.assetType?.assetCategory?.name} > ${item.assetModel.assetType?.name}`;
    },
  }
}
</script>

<style lang="scss" scoped>
.cell {
  padding: 0.75rem !important;
}

.condition, .status {
  padding: 0.3rem 0.5rem;
  color: #000;
  background-color: #ececec;
  border-radius: 5px;
}
</style>
