export default {
  getters: {},

  actions: {
    GET_SUPPLIERS: 'GET_SUPPLIERS',
    UPDATE_SUPPLIER: 'UPDATE_SUPPLIER',
    CREATE_SUPPLIER: 'CREATE_SUPPLIER',
    DELETE_SUPPLIER: 'DELETE_SUPPLIER',
  },

  mutations: {
    SET_SUPPLIERS: 'SET_SUPPLIERS',
    SET_SUPPLIERS_STATUS: 'SET_SUPPLIERS_STATUS',
    SET_SUPPLIERS_META: 'SET_SUPPLIERS_META',
    SET_SUPPLIERS_FILTER: 'SET_SUPPLIERS_FILTER',

    REMOVE_LOCAL_SUPPLIER: 'REMOVE_LOCAL_SUPPLIER',
    ADD_LOCAL_SUPPLIER: 'ADD_LOCAL_SUPPLIER',
    UPDATE_LOCAL_SUPPLIER: 'UPDATE_LOCAL_SUPPLIER',

    SET_ACTIVE_SUPPLIER: 'SET_ACTIVE_SUPPLIER',
    SET_ACTIVE_SUPPLIER_STATE: 'SET_ACTIVE_SUPPLIER_STATE',
    SET_ACTIVE_SUPPLIER_ERRORS: 'SET_ACTIVE_SUPPLIER_ERRORS',
    SET_ACTIVE_SUPPLIER_VALID: 'SET_ACTIVE_SUPPLIER_VALID',
    UPDATE_ACTIVE_SUPPLIER_MODIFIED_FIELDS: 'UPDATE_ACTIVE_SUPPLIER_MODIFIED_FIELDS',
    CLEAR_ACTIVE_SUPPLIER_MODIFIED_FIELDS: 'CLEAR_ACTIVE_SUPPLIER_MODIFIED_FIELDS',
  },
};
