/**
 * Converts Bytes into an appropriate unit
 * @param { Number } bytes The number of bytes to convert
 * @param { Number } decimals Number of decimals to show
 * @returns {Object} {{unit: string, size: number}}
 */
export default function bytesToFormattedSize(bytes, decimals = 2) {
  if (bytes === 0) {
   return {
      size: 0,
      unit: 'Bytes',
    };
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return {
    size: parseFloat((bytes / k ** i).toFixed(dm)),
    unit: sizes[i],
  };
}
