import axios from 'axios';

/**
 * Interface to the MediaAPI endpoints
 * @class
 * @exports src/services/MediaAPI
 */
class MediaAPI {
  /**
   * Deletes the given Media ID
   * @method
   * @static
   * @param {number} id The Media id to delete
   * @return {Promise}          Promise that resolves to true if successful
   */
  static async deleteMedia(id) {
    await axios({
      method: 'DELETE',
      url: `media/${id}`,
      responseType: 'json',
    });

    return true;
  }
}

export default MediaAPI;
