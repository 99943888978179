import PostConfig from "@/services/v2/PostConfig";

class GetConfig extends PostConfig {
  constructor(id) {
    super();

    this.id = id;
  }
}

export default GetConfig;
