import types from '../../types/site.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_SITES
   * @summary Updates the `sites` property.
   * @method
   * @param {Object} state Current state
   * @param {User[]} sites The loaded Sites array
   */
  [m.SET_SITES](state, sites) {
    state.sites = sites;
  },

  [m.SET_SITES_STATUS](state, status) {
    state.sitesStatus = status;
  },

  /**
   * @name m SET_SITES_SORT
   * @summary Updates the `sort` and `direction` properties
   * @method
   * @param {Object} state Current state
   * @param {Object} data The sort data (`sort` and `direction`)
   */
  [m.SET_SITES_SORT](state, { sort, direction }) {
    state.sort = sort;
    state.sortDirection = direction;
  },

  /**
   * @name m SET_SITES_FILTER
   * @summary Updates the `filter` property which holds the collection of filters currently active
   * on the SITES collection
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The filters to be applied
   */
  [m.SET_SITES_FILTER](state, filter) {
    state.filter = filter;
  },

  /**
   * @name m SET_ACTIVE_SITE
   * @summary Updates the `activeSite` and resets the
   * `activeSiteModifiedFields` & `activeSiteErrors` properties
   * @method
   * @param {Object} state Current state
   * @param {Site} site The new Active {@link Site}
   */
  [m.SET_ACTIVE_SITE](state, site) {
    state.activeSite = site;
    state.activeSiteModifiedFields = !site || site.id ? {} : { ...site };
    state.activeSiteErrors = [];
  },

  /**
   * @name m SET_ACTIVE_SITE_STATE
   * @summary Updates the `activeSiteState` property which holds the current status of the
   * based on the API enum
   * @method
   * @param {Object} state Current state
   * @param {enums/API} activeSiteState The current activeSiteState (e.g. loading, success, failure)
   */
  [m.SET_ACTIVE_SITE_STATE](state, activeSiteState) {
    state.activeSiteState = activeSiteState;
  },

  /**
   * @name m SET_ACTIVE_SITE_ERRORS
   * @summary Updates the `activeSiteErrors` property which holds any errors related to activeSite
   * @method
   * @param {Object} state Current state
   * @param {string[]} errors An array of error messages
   */
  [m.SET_ACTIVE_SITE_ERRORS](state, errors) {
    state.activeSiteErrors = errors;
  },

  /**
   * @name m SET_ACTIVE_SITE_VALID
   * @summary Updates the `activeSiteValid` property which holds a boolean for the validation status
   * of the `activeSite`.
   * @method
   * @param {Object} state Current state
   * @param {boolean} valid True if the `activeSite` is valid.
   */
  [m.SET_ACTIVE_SITE_VALID](state, valid) {
    state.activeSiteValid = valid;
  },

  /**
   * @name m UPDATE_ACTIVE_SITE_MODIFIED_FIELDS
   * @summary Adds the given field/value pair to the `activeSiteModifiedFields` collection, which
   * will be sent as a PATCH to the API. If the given `value` equals the one on the `activeSite`
   * object (i.e. it hasn't actually changed, or has been reverted back to it's original) then we
   * remove that field from the modifiedFields collection
   * @method
   * @param {Object} state Current state
   * @param {object} payload Object containing a `field` and `value` properties.
   */
  [m.UPDATE_ACTIVE_SITE_MODIFIED_FIELDS](state, { field, value }) {
    // if the value matches the existing one then we remove the field from the modifiedFields obj
    if (state.activeSite && state.activeSite[field] === value) {
      delete state.activeSiteModifiedFields[field];
    } else {
      state.activeSiteModifiedFields[field] = value;
    }
  },

  /**
   * @name m CLEAR_ACTIVE_SITE_MODIFIED_FIELDS
   * @summary Empties the `activeSiteModifiedFields` object. Used to ensure we're on a clean slate
   * after navigating to a Site Display screen,
   * @method
   * @param {Object} state Current state
   */
  [m.CLEAR_ACTIVE_SITE_MODIFIED_FIELDS](state) {
    state.activeSiteModifiedFields = {};
  },

  /**
   * @name m REMOVE_LOCAL_SITE
   * @summary Removes the given site from the `sites` array. This is used to prevent a full reload
   * being needed after deleting a Site.
   * @method
   * @param {Object} state Current state
   * @param {object} site The Site to remove.
   */
  [m.REMOVE_LOCAL_SITE](state, site) {
    const index = state.sites.findIndex((u) => u.id === site.id);

    if (index >= 0) {
      state.sites.splice(index, 1);
    }
  },

  /**
   * @name m ADD_LOCAL_SITE
   * @summary Adds the given site to the `sites` array. This is used to prevent a full reload
   * being needed after adding a Site.
   * @method
   * @param {Object} state Current state
   * @param {object} site The Site to add.
   */
  [m.ADD_LOCAL_SITE](state, site) {
    state.sites.push(site);
  },
};
