import axios from 'axios';
import createAPIParams from '@/util/createAPIParams.js';

/**
 * Interface to the Notification API endpoints
 * @class
 * @exports src/services/NotificationAPI
 */
class NotificationAPI {
  /**
   * Retrieves all the Notifications
   * @method
   * @static
   * @return {Promise}          Promise that resolves to an aray of {@link Notifications} items.
   */
  static async getNotifications(page, sort, direction) {
    const response = await axios({
      method: 'GET',
      url: 'notification_scheme',
      responseType: 'json',
      params: createAPIParams(page || 'all', sort, direction),
    });

    return response.data.data;
  }

  /**
   * @summary Hits the create Notification endpoint.
   * @desc Sends the given notification data to the create endpoint
   * @param  {Notification}  notification A {@link Notification} object to be saved
   * @return {Promise}      Resolves to the returned full Notification object
   */
  static async createNotification(notification) {
    const response = await axios({
      method: 'POST',
      url: 'notification_scheme',
      responseType: 'json',
      data: notification,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the PATCH Notification endpoint.
   * @desc Sends the specified _modified_ fields to the endpoint to be saved.
   * @param  {String|Number}  notificationId The Notification Id to save
   * @param  {object}  modifiedFields An object with a subset of the
   * {@link Notification} fields that have been modified and should be saved.
   * @return {Promise}      Resolves to the returned full Notification object
   */
  static async updateNotification(notificationId, modifiedFields) {
    const response = await axios({
      method: 'PATCH',
      url: `notification_scheme/${notificationId}`,
      responseType: 'json',
      data: modifiedFields,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the DELETE Notification endpoint.
   * @param  {String|Number}  notificationId The Notification Id to delete
   * @return {Promise}
   */
  static async deleteNotification(notificationId) {
    await axios({
      method: 'DELETE',
      url: `notification_scheme/${notificationId}`,
      responseType: 'json',
    });

    return true;
  }
}

export default NotificationAPI;
