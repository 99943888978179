export default {
  /**
   * @name g getActiveSite
   * @desc Returns the `activeSite` object
   * @param  {object}  state Current state
   * @return {object}
   */
  getActiveSite(state) {
    return state.activeSite;
  },

  /**
   * @name g isActiveSitePhantom
   * @desc Returns true if the activeSite is 'phantom' (i.e. has not been saved).
   * @param  {object}  state Current state
   * @return {Boolean}
   */
  isActiveSitePhantom(state) {
    return !(state.activeSite && state.activeSite.id);
  },
};
