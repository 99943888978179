class PostConfig {
  constructor() {
    this.fields = {};
    this.includes = [];
    this.meta = {};
    this.withCount = '';
    this.data = {};
  }

  setMeta(meta) {
    this.meta = meta;

    return this;
  }

  setData(data) {
    this.data = data;

    return this;
  }

  setFields(fields) {
    this.fields = fields;

    return this;
  }

  setIncludes(includes) {
    this.includes = includes;

    return this;
  }

  setWithCount(fields) {
    this.withCount = fields;

    return this;
  }
}

export default PostConfig;
