export default {
  /**
   * @name g isActiveRolePhantom
   * @desc Returns true if the activeRole is 'phantom' (i.e. has not been saved).
   * @param  {object}  state Current state
   * @return {Boolean}
   */
  isActiveRolePhantom(state) {
    return !(state.activeRole && state.activeRole.id);
  },
};
