/**
 * A Document definition
 * @typedef {Object} Document
 * @property {number} id -
 * @property {string} name -
 * @property {string} created_at -
 * @property {string} updated_at -
 */

export default {
  documents: [],
  documentsStatus: null,
  filter: {},
  sort: 'updated_at',
  sortDirection: 'DESC',
  meta: {},
  activeDocument: null,
  activeDocumentState: null,
  activeDocumentErrors: [],
  activeDocumentValid: false,
  activeDocumentModifiedFields: {},
};
