export default {
  /**
   * @name g getActiveRequestForService
   * @desc Returns the `activeRequestForService` object
   * @param  {object}  state Current state
   * @return {object}
   */
  getActiveRequestForService(state) {
    return state.activeRequestForService;
  },

  /**
   * @name g isActiveRequestForServicePhantom
   * @desc Returns true if the activeRequestForService is 'phantom' (i.e. has not been saved).
   * @param  {object}  state Current state
   * @return {Boolean}
   */
  isActiveRequestForServicePhantom(state) {
    return !(state.activeRequestForService && state.activeRequestForService.id);
  },
};
