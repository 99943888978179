/**
 * Contractor Definition
 * @typedef {Object} Contractor
 * @property {number} id
 * @property {string} name
 * @property {string} account
 * @property {string} address
 * @property {string} city
 * @property {string} county
 * @property {object} country (id, name)
 * @property {string} telephone
 * @property {string} postcode
 * @property {string} email
 * @property {string} po_number_prefix
 * @property {string} po_number_next
 * @property {string} notes
 * @property {number} internal
 * @property {boolean} enabled
 * @property {string} created_at
 * @property {string} updated_at
 *
 */
export default {
  sort: 'name',
  sortDirection: 'ASC',
  enabled: [
    { id: true, name: 'Yes' },
    { id: false, name: 'No' },
  ],
  enabledFilter: [
    { id: null, name: 'All' },
    { id: true, name: 'Yes' },
    { id: false, name: 'No' },
  ],
  site_groups: [
    { value: 1, text: 'Manchester' },
    { value: 2, text: 'London' },
  ],
  meta: {},
  activeContractor: null,
  activeContractorState: null,
  activeContractorErrors: [],
  activeContractorValid: false,
  activeContractorModifiedFields: {},
};
