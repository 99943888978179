<template>
  <div class="login">
    <b-form @submit.prevent="onSubmit">
      <div class="fields-wrapper">
        <b-alert v-if="errors && errors.length > 0" variant="danger" show>
          <h4 class="alert-heading">
            {{ $t('common.forms.errorTitle') }}
          </h4>
          <ul class="list-unstyled mb-0">
            <li v-for="(error, index) in errors" :key="index">
              {{ error }}
            </li>
          </ul>
        </b-alert>

        <FormGroup
          v-if="requireOldPassword"
          v-slot="slotProps"
          :label="$t('components.auth.login.password.oldPassword')"
          :field="v$.oldPassword"
        >
          <Password
            v-model="v$.oldPassword.$model"
            :show-instructions="false"
            :show-feedback="false"
            :show-strength="false"
            :disabled="isLoading"
            @onEnterPress="$emit('onEnterPress')"
          />
        </FormGroup>

        <FormGroup
          v-slot="slotProps"
          :label="$t('components.auth.login.password.newPassword')"
          :field="v$.password"
        >
          <Password
            v-model="v$.password.$model"
            v-model:valid="passwordValid"
            :disabled="isLoading"
            @onEnterPress="$emit('onEnterPress')"
          />
        </FormGroup>

        <FormGroup
          v-slot="slotProps"
          :label="$t('components.auth.login.password.passwordConfirmation')"
          :field="v$.confirmPassword"
        >
          <PasswordConfirmation
            v-model="v$.confirmPassword.$model"
            :state="v$.confirmPassword.$dirty ? !v$.confirmPassword.$invalid : null"
            :disabled="isLoading"
            @onEnterPress="$emit('onEnterPress')"
          />
        </FormGroup>
      </div>
    </b-form>
  </div>
</template>

<script>
import {BForm, BAlert} from 'bootstrap-vue';
import {required, sameAs} from '@vuelidate/validators';
import Password from '@/ux/form/Password.vue';
import types from '@/store/types/authorization.js';
import AuthorizationAPI from '@/services/AuthorizationAPI.js';
import useVuelidate from '@vuelidate/core';
import PasswordConfirmation from "@/ux/form/PasswordConfirmation.vue";
import FormGroup from "@/ux/form/FormGroup.vue";

export default {
  name: 'ChangePassword',
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    FormGroup, PasswordConfirmation,
    BForm,
    BAlert,
    Password,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    requireOldPassword: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      oldPassword: '',
      password: '',
      confirmPassword: '',
      errors: [],
      success: null,
      isLoading: false,
      passwordValid: false,
    };
  },
  validations() {
    const validations = {
      // we keep the password validation here for FormGroup to work, but the actual validation is done within the <Password> component
      password: { required },
      confirmPassword: { required, sameAsPassword: sameAs(this.password) },
    }

    if (this.requireOldPassword) {
      validations.oldPassword = {
        required,
      };
    }

    return validations;
  },
  computed: {
    validationState() {
      return this.passwordValid
        && !this.v$.$error
        && !this.v$.$invalid;
    },
  },
  watch: {
    validationState: function emitValidationChange() {
      this.$emit('validation', this.validationState);
    },
    isLoading() {
      this.$emit('loading', this.isLoading);
    },
  },
  methods: {
    async onSubmit() {
      this.isLoading = true;

      AuthorizationAPI.changePassword(this.userId, this.password, this.oldPassword)
          .then(() => {
            this.errors = [];
            this.isLoading = false;
            this.$store.commit(types.mutations.SET_SESSION_STATUS, this.$enums.SESSION.LOGGED_IN);
            this.$emit('passwordchanged');
          })
          .catch((error) => {
            this.success = '';
            this.errors =
                typeof error.response.data.message === 'string'
                    ? [error.response.data.message]
                    : error.response.data.message;
            this.isLoading = false;
          });
    },
  },
};
</script>

<style lang="scss" scoped>
.field-label {
  margin-right: 0.2rem;
}

.field-input {
  margin-top: 0.5rem;
}

.btn-placeholder {
  width: 4.5rem;
}

.modal-body {
  display: flex;
  flex-direction: column;

  .fields-wrapper {
    flex: 1;
  }

  .bottom-toolbar {
    padding: 1rem 1rem 0;
  }
}
</style>
