import store from '@/store/index.js';

const loadStoreModule = async (name) => {
  return import(/* @vite-ignore */`@/store/modules/${name}/index.js`).then((module) => {
    store.registerModule(name, module.default, {});
  });
};

export default async (storeModules) => {
  const promises = storeModules
    .filter((m) => !store._modules.get([m]))
    .map(async (m) => loadStoreModule(m));

  await Promise.all(promises);
};
