import cloneDeep from 'lodash/cloneDeep';

export default {
  install(app) {
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$filterMaintainableEntityTree = (tree, retainLocationId) => {
      if (!tree) {
        return [];
      }

      const clone = cloneDeep(tree);

      // determines a path of ids, i.e. [1, 2, 8] which describes the path
      // to the active 'current location'. This is done so that we can prevent
      // this path from being removed if any part of the path(s) are disabled
      // since we want to allow the user to re-select the existing path.
      // eslint-disable-next-line consistent-return
      function buildLocationPath(item, path) {
        if (item.id === retainLocationId) {
          return path;
        }

        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < item.children?.length || 0; i++) {
          const clonedPath = cloneDeep(path);
          clonedPath.push(item.children[i].id);
          const locationPath = buildLocationPath(item.children[i], clonedPath);

          if (locationPath) {
            return locationPath;
          }
        }
      }

      const currentPath = retainLocationId ? buildLocationPath(clone, [clone.id]) : [];

      function handle(item, parent, index, forceDeleteChildren) {
        if (!item.enabled || forceDeleteChildren) {
          // eslint-disable-next-line no-param-reassign
          forceDeleteChildren = true;
          // only delete the child if it is not the root node and doesn't
          // exist in the current location path
          if (parent && currentPath.indexOf(item.id) === -1) {
            parent.children.splice(index, 1);
          }
        }

        // work through the children recursively, backwards, so that if
        // a child is removed it doesn't affect the indexing for the
        // following checks on the parent.children list

        // eslint-disable-next-line no-plusplus
        for (let i = item.children?.length - 1; i >= 0; i--) {
          handle(item.children[i], item, i, forceDeleteChildren);
        }
      }
      // work through the tree and attempt to remove anything that is disabled
      // either deterministically based on whether the parent is deleted
      // or explicitly based on whether the child is deleted, make an effort
      // to hold onto the current path so the user always has at least something
      // to select for fully disabled trees
      if (clone?.children) {
        handle(clone);
      }

      return clone.children;
    };
  },
};
