import axios from 'axios';
import createAPIParams from '@/util/createAPIParams.js';

/**
 * Interface to the Supplier API endpoints
 * @class
 * @exports src/services/SupplierAPI
 */
class SupplierAPI {
  /**
   * Retrieves all the Suppliers
   * @method
   * @static
   * @return {Promise}          Promise that resolves to an aray of {@link Supplier} items.
   */
  static async getSuppliers(sort, direction, filters) {
    const response = await axios({
      method: 'GET',
      url: 'supplier',
      responseType: 'json',
      params: createAPIParams('all', sort, direction, filters),
    });

    return response.data.data;
  }

  /**
   * @summary Hits the create Supplier endpoint.
   * @desc Sends the given supplier data to the create endpoint
   * @param  {Supplier}  supplier A {@link Supplier} object to be saved
   * @return {Promise}      Resolves to the returned full Supplier object
   */
  static async createSupplier(supplier) {
    const response = await axios({
      method: 'POST',
      url: 'supplier',
      responseType: 'json',
      data: supplier,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the PATCH Supplier endpoint.
   * @desc Sends the specified _modified_ fields to the endpoint to be saved.
   * @param  {String|Number}  supplierId The Supplier Id to save
   * @param  {object}  modifiedFields An object with a subset of the
   * {@link Supplier} fields that have been modified and should be saved.
   * @return {Promise}      Resolves to the returned full Supplier object
   */
  static async updateSupplier(supplierId, modifiedFields) {
    const response = await axios({
      method: 'PATCH',
      url: `supplier/${supplierId}`,
      responseType: 'json',
      data: modifiedFields,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the DELETE Supplier endpoint.
   * @param  {String|Number}  supplierId The Supplier Id to delete
   * @return {Promise}
   */
  static async deleteSupplier(supplierId) {
    await axios({
      method: 'DELETE',
      url: `supplier/${supplierId}`,
      responseType: 'json',
    });

    return true;
  }
}

export default SupplierAPI;
