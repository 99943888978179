export default {
  /**
   * @name g isActiveFinancialPeriodPhantom
   * @desc Returns true if the activeCostCode is 'phantom' (i.e. has not been saved).
   * @param  {object}  state Current state
   * @return {Boolean}
   */
  isActiveFinancialPeriodPhantom(state) {
    return !(state.activeFinancialPeriod && state.activeFinancialPeriod.id);
  },
};
