import Base from './Base.js';

export default function WorkOrderSlaTypesCreator(i18n) {
  class WorkOrderSlaTypes extends Base {}

  WorkOrderSlaTypes.initEnum({
    RESPONSE_TIME: {
      field_name_prefix: 'response',
      display: i18n.global.t('views.work-orders.WorkOrderDisplay.time.serviceLevels.fields.response_time'),
    },
    TIME_TO_ATTEND: {
      field_name_prefix: 'attendance',
      display: i18n.global.t('views.work-orders.WorkOrderDisplay.time.serviceLevels.fields.time_to_attend'),
    },
    TIME_TO_FIX: {
      field_name_prefix: 'fix',
      display: i18n.global.t('views.work-orders.WorkOrderDisplay.time.serviceLevels.fields.time_to_fix'),
    },
  });

  return WorkOrderSlaTypes;
}
