import types from '../../types/notification.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_NOTIFICATIONS
   * @summary Updates the `notifications` property.
   * @method
   * @param {Object} state Current state
   * @param {User[]} notifications The loaded Notifications array
   */
  [m.SET_NOTIFICATIONS](state, notifications) {
    state.notifications = notifications;
  },

  [m.SET_NOTIFICATIONS_STATUS](state, status) {
    state.notificationsStatus = status;
  },

  /**
   * @name m SET_NOTIFICATIONS_META
   * @summary Updates the `meta` property which holds the meta data for
   * the `NOTIFICATIONS` collection
   * For example, total rows, current page, number rows per page etc
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The meta data to store
   */
  [m.SET_NOTIFICATIONS_META](state, meta) {
    state.meta = meta;
  },

  /**
   * @name m SET_NOTIFICATIONS_FILTER
   * @summary Updates the `filter` property which holds the collection of filters currently active
   * on the Currencies collection
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The filters to be applied
   */
  [m.SET_NOTIFICATIONS_FILTER](state, filter) {
    state.filter = filter;
  },

  /**
   * @name m SET_ACTIVE_NOTIFICATION
   * @summary Updates the `activeNotification` and resets the
   * `activeNotificationModifiedFields` & `activeNotificationErrors` properties
   * @method
   * @param {Object} state Current state
   * @param {Notification} notification The new Active {@link Notification}
   */
  [m.SET_ACTIVE_NOTIFICATION](state, notification) {
    state.activeNotification = notification;
    state.activeNotificationModifiedFields =
      !notification || notification.id ? {} : { ...notification };
    state.activeNotificationErrors = [];
  },

  /**
   * @name m SET_ACTIVE_NOTIFICATION_STATE
   * @summary Updates the `activeNotificationState` property which holds the current status of the
   * based on the API enum
   * @method
   * @param {Object} state Current state
   * @param {enums/API} activeNotificationState The current activeNotificationState
   * (e.g. loading, success, failure)
   */
  [m.SET_ACTIVE_NOTIFICATION_STATE](state, activeNotificationState) {
    state.activeNotificationState = activeNotificationState;
  },

  /**
   * @name m SET_ACTIVE_NOTIFICATION_ERRORS
   * @summary Updates the `activeNotificationErrors` property which holds any
   * errors related to activeNotification
   * @method
   * @param {Object} state Current state
   * @param {string[]} errors An array of error messages
   */
  [m.SET_ACTIVE_NOTIFICATION_ERRORS](state, errors) {
    state.activeNotificationErrors = errors;
  },

  /**
   * @name m SET_ACTIVE_NOTIFICATION_VALID
   * @summary Updates the `activeNotificationValid` property which holds
   * a boolean for the validation status of the `activeNotification`.
   * @method
   * @param {Object} state Current state
   * @param {boolean} valid True if the `activeNotification` is valid.
   */
  [m.SET_ACTIVE_NOTIFICATION_VALID](state, valid) {
    state.activeNotificationValid = valid;
  },

  /**
   * @name m UPDATE_ACTIVE_NOTIFICATION_MODIFIED_FIELDS
   * @summary Adds given field/value pair to the `activeNotificationModifiedFields`
   * collection, which
   * will be sent as a PATCH to the API. If the given `value` equals the one on the
   * `activeNotification` object (i.e. it hasn't actually changed, or has been reverted back to it's
   * original) then we remove that field from the modifiedFields collection
   * @method
   * @param {Object} state Current state
   * @param {object} payload Object containing a `field` and `value` properties.
   */
  [m.UPDATE_ACTIVE_NOTIFICATION_MODIFIED_FIELDS](state, { field, value }) {
    // if the value matches the existing one then we remove the field from the modifiedFields obj
    if (state.activeNotification && state.activeNotification[field] === value) {
      delete state.activeNotificationModifiedFields[field];
    } else {
      state.activeNotificationModifiedFields[field] = value;
    }
  },

  /**
   * @name m CLEAR_ACTIVE_NOTIFICATION_MODIFIED_FIELDS
   * @summary Empties the `activeNotificationModifiedFields` object. Used to ensure we're on a clean
   * slate after navigating to a Notification Display screen,
   * @method
   * @param {Object} state Current state
   */
  [m.CLEAR_ACTIVE_NOTIFICATION_MODIFIED_FIELDS](state) {
    state.activeNotificationModifiedFields = {};
  },

  /**
   * @name m REMOVE_LOCAL_NOTIFICATION
   * @summary Removes the given notification from the `notifications` array.
   * fThis is used to prevent a
   * full reload being needed after deleting a Notification.
   * @method
   * @param {Object} state Current state
   * @param {object} notification The Notification to remove.
   */
  [m.REMOVE_LOCAL_NOTIFICATION](state, notification) {
    const index = state.notifications.findIndex((u) => u.id === notification.id);

    if (index >= 0) {
      state.notifications.splice(index, 1);
    }
  },

  /**
   * @name m ADD_LOCAL_NOTIFICATION
   * @summary Adds the given notification to the `notifications` array. This is used to prevent a
   * full reload being needed after adding a Notification.
   * @method
   * @param {Object} state Current state
   * @param {object} notification The Notification to add.
   */
  [m.ADD_LOCAL_NOTIFICATION](state, notification) {
    state.notifications.push(notification);
  },

  /**
   * @name m UPDATE_LOCAL_NOTIFICATION
   * @summary Updates the related notification object in the `notifications` array after an update.
   * @method
   * @param {Object} state Current state
   * @param {object} notification The Notification to update.
   */
  [m.UPDATE_LOCAL_NOTIFICATION](state, notification) {
    const localNotification = state.notifications.find((c) => c.id === notification.id);

    if (localNotification) {
      Object.assign(localNotification, notification);
    }
  },
};
