export default {
  path: '/',
  alias: '/dashboard',
  meta: {
    requiresAuth: true,
    layout: 'DefaultLayout',
    storeModules: ['authorization', 'role'],
    bcDynamic: true,
    bcLinkText: (dashboard) => dashboard?.name,
  },
  component: () => import('@/views/dashboards/Dashboards.vue'),
  children: [
    {
      path: '',
      name: 'DashboardDisplay',
      component: () => import('@/views/dashboards/DashboardDisplay.vue'),
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        bcLoadingText: 'Loading...',
      },
    },
    {
      path: 'dashboard/:id?',
      name: 'DashboardDisplaySpecific',
      component: () => import('@/views/dashboards/DashboardDisplay.vue'),
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        bcLoadingText: 'Loading...',
      },
    },
  ],
};
