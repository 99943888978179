import axios from 'axios';
import createAPIParams from '@/util/createAPIParams.js';

/**
 * Interface to the WorkOrderState API endpoints
 * @class
 * @exports src/services/WorkOrderStateAPI
 */
class WorkOrderStateAPI {
  /**
   * Retrieves all WorkOrderStates
   * @param {String} page
   * @param {String} sort The field to sort on
   * @param {String} direction The sort direction. Either 'ASC' or 'DESC'. Defaults to ASC.
   * @param {Object} filter An object with key value pairs of filter values.
   * @return {Promise} Promise that resolves to the api response's data
   */
  static async getWorkOrderStates(page, sort, direction, filter) {
    const response = await axios({
      method: 'GET',
      url: 'work_order_state',
      responseType: 'json',
      params: createAPIParams(page || 'all', sort, direction, filter),
    });

    return response.data.data;
  }
}

export default WorkOrderStateAPI;
