export default {
  /**
   * @name g getActiveCostRequest
   * @desc Returns the `activeCostRequest` object
   * @param  {object}  state Current state
   * @return {object}
   */
  getActiveCostRequest(state) {
    return state.activeCostRequest;
  },
};
