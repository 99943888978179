import APIError from '../../../errors/APIError.js';
import types from '../../types/users.js';
import errorTypes from '../../types/error.js';
import UserAPI from '../../../services/UserAPI.js';
import authorizationTypes from '../../types/authorization.js';
import ApiStatusCreator from '../../../enums/API.js';
const API_STATUS = ApiStatusCreator();

const a = types.actions;
const m = types.mutations;
const me = errorTypes.mutations;

export default {
  /**
   * Loads specific User details
   * @param {Object} context
   * @param {number} id The User ID to load
   * @return {User} A {@link User} object.
   */
  async [a.GET_USER]({ commit, state }, { id }) {
    try {
      commit(m.SET_ACTIVE_USER, null);
      commit(m.SET_ACTIVE_USER_STATE, API_STATUS.LOADING);

      const user = await UserAPI.getUser(id);

      user.created_at = new Date(user.created_at);
      user.updated_at = new Date(user.updated_at);

      commit(m.SET_ACTIVE_USER, user);
      commit(m.SET_ACTIVE_USER_STATE, API_STATUS.SUCCESS);
    } catch (error) {
      commit(m.SET_ACTIVE_USER_STATE, API_STATUS.FAILURE);
      let message = 'An error occurred while fetching this User. Please try again.'
      let noPermissionOrNotFoundError = '';
      if (error.response && error.response.data && error.response.data.message) {
        if (error.response.status === 404 || error.response.status === 403) {
          noPermissionOrNotFoundError = error.response.data.message;
        }
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }
      commit(me.SET_NOT_FOUND_OR_PERMISSION_ERRORS, noPermissionOrNotFoundError);
      commit(m.SET_ACTIVE_USER_ERRORS, message);
    }

    return state.users;
  },

  /**
   * Creates a new User
   * @param {Object} context
   * @param {object} user A {@link User} object
   * @return {User} A {@link User} object.
   */
  async [a.CREATE_USER]({ commit, state }, { user }) {
    try {
      commit(m.SET_ACTIVE_USER_ERRORS, []);
      commit(m.SET_ACTIVE_USER_STATE, API_STATUS.SAVING);

      const userResponse = await UserAPI.createUser(user);

      commit(m.SET_ACTIVE_USER_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_USER, userResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_USER_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while creating this User. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeUser;
  },

  /**
   * Updates an existing User
   * @param {Object} context
   * @param {object} user The {@link User} object to update
   * @param {object} modifiedFields An object with the key/values for the fields to update.
   * @return {User} A {@link User} object.
   */
  async [a.UPDATE_USER]({ commit, state }, { user, modifiedFields }) {
    try {
      commit(m.SET_ACTIVE_USER_ERRORS, []);
      commit(m.SET_ACTIVE_USER_STATE, API_STATUS.SAVING);

      const userResponse = await UserAPI.updateUser(user.id, modifiedFields);

      commit(m.SET_ACTIVE_USER_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_USER, userResponse);
      commit(authorizationTypes.mutations.PATCH_SESSION_USER, {
        modifiedFields,
        updatedUserId: user.id,
      });
    } catch (error) {
      commit(m.SET_ACTIVE_USER_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while updating this User. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeUser;
  },
};
