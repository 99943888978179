export default {
  filter: {},
  sort: 'name',
  sortDirection: 'ASC',

  meta: {},

  activeUser: null,
  activeUserState: null,
  activeUserErrors: [],
  activeUserValid: false,
  activeUserModifiedFields: {},

  userTypes: [
    { id: 'ADMIN', name: 'Admin' },
    { id: 'CONTRACTOR', name: 'Contractor' },
  ],
  enabled: [
    { id: true, name: 'Yes' },
    { id: false, name: 'No' },
  ],
  enabledFilter: [
    { id: null, name: 'All' },
    { id: 'true', name: 'Yes' },
    { id: 'false', name: 'No' },
  ],
  lockedFilter: [
    { id: null, name: 'All' },
    { id: 'true', name: 'Yes' },
    { id: 'false', name: 'No' },
  ],
};
