import Base from './Base.js';

export default function ContractorBillingTypeCreator(i18n) {
  class ContractorBillingType extends Base {}

  ContractorBillingType.initEnum({
    NONE: {
      value: 'NONE',
      display: i18n.global.t('enums.ContractorBillingType.none'),
    },
    FIXED: {
      value: 'FIXED',
      display: i18n.global.t('enums.ContractorBillingType.fixed'),
    },
    TIMED: {
      value: 'TIMED',
      display: i18n.global.t('enums.ContractorBillingType.time'),
    },
    ASSET: {
      value: 'ASSET',
      display: i18n.global.t('enums.ContractorBillingType.asset'),
    },
  });

  return ContractorBillingType;
}
