import Base from '@/enums/Base.js';
//import { MaintainableEntityRawType } from '@/enums/MaintainableEntityType.js';

export default function ApplicableEntityTypeCreator(i18n) {
  class ApplicableEntityType extends Base {}

  // TODO: Duplicated from MaintainableEntityType
  const MaintainableEntityRawType = {
    SITE: {
      value: 'SITE',
      display: i18n.global.t('enums.MaintainableEntityType.site'),
    },
    BUILDING: {
      value: 'BUILDING',
      display: i18n.global.t('enums.MaintainableEntityType.building'),
    },
    FLOOR: {
      value: 'FLOOR',
      display: i18n.global.t('enums.MaintainableEntityType.floor'),
    },
    SPACE: {
      value: 'SPACE',
      display: i18n.global.t('enums.MaintainableEntityType.space'),
    },
    SYSTEM: {
      value: 'SYSTEM',
      display: i18n.global.t('enums.MaintainableEntityType.system'),
    },
    ASSET: {
      value: 'ASSET',
      display: i18n.global.t('enums.MaintainableEntityType.asset'),
    },
  };

  ApplicableEntityType.initEnum({
    ...MaintainableEntityRawType,
    WORK_ORDER: {
      value: 'WORK_ORDER',
      display: i18n.global.t('enums.ApplicableEntityType.workOrder'),
    },
    CONTRACTOR: {
      value: 'CONTRACTOR',
      display: i18n.global.t('enums.ApplicableEntityType.contractor'),
    },
  });

  return ApplicableEntityType;
}
