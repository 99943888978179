import Base from './Base.js';

export default function CostRequestStatusCreator(i18n) {
  class CostRequestStatus extends Base {}

  CostRequestStatus.initEnum({
    OPEN: {
      value: 'OPEN',
      display: i18n.global.t('enums.CostRequestStatus.open'),
    },
    ACCEPTED: {
      value: 'ACCEPTED',
      display: i18n.global.t('enums.CostRequestStatus.accepted'),
    },
    CANCELLED: {
      value: 'CANCELLED',
      display: i18n.global.t('enums.CostRequestStatus.cancelled'),
    },
    REJECTED: {
      value: 'REJECTED',
      display: i18n.global.t('enums.CostRequestStatus.rejected'),
    },
  });

  return CostRequestStatus;
}
