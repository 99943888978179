import types from '../../types/organisation.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_ORGANISATIONS
   * @summary Updates the `organisations` property.
   * @method
   * @param {Object} state Current state
   * @param {Organisation[]} organisations The loaded Site Groups array
   */
  [m.SET_ORGANISATIONS](state, organisations) {
    state.organisations = organisations;
  },

  [m.SET_ORGANISATIONS_STATUS](state, status) {
    state.organisationsStatus = status;
  },

  /**
   * @name m SET_ORGANISATIONS_META
   * @summary Updates the `meta` property which holds the meta data for the `ORGANISATIONS`
   * collection. For example, total rows, current page, number rows per page etc
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The meta data to store
   */
  [m.SET_ORGANISATIONS_META](state, meta) {
    state.meta = meta;
  },

  /**
   * @name m SET_ORGANISATIONS_FILTER
   * @summary Updates the `filter` property which holds the collection of filters currently active
   * on the Currencies collection
   * @method
   * @param {Object} state Current state
   * @param {Object} filter The filters to be applied
   */
  [m.SET_ORGANISATIONS_FILTER](state, filter) {
    state.filter = filter;
  },

  /**
   * @name m SET_ACTIVE_ORGANISATION
   * @summary Updates the `activeOrganisation` and resets the
   * `activeOrganisationModifiedFields` & `activeOrganisationErrors` properties
   * @method
   * @param {Object} state Current state
   * @param {Organisation} organisation The new Active {@link Organisation}
   */
  [m.SET_ACTIVE_ORGANISATION](state, organisation) {
    state.activeOrganisation = organisation;
    state.activeOrganisationModifiedFields =
      !organisation || organisation.id ? {} : { ...organisation };
    state.activeOrganisationErrors = [];
  },

  /**
   * @name m SET_ACTIVE_ORGANISATION_STATE
   * @summary Updates the `activeOrganisationState` property which holds the current status of the
   * based on the API enum
   * @method
   * @param {Object} state Current state
   * @param {enums/API} activeOrganisationState The current activeOrganisationState
   * (e.g. loading, success, failure)
   */
  [m.SET_ACTIVE_ORGANISATION_STATE](state, activeOrganisationState) {
    state.activeOrganisationState = activeOrganisationState;
  },

  /**
   * @name m SET_ACTIVE_ORGANISATION_ERRORS
   * @summary Updates the `activeOrganisationErrors` property which holds any
   * errors related to activeOrganisation
   * @method
   * @param {Object} state Current state
   * @param {string[]} errors An array of error messages
   */
  [m.SET_ACTIVE_ORGANISATION_ERRORS](state, errors) {
    state.activeOrganisationErrors = errors;
  },

  /**
   * @name m SET_ACTIVE_ORGANISATION_VALID
   * @summary Updates the `activeOrganisationValid` property which holds
   * a boolean for the validation status of the `activeOrganisation`.
   * @method
   * @param {Object} state Current state
   * @param {boolean} valid True if the `activeOrganisation` is valid.
   */
  [m.SET_ACTIVE_ORGANISATION_VALID](state, valid) {
    state.activeOrganisationValid = valid;
  },

  /**
   * @name m UPDATE_ACTIVE_ORGANISATION_MODIFIED_FIELDS
   * @summary Adds given field/value pair to the `activeOrganisationModifiedFields` collection,
   * which will be sent as a PATCH to the API. If the given `value` equals the one on the
   * `activeOrganisation` object (i.e. it hasn't actually changed, or has been reverted back to it's
   * original) then we remove that field from the modifiedFields collection
   * @method
   * @param {Object} state Current state
   * @param {object} payload Object containing a `field` and `value` properties.
   */
  [m.UPDATE_ACTIVE_ORGANISATION_MODIFIED_FIELDS](state, { field, value }) {
    // if the value matches the existing one then we remove the field from the modifiedFields obj
    if (state.activeOrganisation && state.activeOrganisation[field] === value) {
      delete state.activeOrganisationModifiedFields[field];
    } else {
      state.activeOrganisationModifiedFields[field] = value;
    }
  },

  /**
   * @name m CLEAR_ACTIVE_ORGANISATION_MODIFIED_FIELDS
   * @summary Empties the `activeOrganisationModifiedFields` object. Used to ensure we're on a clean
   * slate after navigating to a Organisation Display screen,
   * @method
   * @param {Object} state Current state
   */
  [m.CLEAR_ACTIVE_ORGANISATION_MODIFIED_FIELDS](state) {
    state.activeOrganisationModifiedFields = {};
  },

  /**
   * @name m REMOVE_LOCAL_ORGANISATION
   * @summary Removes the given organisation from the `organisations` array. This is used to prevent
   * a full reload being needed after deleting a Organisation.
   * @method
   * @param {Object} state Current state
   * @param {object} organisation The Organisation to remove.
   */
  [m.REMOVE_LOCAL_ORGANISATION](state, organisation) {
    const index = state.organisations.findIndex((u) => u.id === organisation.id);

    if (index >= 0) {
      state.organisations.splice(index, 1);
    }
  },

  /**
   * @name m ADD_LOCAL_ORGANISATION
   * @summary Adds the given organisation to the `organisations` array. This is used to prevent a
   * full reload being needed after adding a Organisation.
   * @method
   * @param {Object} state Current state
   * @param {object} organisation The Organisation to add.
   */
  [m.ADD_LOCAL_ORGANISATION](state, organisation) {
    state.organisations.push(organisation);
  },

  /**
   * @name m UPDATE_LOCAL_ORGANISATION
   * @summary Updates the related organisation object in the `organisations` array after an update.
   * @method
   * @param {Object} state Current state
   * @param {object} organisation The Organisation to update.
   */
  [m.UPDATE_LOCAL_ORGANISATION](state, organisation) {
    const localOrganisation = state.organisations.find((c) => c.id === organisation.id);

    if (localOrganisation) {
      Object.assign(localOrganisation, organisation);
    }
  },
};
