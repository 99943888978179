import Base from './Base.js';

export default function CostProposalStatusCreator(i18n) {
  class CostProposalStatus extends Base {}

  CostProposalStatus.initEnum({
    SUBMITTED: {
      value: 'SUBMITTED',
      display: i18n.global.t('enums.CostProposalStatus.submitted'),
      variant: 'primary',
    },
    APPROVAL_REQUESTED: {
      value: 'APPROVAL_REQUESTED',
      display: 'Approval Requested',
      variant: 'primary',
    },
    AUTHORISING: {
      value: 'AUTHORISING',
      display: i18n.global.t('enums.CostProposalStatus.authorising'),
      variant: 'dark',
    },
    AUTHORISED: {
      value: 'AUTHORISED',
      display: i18n.global.t('enums.CostProposalStatus.authorised'),
      variant: 'dark',
    },
    APPROVED: {
      value: 'APPROVED',
      display: i18n.global.t('enums.CostProposalStatus.approved'),
      variant: 'success',
    },
    REJECTED: {
      value: 'REJECTED',
      display: i18n.global.t('enums.CostProposalStatus.rejected'),
      variant: 'danger',
    },
  });

  return CostProposalStatus;
}
