import Base from './Base.js';

export default function ComplianceSubmissionStatusCreator(i18n) {
  class ComplianceSubmissionStatus extends Base {}

  ComplianceSubmissionStatus.initEnum({
    PASS: {
      value: 'PASS',
      display: i18n.global.t('enums.ComplianceSubmissionStatus.pass'),
      variant: 'warning',
    },
    PASS_WITH_RECOMMENDATIONS: {
      value: 'PASS_WITH_RECOMMENDATIONS',
      display: i18n.global.t('enums.ComplianceSubmissionStatus.pass_with_recommendations'),
      variant: 'warning',
    },
    FAIL: {
      value: 'FAIL',
      display: i18n.global.t('enums.ComplianceSubmissionStatus.fail'),
      variant: 'warning',
    },
  });

  return ComplianceSubmissionStatus;
}
