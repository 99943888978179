import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';

// dependent on utc plugin
dayjs.extend(LocalizedFormat);
dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);

export default {
  install(app, store) {
    app.config.globalProperties.$formatDate = (date, formatStyle, altTimezone) => {
      const locale = (store.getters.getLocale || 'en-gb').toLowerCase();
      const userTimezone = altTimezone ?? store.getters.getTimezone;
      // eslint-disable-next-line global-require
      // TODO: NOT WORKING
      // let userLocale;
      // try {
      //   userLocale = import(/* @vite-ignore */ `/dayjs/locale/${locale}.js`);
      // } catch (error) {
      //   //userLocale = import('dayjs/locale/en-gb.js');
      // }
      //dayjs.locale(userLocale);
      const localFormat = 'L';
      if (date) {
        return dayjs
          .utc(date)
          .tz(userTimezone)
          .format(formatStyle || localFormat);
      }
      return '';
    };
  },
};
