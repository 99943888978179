import Base from './Base.js';

export default function ComplianceSubmissionApprovalStatusCreator(i18n) {
  class ComplianceSubmissionApprovalStatus extends Base {}

  ComplianceSubmissionApprovalStatus.initEnum({
    AWAITING: {
      value: 'AWAITING',
      display: i18n.global.t('enums.ComplianceSubmissionApprovalStatus.awaiting'),
      variant: 'light',
    },
    SUBMITTED: {
      value: 'SUBMITTED',
      display: i18n.global.t('enums.ComplianceSubmissionApprovalStatus.submitted'),
      variant: 'secondary',
    },
    APPROVED: {
      value: 'APPROVED',
      display: i18n.global.t('enums.ComplianceSubmissionApprovalStatus.approved'),
      variant: 'success',
    },
    REJECTED: {
      value: 'REJECTED',
      display: i18n.global.t('enums.ComplianceSubmissionApprovalStatus.rejected'),
      variant: 'danger',
    },
  });

  return ComplianceSubmissionApprovalStatus;
}
