<template>
  <nav class="mobile-header navbar">
    <ul class="navbar-nav">
      <li class="nav-item burger-menu">
        <b-button @click="onOpenMenuClick">
          <font-awesome-icon :icon="['far', 'bars']" /><span class="sr-only">
            {{ $t('components.app.MobileHeader.openNavigation') }}
          </span>
        </b-button>
      </li>
      <li class="nav-item text-center">
        <router-link :to="{ name: 'DashboardDisplay' }">
          <img class="logo" :src="logoUrl" :alt="$t('common.logoAltText')" />
        </router-link>
      </li>
      <li class="nav-item">
        <ul class="nav-item d-flex search-account-container">
          <li>
            <b-button class="search" @click="onOpenSearchClick">
              <font-awesome-icon :icon="['far', 'search']" />&nbsp;<!--
            --><span class="sr-only">
                {{ $t('components.app.MobileHeader.search') }}
              </span>
            </b-button>
          </li>
          <li>
            <AccountMenu :popover-id="'mobile'" />
          </li>
        </ul>
      </li>
    </ul>
    <div v-if="searchActive" class="mobile-search-container d-flex">
      <b-input-group class="mobile-search">
        <b-input-group-prepend>
          <div class="input-group-text border-0">
            <font-awesome-icon :icon="['far', 'search']" />
          </div>
        </b-input-group-prepend>
        <b-form-input
          id="mobile-search-input"
          ref="input"
          v-model="search"
          type="search"
          :placeholder="$t('components.app.Header.searchPlaceholder')"
          trim
          class="border-0 border"
          @search="onSearchClick"
          @keydown.enter="onSearchClick"
        />
        <b-input-group-append>
          <b-button variant="primary" @click="onSearchClick">
            {{ $t('components.app.MobileHeader.search') }}
          </b-button>
        </b-input-group-append>
      </b-input-group>
      <button class="close-search" @click="onCloseSearchClick">
        <font-awesome-icon :icon="['far', 'times']" />
      </button>
    </div>
  </nav>
</template>

<script>
import {
  BButton,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormInput,
} from 'bootstrap-vue';
import AccountMenu from '@/ux/AccountMenu.vue';
import DisplaysLogo from '@/mixins/DisplaysLogo.js';

export default {
  name: 'MobileHeader',

  components: {
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInput,
    AccountMenu,
  },

  mixins: [DisplaysLogo],

  props: {},

  data() {
    return {
      logoUrl: '',
      search: '',
      searchActive: false,
    };
  },

  created() {
    this.fetchLogo();
  },

  methods: {
    async fetchLogo() {
      this.logoUrl = await this.logo;
    },

    onSearchClick() {
      const field = this.getSearchField(this.search);
      const query = {};

      if (this.search) {
        query[field] = this.search;
      }

      this.$router.push({
        name: 'WorkOrders',
        query,
      });
    },

    onOpenMenuClick() {
      this.$emit('open');
    },

    onOpenSearchClick() {
      this.searchActive = true;
      this.$nextTick(() => this.$refs.input.focus());
    },
    onOpenTranslateClick() {
      this.translateActive = true;
    },

    onCloseSearchClick() {
      this.searchActive = false;
      this.translateActive = false;
    },

    /**
     * Decides the field to filter on based on the query value.
     * Numerical value will search on `id`, non-numeric on `summary`
     * @param  {string} query The search query
     * @return {string}
     */
    getSearchField(query) {
      return /^\d+$/.test(query) ? 'id' : 'summary';
    },
  },
};
</script>

<style lang="scss" scoped>
#mobile-search-input {
  padding: 1.3rem 1rem;
}

nav.mobile-header {
  background: $dark;
  height: 3.5rem;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .navbar-nav {
    flex: 1;
    flex-direction: row;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .nav-item {
      flex: 0 0 110px;
      align-items: center;
      list-style: none;

      &:nth-of-type(2) {
        flex: 1 1 auto;
      }

      img.logo {
        padding: 0.5rem 0;
        max-height: 3.60rem;
        max-width: 100%;

        @include media-breakpoint-down(sm) {
          margin-bottom: -3px;
        }
      }
    }
    .search-account-container {
      display: flex;
      justify-content: flex-end;
      padding-right: 0.2rem;
      padding-left: 0.2rem;

      .btn {
        padding-left: 0.1rem;
        padding-right: 0.1rem;
        margin-right: 0.5rem;
        height: 3rem;
      }
    }
    @include media-breakpoint-down(sm) {
      :deep(.search-account-container) div button {
        padding-left: 0.3rem;
        padding-right: 0.3rem;
      }
    }

    button {
      color: #fff;
      font-size: 1.5rem;

      background: transparent;
      border: none;
    }
  }

  .mobile-search-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 0.5rem;
    max-height: 100%;
    background: $dark;

    input#mobile-search-input {
      padding: 1.25rem;
    }

    .input-group-text {
      background: #fff;
    }

    .close-search {
      margin-left: 1rem;
      width: 2rem;
      height: 2rem;
      padding-top: 0.5rem;
      font-size: 1.5rem;
      color: #fff;
      background: transparent;
      border: none;
      display: flex;
    }
  }
}

@include media-breakpoint-up(lg) {
  .navbar {
    display: none !important;
  }
}


@media (max-width: 450px) {
  nav.mobile-header {
    .navbar-nav .nav-item {
      flex: 0 0 auto;
    }
  }
}
</style>
