export default {
  filter: {},
  sort: 'created_at',
  sortDirection: 'DESC',

  activeCostRequest: null,
  activeCostRequestState: null,
  activeCostRequestErrors: [],
  activeCostRequestValid: false,
  activeCostRequestModifiedFields: {},
};
