export default {
  /**
   * @name g isActiveDocumentTypePhantom
   * @desc Returns true if the activeDocumentType is 'phantom' (i.e. has not been saved).
   * @param  {object}  state Current state
   * @return {Boolean}
   */
  isActiveDocumentTypePhantom(state) {
    return !(state.activeDocumentType && state.activeDocumentType.id);
  },
  getActiveDocumentType(state) {
    return state.activeDocumentType;
  },
};
