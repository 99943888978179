<template>
  <span>
    <span>
      <slot />
    </span>
    <span class="text-danger text-bold" aria-hidden="true">&nbsp;*</span>
    <span class="sr-only">{{ $t('common.forms.requiredField') }}</span>
  </span>
</template>

<style lang="scss" scoped></style>
