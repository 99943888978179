import { defineStore } from 'pinia';
import Errors from "@/services/v2/Errors";
import MaintainableEntityAPI from "@/services/MaintainableEntityAPI";
import GetAPI from "@/services/v2/GetAPI";
import IndexAPI from "@/services/v2/IndexAPI";

export const useMaintainableEntityStore = defineStore('maintainableEntity', {
  state: () => ({
    maintainableEntities: [],
    maintainableEntity: null,
    tree: [],
    meta: [],
    loading: false,
    error: null,
    errors: null,
    icons: [
      {type: 'SITE', icon: 'home'},
      {type: 'BUILDING', icon: 'building'},
      {type: 'FLOOR', icon: 'layer-group'},
      {type: 'SPACE', icon: 'map-marker-alt'},
      {type: 'SYSTEM', icon: 'project-diagram'},
      {type: 'ASSET', icon: 'cube'},
      {type: 'INFO', icon: 'exclamation-circle'},
    ],
  }),
  actions: {
    async get(config) {
      this.maintainableEntity = null;
      this.loading = true;
      this.error = null;
      this.errors = null;

      try {
        const response = await GetAPI.get('maintainable-entities', config);
        this.maintainableEntity = response.data;
      } catch (error) {
        this.error = error;
        this.errors = Errors.getErrors(error);
      } finally {
        this.loading = false;
      }
    },
    async fetch(config) {
      this.maintainableEntities = [];
      this.meta = [];
      this.loading = true;
      this.error = null;
      this.errors = null;

      try {
        const response = await IndexAPI.fetch('maintainable-entities', config);
        this.maintainableEntities = response.data;
        this.meta = response.meta;
      } catch (error) {
        this.error = error;
        this.errors = Errors.getErrors(error);
      } finally {
        this.loading = false;
      }
    },
    async fetchTree(filter = {}) {
      this.tree = [];
      this.meta = [];
      this.loading = true;
      this.error = null;
      this.errors = null;

      try {
        const response = await MaintainableEntityAPI.getMaintainableEntitiesTree(filter);
        this.tree = response.data;
        this.meta = response.meta;
      } catch (error) {
        this.error = error;
        this.errors = Errors.getErrors(error);
      } finally {
        this.loading = false;
      }
    },
  }
});
