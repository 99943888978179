export default {
  state: {
    costProposal: (data = {}) => ({
      id: null,
      user_id: null,
      work_order: null,
      cost_proposal_request_id: null,
      contractor: null,
      status: null,
      type: null,
      comments: null,
      reference: null,
      expiry_date: null,
      subtotal: null,
      tax_amount: null,
      total: null,
      lines: [],
      files: null,
      created_at: null,
      updated_at: null,
      ...data,
    }),

    costProposalItem: () => ({
      id: null,
      quantity: 1,
      unit_price: '',
      description: '',
      cost_type: 'Labour',
      tax_rate: {
        id: 1,
      },
      tax_rate_id: 1,
    }),
  },

  getters: {},

  actions: {
    GET_COST_PROPOSAL: 'GET_COST_PROPOSAL',
  },

  mutations: {
    SET_ACTIVE_COST_PROPOSAL: 'SET_ACTIVE_COST_PROPOSAL',
    SET_ACTIVE_COST_PROPOSAL_STATE: 'SET_ACTIVE_COST_PROPOSAL_STATE',
    SET_ACTIVE_COST_PROPOSAL_ERRORS: 'SET_ACTIVE_COST_PROPOSAL_ERRORS',

    SET_COST_PROPOSALS_FILTER: 'SET_COST_PROPOSALS_FILTER',
    SET_COST_PROPOSALS_SORT: 'SET_COST_PROPOSALS_SORT',
  },

  types: ['QUOTE', 'ESTIMATE'],
};
