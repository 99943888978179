import Base from './Base.js';

export default function CommentVisibilityCreator(i18n) {
  class CommentVisibility extends Base {}

  CommentVisibility.initEnum({
    PUBLIC: {
      value: 'public',
      display: i18n.global.t('enums.CommentVisibility.public'),
    },
    PRIVATE: {
      value: 'private',
      display: i18n.global.t('enums.CommentVisibility.internal'),
    },
    REQUESTER_ONLY: {
      value: 'requester_only',
      display: i18n.global.t('enums.CommentVisibility.requester_only'),
    },
  });

  return CommentVisibility;
}
