export default {
  state: {
    /**
     * A CostRequest definition
     * @typedef {Object} CostRequest
     * @property {number} id -
     * @property {number} work_order_id
     * @property {number} contractor_id
     * @property {number} type
     * @property {number} message
     * @property {string} due_at
     */

    costRequest: () => ({
      id: null,
      work_order_id: null,
      contractor_id: null,
      type: null,
      message: null,
      due_at: null,
    }),
  },

  getters: {},

  actions: {
    GET_COST_REQUEST: 'GET_COST_REQUEST',
    UPDATE_COST_REQUEST: 'UPDATE_COST_REQUEST',
  },

  mutations: {
    SET_ACTIVE_COST_REQUEST: 'SET_ACTIVE_COST_REQUEST',
    SET_ACTIVE_COST_REQUEST_STATE: 'SET_ACTIVE_COST_REQUEST_STATE',
    SET_ACTIVE_COST_REQUEST_ERRORS: 'SET_ACTIVE_COST_REQUEST_ERRORS',

    SET_COST_REQUESTS_FILTER: 'SET_COST_REQUESTS_FILTER',
    SET_COST_REQUESTS_SORT: 'SET_COST_REQUESTS_SORT',
  },
};
