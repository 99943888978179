export default {
  path: '/asset-management',
  meta: {
    requiresAuth: true,
    layout: 'DefaultLayout',
    bcLinkText: 'Assets',
  },
  component: () => import('@/views/assets/Asset.vue'),
  children: [
    {
      path: ':page(\\d+)?',
      name: 'AssetsHome',
      component: () => import('@/views/assets/AssetHome.vue'),
      meta: {
        requiresAuth: true,
        permission: 'Asset_Manage',
        layout: 'DefaultLayout',
      },
    },
    {
      path: 'view/:id/:pane?',
      name: 'AssetDisplay',
      component: () => import('@/views/assets/AssetDisplay.vue'),
      meta: {
        requiresAuth: true,
        permission: 'Asset_Manage',
        layout: 'DefaultLayout',
        bcDynamic: true,
        bcLinkText: (storeAsset) => storeAsset.name,
        bcLoadingText: 'Loading Asset...',
        translate: false,
      },
    },
  ],
};
