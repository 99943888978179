import types from '../../types/organisation.js';
import APIError from '../../../errors/APIError.js';
import OrganisationAPI from '../../../services/OrganisationAPI.js';
import API from '../../../enums/API.js';

const a = types.actions;
const m = types.mutations;

export default {
  /**
   * Loads all Organisations
   * @param {Object} context
   * @param {number} page The page to load. If not supplied will default to 1
   * @return {Organisation[]} An array of {@link Organisation} objects.
   */
  async [a.GET_ORGANISATIONS]({ commit, state }, { sort, direction }) {
    commit(m.SET_ORGANISATIONS_STATUS, API.LOADING);

    try {
      const organisations = await OrganisationAPI.getOrganisations('all', sort, direction);

      commit(m.SET_ORGANISATIONS_STATUS, API.SUCCESS);
      commit(m.SET_ORGANISATIONS, organisations);
    } catch (error) {
      commit(m.SET_ORGANISATIONS_STATUS, API.FAILURE);

      let message = 'An error occurred while fetching Site Groups. Please try again';

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return state.organisations;
  },

  /**
   * Creates a new Organisation
   * @param {Object} context
   * @param {object} organisation A {@link Organisation} object
   * @return {Organisation} A {@link Organisation} object.
   */
  async [a.CREATE_ORGANISATION]({ commit, state }, { organisation }) {
    try {
      commit(m.SET_ACTIVE_ORGANISATION_ERRORS, []);
      commit(m.SET_ACTIVE_ORGANISATION_STATE, API.SAVING);

      const organisationResponse = await OrganisationAPI.createOrganisation(organisation);

      commit(m.SET_ACTIVE_ORGANISATION_STATE, API.SUCCESS);
      commit(m.SET_ACTIVE_ORGANISATION, organisationResponse);
      commit(m.ADD_LOCAL_ORGANISATION, organisationResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_ORGANISATION_STATE, API.FAILURE);

      let message = 'An error occurred while creating this Site Group. Please try again';

      if (error?.response?.data?.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeOrganisation;
  },

  /**
   * Updates an existing Organisation
   * @param {Object} context
   * @param {object} organisation The {@link Organisation} object to update
   * @param {object} modifiedFields An object with the key/values for the fields to update.
   * @return {Organisation} A {@link Organisation} object.
   */
  async [a.UPDATE_ORGANISATION]({ commit, state }, { organisation, modifiedFields }) {
    try {
      commit(m.SET_ACTIVE_ORGANISATION_ERRORS, []);
      commit(m.SET_ACTIVE_ORGANISATION_STATE, API.SAVING);

      const organisationResponse = await OrganisationAPI.updateOrganisation(
        organisation.id,
        modifiedFields,
      );

      commit(m.SET_ACTIVE_ORGANISATION_STATE, API.SUCCESS);
      commit(m.SET_ACTIVE_ORGANISATION, organisationResponse);
      commit(m.UPDATE_LOCAL_ORGANISATION, organisationResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_ORGANISATION_STATE, API.FAILURE);

      let message = 'An error occurred while updating this Organisation. Please try again';

      if (error?.response?.data?.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeOrganisation;
  },

  /**
   * Deletes a Organisation
   * @param {Object} context
   * @param {object} organisation The {@link Organisation} object to delete
   * @return {Organisation} A {@link Organisation} object.
   */
  async [a.DELETE_ORGANISATION]({ commit }, { organisation }) {
    try {
      commit(m.SET_ACTIVE_ORGANISATION_ERRORS, []);
      commit(m.SET_ACTIVE_ORGANISATION_STATE, API.DELETING);

      await OrganisationAPI.deleteOrganisation(organisation.id);

      commit(m.SET_ACTIVE_ORGANISATION_STATE, API.SUCCESS);
      commit(m.SET_ACTIVE_ORGANISATION, null);

      commit(m.REMOVE_LOCAL_ORGANISATION, organisation);
    } catch (error) {
      commit(m.SET_ACTIVE_ORGANISATION_STATE, API.FAILURE);

      let message = 'An error occurred while deleting this Organisation. Please try again';

      if (error?.response?.data?.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return true;
  },
};
