import types from '../../types/role.js';
import RoleAPI from '../../../services/RoleAPI.js';
import API from '../../../enums/API.js';
import APIError from '../../../errors/APIError.js';

const a = types.actions;
const m = types.mutations;

export default {
  /**
   * Loads all Roles
   * @param {Object} context
   * @param {number} page The page to load. If not supplied will default to 1
   * @return {User[]} An array of {@link Role} objects.
   */
  async [a.GET_ROLES]({ commit, state }, { sort = 'name', direction, filter }) {
    commit(m.SET_ROLES_STATUS, API.LOADING);

    try {
      const roles = await RoleAPI.getRoles('all', sort, direction, filter);

      commit(m.SET_ROLES_STATUS, API.SUCCESS);
      commit(m.SET_ROLES, roles);
    } catch (error) {
      commit(m.SET_ROLES_STATUS, API.FAILURE);

      let message = 'An error occurred while fetching these Roles. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return state.roles;
  },

  /**
   * Creates a new Role
   * @param {Object} context
   * @param {object} role A {@link Role} object
   * @return {Role} A {@link Role} object.
   */
  async [a.CREATE_ROLE]({ commit, state }, { role }) {
    try {
      commit(m.SET_ACTIVE_ROLE_ERRORS, []);
      commit(m.SET_ACTIVE_ROLE_STATE, API.SAVING);

      const roleResponse = await RoleAPI.createRole(role);

      commit(m.SET_ACTIVE_ROLE_STATE, API.SUCCESS);
      commit(m.SET_ACTIVE_ROLE, roleResponse);
      commit(m.ADD_LOCAL_ROLE, roleResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_ROLE_STATE, API.FAILURE);

      let message = 'An error occurred while creating this Role. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeRole;
  },

  /**
   * Updates an existing Role
   * @param {Object} context
   * @param {object} role The {@link Role} object to update
   * @param {object} modifiedFields An object with the key/values for the fields to update.
   * @return {Role} A {@link Role} object.
   */
  async [a.UPDATE_ROLE]({ commit, state }, { role, modifiedFields }) {
    try {
      commit(m.SET_ACTIVE_ROLE_ERRORS, []);
      commit(m.SET_ACTIVE_ROLE_STATE, API.SAVING);

      const roleResponse = await RoleAPI.updateRole(role.id, modifiedFields);

      commit(m.SET_ACTIVE_ROLE_STATE, API.SUCCESS);
      commit(m.SET_ACTIVE_ROLE, roleResponse);
      commit(m.UPDATE_LOCAL_ROLE, roleResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_ROLE_STATE, API.FAILURE);

      let message = 'An error occurred while updating this Role. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeRole;
  },

  /**
   * Deletes a Role
   * @param {Object} context
   * @param {object} role The {@link Role} object to delete
   * @return {Role} A {@link Role} object.
   */
  async [a.DELETE_ROLE]({ commit }, { role }) {
    try {
      commit(m.SET_ACTIVE_ROLE_ERRORS, []);
      commit(m.SET_ACTIVE_ROLE_STATE, API.DELETING);

      await RoleAPI.deleteRole(role.id);

      commit(m.SET_ACTIVE_ROLE_STATE, API.SUCCESS);
      commit(m.SET_ACTIVE_ROLE, null);

      commit(m.REMOVE_LOCAL_ROLE, role);
    } catch (error) {
      commit(m.SET_ACTIVE_ROLE_STATE, API.FAILURE);

      let message = 'An error occurred while deleting this Role. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return true;
  },
};
