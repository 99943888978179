import axios from 'axios';
import createAPIParams from '@/util/createAPIParams.js';

/**
 * Interface to the Site API endpoints
 * @class
 * @exports src/services/SiteAPI
 */
class SiteAPI {
  /**
   * Retrieves all Sites for the given page
   * @param {Number} page The page to load
   * @param {String} sort The field to sort on
   * @param {String} direction The sort direction. Either 'ASC' or 'DESC'. Defaults to ASC.
   * @param {Object} filter An object with key value pairs of filter values.
   * @return {Promise} Promise that resolves to the api response.
   * In format: { sites: {object[]}, meta: {object}, links: {object} }
   */
  static async getSites(page, sort, direction, filter) {
    const response = await axios({
      method: 'GET',
      url: 'site',
      responseType: 'json',
      params: createAPIParams(page, sort, direction, filter),
    });

    return response.data;
  }

  /**
   * Retrieves one Site
   * @method
   * @static
   * @return {Promise}          Promise that resolves to an aray of {@link Site} items.
   */
  static async getSite(siteId) {
    const response = await axios({
      method: 'GET',
      url: `site/${siteId}`,
      responseType: 'json',
    });

    return response.data.data;
  }

  /**
   * @summary Hits the create Site endpoint.
   * @desc Sends the given site data to the create endpoint
   * @param  {Site}  site A {@link Site} object to be saved
   * @return {Promise}      Resolves to the returned full Site object
   */
  static async createSite(site) {
    const response = await axios({
      method: 'POST',
      url: 'site',
      responseType: 'json',
      data: site,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the PATCH Site endpoint.
   * @desc Sends the specified _modified_ fields to the endpoint to be saved.
   * @param  {String|Number}  siteId The Site Id to save
   * @param  {object}  modifiedFields An object with a subset of the
   * {@link Site} fields that have been modified and should be saved.
   * @return {Promise}      Resolves to the returned full Site object
   */
  static async updateSite(siteId, modifiedFields) {
    const response = await axios({
      method: 'PATCH',
      url: `site/${siteId}`,
      responseType: 'json',
      data: modifiedFields,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the DELETE Site endpoint.
   * @param  {String|Number}  siteId The Site Id to delete
   * @return {Promise}
   */
  static async deleteSite(siteId) {
    await axios({
      method: 'DELETE',
      url: `site/${siteId}`,
      responseType: 'json',
    });

    return true;
  }
}

export default SiteAPI;
