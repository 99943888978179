export default {
  path: '/documents',
  meta: {
    requiresAuth: true,
    layout: 'DefaultLayout',

    permissionOr: ['Document_View'],
    bcLinkText: 'Documents',
    storeModules: ['document', 'documentType'],
  },
  component: () => import('@/views/documents/DocumentList.vue'),
  children: [
    {
      path: ':page(\\d+)?',
      name: 'DocumentList',
      component: () => import('@/views/documents/DocumentList.vue'),
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permissionOr: ['Document_View'],
      },
    },
  ],
};
