<template>
  <div
    :class="{ 'is-invalid': state === false, 'is-valid': state === true }"
    class="tree-field text-left form-control"
  >
    <span :key="label" class="label">{{ label }}</span>
    <button
      class="d-flex align-items-center"
      :disabled="disabled"
      @click="onFieldClick"
      @blur="onBlur"
    >
      <div class="value-wrap flex-fill text-left">
        <div v-if="value.length" class="value">
          <span v-for="(asset, index) in assetNames" :key="asset.id" translate="no">
            <span :key="asset.name" translate="no">{{ asset.name }}</span>
            <span v-if="index !== assetNames.length -1" translate="no">, </span>
          </span>
        </div>
        <span v-else :key="emptyText" class="value none">{{ emptyText }}</span>
      </div>
      <span class="float-right">
        <font-awesome-icon :icon="['far', 'chevron-right']" size="lg"
      /></span>
    </button>

    <AssetSelectorModal
      ref="modal"
      :maintainable-entities-tree="maintainableEntitiesTree"
      :assets="assets"
      :value="value"
      :title="label"
      :location="location"
      @input="$emit('input', $event)"
      @cancel="onEditCancel"
      @hidden="onBlur"
    />
  </div>
</template>

<script>
import flattenTree from '@/util/flattenTree.js';
import AssetSelectorModal from './AssetSelectorModal.vue';

export default {
  name: 'AssetSelector',
  components: { AssetSelectorModal },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    value: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    maintainableEntitiesTree: {
      type: Array,
      default: () => [],
    },
    assets: {
      type: Array,
      default: () => [],
    },
    displayField: {
      type: String,
      default: () => 'name',
    },
    label: {
      type: String,
      default: () => '',
    },

    emptyText: {
      type: String,
      default: () => '',
    },
    state: {
      type: Boolean,
      default: () => true,
    },
    location: {
      type: Number,
      default: () => -1,
    },
  },
  computed: {
    assetNames() {
      return this.assets.filter((item) => this.value.includes(item.id));
    },
    flatTree() {
      return [...flattenTree(this.maintainableEntitiesTree)];
    },
  },
  methods: {
    onFieldClick() {
      this.showModal();
    },
    showModal() {
      this.$refs.modal.show();
    },
    hideModal() {
      this.$refs.modal.hide();
    },

    onBlur() {
      this.$emit('blur');
    },
    onEditCancel() {
      /**
       * @summary Fired when the modal's cancel button is clicked.
       * @event cancel
       */
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss" scoped>
.tree-field {
  padding: 0;

  &.is-invalid,
  &.is-valid {
    background-position: right calc(1.75rem) center !important;
  }

  button {
    width: 100%;
    background: none;
    border: none;
    height: 100%;
    padding: 0.375rem 0.75rem;
    color: #495057;
  }

  .value-wrap {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 1rem;

    .none {
      color: #777;
      font-style: italic;
    }
  }
}
</style>
