import types from '../../types/recurringWorkOrders.js';

const m = types.mutations;

export default {
  [m.SET_EXPANDED_GROUPS](state, groups) {
    const expandedGroups = { ...state.expandedGroups };
    expandedGroups[state.activeGroup] = groups;

    state.expandedGroups = expandedGroups;
  },

  [m.SET_ACTIVE_GROUP](state, group) {
    state.activeGroup = group;
  },

  /**
   * @name m SET_RECURRING_WORK_ORDERS_FILTER
   * @summary Updates the `filter` property which holds the collection of filters currently active
   * on the RecurringWorkOrders collection
   * @method
   * @param {Object} state Current state
   * @param {Object} filter The filters to be applied
   */
  [m.SET_RECURRING_WORK_ORDERS_FILTER](state, filter) {
    state.filter = filter;
  },

  /**
   * @name m SET_RECURRING_WORK_ORDERS_SORT
   * @summary Updates the `sort` and `direction` properties
   * @method
   * @param {Object} state Current state
   * @param {Object} data The sort data (`sort` and `direction`)
   */
  [m.SET_RECURRING_WORK_ORDERS_SORT](state, { sort, direction }) {
    state.sort = sort;
    state.sortDirection = direction;
  },

  /**
   * @name m SET_ACTIVE_RECURRING_WORK_ORDER
   * @summary Updates the `activeRecurringWorkOrder` and resets the
   * `activeRecurringWorkOrderModifiedFields` & `activeRecurringWorkOrderErrors` properties
   * @method
   * @param {Object} state Current state
   * @param {RecurringWorkOrder} recurringWorkOrder The new Active {@link RecurringWorkOrder}
   */
  [m.SET_ACTIVE_RECURRING_WORK_ORDER](state, recurringWorkOrder) {
    state.activeRecurringWorkOrder = recurringWorkOrder;
    state.activeRecurringWorkOrderModifiedFields =
      !recurringWorkOrder || recurringWorkOrder.id ? {} : { ...recurringWorkOrder };
    state.activeRecurringWorkOrderErrors = [];
  },

  /**
   * @name m SET_ACTIVE_RECURRING_WORK_ORDER_STATE
   * @summary Updates the `activeRecurringWorkOrderState` property which holds the current status of the
   * based on the API enum
   * @method
   * @param {Object} state Current state
   * @param {enums/API} activeRecurringWorkOrderState The current activeRecurringWorkOrderState (e.g. loading, success, failure)
   */
  [m.SET_ACTIVE_RECURRING_WORK_ORDER_STATE](state, activeRecurringWorkOrderState) {
    state.activeRecurringWorkOrderState = activeRecurringWorkOrderState;
  },

  /**
   * @name m SET_ACTIVE_RECURRING_WORK_ORDER_ERRORS
   * @summary Updates the `activeRecurringWorkOrderErrors` property which holds any errors related to activeRecurringWorkOrder
   * @method
   * @param {Object} state Current state
   * @param {string[]} errors An array of error messages
   */
  [m.SET_ACTIVE_RECURRING_WORK_ORDER_ERRORS](state, errors) {
    state.activeRecurringWorkOrderErrors = errors;
  },

  /**
   * @name m SET_ACTIVE_RECURRING_WORK_ORDER_VALID
   * @summary Updates the `activeRecurringWorkOrderValid` property which holds a boolean for the validation status
   * of the `activeRecurringWorkOrder`.
   * @method
   * @param {Object} state Current state
   * @param {boolean} valid True if the `activeRecurringWorkOrder` is valid.
   */
  [m.SET_ACTIVE_RECURRING_WORK_ORDER_VALID](state, valid) {
    state.activeRecurringWorkOrderValid = valid;
  },

  /**
   * @name m UPDATE_ACTIVE_RECURRING_WORK_ORDER_MODIFIED_FIELDS
   * @summary Adds the given field/value pair to the `activeRecurringWorkOrderModifiedFields` collection, which
   * will be sent as a PATCH to the API. If the given `value` equals the one on the `activeRecurringWorkOrder`
   * object (i.e. it hasn't actually changed, or has been reverted back to it's original) then we
   * remove that field from the modifiedFields collection
   * @method
   * @param {Object} state Current state
   * @param {object} payload Object containing a `field` and `value` properties.
   */
  [m.UPDATE_ACTIVE_RECURRING_WORK_ORDER_MODIFIED_FIELDS](state, { field, value }) {
    // if the value matches the existing one then we remove the field from the modifiedFields obj
    if (state.activeRecurringWorkOrder && state.activeRecurringWorkOrder[field] === value) {
      delete state.activeRecurringWorkOrderModifiedFields[field];
    } else {
      state.activeRecurringWorkOrderModifiedFields[field] = value;
    }
  },

  /**
   * @name m CLEAR_ACTIVE_RECURRING_WORK_ORDER_MODIFIED_FIELDS
   * @summary Empties the `activeRecurringWorkOrderModifiedFields` object. Used to ensure we're on a clean slate
   * after navigating to a RecurringWorkOrder Display screen,
   * @method
   * @param {Object} state Current state
   */
  [m.CLEAR_ACTIVE_RECURRING_WORK_ORDER_MODIFIED_FIELDS](state) {
    state.activeRecurringWorkOrderModifiedFields = {};
  },
};
