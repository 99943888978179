/**
 * A CostCode definition
 * @typedef {Object} CostCode
 * @property {number} id -
 * @property {string} name -
 * @property {string} code -
 * @property {string} symbol -
 */

export default {
  costCodes: [],
  costCodesState: null,

  filter: {},

  meta: {},

  activeCostCode: null,
  activeCostCodeState: null,
  activeCostCodeErrors: [],
  activeCostCodeValid: false,
  activeCostCodeModifiedFields: {},
};
