import {createStore} from 'vuex';
import VuexPersistence from 'vuex-persist';
import merge from 'deepmerge';
import getStorage from '@/util/storage.js';
import SessionCreator from '../enums/Session.js';
import authorization from './modules/authorization/index.js';
import viewEvents from './modules/viewEvents/index.js';
import error from './modules/error/index.js';
import types from './types/authorization.js';

const m = types.mutations;

const debug = import.meta.env.NODE_ENV !== 'production';
const storage = getStorage();
const SESSION_STATUS = SessionCreator();

const vuexCookie = new VuexPersistence({
  strictMode: true,
  restoreState: (key) => {
    const data = JSON.parse(storage.getItem(key));

    // if the `session_status` value we're restoring is 'LOGGING_IN' then we clear it, otherwise
    // we'll get stuck in a logging in loop
    if (
      data &&
      data.authorization &&
      data.authorization.session_status &&
      SESSION_STATUS.LOGGING_IN.eql(data.authorization.session_status)
    ) {
      data.authorization.session_status = null;
    }

    return data;
  },
  saveState: (key, state) => storage.setItem(key, JSON.stringify(state)),

  modules: ['authorization'],
  filter: (mutation) =>
    mutation.type === m.SET_SESSION ||
    mutation.type === m.SET_SESSION_STATUS ||
    mutation.type === m.SET_SESSION_USER ||
    mutation.type === m.SET_SESSION_USER_ID ||
    mutation.type === m.SET_OTP_ENABLED ||
    mutation.type === m.PATCH_SESSION_USER,
});

// Hacking the RESTORE_MUTATION method for VuexPersistence to work for Vue 3
vuexCookie.RESTORE_MUTATION = function RESTORE_MUTATION(state, savedState) {
  const mergedState = merge(state, savedState || {}, this._mergeOption);
  for (var _i2 = 0, _Object$keys2 = Object.keys(mergedState); _i2 < _Object$keys2.length; _i2++) {
    var propertyName = _Object$keys2[_i2];
    state[propertyName] = mergedState[propertyName];
  }
};

const moduleFiles = import.meta.glob('./modules/**/*.js', { eager: true })
let modules = Object.entries(moduleFiles).reduce((modules, [path, module]) => {
  // Extract module name from path './modules/moduleName/index.js'
  const match = path.match(/\.\/modules\/(.*)\/index\.js$/i)
  if (match && match[1]) {
    const moduleName = match[1]
    modules[moduleName] = module.default
  }
  return modules
}, {});

modules = {...modules, authorization, viewEvents, error};

export default createStore({
  modules,
  strict: debug,
  plugins: [vuexCookie.plugin],
  mutations: {
    RESTORE_MUTATION: vuexCookie.RESTORE_MUTATION,
  }
});


