import Base from './Base.js';

export default function WorkOrderStateStatusCreator(i18n) {
  class WorkOrderStateStatus extends Base {}

  WorkOrderStateStatus.initEnum({
    TRIAGING: {
      value: 'Triaging',
      display: i18n.global.t('enums.WorkOrderCostStatus.triaging'),
      variant: 'secondary',
    },
    CLOSED: {
      value: 'Closed',
      display: i18n.global.t('enums.WorkOrderCostStatus.closed'),
      variant: 'success',
    },
    SOURCING: {
      value: 'Sourcing',
      display: i18n.global.t('enums.WorkOrderCostStatus.sourcing'),
      variant: 'info',
    },
    IN_PROGRESS: {
      value: 'In Progress',
      display: i18n.global.t('enums.WorkOrderCostStatus.inProgress'),
      variant: 'primary',
    },
    REVIEW: {
      value: 'Review',
      display: i18n.global.t('enums.WorkOrderCostStatus.review'),
      variant: 'warning',
    },
    INVOICING: {
      value: 'Invoicing',
      display: i18n.global.t('enums.WorkOrderCostStatus.invoicing'),
      variant: 'light',
    },
  });

  return WorkOrderStateStatus;
}
