<template>
  <div>
    <div class="text-center">
      <p>
        {{ $t('components.auth.otp.setup.qrCode.instruction') }}
      </p>
      <p>
        {{ $t('components.auth.otp.setup.qrCode.prompt') }}
      </p>
      <img :src="qrCode" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'QrCode',

  props: {
    qrCode: {
      type: String,
      default: '',
    },
  },
};
</script>
