<template>
  <div>
    <!-- if it's read only, we just display the value and bypass the Flatpickr -->
    <b-form-input
      v-if="readOnly"
      :value="readOnlyValue"
      disabled
    />

    <!-- if it allows empty, we wrap the Flatpickr input in an input group and add a clear icon -->
    <b-input-group
      v-else-if="allowEmpty && !readOnly"
      class="date-picker form-control"
      @blur.stop="() => {}"
    >
      <FlatPickr
        v-bind="$attrs"
        ref="control"
        :config="mergedConfig"
        :model-value="newValue"
      />
      <b-input-group-append v-if="allowEmpty && !readOnly">
        <button class="btn btn-light" type="button" @click="clearDate">
          <font-awesome-icon :icon="['far', 'times']" size="lg" />
        </button>
      </b-input-group-append>
    </b-input-group>

    <!-- otherwise we just display the Flatpickr input -->
    <FlatPickr
      v-else
      v-bind="$attrs"
      ref="control"
      :model-value="newValue"
      :config="mergedConfig"
    />
  </div>
</template>

<script>
import FlatPickr from 'vue-flatpickr-component';
import { BFormInput, BInputGroup, BInputGroupAppend } from 'bootstrap-vue';
import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { mapState } from 'vuex';
import 'flatpickr/dist/flatpickr.css';

// dependent on utc plugin
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(relativeTime);
dayjs.extend(timezone);
dayjs.extend(LocalizedFormat);

export default {
  components: {
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    FlatPickr,
  },
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Date],
      default: '',
    },
    allowEmpty: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => {},
    },
    endOfDay: {
      type: Boolean,
      default: false,
    },
    state: {
      type: Boolean,
      default: null,
    },
    allowInput: {
      type: Boolean,
      default: true,
    },
    timezoneSensitive: {
      type: Boolean,
      default: true,
    },
    convertToTime: {
      type: Boolean,
      default: true,
    },
    timezone: {
      type: String,
      default: null,
    },
  },
  computed: {
    tzToUse() {
      return this.timezone ?? this.userTimezone;
    },
    newValue() {
      if (this.value && this.timezoneSensitive) {
        return dayjs.utc(this.value).tz(this.tzToUse).format('YYYY-MM-DDTHH:mm:ss');
      }
      if (this.value) {
        return this.value;
      }
      return '';
    },
    ...mapState({
      userTimezone: (state) => state.authorization.user.timezone,
    }),
    defaultConfig() {
      if (this.readOnly) {
        return {}
      }

      const timeConfig = {
        time_24hr: true,
        enableSeconds: false,
      };

      return {
        allowInput: this.allowInput,
        wrap: this.allowEmpty,
        onOpen(_a, _b, instance) {
          // todo: confirm if this is still needed
          // prevent focusin from bubbling up far enough for the bootstrap modal to notice
          instance.calendarContainer.addEventListener('focusin', (e) => {
            e.stopPropagation();
          });
        },
        altInput: true,
        dateFormat: this.config?.enableTime ? 'Y-m-dTH:i:S' : 'Y-m-d',
        altFormat: this.config?.enableTime ? 'd/m/Y H:i' : 'd/m/Y',
        minuteIncrement: 1,
        disableMobile: true,
        time_24hr: true,

        onReady: () => {
          this.$nextTick(() => {
            this.updateValidityState();

            if (this.newValue) {
              this.emitConverted(dayjs(this.newValue));
            }

            // add classes to mobile input so it matches non-mobile input
            // eslint-disable-next-line no-unused-expressions
            this.$refs.control.fp.mobileInput?.classList.add(
              'form-control',
              'input-group',
              'input',
            );

            this.$refs.control.fp.calendarContainer.translate = false;
          });
        },
        onChange: (dates, currentdatestring, picker) => this.parsePicker(picker),
        onClose: (dates, currentdatestring, picker) => this.parsePicker(picker),

        ...timeConfig,
      };
    },
    mergedConfig() {
      return { ...this.defaultConfig, ...this.config };
    },
    readOnlyValue() {
      if (!this.value) {
        return null;
      }

      return this.config?.enableTime
        ? this.$formatDate(this.value, 'DD/MM/YYYY HH:mm')
        : this.$formatDate(this.value, 'DD/MM/YYYY');
    }
  },
  watch: {
    state() {
      this.updateValidityState();
    },
  },
  methods: {
    parsePicker(picker) {
      if (!picker.altInput.value) {
        this.$emit('input', '');
        return;
      }

      this.emitConverted(
        this.config?.enableTime
          ? dayjs(picker.altInput.value, 'DD/MM/YYYY HH:mm')
          : dayjs(picker.altInput.value, 'DD/MM/YYYY'),
      );
    },
    emitConverted(value) {
      const minuteAdjustedTime = this.addMinutesAndSeconds(value);

      if (this.timezoneSensitive) {
        const formattedUtcTime = this.convertToUtc(minuteAdjustedTime);
        this.$emit('input', formattedUtcTime);
      } else {
        const formattedUtcTime = this.convertToTime
          ? dayjs(minuteAdjustedTime).format('YYYY-MM-DDTHH:mm:ss')
          : dayjs(minuteAdjustedTime).format('YYYY-MM-DD');
        this.$emit('input', formattedUtcTime);
      }
      this.$emit('blur');
    },
    convertToUtc(parsedTime) {
      const timezoneSensitiveTime = parsedTime.tz(this.tzToUse);
      const offsetInMinutes = timezoneSensitiveTime.utcOffset();
      return dayjs(parsedTime)
        .subtract(offsetInMinutes, 'minutes')
        .format('YYYY-MM-DDTHH:mm:ss');
    },
    addMinutesAndSeconds(time) {
      if (!this.config?.enableTime && this.endOfDay) {
        return time.endOf('day');
      }
      return time;
    },
    updateValidityState() {
      const input = this.$el.querySelector('.input');

      if (input) {
        input?.classList.remove('is-invalid', 'is-valid');

        if (this.state === true) {
          input?.classList.add('is-valid');
        } else if (this.state === false) {
          input?.classList.add('is-invalid');
        }
      }
    },
    clearDate() {
      this.$emit('input', '');
    },
  },
};
</script>

<style lang="scss">
.datepicker {
  .btn-light {
    border-color: #ced4da;
  }
}

.input-group > .input-group.form-control {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.date-picker.form-control {
  border: none !important;
  background: none !important;
  padding: 0 !important;
  margin: 0 !important;
  height: auto !important;
  border-radius: 0 !important;
  transition: none !important;
}

// we need to move the valid/invalid icons in a bit on mobile to avoid overlap
// with Chrome for Android's arrow icon. No icon is shown on iOS.
.date-picker input.flatpickr-input.flatpickr-mobile {
  &.is-valid,
  &.is-invalid {
    padding-right: 3.25rem;
    background-position: right calc(0.375em + 0.875rem) center;
  }
}
</style>
