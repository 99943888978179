import APIError from '../../../errors/APIError.js';
import types from '../../types/tag.js';
import TagAPI from '../../../services/TagAPI.js';
import ApiStatusCreator from '../../../enums/API.js';
const API_STATUS = ApiStatusCreator();

const a = types.actions;
const m = types.mutations;

export default {
  /**
   * Loads all Tags
   * @param {Object} context
   * @return {Tags[]} An array of {@link Tag} objects.
   */
  async [a.GET_TAGS]({ commit, state }, { page, sort, direction, filter }) {
    commit(m.SET_TAGS_STATUS, API_STATUS.LOADING);

    try {
      const tags = await TagAPI.getTags(page, sort, direction, filter);

      commit(m.SET_TAGS_STATUS, API_STATUS.SUCCESS);
      commit(m.SET_TAGS, tags);
    } catch (error) {
      commit(m.SET_TAGS_STATUS, API_STATUS.FAILURE);

      let message = 'An error occurred while fetching these Tags. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.tags;
  },

  /**
   * Creates a new tag
   * @param {Object} context
   * @param {object} tag A {@link tag} object
   * @return {Tag} A {@link Tag} object.
   */
  async [a.CREATE_TAG]({ commit, state }, { tag }) {
    try {
      commit(m.SET_ACTIVE_TAG_ERRORS, []);
      commit(m.SET_ACTIVE_TAG_STATE, API_STATUS.SAVING);

      const tagResponse = await TagAPI.createTag(tag);

      commit(m.SET_ACTIVE_TAG_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_TAG, tagResponse);
      commit(m.ADD_LOCAL_TAG, tagResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_TAG_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while creating this Tag. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeTag;
  },

  /**
   * Updates an existing Tag
   * @param {Object} context
   * @param {object} tag The {@link Tag} object to update
   * @param {object} modifiedFields An object with the key/values for the fields to update.
   * @return {Tag} A {@link Tag} object.
   */
  async [a.UPDATE_TAG]({ commit, state }, { tag, modifiedFields }) {
    try {
      commit(m.SET_ACTIVE_TAG_ERRORS, []);
      commit(m.SET_ACTIVE_TAG_STATE, API_STATUS.SAVING);

      const tagResponse = await TagAPI.updateTag(tag.id, modifiedFields);

      commit(m.SET_ACTIVE_TAG_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_TAG, tagResponse);
      commit(m.UPDATE_LOCAL_TAG, tagResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_TAG_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while updating this Tag. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeTag;
  },

  /**
   * Deletes a Tag
   * @param {Object} context
   * @param {Tag} tag The {@link Tag} object to delete
   * @return {Tag} A {@link Tag} object.
   */
  async [a.DELETE_TAG]({ commit }, { tag }) {
    try {
      commit(m.SET_ACTIVE_TAG_ERRORS, []);
      commit(m.SET_ACTIVE_TAG_STATE, API_STATUS.DELETING);

      await TagAPI.deleteTag(tag.id);

      commit(m.SET_ACTIVE_TAG_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_TAG, null);

      commit(m.REMOVE_LOCAL_TAG, tag);
    } catch (error) {
      commit(m.SET_ACTIVE_TAG_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while deleting this Tag. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return true;
  },
};
