export default {
  /**
   * @name g getActiveContractor
   * @desc Returns the `activeContractor` object
   * @param  {object}  state Current state
   * @return {object}
   */
  getActiveContractor(state) {
    return state.activeContractor;
  },

  /**
   * @name g isActiveContractorPhantom
   * @desc Returns true if the activeContractor is 'phantom' (i.e. has not been saved).
   * @param  {object}  state Current state
   * @return {Boolean}
   */
  isActiveContractorPhantom(state) {
    return !(state.activeContractor && state.activeContractor.id);
  },
};
