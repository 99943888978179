import Base from './Base';

export default function WorkOrderWorkflowPrototype(i18n) {
  class WorkOrderWorkflowPrototype extends Base {
    static get Namespace() { return 'App\\Lib\\Workflow\\Prototype\\'; }

  }

  WorkOrderWorkflowPrototype.initEnum({
    ContractorWorkflow: {
      value: 'App\\Lib\\Workflow\\Prototype\\ContractorWorkflow',
      display: 'ContractorWorkflow',
    },
    CPPMWorkflow: {
      value: 'App\\Lib\\Workflow\\Prototype\\CPPMWorkflow',
      display: 'CPPMWorkflow',
    },
    InternalEngineerWorkflow: {
      value: 'App\\Lib\\Workflow\\Prototype\\InternalEngineerWorkflow',
      display: 'InternalEngineerWorkflow',
    },
    PartOrderWorkflow: {
      value: 'App\\Lib\\Workflow\\Prototype\\PartOrderWorkflow',
      display: 'PartOrderWorkflow',
    },
    SimpleAssignableWorkflow: {
      value: 'App\\Lib\\Workflow\\Prototype\\SimpleAssignableWorkflow',
      display: 'SimpleAssignableWorkflow',
    },
    SimpleNonAssignableWorkflow: {
      value: 'App\\Lib\\Workflow\\Prototype\\SimpleNonAssignableWorkflow',
      display: 'SimpleNonAssignableWorkflow',
    },
    TriageWorkflow: {
      value: 'App\\Lib\\Workflow\\Prototype\\TriageWorkflow',
      display: 'TriageWorkflow',
    },
  });

  return WorkOrderWorkflowPrototype;
}
