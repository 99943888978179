export default {
  /**
   * @name g isActiveWorkOrderTypePhantom
   * @desc Returns true if the activeWorkOrderType is 'phantom' (i.e. has not been saved).
   * @param  {object}  state Current state
   * @return {Boolean}
   */
  isActiveWorkOrderTypePhantom(state) {
    return !(state.activeWorkOrderType && state.activeWorkOrderType.id);
  },
};
