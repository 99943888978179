import flattenTree from '@/util/flattenTree.js';
import types from '../../types/maintainableEntity.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_MAINTAINABLE_ENTITIES
   * @summary Updates the `maintainableEntities` property which holds the current maintainableEntities being shown
   * @method
   * @param {Object} state Current state
   * @param maintainableEntities
   */
  [m.SET_MAINTAINABLE_ENTITIES](state, maintainableEntities) {
    state.maintainableEntities = maintainableEntities;
  },

  /**
   * @name m SET_MAINTAINABLE_ENTITIES_TREE
   * @summary Updates the `maintainableEntities` property which holds the current maintainableEntities being shown
   * @method
   * @param {Object} state Current state
   * @param maintainableEntitiesTree
   */
  [m.SET_MAINTAINABLE_ENTITIES_TREE](state, maintainableEntitiesTree) {
    state.maintainableEntitiesTree = maintainableEntitiesTree;
  },

  /**
   * @name m SET_MAINTAINABLE_ENTITIES_META
   * @summary Updates the `maintainableEntities` property which holds the current maintainableEntities being shown
   * @method
   * @param {Object} state Current state
   * @param meta
   */
  [m.SET_MAINTAINABLE_ENTITIES_META](state, meta) {
    state.maintainableEntitiesMeta = meta;
  },

  /**
   * @name m SET_MAINTAINABLE_ENTITIES_STATE
   * @summary Updates the `maintainableEntities` property which holds the current maintainableEntities being shown
   * @method
   * @param {Object} state Current state
   * @param status
   */
  [m.SET_MAINTAINABLE_ENTITIES_STATE](state, status) {
    state.maintainableEntitiesState = status;
  },

  /**
   * @name m SET_MAINTAINABLE_ENTITIES_ERRORS
   * @summary Updates the `maintainableEntities` property which holds the current maintainableEntities being shown
   * @method
   * @param {Object} state Current state
   * @param errors
   */
  [m.SET_MAINTAINABLE_ENTITIES_ERRORS](state, errors) {
    // eslint-disable-next-line no-unused-expressions
    Array.isArray(errors)
      ? (state.activeMaintainableEntityErrors = errors)
      : state.activeMaintainableEntityErrors.push(errors);
  },

  /**
   * @name m SET_MAINTAINABLE_ENTITIES_SORT
   * @summary Updates the `sort` and `direction` properties
   * @method
   * @param {Object} state Current state
   * @param {Object} data The sort data (`sort` and `direction`)
   */
  [m.SET_MAINTAINABLE_ENTITIES_SORT](state, { sort, direction }) {
    state.sort = sort;
    state.sortDirection = direction;
  },

  /**
   * @name m SET_MAINTAINABLE_ENTITIES_FILTER
   * @summary Updates the `filter` property which holds the collection of filters currently active
   * on the MaintainableEntitys collection
   * @method
   * @param {Object} state Current state
   * @param {Object} filter The filters to be applied
   */
  [m.SET_MAINTAINABLE_ENTITIES_FILTER](state, filter) {
    state.filter = filter;
  },

  /**
   * @name m SET_ACTIVE_MAINTAINABLE_ENTITY
   * @summary Updates the `activeMaintainableEntity` and resets the
   * `activeMaintainableEntityModifiedFields` & `activeMaintainableEntityErrors` properties
   * @method
   * @param {Object} state Current state
   * @param {MaintainableEntity} maintainableEntity The new Active {@link MaintainableEntity}
   */
  [m.SET_ACTIVE_MAINTAINABLE_ENTITY](state, maintainableEntity) {
    state.activeMaintainableEntity = maintainableEntity;
    state.activeMaintainableEntityModifiedFields =
      !maintainableEntity || maintainableEntity.id ? {} : { ...maintainableEntity };
    state.activeMaintainableEntityErrors = [];
  },

  /**
   * @name m SET_ACTIVE_MAINTAINABLE_ENTITY_STATE
   * @summary Updates the `activeMaintainableEntityState` property which holds the current loading status of
   * the active maintainableEntity based on the API enum
   * @method
   * @param {Object} state Current state
   * @param {enums/API} activeMaintainableEntityState The current state (e.g. loading, success, failure)
   */
  [m.SET_ACTIVE_MAINTAINABLE_ENTITY_STATE](state, activeMaintainableEntityState) {
    state.activeMaintainableEntityState = activeMaintainableEntityState;
  },

  /**
   * @name m SET_ACTIVE_MAINTAINABLE_ENTITY_ERRORS
   * @summary Updates the `activeMaintainableEntityErrors` property which holds any errors related to
   * activeMaintainableEntity
   * @method
   * @param {Object} state Current state
   * @param {string[]} errors An array of error messages
   */
  [m.SET_ACTIVE_MAINTAINABLE_ENTITY_ERRORS](state, errors) {
    // eslint-disable-next-line no-unused-expressions
    Array.isArray(errors)
      ? (state.activeMaintainableEntityErrors = errors)
      : state.activeMaintainableEntityErrors.push(errors);
  },

  /**
   * @name m SET_ACTIVE_MAINTAINABLE_ENTITY_VALID
   * @summary Updates the `activeMaintainableEntityValid` property which holds a boolean for the validation
   * status of the `activeMaintainableEntity`.
   * @method
   * @param {Object} state Current state
   * @param {boolean} valid True if the `activeMaintainableEntity` is valid.
   */
  [m.SET_ACTIVE_MAINTAINABLE_ENTITY_VALID](state, valid) {
    state.activeMaintainableEntityValid = valid;
  },

  /**
   * @name m UPDATE_ACTIVE_MAINTAINABLE_ENTITY_MODIFIED_FIELDS
   * @summary Adds the given field/value pair to the `activeMaintainableEntityModifiedFields` collection,
   * which will be sent as a PATCH to the API. If the given `value` equals the one on the
   * `activeMaintainableEntity` object (i.e. it hasn't actually changed, or has been reverted back to it's
   * original) then we remove that field from the modifiedFields collection
   * @method
   * @param {Object} state Current state
   * @param {object} payload Object containing a `field` and `value` properties. Also has an isAttribute
   * flag that checks whether this field is part of the sub-form of maintainableEntities.attributes.
   */
  [m.UPDATE_ACTIVE_MAINTAINABLE_ENTITY_MODIFIED_FIELDS](
    state,
    { field, value, isAttribute = false },
  ) {
    let unchanged = true;
    if (isAttribute) {
      state.activeMaintainableEntityModifiedFields.attributes = {
        ...(state.activeMaintainableEntityModifiedFields.attributes ?? {}),
        [field]: value,
      };
    } else {
      unchanged =
        !state.activeMaintainableEntity ||
        state.activeMaintainableEntity[field] === value ||
        (state.activeMaintainableEntity[field] === null && value === '');
      // if the value matches the existing one then we remove the field from the modifiedFields obj
      if (unchanged) {
        delete state.activeMaintainableEntityModifiedFields[field];
      } else {
        state.activeMaintainableEntityModifiedFields[field] = value;
      }
    }
  },

  /**
   * @name m CLEAR_ACTIVE_MAINTAINABLE_ENTITY_MODIFIED_FIELDS
   * @summary Empties the `activeMaintainableEntityModifiedFields` object. Used to ensure we're on a clean
   * slate after navigating to a MaintainableEntity Display screen,
   * @method
   * @param {Object} state Current state
   */
  [m.CLEAR_ACTIVE_MAINTAINABLE_ENTITY_MODIFIED_FIELDS](state) {
    state.activeMaintainableEntityModifiedFields = {};
  },

  /**
   * @name m INSERT_MAINTAINABLE_ENTITY_TREE_ITEM
   * @summary Adds a new ME item to the `children` array of the specified parent. This is used to
   * avoid a full refresh of the tree when we have all the data available locally.
   * @method
   * @param {Object} state Current state
   * @param {number} meIndex index in the parent's children of item that has been cloned.
   * @param {Object} `parent` is the node that the `items` are added as a child to. `items` are cloned
   */
  [m.INSERT_MAINTAINABLE_ENTITY_TREE_ITEM](state, { parent, items, meIndex }) {
    const itemsArray = Array.isArray(items) ? items : [items];
    const flatTree = flattenTree(state.maintainableEntitiesTree);

    const parentToAddItemTo = flatTree.find((item) => item.id === parent.id);

    itemsArray.forEach((item) => {
      // we want to add the item's children (if any) along
      // with the item and therefore we need the tree version of the item.
      // If the item isn't in the tree we set the children property to it.

      const itemToAddToParent = flatTree.find((i) => i.id === item.id) || item;
      itemToAddToParent.children = itemToAddToParent.children || [];

      if (meIndex || meIndex === 0) {
        // if we have cloned an item we insert clone underneath it. if not add to the end of list.
        parentToAddItemTo.children.splice(meIndex + 1, 0, itemToAddToParent);
      } else {
        parentToAddItemTo.children.push(itemToAddToParent);
      }
    });
  },
  [m.REMOVE_MAINTAINABLE_ENTITY_TREE_ITEM](state, { parentId, childId }) {
    const flatTree = flattenTree(state.maintainableEntitiesTree);
    const parent = flatTree.find((i) => i.id === parentId);
    parent.children = parent.children.filter((i) => i.id !== childId);
  },

  [m.UPDATE_MAINTAINABLE_ENTITY_TREE_ITEM](state, { maintainableEntityId, field, value }) {
    const flatTree = flattenTree(state.maintainableEntitiesTree);

    const itemToUpdate = flatTree.find((me) => me.id === maintainableEntityId);

    itemToUpdate[field] = value;
  },
};
