import Base from './Base.js';

export default function OpenQuoteRequestsFilterCreator(i18n) {
  class OpenQuoteRequestsFilterOptions extends Base {}

  OpenQuoteRequestsFilterOptions.initEnum({
    NONE: {
      value: 'NONE',
      display: i18n.global.t('enums.OpenQuoteRequestsFilterOptions.none'),
    },
    NO_RESPONSE: {
      value: 'NO_RESPONSE',
      display: i18n.global.t('enums.OpenQuoteRequestsFilterOptions.noResponse'),
    },
    PARTIAL_RESPONSE: {
      value: 'PARTIAL_RESPONSE',
      display: i18n.global.t('enums.OpenQuoteRequestsFilterOptions.partialResponse'),
    },
    FULL_RESPONSE: {
      value: 'FULL_RESPONSE',
      display: i18n.global.t('enums.OpenQuoteRequestsFilterOptions.fullResponse'),
    },
  });

  return OpenQuoteRequestsFilterOptions;
}
