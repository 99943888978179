import Base from './Base';

export default function AssetStatusCreator(i18n) {
  class AssetStatus extends Base {}

  AssetStatus.initEnum({
    DISPOSED: {
      value: 'Disposed',
      display: 'Disposed',
    },
    IN_REPAIRS: {
      value: 'In repairs',
      display: 'In repairs',
    },
    IN_STORAGE: {
      value: 'In storage',
      display: 'In storage',
    },
    OUT_OF_SERVICE: {
      value: 'Out of service',
      display: 'Out of service',
    },
    IN_SERVICE: {
      value: 'In service',
      display: 'In service',
    },
  });

  return AssetStatus;
}
