export default {
  path: '/account-settings',
  name: 'accountSettings',
  meta: {
    requiresAuth: true,
    bcDynamic: false,
    bcGetter: 'getActiveUser',
    bcLinkText: 'Account Settings',
    layout: 'DefaultLayout',
    storeModules: ['users', 'site'],
  },
  component: () => import(/* webpackPrefetch: true */ '@/views/AccountSettings.vue'),
};
