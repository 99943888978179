import axios from 'axios';
import createAPIParams from '@/util/createAPIParams.js';

class WorkOrderPriorityAPI {
  static async getForExternalForm(accessToken) {
    const response = await axios({
      method: 'GET',
      url: `work_order_form/external/${accessToken}/work_order_priorities`,
      responseType: 'json',
    });

    return response.data;
  };

  static async getWorkOrderPriorities(page, sort, direction) {
    const response = await axios({
      method: 'GET',
      url: 'work_order_priority',
      responseType: 'json',
      params: createAPIParams(page || 'all', sort, direction),
    });

    return response.data;
  }

  static async createWorkOrderPriority(workOrderPriority) {
    const response = await axios({
      method: 'POST',
      url: 'work_order_priority',
      responseType: 'json',
      data: workOrderPriority,
    });

    return response.data;
  }

  static async updateWorkOrderPriority(workOrderPriorityId, modifiedFields) {
    const response = await axios({
      method: 'PATCH',
      url: `work_order_priority/${workOrderPriorityId}`,
      responseType: 'json',
      data: modifiedFields,
    });

    return response.data;
  }

  static async deleteWorkOrderPriority(workOrderPriorityId) {
    await axios({
      method: 'DELETE',
      url: `work_order_priority/${workOrderPriorityId}`,
      responseType: 'json',
    });

    return true;
  }
}

export default WorkOrderPriorityAPI;
