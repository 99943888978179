import types from '../../types/invoice.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_INVOICES_FILTER
   * @summary Updates the `filter` property which holds the collection of filters currently active
   * on the INVOICES collection
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The filters to be applied
   */
  [m.SET_INVOICES_FILTER](state, filter) {
    state.filter = filter;
  },

  /**
   * @name m SET_INVOICES_SORT
   * @summary Updates the `sort` and `direction` properties
   * @method
   * @param {Object} state Current state
   * @param {Object} data The sort data (`sort` and `direction`)
   */
  [m.SET_INVOICES_SORT](state, { sort, direction }) {
    state.sort = sort;
    state.sortDirection = direction;
  },
};
