<template>
  <div>
    <b-button
      :id="popoverId"
      class="d-flex account-menu-button"
      data-ref="accountMenu"
      variant="none"
      ><div class="initials-container">
        <div translate="no" class="initials">{{ userInitials }}</div>
      </div>

      <span class="sr-only">
        {{ $t('components.app.MobileHeader.openNavigation') }}
      </span>

      <div v-if="sidebarVisible" class="caret">
        <font-awesome-icon :icon="['fas', 'caret-down']" />
      </div>
    </b-button>

    <BPopover
      ref="popover"
      custom-class="popover account-menu-popover"
      placement="bottom"
      :target="popoverId"
      triggers="click blur"
      boundary-padding="5"
    >
      <ul>
        <li class="info">
          <span :key="'name_' + user.name" class="name" translate="no">{{ user.name }}</span>
          <span :key="'role_' + user.role ? user.role.name : ''" class="role">{{ user.role ? user.role.name : '' }}</span>
        </li>
        <hr />
        <li @click="showAccountSettingsPage">
          <span>{{ $t('components.app.Navigation.accountSettings') }}</span>
          <font-awesome-icon :icon="['far', 'cog']" class="ml-1" />
        </li>
        <li v-if="!userIsSSO" data-ref="changePasswordButton" @click="showPasswordModal">
          <span>{{ $t('components.app.Navigation.changePassword') }}</span>
          <font-awesome-icon :icon="['far', 'lock']" class="ml-1" />
        </li>
        <li v-if="user && !otp_enabled && !userIsSSO" @click="showEnable2faModal">
          <span>{{ $t('components.app.Navigation.enable2fa') }}</span>
          <font-awesome-icon :icon="['far', 'lock']" class="ml-1" />
        </li>
        <li v-if="user && otp_enabled && !userIsSSO" @click="showDisable2faModal">
          <span>{{ $t('components.app.Navigation.disable2fa') }}</span>
          <font-awesome-icon :icon="['far', 'lock']" class="ml-1" />
        </li>
        <li id="nav-logout-button" @click="onLogoutClick">
          <span>{{ $t('components.app.Navigation.logOut') }}</span>
          <font-awesome-icon :icon="['far', 'sign-out-alt']" class="ml-1" />
        </li>
      </ul>
    </BPopover>
    <ChangePasswordModal
      v-if="user && user.id"
      ref="changePasswordModal"
      :require-old-password="true"
      :user-id="user.id"
    />
    <OtpSetupModal v-if="user && user.id" ref="otpSetupModal" :user="user"></OtpSetupModal>
    <OtpDisableModal v-if="user && user.id" ref="otpDisableModal"></OtpDisableModal>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { BButton, BPopover } from 'bootstrap-vue';
import authorizationTypes from '@/store/types/authorization.js';
import ChangePasswordModal from '@/ux/form/ChangePasswordModal.vue';
import OtpSetupModal from '@/components/auth/otp/SetupModal.vue';
import OtpDisableModal from '@/components/auth/otp/DisableModal.vue';

export default {
  name: 'AccountMenu',
  inject: ["mq"],
  components: {
    BButton,
    BPopover,
    ChangePasswordModal,
    OtpSetupModal,
    OtpDisableModal,
  },
  props: {
    popoverId: {
      type: String,
      default: 'popover',
    },
  },

  computed: {
    ...mapState({
      user: (state) => state.authorization.user || {},
      session_status: (state) => state.authorization.session_status,
      otp_enabled: (state) => state.authorization.otp_enabled,
    }),
    userIsSSO() {
      return this.user && this.user.isSSO;
    },
    sidebarVisible() {
      return ['lg', 'xl'].indexOf(this.mq.current) >= 0;
    },
    userInitials() {
      const initials = [];

      if (this.user.name) {
        // Remove any parentheses and square or curly brackets
        const fullName = this.user.name
          .replace(/ *\[[^)]*\]|\([^)]*\)|\{[^)]*\} */g, '')
          .split(' ');
        initials.push(fullName[0].substring(0, 1).toUpperCase());
        if (fullName.length > 1) {
          initials.push(fullName[fullName.length - 1].substring(0, 1).toUpperCase());
        }
      }

      return initials.join('');
    },
  },
  methods: {
    showAccountSettingsPage() {
      this.$router.push({ name: 'accountSettings' });
      this.$refs.popover.$emit('close');
    },

    showPasswordModal() {
      this.$refs.changePasswordModal.show();
    },

    showEnable2faModal() {
      this.$refs.otpSetupModal.show();
    },

    showDisable2faModal() {
      this.$refs.otpDisableModal.show();
    },

    async onLogoutClick() {
      await this.$store.dispatch(authorizationTypes.actions.LOGOUT_USER);

      ChurnZero.push(['trackEvent', 'Logout']);

      if (this.$enums.SESSION.LOGGED_OUT.eql(this.session_status)) {
        this.$router.push({ name: 'login' });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.caret {
  display: inline-block;
  text-align: center;
  padding: 0.5rem;
}
.initials-container {
  position: relative;
  border-radius: 50%;
  padding: 2px;
  width: 2.5rem;
  height: 2.5rem;
  background-color: $primary;
  .initials {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
  }
  @include media-breakpoint-down(md) {
    width: 2rem;
    height: 2rem;
    font-size: 0.8rem;
  }

  @include media-breakpoint-down(xs) {
    padding-left: 1rem;
  }
}

.popover.account-menu-popover {
  :global(.popover-body) {
    padding: 0 !important;
  }

  ul {
    margin-bottom: 0;
    padding: 0;

    hr {
      margin-top: 0.7rem;
      margin-bottom: 0;
    }

    li {
      list-style-type: none;
      padding-right: 1.1rem;
      padding-left: 1.1rem;
      line-height: 2rem;
      text-align: right;
      &:first-of-type {
        padding-top: 0.5rem;
      }
      &:not(:first-of-type) {
        &:hover {
          background: $primary;
          color: white;
          cursor: pointer;
        }
      }
      &:last-of-type {
        border-radius: 0 0 0.2rem 0.2rem;
      }

      svg {
        vertical-align: -0.125em;
      }
    }
  }
}

.info {
  span {
    display: block;
  }

  span.name {
    font-size: 1rem;
    font-weight: bold;
    margin: 0;
    line-height: 1.2rem;
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }

  span.role {
    font-size: 0.75rem;
    line-height: 0.75rem;
    text-transform: capitalize;

    &::first-letter {
      text-transform: uppercase;
    }
  }
}
</style>
