import axios from 'axios';
import createAPIParams from '@/util/createAPIParams.js';

/**
 * A Landlord definition
 * @typedef {Object} Landlord
 * @property {number} id -
 * @property {string} address
 * @property {string} created_at
 * @property {string} email
 * @property {string} name
 * @property {string} notes
 * @property {string} telephone
 * @property {string} updated_at
 */

/**
 * Interface to the Country API endpoints
 * @class
 * @exports src/services/LandlordAPI
 */
class LandlordAPI {
  /**
   * Retrieves all the Landlords
   * @method
   * @static
   * @return {Promise}          Promise that resolves to an aray of {@link Landlord} items.
   */
  static async getLandlords(page, sort, direction, filter) {
    const response = await axios({
      method: 'GET',
      url: 'landlord',
      responseType: 'json',
      params: createAPIParams(page || 'all', sort, direction, filter),
    });

    return response.data.data;
  }

  /**
   * @summary Hits the create Landlord endpoint.
   * @desc Sends the given landlord data to the create endpoint
   * @param  {Landlord}  landlord A {@link Landlord} object to be saved
   * @return {Promise}      Resolves to the returned full Landlord object
   */
  static async createLandlord(landlord) {
    const response = await axios({
      method: 'POST',
      url: 'landlord',
      responseType: 'json',
      data: landlord,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the PATCH Landlord endpoint.
   * @desc Sends the specified _modified_ fields to the endpoint to be saved.
   * @param  {String|Number}  landlordId The Landlord Id to save
   * @param  {object}  modifiedFields An object with a subset of the
   * {@link Landlord} fields that have been modified and should be saved.
   * @return {Promise}      Resolves to the returned full Landlord object
   */
  static async updateLandlord(landlordId, modifiedFields) {
    const response = await axios({
      method: 'PATCH',
      url: `landlord/${landlordId}`,
      responseType: 'json',
      data: modifiedFields,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the DELETE Landlord endpoint.
   * @param  {String|Number}  landlordId The Landlord Id to delete
   * @return {Promise}
   */
  static async deleteLandlord(landlordId) {
    await axios({
      method: 'DELETE',
      url: `landlord/${landlordId}`,
      responseType: 'json',
    });

    return true;
  }
}

export default LandlordAPI;
