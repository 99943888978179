import Base from './Base.js';

export default function RelativeDateRangeCreator(i18n) {
  class RelativeDateRange extends Base {}

  RelativeDateRange.initEnum({
    TODAY: {
      value: {from: 'start-of-day', to: 'end-of-day'},
      display: i18n.global.t('ux.form.DateRange.relativeOptions.today'),
    },
    LAST_24: {
      value: {from: '-1d', to: 'now'},
      display: i18n.global.t('ux.form.DateRange.relativeOptions.last24Hours'),
    },
    LAST_72: {
      value: {from: '-3d', to: 'now'},
      display: i18n.global.t('ux.form.DateRange.relativeOptions.last72Hours'),
    },
    LAST_7_DAYS: {
      value: {from: '-7d', to: 'now'},
      display: i18n.global.t('ux.form.DateRange.relativeOptions.last7Days'),
    },
    LAST_30_DAYS: {
      value: {from: '-30d', to: 'now'},
      display: i18n.global.t('ux.form.DateRange.relativeOptions.last30Days'),
    },
    LAST_90_DAYS: {
      value: {from: '-90d', to: 'now'},
      display: i18n.global.t('ux.form.DateRange.relativeOptions.last90Days'),
    },
    WEEK_TO_DATE: {
      value: {from: 'start-of-week', to: 'now'},
      display: i18n.global.t('ux.form.DateRange.relativeOptions.weekToDate'),
    },
    MONTH_TO_DATE: {
      value: {from: 'start-of-month', to: 'now'},
      display: i18n.global.t('ux.form.DateRange.relativeOptions.monthToDate'),
    },
    YEAR_TO_DATE: {
      value: {from: 'start-of-year', to: 'now'},
      display: i18n.global.t('ux.form.DateRange.relativeOptions.yearToDate'),
    },
  });

  // TODO: This should be done in component and not in enum class (RelativeDateRangeListOptions)
  // export const RelativeDateRangeListOptions = RelativeDateRange.enumValues.map((v) => ({
  //   value: v.value,
  //   text: v.display,
  // }));

  return RelativeDateRange;
}
