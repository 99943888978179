export default {
  /**
   * @name g isActiveExpensePhantom
   * @desc Returns true if the activeExpense is 'phantom' (i.e. has not been saved).
   * @param  {object}  state Current state
   * @return {Boolean}
   */
  isActiveExpensePhantom(state) {
    return !(state.activeExpense && state.activeExpense.id);
  },
};
