<template>
  <li>
    <button class="d-flex align-items-center" @click="onNodeSelect(node)">
      <div class="value-wrap flex-fill text-left">
        <font-awesome-icon v-if="icon" class="mr-3" :icon="['far', icon]" />
        <span :key="node[displayField]">
          {{ node[displayField] }}
        </span>
      </div>
      <font-awesome-icon v-if="hasChildren" :icon="['far', 'chevron-right']" size="lg" />
    </button>
  </li>
</template>

<script>
/**
 * This component is a single List Item. It holds a button and raises the `nodeclicked` event
 * when clicked.
 * @exports src/ux/list/ListItem
 * @property node {object} The node to represent
 * @property {string} displayField The field of the items to use for display
 * @property {string} icon The name of an icon to display before the node name
 */
export default {
  name: 'ListItem',

  props: {
    node: {
      type: Object,
      default: () => ({ children: [] }),
    },

    icon: {
      type: String,
      default: () => '',
    },

    displayField: {
      type: String,
      default: () => 'name',
    },

    childrenField: {
      type: [String, Function],
      default: () => 'children',
    },
  },

  computed: {
    hasChildren() {
      return this.getChildren(this.node).length > 0;
    },
  },

  methods: {
    onNodeSelect(node) {
      this.$emit('nodeclicked', node);
    },

    getChildren(node) {
      return (
        (typeof this.childrenField === 'function'
          ? this.childrenField(node)
          : node[this.childrenField || 'children']) || []
      );
    },
  },
};
</script>

<style lang="scss" scoped>
li {
  padding: 0.1rem;
  list-style: none;
  width: 100%;
  text-align: left;
  border-bottom: 1px solid #eee;

  button {
    text-align: left;
    width: 100%;
    border: none;
    background: transparent;
    padding: 0.75rem 2rem;
    color: #777;
  }
}
</style>
