/**
 * A Organisation definition
 * @typedef {Object} Organisation
 * @property {number} id
 * @property {string} name
 * @property {string} created_at
 * @property {string} updated_at
 */

export default {
  organisations: [],
  organisationsState: null,

  filter: {},

  meta: {},

  activeOrganisation: null,
  activeOrganisationState: null,
  activeOrganisationErrors: [],
  activeOrganisationValid: false,
  activeOrganisationModifiedFields: {},
};
