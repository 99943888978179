<template>
  <b-form-group
    :label="label"
    :label-for="`id_${_uid}`"
    :description="description"
  >
    <template v-if="field?.required && label" v-slot:label>
      <Required>
        <span :key="label" class="mr-1">{{ label }}</span>
      </Required>
    </template>

    <div :class="$slots.prepend || $slots.append ? 'input-group' : ''">
      <slot name="prepend" />
      <slot
        :id="`id_${_uid}`"
        :state="errorState"
        :aria-labelledBy="`feedback_${_uid}`"
        :value="field?.$model"
      />
      <slot name="append" />
    </div>

    <b-form-invalid-feedback
      v-if="errorState === false"
      :id="`feedback_${_uid}`"
      :state="errorState"
    >
      <p v-if="field?.required === false">
        {{ $t('common.forms.validation.required', { field: label }) }}
      </p>
    </b-form-invalid-feedback>
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormInvalidFeedback } from 'bootstrap-vue';
import Required from './Required.vue';

export default {
  name: 'FormGroup',
  components: {
    BFormGroup,
    BFormInvalidFeedback,
    Required,
  },
  props: {
    label: { type: String, default: null },
    description: { type: String, default: null },
    required: { type: Boolean, default: false },
    field: { type: Object, required: true },
  },
  computed: {
    errorState() {
      return this.field?.$error === true ? false : null;
    },
  },
};
</script>
