<template>
  <b-button v-bind="$attrs" :variant="variant" @click="$emit('click')">
    <font-awesome-icon :icon="[iconSet, icon]" :size="iconSize" />
    <span :key="text" class="sr-only">{{ text }}</span>
  </b-button>
</template>

<script>
import { BButton } from 'bootstrap-vue';

/**
 * This component creates a basic Button with just an icon on it, and hidden,
 * screen-reader only text. All props of the BootstrapVue `b-button` class are merged with this.
 * @exports src/ux/IconButton
 * @property {string} icon The name of the icon to display
 * @property {string} text The text to put in the screen reader only element
 */
export default {
  name: 'IconButton',

  components: {
    'b-button': BButton,
  },
  props: {
    iconSet: {
      type: String,
      default: () => 'far',
    },
    icon: {
      type: String,
      default: () => '',
    },
    text: {
      type: String,
      default: () => '',
    },
    variant: {
      type: String,
      default: () => 'none',
    },
    iconSize: {
      type: [String, Boolean],
      default: () => undefined,
    },
  },
  emits: ['click'],

  computed: {},
};
</script>

<style lang="scss" scoped>
.btn:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}
</style>
