import axios from 'axios';
import JsonApiResponseConverter from 'json-api-response-converter';
import MetaExtractor from "@/services/v2/MetaExtractor";
import IndexParameterBuilder from "@/services/v2/IndexParameterBuilder";

class GetAPI {
  static async get(route, config) {
    const response = await axios({
      method: 'GET',
      url: `v2/${ route }/${ config.id}`,
      responseType: 'json',
      params: IndexParameterBuilder.prepare(config),
    });

    return {
      data: new JsonApiResponseConverter(response.data).formattedResponse,
      meta: {
        ...response.data.meta,
        ...(await MetaExtractor.extractMeta([response.data.data]))[0]
      },
    }
  }
}

export default GetAPI;
