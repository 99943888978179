/**
 * Calculates a faux upload progress status of the form data's files.
 * We know the number of bytes that have been uploaded so we use this to guess
 * which files have been completed, in progress and pending. Along with overall percentage complete.
 * For example, if we have 3 100byte files and 150bytes has been uploaded. We would deduce that
 * file 1 has been completed, file 2 is 50% complete and file 3 is pending.
 * Obviously not 100% accurate but gives an idea for displaying to the user.
 * @param  {Number} loaded   Number of bytes uploaded
 * @param  {FormData} formData The FormData object sent in the request. Used to extract the File
 * items and calculate progress from.
 * @return {Object} An object with the following properties:
 * - complete {File[]} An array of File instances (from the formData) that are complete.
 * - inProgress {File} The File instance currently uploading
 * - inProgressPercent {Number} Percentage complete of the in-progress File
 * - pending {File[]} An array of File instances (from the formData) that are waiting to be uploaded
 * - totalSize {Number} Number of bytes in total being uploaded (just from the Files)
 * - totalLoaded {Number} Number of bytes uploaded. (Just the `loaded` param value)
 * - percentageComplete {Number} Overall percentage complete of all files.
 */
export default function getFileUploadStatus(loaded, formData) {
  const complete = [];
  const pending = [];
  let runningTotal = 0;
  let inProgress = null;
  let inProgressPercent = 0;

  const values = formData.values();
  let item = values.next();

  // loop all formData fields
  while (!item.done) {
    const { value } = item;

    // only deal with Files
    if (value instanceof File) {
      runningTotal += value.size;

      // if the runningTotal is less than what's been uploaded then the current file is complete.
      if (runningTotal <= loaded) {
        complete.push(value);
      } else if (!inProgress) {
        // if not, and `inProgress` is empty then this file is 'in progress'
        // calc amount uploaded of the in-progress item
        const inProgressComplete = loaded - (runningTotal - value.size);

        // make it a percentage, and round down
        inProgressPercent = Math.floor(loaded === 0 ? 0 : (inProgressComplete / value.size) * 100);

        inProgress = value;
      } else {
        // otherwise the file is pending
        pending.push(value);
      }
    }

    item = values.next();
  }

  return {
    complete,
    inProgress,
    inProgressPercent,
    pending,
    totalSize: runningTotal,
    totalLoaded: loaded,
    percentageComplete: Math.floor((loaded / runningTotal) * 100),
  };
}
