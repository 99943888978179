export default {
  install(app, store) {
    app.config.globalProperties.$currency = (value, currency) => {
      const locale = (store.getters.getLocale || 'en-GB').toLowerCase();

      if (!currency) {
        currency =
          window.appConfig?.DEFAULT_CURRENCY.value ??
          window.appConfig?.DEFAULT_CURRENCY.default_value ??
          'GBP';
      }

      return !Number.isNaN(parseFloat(value))
        ? new Intl.NumberFormat(locale, { style: 'currency', currency }).format(value)
        : '';
    };

    app.config.globalProperties.$defaultCurrencyCode =
      window.appConfig?.DEFAULT_CURRENCY.value ??
      window.appConfig?.DEFAULT_CURRENCY.default_value ??
      'GBP';

    app.config.globalProperties.$enabledCurrenciesCodes =
      window.appConfig?.ENABLED_CURRENCIES.value ||
      window.appConfig?.ENABLED_CURRENCIES.default_value ||
      [];
  },
};
