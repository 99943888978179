import Base from './Base.js';

export default function LocationRestrictionSelectorOptionsCreator() {
  class LocationRestrictionSelectorOptions extends Base {}

  LocationRestrictionSelectorOptions.initEnum({
    NONE: {
      value: 'NONE',
      display: 'None',
    },
    ALL: {
      value: 'ALL',
      display: 'All',
    },
    TAGS: {
      value: 'TAGS',
      display: 'Tags',
    },
    AREA: {
      value: 'AREA',
      display: 'Area',
    },
    SITES: {
      value: 'SITES',
      display: 'Sites',
    },
  });

  return LocationRestrictionSelectorOptions;
}