<template>
  <b-button v-bind="attrs" :disabled="loading || disabled" data-ref="spin-button" v-on="listeners">
    <template v-if="loading">
      <b-spinner small :label="srOnlyMsg" />&nbsp;<!--
      --><span v-if="showLoadingMsg" class="d-none d-md-inline msg"><!--
        --><span :key="loadingMsg || $t('common.forms.loading')">
          {{ loadingMsg || $t('common.forms.loading') }}
        </span>
      </span>
    </template>
    <div v-if="!loading">
      <slot />
    </div>
  </b-button>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue';

/**
 * This component creates a basic Button but with a built in loading spinner that can be
 * controlled by the `loading` prop.
 * All props of the BootstrapVue `b-button` class are merged with this.
 * @exports src/ux/SpinButton
 * @property {boolean} loading Determines if the loading spinner is shown
 * @property {boolean} showLoadingMsg True to show a visible loading message. See `loadingMsg`
 * @property {string} loadingMsg The message to show when loading. If `showLoadingMsg` is true this
 * is shown visually, otherwise it is displayed as a screen-reader only element.
 */
export default {
  name: 'SpinButton',

  components: {
    'b-button': BButton,
    'b-spinner': BSpinner,
  },

  props: {
    disabled: {
      type: Boolean,
      default: () => false,
    },
    loading: {
      type: Boolean,
      default: () => false,
    },
    showLoadingMsg: {
      type: Boolean,
      default: () => true,
    },
    loadingMsg: {
      type: String,
      default: null,
    },
  },

  computed: {
    srOnlyMsg() {
      return this.showLoadingMsg ? null : this.loadingMsg;
    },

    listeners() {
      const { ...listeners } = this.$listeners;
      return listeners;
    },
    attrs() {
      const { ...attrs } = this.$attrs;
      return attrs;
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  .msg {
    margin-left: 0.5rem;
  }
}
</style>
