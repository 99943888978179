export default {
  state: {
    /**
     * A Contractor definition
     * @typedef {Object} MaintainableEntity
     * @property {string} name -
     * @property {string} code -
     * @property {number} parent_id -
     * @property {number} service_type_id -
     * @property {string} notes -
     * @property {string} out_of_service_since_date -
     * @property {string} disposal_date -
     * @property {Object} attributes -
     */
    maintainableEntity: () => ({
      name: null,
      code: null,
      parent_id: null,
      service_type_id: null,
      notes: null,
      out_of_service_since_date: null,
      disposal_date: null,
      maintainableEntityType: null,
      cost_centre: null,
      attributes: {
        site_type_id: null,
        built_date: null,
        lease_end_date: null,
        total_space: null,
        usable_space: null,
        number_of_floors: null,
        number_of_parking_spaces: null,
        opening_times: null,
        disabled_access: null,
        number_of_lifts: null,
        hazards: null,
        number_of_meeting_rooms: null,
        number_of_employees: null,
        capacity: null,
        confined_space: false,
        in_storage: false,
        manufacturer: null,
        model: null,
        serial_number: null,
        qty: 0,
        purchase_cost: null,
        purchase_date: null,
        install_date: null,
        install_contractor: null,
        expected_replacement_date: null,
        warranty_labour_expiry_date: null,
        warranty_parts_expiry_date: null,
        warranty_details: null,
        condition_grade_id: null,
        geofence: null,
        geofence_area: null,
        cost_centre: null,
      },
    }),
  },

  getters: {},

  actions: {
    GET_MAINTAINABLE_ENTITIES: 'GET_MAINTAINABLE_ENTITIES',
    GET_MAINTAINABLE_ENTITIES_TREE: 'GET_MAINTAINABLE_ENTITIES_TREE',
    GET_MAINTAINABLE_ENTITY: 'GET_MAINTAINABLE_ENTITY',
    UPDATE_MAINTAINABLE_ENTITY: 'UPDATE_MAINTAINABLE_ENTITY',
    CREATE_MAINTAINABLE_ENTITY: 'CREATE_MAINTAINABLE_ENTITY',
    DELETE_MAINTAINABLE_ENTITY: 'DELETE_MAINTAINABLE_ENTITY',
  },

  mutations: {
    SET_MAINTAINABLE_ENTITIES: 'SET_MAINTAINABLE_ENTITIES',
    SET_MAINTAINABLE_ENTITIES_TREE: 'SET_MAINTAINABLE_ENTITIES_TREE',
    DELETE_MAINTAINABLE_ENTITY_TREE_ITEM: 'DELETE_MAINTAINABLE_ENTITY_TREE_ITEM',
    SET_MAINTAINABLE_ENTITIES_STATE: 'SET_MAINTAINABLE_ENTITIES_STATE',
    SET_MAINTAINABLE_ENTITIES_META: 'SET_MAINTAINABLE_ENTITIES_META',
    SET_MAINTAINABLE_ENTITIES_ERRORS: 'SET_MAINTAINABLE_ENTITIES_ERRORS',
    SET_MAINTAINABLE_ENTITIES_SORT: 'SET_MAINTAINABLE_ENTITIES_SORT',
    SET_ACTIVE_MAINTAINABLE_ENTITY: 'SET_ACTIVE_MAINTAINABLE_ENTITY',
    SET_ACTIVE_MAINTAINABLE_ENTITY_STATE: 'SET_ACTIVE_MAINTAINABLE_ENTITY_STATE',
    SET_ACTIVE_MAINTAINABLE_ENTITY_ERRORS: 'SET_ACTIVE_MAINTAINABLE_ENTITY_ERRORS',
    SET_ACTIVE_MAINTAINABLE_ENTITY_VALID: 'SET_ACTIVE_MAINTAINABLE_ENTITY_VALID',
    UPDATE_ACTIVE_MAINTAINABLE_ENTITY_MODIFIED_FIELDS:
      'UPDATE_ACTIVE_MAINTAINABLE_ENTITY_MODIFIED_FIELDS',
    CLEAR_ACTIVE_MAINTAINABLE_ENTITY_MODIFIED_FIELDS:
      'CLEAR_ACTIVE_MAINTAINABLE_ENTITY_MODIFIED_FIELDS',
    SET_MAINTAINABLE_ENTITIES_FILTER: 'SET_MAINTAINABLE_ENTITIES_FILTER',
    INSERT_MAINTAINABLE_ENTITY_TREE_ITEM: 'INSERT_MAINTAINABLE_ENTITY_TREE_ITEM',
    REMOVE_MAINTAINABLE_ENTITY_TREE_ITEM: 'REMOVE_MAINTAINABLE_ENTITY_TREE_ITEM',
    UPDATE_MAINTAINABLE_ENTITY_TREE_ITEM: 'UPDATE_MAINTAINABLE_ENTITY_TREE_ITEM',
  },
};
