export default {
  path: '/settings',
  component: () => import('@/views/settings/Settings.vue'),
  meta: {
    requiresAuth: true,
    layout: 'DefaultLayout',
    permissionOr: ['User_Manage', 'Role_Manage', 'ServiceType_Manage', 'Budget_Manage'],
    bcLinkText: 'Settings',
  },
  children: [
    {
      path: '',
      name: 'Settings',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permissionOr: ['User_Manage', 'Role_Manage', 'ServiceType_Manage', 'Budget_Manage'],
        bcLinkText: 'Settings',
      },
      component: () => import(/* webpackPrefetch: true */ '@/views/settings/SettingsHome.vue'),
    },
    {
      path: 'users',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        bcLinkText: 'Users',
        storeModules: ['users', 'role', 'site', 'contractor'],
      },
      component: () => import(/* webpackPrefetch: true */ '@/views/users/Users.vue'),
      children: [
        {
          path: ':page(\\d+)?',
          name: 'Users',
          component: () => import(/* webpackPrefetch: true */ '@/views/users/UsersHome.vue'),
          meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            permission: 'User_Manage',
          },
        },
        {
          path: 'view/:id/:pane?',
          name: 'UserDisplay',
          component: () => import(/* webpackPrefetch: true */ '@/views/users/UserDisplay.vue'),
          meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            permission: 'User_Manage',
            bcDynamic: true,
            bcGetter: 'getActiveUser',
            bcLinkText: (user) => user && user.name,
            bcLoadingText: 'Loading User...',
            translate: false,
          },
        },
        {
          path: 'create',
          name: 'UserCreate',
          component: () => import(/* webpackPrefetch: true */ '@/views/users/UserCreate.vue'),
          meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            permission: 'User_Manage',
            bcLinkText: 'Create',
          },
        },
      ],
    },
    {
      path: 'uncaptured-spends',
      name: 'UncapturedSpends',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'UncapturedSpend_Manage',
        bcLinkText: 'Uncaptured Spends',
        storeModules: [],
      },
      component: () => import('@/views/uncaptured-spends/UncapturedSpendHome.vue'),
    },
    {
      path: 'invoice-groups',
      name: 'InvoiceGroups',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'Organisation_Manage',
        bcLinkText: 'Invoice Groups',
        storeModules: [],
      },
      component: () => import('@/views/invoice-groups/InvoiceGroup.vue'),
    },
    {
      path: 'dashboards',
      name: 'Dashboards',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'Dashboard_Manage',
        bcLinkText: 'Dashboards',
        storeModules: ['dashboard', 'role'],
      },
      component: () => import('@/views/dashboards/DashboardHome.vue'),
    },
    {
      path: 'roles',
      name: 'Roles',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'Role_Manage',
        bcLinkText: 'Roles',
        storeModules: ['role', 'servicePriority'],
      },
      component: () => import('@/views/roles/Roles.vue'),
    },
    {
      path: 'service-types',
      name: 'ServiceTypes',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'ServiceType_Manage',
        bcLinkText: 'Service Types',
        storeModules: ['workOrder'],
      },
      component: () => import('@/views/service-types/ServiceType.vue'),
    },
    {
      path: 'organisations',
      name: 'Organisations',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'Organisation_Manage',
        bcLinkText: 'Organisations',
        storeModules: ['organisation'],
      },
      component: () => import('@/views/organisations/Organisation.vue'),
    },
    {
      path: 'financial-periods',
      name: 'FinancialPeriods',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'FinancialPeriod_Manage',
        bcLinkText: 'Financial Periods',
        storeModules: ['financialPeriod'],
      },
      component: () => import('@/views/financial-periods/FinancialPeriods.vue'),
    },
    {
      path: 'budgets',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        bcLinkText: 'Budgets',
      },
      component: () => import('@/views/budgets/Budgets.vue'),
      children: [
        {
          path: '',
          name: 'Budgets',
          component: () => import('@/views/budgets/BudgetSettingHome.vue'),
          meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            permission: 'Budget_View',
          },
        },
        {
          path: 'view/:id/:pane?',
          name: 'BudgetDisplay',
          meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            permission: 'Budget_Manage',
            bcDynamic: true,
            bcLinkText: (budget) => budget?.name,
            bcLoadingText: 'Loading Budget...',
          },
          component: () => import('@/views/budgets/BudgetDisplay.vue'),
        },
      ]
    },
    {
      path: 'budget-types',
      name: 'BudgetTypes',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'BudgetType_Manage',
        bcLinkText: 'Budget Types',
        storeModules: [],
      },
      component: () => import('@/views/budget-types/BudgetType.vue'),
    },
    {
      path: 'cost-codes',
      name: 'CostCodes',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'CostCode_Manage',
        bcLinkText: 'Cost Codes',
        storeModules: ['costCode'],
      },
      component: () => import('@/views/cost-codes/CostCode.vue'),
    },
    {
      path: 'countries',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        bcLinkText: 'Countries',
        storeModules: ['country'],
      },
      component: () => import('@/views/countries/Countries.vue'),
      children: [
        {
          path: ':page(\\d+)?',
          name: 'Countries',
          component: () => import('@/views/countries/CountryHome.vue'),
          meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            permission: 'Country_Manage',
          },
        },
        {
          path: 'view/:id/:pane?',
          name: 'CountryDisplay',
          component: () => import('@/views/countries/CountryDisplay.vue'),
          meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            permission: 'Country_Manage',
            bcDynamic: true,
            bcLinkText: (country) => country.name,
            bcLoadingText: 'Loading Country...',
            translate: false,
          },
        },
      ],
    },
    {
      path: 'tax-rates',
      name: 'TaxRates',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'TaxRate_Manage',
        bcLinkText: 'Tax Rates',
        storeModules: ['taxRate'],
      },
      component: () => import('@/views/tax-rates/TaxRateHome.vue'),
    },
    {
      path: 'site-types',
      name: 'SiteTypes',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'SiteType_Manage',
        bcLinkText: 'Site Types',
      },
      component: () => import('@/views/site-types/SiteTypes.vue'),
    },
    {
      path: 'building-types',
      name: 'BuildingTypes',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'BuildingType_Manage',
        bcLinkText: 'Building Types',
      },
      component: () => import('@/views/building-types/BuildingTypes.vue'),
    },
    {
      path: 'cost-types',
      name: 'CostTypes',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'CostType_Manage',
        bcLinkText: 'Cost Types',
      },
      component: () => import('@/views/cost-types/CostTypes.vue'),
    },
    {
      path: 'landlords',
      name: 'Landlords',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'Landlord_Manage',
        bcLinkText: 'Landlords',
        storeModules: ['landlord'],
      },
      component: () => import('@/views/landlords/Landlords.vue'),
    },
    {
      path: 'work-order-types',
      name: 'WorkOrderTypes',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'WorkOrderType_Manage',
        bcLinkText: 'Work Order Types',
        storeModules: ['workOrderType'],
      },
      component: () => import('@/views/work-order-types/WorkOrderType.vue'),
    },
    {
      path: 'work-order-creation-rules',
      name: 'WorkOrderCreationRules',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'WorkOrderType_Manage',
        bcLinkText: 'Work Order Creation Rules',
        storeModules: ['workOrderType'],
      },
      component: () => import('@/views/work-order-creation-rules/WorkOrderCreationRules.vue'),
    },
    {
      path: 'parts-order-categories',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        bcLinkText: 'Parts Order Categories',
      },
      component: () => import('@/views/parts-order-category/PartsOrderCategory.vue'),
      children: [
        {
          path: '',
          name: 'PartsOrderCategories',
          component: () => import('@/views/parts-order-category/Home.vue'),
          meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            permission: 'PartsCategory_Manage',
          },
        },
        {
          path: 'view/:id/:pane?',
          name: 'CategoryDisplay',
          component: () => import('@/views/parts-order-category/Display.vue'),
          meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            bcDynamic: true,
            bcLinkText: (activeCategory) => activeCategory.name,
            bcLoadingText: 'Loading Category...',
            permission: 'PartsCategory_Manage',
          },
        },
        {
          path: 'create',
          name: 'CategoryCreate',
          component: () => import('@/views/parts-order-category/Create.vue'),
          meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            bcLinkText: 'Create',
            permission: 'PartsCategory_Manage',
          },
        },
      ],
    },
    {
      path: 'parts',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        bcLinkText: 'Parts',
      },
      component: () => import('@/views/parts-order-parts/PartsOrderPart.vue'),
      children: [
        {
          path: '',
          name: 'PartsOrderParts',
          component: () => import('@/views/parts-order-parts/Home.vue'),
          meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            permission: 'PartsPart_Manage',
          },
        },
        {
          path: 'view/:id/:pane?',
          name: 'PartDisplay',
          component: () => import('@/views/parts-order-parts/Display.vue'),
          meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            bcDynamic: true,
            bcLinkText: (part) => part.name,
            bcLoadingText: 'Loading Parts...',
            permission: 'PartsPart_Manage',
            storeModules: ['site'],
          },
        },
        {
          path: 'create/:contractor?/:category?',
          name: 'PartCreate',
          component: () => import('@/views/parts-order-parts/Create.vue'),
          meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            bcLinkText: 'Create',
            permission: 'PartsPart_Manage',
          },
        },
      ],
    },
    {
      path: 'workflow',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'Config_System',
        bcLinkText: 'Workflows',
        storeModules: [],
      },
      component: () => import('@/views/workflows/WorkflowsHome.vue'),
      children: [
        {
          path: '',
          name: 'Workflows',
          component: () => import('@/views/workflows/WorkflowsList.vue'),
          meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            permission: 'Config_System',
          },
        },
        {
          path: 'view/:id/:pane?',
          name: 'WorkflowDisplay',
          component: () => import('@/views/workflows/WorkflowDisplay.vue'),
          meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            permission: 'Config_System',
            bcDynamic: true,
            bcLinkText: (workflow) => workflow.name,
            bcLoadingText: 'Loading Workflow...',
            translate: false,
          },
        },
      ],
    },
    {
      path: 'service-priorities',
      name: 'ServicePriorities',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'ServicePriority_Manage',
        bcLinkText: 'Service Priorities',
        storeModules: ['servicePriority', 'role'],
      },
      component: () => import('@/views/service-priorities/ServicePriority.vue'),
    },
    {
      path: 'suppliers',
      name: 'Suppliers',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'Supplier_Manage',
        bcLinkText: 'Suppliers',
        storeModules: ['supplier'],
      },
      component: () => import('@/views/suppliers/Supplier.vue'),
    },
    {
      path: 'start-work-checks',
      name: 'StartWorkChecks',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'StartWorkChecks_Manage',
        bcLinkText: 'Start Work Checks',
        storeModules: []
      },
      component: () => import('@/views/start-work-checks/StartWorkChecks.vue'),
    },
    {
      path: 'notifications',
      name: 'Notifications',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        bcLinkText: 'Notifications',
        storeModules: ['notification'],
      },
      component: () => import('@/views/notifications/Notifications.vue'),
    },
    {
      path: 'tags',
      name: 'Tags',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'TagType_Manage',
        bcLinkText: 'Tags',
        storeModules: ['tag'],
      },
      component: () => import('@/views/tags/Tags.vue'),
    },
    {
      path: 'tag-types',
      name: 'TagTypes',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'TagType_Manage',
        bcLinkText: 'Tag Types',
        storeModules: ['tag'],
      },
      component: () => import('@/views/tag-types/TagTypes.vue'),
    },
    {
      path: 'audits',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'Audit_View',
        bcLinkText: 'Audit Logs',
        storeModules: ['audit'],
      },
      component: () => import('@/views/audits/Audit.vue'),
      children: [
        {
          path: ':page(\\d+)?',
          name: 'Audits',
          component: () => import('@/views/audits/AuditHome.vue'),
          meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
          },
        },
        {
          path: 'view/:id',
          name: 'AuditDisplay',
          component: () => import('@/views/audits/AuditDisplay.vue'),
          meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            bcDynamic: true,
            bcLinkText: 'breadcrumbs.settings.audit',
            bcLoadingText: 'Loading Audit Logs...',
          },
        },
      ],
    },
    {
      path: 'document-types',
      name: 'DocumentTypes',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'DocumentType_Manage',
        bcLinkText: 'Document Types',
        storeModules: ['documentType', 'role'],
      },
      component: () => import('@/views/document-types/DocumentTypes.vue'),
    },
    {
      path: 'work-order-form-manage',
      name: 'WorkOrderFormManage',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'WorkOrderForm_Manage',
        bcLinkText: 'External Work Order Forms',
        storeModules: ['site', 'tag'],
      },
      component: () => import('@/views/manage-external-work-order-form/ManageExternalWorkOrderForm.vue'),
    },
    {
      path: 'work-order-form-request-type-manage',
      name: 'WorkOrderFormRequestTypeManage',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'WorkOrderFormRequestType_Manage',
        bcLinkText: 'External Work Order Form Request Types',
        storeModules: [],
      },
      component: () => import('@/views/work-order-form-request-types/Manage.vue'),
    },
    {
      path: 'hazard-types',
      name: 'HazardTypes',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'HazardType_Manage',
        bcLinkText: 'Hazard Types',
        storeModules: [],
      },
      component: () => import('@/views/hazard-types/HazardType.vue'),
    },
    {
      path: 'asset-categories',
      name: 'AssetCategories',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'AssetCategory_Manage',
        bcLinkText: 'Asset Categories',
        storeModules: [],
      },
      component: () => import('@/views/asset-categories/AssetCategory.vue'),
    },
    {
      path: 'asset-types',
      name: 'AssetTypes',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'AssetType_Manage',
        bcLinkText: 'Asset Types',
        storeModules: [],
      },
      component: () => import('@/views/asset-types/AssetType.vue'),
    },
    {
      path: 'asset-manufacturers',
      name: 'AssetManufacturers',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'AssetManufacturer_Manage',
        bcLinkText: 'Asset Manufacturers',
        storeModules: [],
      },
      component: () => import('@/views/asset-manufacturers/AssetManufacturer.vue'),
    },
    {
      path: 'asset-models',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        bcLinkText: 'Asset Models',
      },
      component: () => import('@/views/asset-models/AssetModel.vue'),
      children: [
        {
          path: '',
          name: 'AssetModels',
          component: () => import('@/views/asset-models/Home.vue'),
          meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            permission: 'AssetModel_Manage',
          },
        },
        {
          path: 'view/:id/:pane?',
          name: 'AssetModelDisplay',
          component: () =>
            import(/* webpackPrefetch: true */ '@/views/asset-models/Display.vue'),
          meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            bcDynamic: true,
            bcLinkText: (assetModel) => assetModel.name,
            bcLoadingText: 'Loading Asset Models...',
            permission: 'AssetModel_Manage',
            storeModules: [],
            translate: false,
          },
        },
        {
          path: 'create',
          name: 'AssetModelCreate',
          component: () =>
            import(/* webpackPrefetch: true */ '@/views/asset-models/Create.vue'),
          meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            bcLinkText: 'Create',
            permission: 'AssetModel_Create',
            storeModules: [],
          },
        },
      ],
    },
    {
      path: 'asset-suppliers',
      name: 'AssetSuppliers',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'AssetSupplier_Manage',
        bcLinkText: 'Asset Suppliers',
        storeModules: [],
      },
      component: () => import('@/views/asset-suppliers/AssetSupplier.vue'),
    },
    {
      path: 'work-permits',
      name: 'WorkPermits',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'WorkPermit_Manage',
        bcLinkText: 'Permit to Work Templates',
      },
      component: () => import('@/views/work-permits/WorkPermitHome.vue'),
    },
    {
      path: 'contractor-settings',
      name: 'ContractorSettings',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'ContractorSettings_Manage',
        bcLinkText: 'Contractor Settings',
      },
      component: () => import('@/views/contractor-settings/ContractorSettingHome.vue'),
    },
    {
      path: 'planned-work-templates',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        bcLinkText: 'Planned Work Templates',
      },
      component: () => import('@/views/planned-work-templates/PlannedWorkTemplate.vue'),
      children: [
        {
          path: ':page(\\d+)?',
          name: 'PlannedWorkTemplates',
          component: () => import('@/views/planned-work-templates/Home.vue'),
          meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            permission: 'PlannedWorkTemplate_View',
          },
        },
        {
          path: 'edit/:id?',
          name: 'PlannedWorkTemplateForm',
          component: () => import('@/views/planned-work-templates/TemplateForm.vue'),
          meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            permission: 'PlannedWorkTemplate_Manage',
            bcDynamic: true,
            bcLinkText: (pwt) => pwt?.summary || 'New Template',
            bcLoadingText: 'Loading Planned Work Template...',
          },
        },
        {
          path: 'view/:id/:page(\\d+)?',
          name: 'PlannedWorkTemplateDisplay',
          component: () => import('@/views/planned-work-templates/Display.vue'),
          meta: {
            requiresAuth: true,
            layout: 'DefaultLayout',
            bcDynamic: true,
            bcLinkText: (pwt) => pwt?.summary,
            bcLoadingText: 'Loading Planned Work Template...',
            permission: 'PlannedWorkTemplate_View',
          },
        },
      ]
    },
    {
      path: 'custom-fields',
      name: 'CustomFields',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'CustomField_Manage',
        bcLinkText: 'Custom Fields',
      },
      component: () => import('@/views/custom-fields/CustomFieldsHome.vue'),
    },
    {
      path: 'configuration-variables',
      name: 'ConfigurationVariables',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'Config_System',
        bcLinkText: 'Configuration Variables',
      },
      component: () => import('@/views/config-vars/ConfigVarSettings.vue'),
    },
    {
      path: 'navigation-schemes',
      name: 'NavigationSchemes',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'Config_System',
        bcLinkText: 'Navigation Schemes',
      },
      component: () => import('@/views/navigation-schemes/NavigationSchemesHome.vue'),
    },
  ],
};
