export default {
  /**
   * @name getActiveMaintainableEntity
   * @desc Returns the `activeMaintainableEntity` object
   * @param  {object}  state Current state
   * @return {object}
   */
  getActiveMaintainableEntity(state) {
    return state.activeMaintainableEntity;
  },
};
