<template>
  <div v-if="password" class="password-strength container">
    <div class="row no-gutters">
      <div class="col">
        <div
          :class="{
            'bg-danger' : passwordStrengthScore <= 2,
            'bg-warning' : passwordStrengthScore === 3,
            'bg-success' : passwordStrengthScore === 4
          }"
        />
      </div>
      <div class="col">
        <div
          :class="{
            'bg-danger' : passwordStrengthScore === 2,
            'bg-warning' : passwordStrengthScore === 3,
            'bg-success' : passwordStrengthScore === 4
          }"
        />
      </div>
      <div class="col">
        <div
          :class="{
            'bg-warning' : passwordStrengthScore === 3,
            'bg-success' : passwordStrengthScore === 4
          }"
        />
      </div>
      <div class="col">
        <div
          :class="{
            'bg-success' : passwordStrengthScore === 4
          }"
        />
      </div>
    </div>
  </div>
</template>

<script>
import zxcvbn from 'zxcvbn';

export default {
  name: "PasswordStrength",
  props: {
    modelValue: {
      type: Number,
      default: 0,
    },
    password: {
      type: String,
      default: null,
    },
    userInputs: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      passwordStrengthScore: 0,
    };
  },
  computed: {
    passwordStrength(){
      if (!this.password) {
        return null;
      }

      return zxcvbn(this.password, this.userInputs);
    },
  },
  watch: {
    password: {
      handler: 'setPasswordStrengthScore',
      immediate: true,
    },
  },
  methods: {
    setPasswordStrengthScore() {
      this.passwordStrengthScore = this.passwordStrength?.score ?? 0;

      this.$emit('input', this.passwordStrengthScore === 4);
    }
  }
}
</script>

<style scoped lang="scss">
.password-strength {
  .col {
    padding: 0 0.1rem;

    > div {
      display: block;
      width: 100%;
      padding: 0 0.5rem;
      height: 10px;
      border-radius: 0.25rem;
      background-color: #D4D4D4;
    }
  }
}
</style>
