export default {
  path: '/remedial-actions',
  name: 'RemedialActions',
  meta: {
    requiresAuth: true,
    layout: 'DefaultLayout',
    bcLinkText: 'Remedial Actions',
  },
  component: () => import('@/views/remedial-actions/RemedialActionHome.vue'),
};
