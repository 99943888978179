import types from '@/store/types/error.js';

const a = types.actions;
const m = types.mutations;

export default {
  /**
   * Raise global errors that can't be handled by the local component
   * @method
   * @param {Function} commit Vue commit callback
   * @param {Object} state Current state
   * @param {string[]} errors The errors to display to the user
   */
  [a.RAISE_ERROR]({ commit, state }, errors) {
    const id = state.totalCount;

    const timeout = setTimeout(() => {
      commit(m.EXPIRE_ERROR, id);
    }, 10000);

    commit(m.PUSH_ERROR, { errors, timeout, id });
  },
};
