<template>
  <b-list-group-item
    :to="{ name: 'WorkOrderDisplay', params: { id: workOrder.id } }"
    target="_blank"
    class="work-order"
  >
    <div class="d-flex align-items-center justify-content-between">
      <div class="small">
        <strong :key="workOrder.display_reference">{{ workOrder.display_reference }}</strong>:
        <span :key="workOrder.summary">{{ workOrder.summary }}</span>
      </div>
      <b-badge :variant="workOrderStatusVariant" class="font-weight-normal">
        <span :key="workOrderStatus">{{ workOrderStatus }}</span>
      </b-badge>
    </div>

    <div class="mt-1 small text-muted">
      <div>
        <font-awesome-icon :icon="['far', 'map-marker-alt']" class="fa-fw" />
        <span :key="location">{{ location }}</span>
      </div>

      <div>
        <font-awesome-icon :icon="['far', 'calendar-alt']" class="fa-fw" />
        <template v-if="workOrder.closed_at">
          {{ $t('components.workOrder.duplicate.DuplicateWorkOrder.closedAt') }}:
          <span :key="$formatDate(workOrder.closed_at, 'L')">
            {{ $formatDate(workOrder.closed_at, 'L') }}
          </span>
        </template>
        <template v-else>
          {{ $t('components.workOrder.duplicate.DuplicateWorkOrder.createdAt') }}:
          <span :key="$formatDate(workOrder.created_at, 'L')">
            {{ $formatDate(workOrder.created_at, 'L') }}
          </span>
        </template>
      </div>
    </div>
  </b-list-group-item>
</template>

<script>
import { BListGroupItem, BBadge } from 'bootstrap-vue';

export default {
  name: 'DuplicateWorkOrderList',
  components: {
    BListGroupItem,
    BBadge,
  },
  props: {
    workOrder: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    workOrderStatus() {
      return this.workOrder?.state?.status;
    },

    workOrderStatusVariant() {
      const { enumValues } = this.$enums.WORK_ORDER_STATE_STATUS;
      const status = enumValues.find((v) => v.value === this.workOrderStatus);
      return status?.variant ?? 'none';
    },

    location() {
      return [
        this.workOrder.primary_location.name,
        ...this.workOrder.primary_location.ancestors.map((v) => v.name),
      ]
        .reverse()
        .join(' / ');
    },
  },
};
</script>

<style lang="scss" scoped>
.work-order {
  padding: 0.75rem;
}
.summary {
  font-size: 90%;
}
</style>
