export default {
  getters: {},

  actions: {
    GET_NOTIFICATIONS: 'GET_NOTIFICATIONS',
    UPDATE_NOTIFICATION: 'UPDATE_NOTIFICATION',
    CREATE_NOTIFICATION: 'CREATE_NOTIFICATION',
    DELETE_NOTIFICATION: 'DELETE_NOTIFICATION',
  },

  mutations: {
    SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
    SET_NOTIFICATIONS_STATUS: 'SET_NOTIFICATIONS_STATUS',
    SET_NOTIFICATIONS_META: 'SET_NOTIFICATIONS_META',
    SET_NOTIFICATIONS_FILTER: 'SET_NOTIFICATIONS_FILTER',

    REMOVE_LOCAL_NOTIFICATION: 'REMOVE_LOCAL_NOTIFICATION',
    ADD_LOCAL_NOTIFICATION: 'ADD_LOCAL_NOTIFICATION',
    UPDATE_LOCAL_NOTIFICATION: 'UPDATE_LOCAL_NOTIFICATION',

    SET_ACTIVE_NOTIFICATION: 'SET_ACTIVE_NOTIFICATION',
    SET_ACTIVE_NOTIFICATION_STATE: 'SET_ACTIVE_NOTIFICATION_STATE',
    SET_ACTIVE_NOTIFICATION_ERRORS: 'SET_ACTIVE_NOTIFICATION_ERRORS',
    SET_ACTIVE_NOTIFICATION_VALID: 'SET_ACTIVE_NOTIFICATION_VALID',
    UPDATE_ACTIVE_NOTIFICATION_MODIFIED_FIELDS: 'UPDATE_ACTIVE_NOTIFICATION_MODIFIED_FIELDS',
    CLEAR_ACTIVE_NOTIFICATION_MODIFIED_FIELDS: 'CLEAR_ACTIVE_NOTIFICATION_MODIFIED_FIELDS',
  },
};
