import types from '../../types/costCode.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_COST_CODES
   * @summary Updates the `costCodes` property.
   * @method
   * @param {Object} state Current state
   * @param {CostCode[]} costCodes The loaded CostCodes array
   */
  [m.SET_COST_CODES](state, costCodes) {
    state.costCodes = costCodes;
  },

  [m.SET_COST_CODES_STATUS](state, status) {
    state.costCodesState = status;
  },

  /**
   * @name m SET_COST_CODES_META
   * @summary Updates the `meta` property which holds the meta data for the `COST_CODES` collection.
   * For example, total rows, current page, number rows per page etc
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The meta data to store
   */
  [m.SET_COST_CODES_META](state, meta) {
    state.meta = meta;
  },

  /**
   * @name m SET_COST_CODES_FILTER
   * @summary Updates the `filter` property which holds the collection of filters currently active
   * on the CostCodes collection
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The filters to be applied
   */
  [m.SET_COST_CODES_FILTER](state, filter) {
    state.filter = filter;
  },

  /**
   * @name m SET_ACTIVE_COST_CODE
   * @summary Updates the `activeCostCode` and resets the
   * `activeCostCodeModifiedFields` & `activeCostCodeErrors` properties
   * @method
   * @param {Object} state Current state
   * @param {CostCode} costCode The new Active {@link CostCode}
   */
  [m.SET_ACTIVE_COST_CODE](state, costCode) {
    state.activeCostCode = costCode;
    state.activeCostCodeModifiedFields = !costCode || costCode.id ? {} : { ...costCode };
    state.activeCostCodeErrors = [];
  },

  /**
   * @name m SET_ACTIVE_COST_CODE_STATE
   * @summary Updates the `activeCostCodeState` property which holds the current status of the
   * based on the API enum
   * @method
   * @param {Object} state Current state
   * @param {enums/API} activeCostCodeState The current activeCostCodeState
   * (e.g. loading, success, failure)
   */
  [m.SET_ACTIVE_COST_CODE_STATE](state, activeCostCodeState) {
    state.activeCostCodeState = activeCostCodeState;
  },

  /**
   * @name m SET_ACTIVE_COST_CODE_ERRORS
   * @summary Updates the `activeCostCodeErrors` property which holds any
   * errors related to activeCostCode
   * @method
   * @param {Object} state Current state
   * @param {string[]} errors An array of error messages
   */
  [m.SET_ACTIVE_COST_CODE_ERRORS](state, errors) {
    state.activeCostCodeErrors = errors;
  },

  /**
   * @name m SET_ACTIVE_COST_CODE_VALID
   * @summary Updates the `activeCostCodeValid` property which holds
   * a boolean for the validation status of the `activeCostCode`.
   * @method
   * @param {Object} state Current state
   * @param {boolean} valid True if the `activeCostCode` is valid.
   */
  [m.SET_ACTIVE_COST_CODE_VALID](state, valid) {
    state.activeCostCodeValid = valid;
  },

  /**
   * @name m UPDATE_ACTIVE_COST_CODE_MODIFIED_FIELDS
   * @summary Adds given field/value pair to the `activeCostCodeModifiedFields` collection, which
   * will be sent as a PATCH to the API. If the given `value` equals the one on the `activeCostCode`
   * object (i.e. it hasn't actually changed, or has been reverted back to it's original) then we
   * remove that field from the modifiedFields collection
   * @method
   * @param {Object} state Current state
   * @param {object} payload Object containing a `field` and `value` properties.
   */
  [m.UPDATE_ACTIVE_COST_CODE_MODIFIED_FIELDS](state, { field, value }) {
    // if the value matches the existing one then we remove the field from the modifiedFields obj
    if (state.activeCostCode && state.activeCostCode[field] === value) {
      delete state.activeCostCodeModifiedFields[field];
    } else {
      state.activeCostCodeModifiedFields[field] = value;
    }
  },

  /**
   * @name m CLEAR_ACTIVE_COST_CODE_MODIFIED_FIELDS
   * @summary Empties the `activeCostCodeModifiedFields` object. Used to ensure we're on a clean
   * slate after navigating to a CostCode Display screen,
   * @method
   * @param {Object} state Current state
   */
  [m.CLEAR_ACTIVE_COST_CODE_MODIFIED_FIELDS](state) {
    state.activeCostCodeModifiedFields = {};
  },

  /**
   * @name m REMOVE_LOCAL_COST_CODE
   * @summary Removes the given costCode from the `costCodes` array. This is used to prevent a
   * full reload being needed after deleting a CostCode.
   * @method
   * @param {Object} state Current state
   * @param {object} costCode The CostCode to remove.
   */
  [m.REMOVE_LOCAL_COST_CODE](state, costCode) {
    const index = state.costCodes.findIndex((u) => u.id === costCode.id);

    if (index >= 0) {
      state.costCodes.splice(index, 1);
    }
  },

  /**
   * @name m ADD_LOCAL_COST_CODE
   * @summary Adds the given costCode to the `costCodes` array. This is used to prevent a
   * full reload being needed after adding a CostCode.
   * @method
   * @param {Object} state Current state
   * @param {object} costCode The CostCode to add.
   */
  [m.ADD_LOCAL_COST_CODE](state, costCode) {
    state.costCodes.push(costCode);
  },

  /**
   * @name m UPDATE_LOCAL_COST_CODE
   * @summary Updates the related costCode object in the `costCodes` array after an update.
   * @method
   * @param {Object} state Current state
   * @param {object} costCode The CostCode to update.
   */
  [m.UPDATE_LOCAL_COST_CODE](state, costCode) {
    const localCostCode = state.costCodes.find((c) => c.id === costCode.id);

    if (localCostCode) {
      Object.assign(localCostCode, costCode);
    }
  },
};
