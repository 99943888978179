import types from '../../types/workOrderType.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_WORK_ORDER_TYPES
   * @summary Updates the `workOrderTypes` property.
   * @method
   * @param {Object} state Current state
   * @param {WorkOrderType[]} workOrderTypes The loaded WorkOrderTypes array
   */
  [m.SET_WORK_ORDER_TYPES](state, workOrderTypes) {
    state.workOrderTypes = workOrderTypes;
  },

  [m.SET_WORK_ORDER_TYPES_STATUS](state, status) {
    state.workOrderTypesState = status;
  },

  /**
   * @name m SET_WORK_ORDER_TYPES_META
   * @summary Updates the `meta` property which holds the meta data for the
   * `WORK_ORDER_TYPES` collection.
   * For example, total rows, current page, number rows per page etc
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The meta data to store
   */
  [m.SET_WORK_ORDER_TYPES_META](state, meta) {
    state.meta = meta;
  },

  /**
   * @name m SET_WORK_ORDER_TYPES_FILTER
   * @summary Updates the `filter` property which holds the collection of filters currently active
   * on the WorkOrderTypes collection
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The filters to be applied
   */
  [m.SET_WORK_ORDER_TYPES_FILTER](state, filter) {
    state.filter = filter;
  },

  /**
   * @name m SET_ACTIVE_WORK_ORDER_TYPE
   * @summary Updates the `activeWorkOrderType` and resets the
   * `activeWorkOrderTypeModifiedFields` & `activeWorkOrderTypeErrors` properties
   * @method
   * @param {Object} state Current state
   * @param {WorkOrderType} workOrderType The new Active {@link WorkOrderType}
   */
  [m.SET_ACTIVE_WORK_ORDER_TYPE](state, workOrderType) {
    state.activeWorkOrderType = workOrderType;
    state.activeWorkOrderTypeModifiedFields =
      !workOrderType || workOrderType.id ? {} : { ...workOrderType };
    state.activeWorkOrderTypeErrors = [];
  },

  /**
   * @name m SET_ACTIVE_WORK_ORDER_TYPE_STATE
   * @summary Updates the `activeWorkOrderTypeState` property which holds the current status of the
   * based on the API enum
   * @method
   * @param {Object} state Current state
   * @param {enums/API} activeWorkOrderTypeState The current activeWorkOrderTypeState
   * (e.g. loading, success, failure)
   */
  [m.SET_ACTIVE_WORK_ORDER_TYPE_STATE](state, activeWorkOrderTypeState) {
    state.activeWorkOrderTypeState = activeWorkOrderTypeState;
  },

  /**
   * @name m SET_ACTIVE_WORK_ORDER_TYPE_ERRORS
   * @summary Updates the `activeWorkOrderTypeErrors` property which holds any
   * errors related to activeWorkOrderType
   * @method
   * @param {Object} state Current state
   * @param {string[]} errors An array of error messages
   */
  [m.SET_ACTIVE_WORK_ORDER_TYPE_ERRORS](state, errors) {
    state.activeWorkOrderTypeErrors = errors;
  },

  /**
   * @name m SET_ACTIVE_WORK_ORDER_TYPE_VALID
   * @summary Updates the `activeWorkOrderTypeValid` property which holds
   * a boolean for the validation status of the `activeWorkOrderType`.
   * @method
   * @param {Object} state Current state
   * @param {boolean} valid True if the `activeWorkOrderType` is valid.
   */
  [m.SET_ACTIVE_WORK_ORDER_TYPE_VALID](state, valid) {
    state.activeWorkOrderTypeValid = valid;
  },

  /**
   * @name m UPDATE_ACTIVE_WORK_ORDER_TYPE_MODIFIED_FIELDS
   * @summary Adds given field/value pair to the
   * `activeWorkOrderTypeModifiedFields` collection, which
   * will be sent as a PATCH to the API. If the given `value`
   * equals the one on the `activeWorkOrderType`
   * object (i.e. it hasn't actually changed, or has been reverted back to it's original) then we
   * remove that field from the modifiedFields collection
   * @method
   * @param {Object} state Current state
   * @param {object} payload Object containing a `field` and `value` properties.
   */
  [m.UPDATE_ACTIVE_WORK_ORDER_TYPE_MODIFIED_FIELDS](state, { field, value }) {
    // if the value matches the existing one then we remove the field from the modifiedFields obj
    if (state.activeWorkOrderType && state.activeWorkOrderType[field] === value) {
      delete state.activeWorkOrderTypeModifiedFields[field];
    } else {
      state.activeWorkOrderTypeModifiedFields[field] = value;
    }
  },

  /**
   * @name m CLEAR_ACTIVE_WORK_ORDER_TYPE_MODIFIED_FIELDS
   * @summary Empties the `activeWorkOrderTypeModifiedFields` object.
   * Used to ensure we're on a clean
   * slate after navigating to a WorkOrderType Display screen,
   * @method
   * @param {Object} state Current state
   */
  [m.CLEAR_ACTIVE_WORK_ORDER_TYPE_MODIFIED_FIELDS](state) {
    state.activeWorkOrderTypeModifiedFields = {};
  },

  /**
   * @name m REMOVE_LOCAL_WORK_ORDER_TYPE
   * @summary Removes the given workOrderType from the `workOrderTypes` array.
   * This is used to prevent a
   * full reload being needed after deleting a WorkOrderType.
   * @method
   * @param {Object} state Current state
   * @param {object} workOrderType The WorkOrderType to remove.
   */
  [m.REMOVE_LOCAL_WORK_ORDER_TYPE](state, workOrderType) {
    const index = state.workOrderTypes.findIndex((u) => u.id === workOrderType.id);

    if (index >= 0) {
      state.workOrderTypes.splice(index, 1);
    }
  },

  /**
   * @name m ADD_LOCAL_WORK_ORDER_TYPE
   * @summary Adds the given workOrderType to the `workOrderTypes` array. This is used to prevent a
   * full reload being needed after adding a WorkOrderType.
   * @method
   * @param {Object} state Current state
   * @param {object} workOrderType The WorkOrderType to add.
   */
  [m.ADD_LOCAL_WORK_ORDER_TYPE](state, workOrderType) {
    state.workOrderTypes.push(workOrderType);
  },

  /**
   * @name m UPDATE_LOCAL_WORK_ORDER_TYPE
   * @summary Updates the related workOrderType object in the `workOrderTypes`
   * array after an update.
   * @method
   * @param {Object} state Current state
   * @param {object} workOrderType The WorkOrderType to update.
   */
  [m.UPDATE_LOCAL_WORK_ORDER_TYPE](state, workOrderType) {
    const localWorkOrderType = state.workOrderTypes.find((c) => c.id === workOrderType.id);

    if (localWorkOrderType) {
      Object.assign(localWorkOrderType, workOrderType);
    }
  },
};
