import Base from './Base.js';

export default function MaintainableEntityTypeCreator() {
  class MaintainableEntityType extends Base {}

  const MaintainableEntityRawType = {
    SITE: {
      value: 'SITE',
      display: 'Site',
    },
    BUILDING: {
      value: 'BUILDING',
      display: 'Building',
    },
    FLOOR: {
      value: 'FLOOR',
      display: 'Floor',
    },
    SPACE: {
      value: 'SPACE',
      display: 'Space',
    },
    SYSTEM: {
      value: 'SYSTEM',
      display: 'System',
    },
    ASSET: {
      value: 'ASSET',
      display: 'Asset',
    },
  };

  MaintainableEntityType.initEnum({
    ...MaintainableEntityRawType,
  });

  return MaintainableEntityType;
}
