<template>
  <div class="toolbar-profile d-flex align-items-center">
    <div v-if="!sidebarVisible" class="info">
      <span :key="'name_' + name" class="name" translate='no'>{{ name }}</span>
      <span :key="'role_' + role" class="role">{{ role }}</span>
    </div>

    <template v-if="!hideAccountMenu">
      <AccountMenu :popover-id="'desktop'" />
    </template>
  </div>
</template>

<script>
/**
 * This component displays the current user's name, role and profile picture. Used in the mobile
 * menu and top right of desktop Header.
 * @exports src/components/user/ToolbarProfile
 */

import AccountMenu from '@/ux/AccountMenu.vue';

export default {
  name: 'ToolbarProfile',
  inject: ['mq'],
  components: { AccountMenu },
  props: {
    name: {
      type: String,
      default: '',
    },
    role: {
      type: String,
      default: '',
    },

    hideAccountMenu: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    sidebarVisible() {
      return ['lg', 'xl'].indexOf(this.mq.current) >= 0;
    },
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.toolbar-profile {
  color: #6c757d;
  text-align: right;

  .info {
    margin-right: 1rem;

    span {
      display: block;
    }

    span.name {
      font-size: 1rem;
      font-weight: bold;
      margin: 0;
    }

    span.role {
      font-size: 0.75rem;
      line-height: 0.75rem;
      text-transform: capitalize;

      &::first-letter {
        text-transform: uppercase;
      }
    }
  }

  img {
    border-radius: 50%;
    border: 1px solid #d8d8d8;
    padding: 2px;
    width: 2.5rem;
    height: 2.5rem;
  }
}
</style>
