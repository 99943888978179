import Base from './Base.js';

export default function ContractorHoursDaysCreator(i18n) {
  class ContractorHoursDays extends Base {}

  ContractorHoursDays.initEnum({
    WEEKDAYS: {
      value: 'WEEKDAYS',
      display: 'Weekdays',
    },
    SATURDAY: {
      value: 'SATURDAY',
      display: 'Saturday',
    },
    SUNDAY: {
      value: 'SUNDAY',
      display: 'Sunday',
    },
  });

  return ContractorHoursDays;
}
