import types from '@/store/types/filter.js';
import MaintainableEntityAPI from '@/services/MaintainableEntityAPI.js';
import flattenTree from '@/util/flattenTree.js';
import MaintainableEntityTypeCreator from '@/enums/MaintainableEntityType.js';

const a = types.actions;
const m = types.mutations;
const MaintainableEntityType = MaintainableEntityTypeCreator();

export default {
  async [a.PRIME_FILTERING_LOCATIONS]({ commit, state }, { filter }) {
    const ids = filter.value.filter((id) => state.locationFilterOptionsRequested[id] === undefined);
    if (!ids.length) {
      return;
    }

    commit(m.REGISTER_LOCATION_FILTER_OPTIONS_REQUESTED, { ids });

    const res = await MaintainableEntityAPI.getMaintainableEntitiesTree({
      shared_root_id: ids[0],
      deepest_type: MaintainableEntityType.SPACE.value,
    });

    if (!res.data.length) {
      return;
    }

    const tree = res.data;
    const options = flattenTree(tree);

    commit(m.REGISTER_LOCATION_FILTER_OPTIONS, { site: tree[0], options });
  },
};
