import Base from './Base.js';

export default function CostStatusCreator(i18n) {
  class CostStatus extends Base {}

  CostStatus.initEnum({
    UNINVOICED: {
      value: 'uninvoiced',
      display: i18n.global.t('enums.CostStatus.uninvoiced'),
    },
    PENDING: {
      value: 'pending',
      display: i18n.global.t('enums.CostStatus.pending'),
    },
    APPROVED: {
      value: 'approved',
      display: i18n.global.t('enums.CostStatus.approved'),
    },
  });

  return CostStatus;
}
