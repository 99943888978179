<template>
  <div
    class="header d-none d-lg-flex align-items-center pl-3 pr-2 pt-2 pb-2">
    <b-form-group
      v-if="!isContractorOnboarding"
      :label="$t('components.app.Header.searchLabel')"
      label-for="search-wos"
      :label-sr-only="true"
      class="mb-0"
    >
      <b-input-group class="work-order-search">
        <b-input-group-prepend>
          <div class="input-group-text bg-transparent border-0">
            <font-awesome-icon :icon="['far', 'search']" />
          </div>
        </b-input-group-prepend>
        <b-form-input
          id="search-wos"
          v-model="search"
          type="search"
          :placeholder="$t('components.app.Header.searchPlaceholder')"
          trim
          class="border-0 border py-4"
          @search="onSearchClick"
          @keydown.enter="onSearchClick"
        />
        <b-input-group-append>
          <b-button variant="primary" @click="onSearchClick">
            {{ $t('components.app.Header.search') }}
          </b-button>
        </b-input-group-append>
      </b-input-group>
    </b-form-group>
    <div class="flex-grow-1" />
    <ToolbarProfile
      :name="user.name"
      :role="user.role ? user.role.name : ''"
      :profile-image-url="user.profileImageUrl"
    />
  </div>
</template>

<script>
import {
  BButton,
  BInputGroup,
  BInputGroupAppend,
  BInputGroupPrepend,
  BFormInput,
  BFormGroup,
} from 'bootstrap-vue';
import {mapGetters, mapState} from 'vuex';
import ToolbarProfile from '@/components/user/ToolbarProfile.vue';

/**
 * This component forms the uppermost header bar on the desktop view, housing
 * the search field, add work order and profile info items.
 * @exports src/components/app/Header
 */
export default {
  name: 'DesktopHeader',
  components: {
    BButton,
    BInputGroup,
    BInputGroupPrepend,
    BInputGroupAppend,
    BFormInput,
    BFormGroup,
    ToolbarProfile,
  },
  props: {},
  data() {
    return {
      search: '',
    };
  },
  computed: {
    ...mapState({
      user: (state) => state.authorization.user || {},
    }),
    ...mapGetters(['isContractorOnboarding']),
  },
  methods: {
    onSearchClick() {
      const field = 'search';
      const query = {};

      if (this.search) {
        query[field] = this.search;
      }

      this.$router.push({
        name: 'WorkOrders',
        query,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  background: #fff;
  box-shadow: 0 0 10px 0 rgba(154, 161, 171, 0.3);

  .work-order-search {
    width: 20rem;
    margin-left: 1rem;

    .input-group-text {
      color: #6c757d;
    }

    input,
    .input-group-prepend {
      background: #f1f3fa;
      border: none;
    }

    .input-group-prepend {
      border-top-left-radius: 0.25rem;
      border-bottom-left-radius: 0.25rem;
    }
  }

  .new-work-order {
    svg {
      font-size: 2rem;
    }
  }

  &.scrolling-down {
    top: -70px !important;
  }
}
</style>
