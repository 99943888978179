import types from '../../types/requestForServices.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_REQUEST_FOR_SERVICES_FILTER
   * @summary Updates the `filter` property which holds the collection of filters currently active
   * on the RequestForServices collection
   * @method
   * @param {Object} state Current state
   * @param {Object} filter The filters to be applied
   */
  [m.SET_REQUEST_FOR_SERVICES_FILTER](state, filter) {
    state.filter = filter;
  },

  /**
   * @name m SET_REQUEST_FOR_SERVICES_SORT
   * @summary Updates the `sort` and `direction` properties
   * @method
   * @param {Object} state Current state
   * @param {Object} data The sort data (`sort` and `direction`)
   */
  [m.SET_REQUEST_FOR_SERVICES_SORT](state, { sort, direction }) {
    state.sort = sort;
    state.sortDirection = direction;
  },

  /**
   * @name m SET_ACTIVE_REQUEST_FOR_SERVICE
   * @summary Updates the `activeRequestForService` and resets the
   * `activeRequestForServiceModifiedFields` & `activeRequestForServiceErrors` properties
   * @method
   * @param {Object} state Current state
   * @param {RequestForService} requestForService The new Active {@link RequestForService}
   */
  [m.SET_ACTIVE_REQUEST_FOR_SERVICE](state, requestForService) {
    state.activeRequestForService = requestForService;
    state.activeRequestForServiceModifiedFields =
      !requestForService || requestForService.id ? {} : { ...requestForService };
    state.activeRequestForServiceErrors = [];
  },

  /**
   * @name m SET_ACTIVE_REQUEST_FOR_SERVICE_STATE
   * @summary Updates the `activeRequestForServiceState` property
   * which holds the current status of the
   * based on the API enum
   * @method
   * @param {Object} state Current state
   * @param {enums/API} activeRequestForServiceState The current activeRequestForServiceState
   * (e.g. loading, success, failure)
   */
  [m.SET_ACTIVE_REQUEST_FOR_SERVICE_STATE](state, activeRequestForServiceState) {
    state.activeRequestForServiceState = activeRequestForServiceState;
  },

  /**
   * @name m SET_ACTIVE_REQUEST_FOR_SERVICE_ERRORS
   * @summary Updates the `activeRequestForServiceErrors` property which holds any errors
   * related to activeRequestForService
   * @method
   * @param {Object} state Current state
   * @param {string[]} errors An array of error messages
   */
  [m.SET_ACTIVE_REQUEST_FOR_SERVICE_ERRORS](state, errors) {
    // eslint-disable-next-line no-unused-expressions
    Array.isArray(errors)
      ? (state.activeRequestForServiceErrors = errors)
      : state.activeRequestForServiceErrors.push(errors);
  },

  /**
   * @name m SET_ACTIVE_REQUEST_FOR_SERVICE_VALID
   * @summary Updates the `activeRequestForServiceValid` property which holds a boolean
   * for the validation status of the `activeRequestForService`.
   * @method
   * @param {Object} state Current state
   * @param {boolean} valid True if the `activeRequestForService` is valid.
   */
  [m.SET_ACTIVE_REQUEST_FOR_SERVICE_VALID](state, valid) {
    state.activeRequestForServiceValid = valid;
  },

  /**
   * @name m UPDATE_ACTIVE_REQUEST_FOR_SERVICE_MODIFIED_FIELDS
   * @summary Adds the given field/value pair to the
   * `activeRequestForServiceModifiedFields` collection, which
   * will be sent as a PATCH to the API. If the given `value` equals the one on the
   * `activeRequestForService`
   * object (i.e. it hasn't actually changed, or has been reverted back to it's original) then we
   * remove that field from the modifiedFields collection
   * @method
   * @param {Object} state Current state
   * @param {object} payload Object containing a `field` and `value` properties.
   */
  [m.UPDATE_ACTIVE_REQUEST_FOR_SERVICE_MODIFIED_FIELDS](state, { field, value }) {
    // if the value matches the existing one then we remove the field from the modifiedFields obj
    if (state.activeRequestForService && state.activeRequestForService[field] === value) {
      delete state.activeRequestForServiceModifiedFields[field];
    } else {
      state.activeRequestForServiceModifiedFields[field] = value;
    }
  },

  /**
   * @name m CLEAR_ACTIVE_REQUEST_FOR_SERVICE_MODIFIED_FIELDS
   * @summary Empties the `activeRequestForServiceModifiedFields` object.
   * Used to ensure we're on a clean slate
   * after navigating to a RequestForService Display screen,
   * @method
   * @param {Object} state Current state
   */
  [m.CLEAR_ACTIVE_REQUEST_FOR_SERVICE_MODIFIED_FIELDS](state) {
    state.activeRequestForServiceModifiedFields = {};
  },
};
