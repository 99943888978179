import dayjs from '@/dayjs.js';

export default {
  install(app, store) {
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$dayjs = (date = null, timezone = null) => {
      const tz = timezone || store.getters.getTimezone;
      if (date) {
        return dayjs.utc(date).tz(tz);
      }
      return dayjs.utc().tz(tz);
    };
  },
};
