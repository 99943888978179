import Base from './Base.js';

export default function ContractorQuestionAnswerCreator(i18n) {
  class ContractorQuestionAnswer extends Base {}

  ContractorQuestionAnswer.initEnum({
    YES: {
      value: 'YES',
      display: 'Yes',
    },
    NO: {
      value: 'NO',
      display: 'No',
    },
  });

  return ContractorQuestionAnswer;
}
