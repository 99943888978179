import { processFilterParameters } from "@/util/createAPIParams";
import IndexConfig from "@/services/v2/IndexConfig";

class IndexParameterBuilder {
  static prepare(config) {
    let params = {};

    params.sort = config.direction === IndexConfig.DESCENDING ? `-${config.sort}` : config.sort;

    if (config.page) {
      params['page[number]'] = config.page;
    }

    if (config.pageSize) {
      params['page[size]'] = config.pageSize;
    }

    // set a default page size if one hasn't been set
    if (config.page && !config.pageSize) {
      params['page[size]'] = 50;
    }

    Object.keys(config.fields ?? {}).forEach((value) => {
      params[`fields[${value}]`] = config.fields[value].join(',');
    });

    Object.keys(config.meta ?? {}).forEach((key) => {
      params[`meta[${key}]`] = config.meta[key];
    });

    if ((config.includes ?? []).length) {
      params.include = config.includes.join(',');
    }

    if (config.filters) {
      params = {
        ...params,
        ...processFilterParameters(config.filters),
      };
    }

    if (config.withCount) {
      params['withCount'] = Array.isArray(config.withCount) ? config.withCount.join(',') : config.withCount;
    }

    return params;
  }
}

export default IndexParameterBuilder;
