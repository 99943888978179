function isRange(value) {
  const keys = Object.keys(value || {});

  return keys.indexOf('from') >= 0 || keys.indexOf('to') >= 0;
}

function convertNumberRange(value) {
  if (value.min === !null || undefined) {
    return `,${value.max}`;
  }
  if (value.max === !null || undefined) {
    return `${value.min},`;
  }
  return `${value.min},${value.max}`;
}

/**
 * Returns true when the value is a Range but with an empty
 * UPPER limit, such as "AFTER TODAY"
 * i.e. { from: 'today', to: '' }
 * @param  {Object}  value
 * @return {Boolean}
 */
function isUpperUnboundedRange(value) {
  const valueObj = value || {};

  return isRange(value) && !valueObj.to;
}

/**
 * Returns true when the value is a Range but with an empty
 * LOWER limit, such as "BEFORE TODAY"
 * i.e. { from: '', to: 'today' }
 * @param  {Object}  value
 * @return {Boolean}
 */
function isLowerUnboundedRange(value) {
  const valueObj = value || {};

  return isRange(value) && !valueObj.from;
}

export function processFilterParameters(filter) {
  const processedFilter = {};
  if (filter) {
    Object.entries(filter).forEach(([key, value]) => {
      let out = value;

      if (Array.isArray(value)) {
        out = value.join(',');
      }

      if (Object.keys(value || {}).includes('min' && 'max')) {
        out = convertNumberRange(value);
      }

      if (isUpperUnboundedRange(value)) {
        out = `min:${value.from || ''}`;
      } else if (isLowerUnboundedRange(value)) {
        out = `max:${value.to || ''}`;
      } else if (isRange(value)) {
        out = `range:${value.from || ''}/${value.to || ''}`;
        // TODO: This may not be needed, PartsOrderCategory filtering glitches out without this
      } else if (value?.value !== undefined) {
        out = value.value;
      }
      processedFilter[`filter[${key}]`] = out;
    });
  }
  return processedFilter;
}

/**
 * Creates an object with the correctly formatted API parameters based on given page, sort,
 * direction and filter data.
 * @method
 * @static
 * @param {Number} page The page to load
 * @param {String} sort The field to sort on
 * @param {String} direction The sort direction. Either 'ASC' or 'DESC'. Defaults to ASC.
 * @param {Object} filter An object with key value pairs of filter values.
 * @param {Object} additionalQueryStringParams An object with key value pairs of query string parameters.
 * @return {Object} Processed request parameters:
 * { page: {Number|String}, sort: {String}, filter['field']: {String} }
 */
export default function createAPIParams(
  page,
  sort,
  direction,
  filter,
  additionalQueryStringParams = null,
) {
  let directionSort = null;

  // if sort field isn't provided then don't set it
  if (sort) {
    // todo: we have instances of 'asc' and 'ASC' in the codebase - either unify them or convert to an enum/bool
    directionSort = direction === 'DESC' || direction === 'desc' ? `-${sort}` : sort;
  }

  // create params object
  let params = {
    page: page || 'all',
    ...processFilterParameters(filter),
  };

  if (additionalQueryStringParams) {
    params = {
      ...params,
      ...additionalQueryStringParams,
    };
  }
  // add sort if present
  if (directionSort) {
    params.sort = directionSort;
  }

  return params;
}
