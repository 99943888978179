import axios from 'axios';
import createAPIParams from '@/util/createAPIParams.js';

/**
 * Interface to the RequestForService API endpoints
 * @class
 * @exports src/services/RequestForServiceAPI
 */
class RequestForServiceAPI {
  /**
   * Retrieves the specified RequestForService's details
   * @method
   * @static
   * @param  {number}  requestForServiceId    The requestForService id to load
   * @return {Promise}          Promise that resolves to the {@link RequestForService} details.
   */
  static async getRequestForService(requestForServiceId) {
    const response = await axios({
      method: 'GET',
      url: `work_order_request_for_service/${requestForServiceId}`,
      responseType: 'json',
    });

    return response.data.data;
  }

  /**
   * Retrieves all RequestForServices for the given page
   * @param {Number} page The page to load
   * @param {String} sort The field to sort on
   * @param {String} direction The sort direction. Either 'ASC' or 'DESC'. Defaults to ASC.
   * @param {Object} filter An object with key value pairs of filter values.
   * @return {Promise} Promise that resolves to the api response.
   * In format: { requestForServices: {object[]}, meta: {object}, links: {object} }
   */
  static async getRequestForServices(page, sort, direction, filter) {
    const response = await axios({
      method: 'GET',
      url: 'work_order_request_for_service',
      responseType: 'json',
      params: createAPIParams(page, sort, direction, filter),
    });

    return response.data;
  }

  /**
   * Creates a request for service for a WorkOrder and moves to new transition state.
   * @method
   * @static
   * @param  {number}  workOrderId    The workOrder id to transition
   * @param  {number}  transitionId   The Id of the transition to move to
   * @param  {string}  serviceId   The ID of the service
   * @param  {string}  message        The message to attach
   * @param  {boolean}  shareAttachments        Whether to share attachments with the contractor
   * @return {Promise}          Promise that resolves to the {@link WorkOrder} details.
   */
  static async createRequestForService(
    workOrderId,
    transitionId,
    serviceId,
    message,
    shareAttachments,
  ) {
    const response = await axios({
      method: 'POST',
      url: 'work_order_request_for_service',
      responseType: 'json',
      data: {
        work_order_id: workOrderId,
        transition_id: transitionId,
        service_id: serviceId,
        message,
        share_attachments: shareAttachments,
      },
    });

    return response.data.data;
  }

  static async getActiveRequestForServiceCounts(group, filter) {
    const params = createAPIParams('all', null, null, filter);

    params.group_by = (group || []).join(',');
    params.include_related = '1';

    const response = await axios({
      method: 'GET',
      url: 'work_order_request_for_service/active_stats',
      responseType: 'json',
      params,
    });

    return response?.data;
  }
}

export default RequestForServiceAPI;
