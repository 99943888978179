<template>
  <b-modal
    id="asset-selector-modal"
    ref="modal"
    scrollable
    size="lg"
    dialog-class="expansive-modal full-height asset-selector-modal"
  >
    <template v-slot:modal-header="{ close }">
      <div class="d-flex w-100 align-items-center" data-ref="asset-selector-modal">
        <span class="flex-fill text-center ml-5">
          {{ $t('views.work-orders.WorkOrderEdit.selectAssets') }}
        </span>

        <b-button variant="none" @click="close()">
          <font-awesome-icon :icon="['far', 'times']" size="lg" />
        </b-button>
      </div>
    </template>

    <template v-slot:modal-footer>
      <b-button id="selectButton" class="mt-2" variant="primary" block @click="saveAssets">
        {{ $t('common.select') }}</b-button
      >
    </template>

    <b-tabs pills justified nav-class="tabs-navigation">
      <b-tab title-link-class="first-tab" active>
        <template #title>
          <font-awesome-icon :icon="['far', 'sitemap']" size="sm" class="mr-1" /><!--
          -->{{ $t('common.tree') }}
        </template>

        <TreeView
          class="mt-3 ml-3 mr-3 mb-4"
          :can-add-maintainable-entity="false"
          :pre-selected-keys="selectedValuesIds"
          :scroll-to-selected="false"
          :maintainable-entities-tree="maintainableEntitiesTree"
          :assets="assets"
          :location="location"
          :asset-selection-only="true"
          selection-mode="multiple"
          @treeValueUpdated="treeValueUpdated($event)"
          @addAsset="addAsset($event)"
          @removeAsset="removeAsset($event)"
          @preNodeExpansion="preNodeExpansion"
          @postNodeExpansion="postNodeExpansion"
        />
      </b-tab>
      <b-tab title-link-class="last-tab">
        <template #title>
          <font-awesome-icon :icon="['far', 'search']" size="sm" class="mr-1" /><!--
          -->{{ $t('common.search') }}
        </template>
        <AssetSearch
          class="mt-3 ml-3 mr-3 mb-4"
          :assets="assets"
          :selected-values="selectedValuesIds"
          :maintainable-entities-tree="maintainableEntitiesTree"
          @addAsset="addAsset($event)"
          @removeAsset="removeAsset($event)"
        />
      </b-tab>
    </b-tabs>
  </b-modal>
</template>

<script>
import { BModal, BButton, BTabs, BTab } from 'bootstrap-vue';
import TreeView from '@/components/maintainableEntity/TreeView.vue';
import AssetSearch from './AssetSearch.vue';

export default {
  name: 'AssetSelectorModal',
  components: {
    BModal,
    BTabs,
    BTab,
    BButton,
    TreeView,
    AssetSearch,
  },
  props: {
    maintainableEntitiesTree: {
      type: Array,
      default: () => [],
    },
    assets: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Array,
      default: () => [],
    },
    location: {
      type: Number,
      default: () => -1,
    },
  },

  data() {
    return {
      selectedValuesIds: [],
      preNodeSelectionTopOffset: 0,
    };
  },

  methods: {
    preNodeExpansion() {
      const modal = document.getElementsByClassName('asset-selector-modal')[0];
      this.preNodeSelectionTopOffset = modal.querySelector('.modal-body').scrollTop;
    },
    postNodeExpansion() {
      const modal = document.getElementsByClassName('asset-selector-modal')[0];
      this.$nextTick(() => {
        modal.querySelector('.modal-body').scrollTop = this.preNodeSelectionTopOffset;
      });
    },
    show() {
      this.selectedValuesIds = this.value.map((assetId) => ({
        id: assetId,
        type: 'ASSET',
      }));
      this.$refs.modal.show();
    },
    treeValueUpdated(treeValue) {
      this.selectedValuesIds = treeValue.map((node) => parseInt(node.id));
    },
    onCancelButtonClick() {
      this.hide();
    },
    hide() {
      this.$refs.modal.hide();
    },
    addAsset(asset) {
      this.selectedValuesIds.push(asset.id);
    },
    removeAsset(asset) {
      this.selectedValuesIds = this.selectedValuesIds.filter((a) => a !== asset.id);
    },
    saveAssets() {
      this.$refs.modal.hide();
      this.$emit('input', this.selectedValuesIds);
    },
  },
};
</script>

<style lang="scss">
#asset-selector-modal___BV_modal_outer_ {
  // To get the modal to appear when triggered from a side panel
  z-index: 999999999 !important;
}

.tabs-navigation {
  border: $primary solid 1px;
  border-radius: 5px;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.first-tab {
  border-radius: 4px 0px 0px 5px !important;
  margin-bottom: -1px;
}
.last-tab {
  border-radius: 0px 4px 5px 0px !important;
  margin-bottom: -1px;
}
</style>
