import APIError from '../../../errors/APIError.js';
import types from '../../types/workOrderAvailableServices.js';
import AvailableServicesAPI from '../../../services/WorkOrderAvailableServicesAPI.js';
import ApiStatusCreator from '../../../enums/API.js';
const API_STATUS = ApiStatusCreator();

const a = types.actions;
const m = types.mutations;

export default {
  /**
   * Loads WorkOrderAvailableServices
   * @param {Object} context
   * @return {WorkOrderAvailableServices[]}
   */
  async [a.GET_WORK_ORDER_AVAILABLE_SERVICES](
    { commit, state },
    { page, sort, direction, filter },
  ) {
    commit(m.SET_WORK_ORDER_AVAILABLE_SERVICES_STATUS, API_STATUS.LOADING);

    try {
      // eslint-disable-next-line max-len
      const data = await AvailableServicesAPI.getWorkOrderAvailableServices(
        page,
        sort,
        direction,
        filter,
      );

      commit(m.SET_WORK_ORDER_AVAILABLE_SERVICES_STATUS, API_STATUS.SUCCESS);
      commit(m.SET_WORK_ORDER_AVAILABLE_SERVICES, data);
    } catch (error) {
      commit(m.SET_WORK_ORDER_AVAILABLE_SERVICES_STATUS, API_STATUS.FAILURE);

      let message = 'An error occurred while fetching these Work Order Available Services. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.workOrderAvailableServices;
  },
};
