export default {
  path: '/compliance-documents',
  meta: {
    requiresAuth: true,
    layout: 'DefaultLayout',

    bcLinkText: 'Compliance Documents',
    storeModules: ['document', 'documentType'],
  },
  component: () => import('@/views/compliance-documents/ComplianceDocumentList.vue'),
  children: [
    {
      path: ':page(\\d+)?',
      name: 'ComplianceDocumentList',
      component: () =>
        import('@/views/compliance-documents/ComplianceDocumentList.vue'),
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
      },
    },
  ],
};
