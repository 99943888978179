export default {
  path: '/sites',
  meta: {
    requiresAuth: true,
    layout: 'DefaultLayout',
    bcLinkText: 'Sites',
    storeModules: ['site', 'organisation', 'siteType'],
  },
  component: () => import('@/views/sites/Sites.vue'),
  children: [
    {
      path: ':page(\\d+)?',
      name: 'Sites',
      component: () => import('@/views/sites/SitesHome.vue'),
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'Site_Manage',
      },
    },
  ],
};
