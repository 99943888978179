import APIError from '../../../errors/APIError.js';
import types from '../../types/serviceType.js';
import ServiceTypeAPI from '../../../services/ServiceTypeAPI.js';
import ApiStatusCreator from '../../../enums/API.js';
const API_STATUS = ApiStatusCreator();

const a = types.actions;
const m = types.mutations;

export default {
  /**
   * Loads all Service Types
   * @param {Object} context
   * @param {Object} args
   * @return {ServiceType[]} An array of {@link ServiceType} objects.
   */
  async [a.GET_SERVICE_TYPES]({ commit, state }, args) {
    const filter = args?.filter ?? {};
    commit(m.SET_SERVICE_TYPES_STATUS, API_STATUS.LOADING);

    try {
      const serviceTypes = await ServiceTypeAPI.getServiceTypes(filter);

      commit(m.SET_SERVICE_TYPES_STATUS, API_STATUS.SUCCESS);
      commit(m.SET_SERVICE_TYPES, serviceTypes);
    } catch (error) {
      commit(m.SET_SERVICE_TYPES_STATUS, API_STATUS.FAILURE);

      let message = 'An error occurred while fetching these Service Types. Please try again';

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new APIError(Array.isArray(message) ? message : [message]);
    }

    return state.serviceTypes;
  },
};
