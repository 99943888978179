export default {
  path: '/parts',
  meta: {
    requiresAuth: true,
    layout: 'DefaultLayout',
    bcLinkText: 'Parts Ordering',
  },
  component: () => import('@/views/parts/Parts.vue'),
  children: [
    {
      path: ':category?',
      name: 'PartsScreen',
      component: () => import('@/views/parts/Home.vue'),
      meta: {
        requiredAuth: true,
        layout: 'DefaultLayout',
        permission: 'PartsOrder_Order',
        bcLinkText: 'Parts Ordering',
      },
      children: [
        {
          path: ':partId/view',
          name: 'PartsView',
          component: () => import('@/views/parts/ViewModalContent.vue'),
          meta: {
            requiredAuth: true,
            layout: 'DefaultLayout',
            bcLinkText: 'View Part',
          },
        },
      ],
    },
  ],
};
