<template>
  <div v-if="workOrders && workOrders.length" class="duplicate-work-orders">
    <div class="d-flex align-items-center justify-content-between mb-2">
      <h6 :key="workOrders.length" class="title m-0">
        <font-awesome-icon :icon="['far', 'robot']" />&nbsp;<!--
        -->{{
          $t('components.workOrder.duplicate.DuplicateWorkOrderList.title', {
            count: workOrders.length,
          })
        }}
      </h6>
      <b-button
        class="text-muted btn-xs d-flex align-items-center p-1 small"
        variant="light"
        @click="expanded = !expanded"
      >
        <font-awesome-icon :icon="['far', expanded ? 'minus' : 'plus']" class="fa-fw" />
      </b-button>
    </div>
    <p class="mb-1 small text-muted">
      {{ $t('components.workOrder.duplicate.DuplicateWorkOrderList.intro') }}
    </p>
    <b-collapse v-model="expanded">
      <b-list-group v-if="displayWorkOrders.length" data-ref="list">
        <DuplicateWorkOrder
          v-for="workOrder in displayWorkOrders"
          :key="workOrder.id"
          class="mt-2"
          :work-order="workOrder"
        />
      </b-list-group>
      <span v-if="truncatedCount && showAllOptions === false" class="d-flex justify-content-center">
        <b-button
          data-ref="show-more"
          variant="outline-secondary"
          class="mt-3"
          @click="showAllOptions = true"
        >
          {{$t('common.showMore', {number: truncatedCount} )}}
        </b-button>
      </span>
    </b-collapse>
  </div>
</template>

<script>
import { BListGroup, BButton, BCollapse } from 'bootstrap-vue';
import WorkOrderAPI from '@/services/WorkOrderAPI.js';
import DuplicateWorkOrder from '@/components/workOrder/duplicate/DuplicateWorkOrder.vue';

export default {
  name: 'DuplicateWorkOrderList',
  components: {
    BListGroup,
    BButton,
    BCollapse,
    DuplicateWorkOrder,
  },
  props: {
    serviceTypeId: {
      type: Number,
      required: true,
    },
    primaryLocationId: {
      type: Number,
      required: true,
    },
  },

  data() {
    return {
      workOrders: null,
      errors: null,
      showAllOptions: false,
      expanded: false,
    };
  },

  computed: {
    displayWorkOrders() {
      if (!this.workOrders) {
        return null;
      }

      return this.showAllOptions ? this.workOrders : this.workOrders.slice(0, 3);
    },
    truncatedCount() {
      if (!this.workOrders) {
        return 0;
      }

      return this.workOrders.length - this.displayWorkOrders.length;
    },
  },

  watch: {
    serviceTypeId() {
      this.loadWorkOrders();
    },
    primaryLocationId() {
      this.loadWorkOrders();
    },
  },

  created() {
    this.loadWorkOrders();
  },

  methods: {
    async loadWorkOrders() {
      const filter = {
        service_type_id_exact: this.serviceTypeId,
        'PrimaryLocation.id': this.primaryLocationId,
        is_recent: true,
        recurring_work_order_id: 'null',
      };
      try {
        this.workOrders = null;
        const response = await WorkOrderAPI.getWorkOrders(0, 'created_at', 'DESC', filter);
        this.workOrders = response.data;
      } catch (error) {
        this.errors = this.$handleErrors(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.duplicate-work-orders {
  border-top: 3px dotted #ca8d30;
  border-bottom: 3px dotted #ca8d30;
  padding: 1rem;
  margin: 1rem 0;
  background-color: #f0f0f0;
}

.title {
  color: #ca8d30;
  font-weight: bold;
}
</style>
