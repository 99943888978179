import Base from './Base.js';

export default function RelativeDateRangeFutureCreator(i18n) {
  class RelativeDateRangeFuture extends Base {}

  RelativeDateRangeFuture.initEnum({
    BEFORE_TODAY: {
      value: {from: '', to: 'end-of-previous-day'},
      display: i18n.global.t('ux.form.DateRange.relativeOptions.beforeToday'),
    },
    BEFORE_NOW: {
      value: {from: '', to: 'now'},
      display: i18n.global.t('ux.form.DateRange.relativeOptions.beforeNow'),
    },
    TODAY: {
      value: {from: 'start-of-day', to: 'end-of-day'},
      display: i18n.global.t('ux.form.DateRange.relativeOptions.today'),
    },
    TOMORROW: {
      value: {from: 'start-of-next-day', to: 'end-of-next-day'},
      display: i18n.global.t('ux.form.DateRange.relativeOptions.tomorrow'),
    },
    NEXT_24: {
      value: {from: 'now', to: '+1d'},
      display: i18n.global.t('ux.form.DateRange.relativeOptions.next24Hours'),
    },
    NEXT_72: {
      value: {from: 'now', to: '+3d'},
      display: i18n.global.t('ux.form.DateRange.relativeOptions.next72Hours'),
    },
    NEXT_7_DAYS: {
      value: {from: 'now', to: '+7d'},
      display: i18n.global.t('ux.form.DateRange.relativeOptions.next7Days'),
    },
    NEXT_14_DAYS: {
      value: {from: 'now', to: '+14d'},
      display: i18n.global.t('ux.form.DateRange.relativeOptions.next14Days'),
    },
    NEXT_30_DAYS: {
      value: {from: 'now', to: '+30d'},
      display: i18n.global.t('ux.form.DateRange.relativeOptions.next30Days'),
    },
    NEXT_90_DAYS: {
      value: {from: 'now', to: '+90d'},
      display: i18n.global.t('ux.form.DateRange.relativeOptions.next90Days'),
    },
    REST_OF_WEEK: {
      value: {from: 'now', to: 'end-of-week'},
      display: i18n.global.t('ux.form.DateRange.relativeOptions.restOfWeek'),
    },
    NEXT_WEEK: {
      value: {from: 'start-of-next-week', to: 'end-of-next-week'},
      display: i18n.global.t('ux.form.DateRange.relativeOptions.nextWeek'),
    },
    REST_OF_MONTH: {
      value: {from: 'now', to: 'end-of-month'},
      display: i18n.global.t('ux.form.DateRange.relativeOptions.restOfMonth'),
    },
    NEXT_MONTH: {
      value: {from: 'start-of-next-month', to: 'end-of-next-month'},
      display: i18n.global.t('ux.form.DateRange.relativeOptions.nextMonth'),
    },
    REST_OF_YEAR: {
      value: {from: 'now', to: 'end-of-year'},
      display: i18n.global.t('ux.form.DateRange.relativeOptions.restOfYear'),
    },
  });

  return RelativeDateRangeFuture;

  // TODO: Should be done in component (RelativeDateRangeFutureListOptions)
  // export const RelativeDateRangeFutureListOptions = RelativeDateRangeFuture.enumValues.map((v) => ({
  //   value: v.value,
  //   text: v.display,
  // }));
}
