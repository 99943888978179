import Base from './Base.js';

export default function RamsTypeCreator(i18n) {
  class RamsType extends Base {}

  RamsType.initEnum({
    GENERIC: {
      value: 'GENERIC',
      display: i18n.global.t('enums.RamsType.generic'),
    },
    SITE_SPECIFIC: {
      value: 'SITE_SPECIFIC',
      display: i18n.global.t('enums.RamsType.siteSpecific'),
    },
  });

  return RamsType;
}
