<template>
  <div>
    <b-modal ref="disableModal">
      <template #modal-header>
        <div class="d-flex w-100 align-items-center">
          <b-button class="cancel-close-button" variant="none" @click="hide">
            <span v-if="otp_enabled">{{ $t('common.forms.cancel') }}</span>
            <span v-else>{{ $t('common.forms.close') }}</span>
          </b-button>
          <span class="flex-fill text-center">
            {{ $t('components.auth.otp.disable.header') }}
          </span>
        </div>
        <div class="btn-placeholder"
      /></template>

      <div v-if="otp_enabled">
        <p>
          {{ $t('components.auth.otp.disable.explanation') }}
        </p>
        <p>
          {{ $t('components.auth.otp.disable.instruction') }}
        </p>

        <b-form @submit.prevent>
          <div class="fields-wrapper">
            <b-form-group label-for="password">
              <template #label>
                <Required>{{ $t('components.auth.password.password') }}</Required>
              </template>

              <b-form-input
                v-model="v$.password.$model"
                class="field-input"
                type="password"
                :state="v$.password.$dirty ? !v$.password.$invalid : null"
              ></b-form-input>
            </b-form-group>
          </div>
        </b-form>

        <b-alert v-if="error" class="mb-0" variant="danger" show>
          <font-awesome-icon :icon="['far', 'exclamation-triangle']" class="mr-1" />&nbsp;<!--
          --><span :key="error">{{ error }}</span>
        </b-alert>
      </div>
      <b-alert v-if="!otp_enabled" class="mb-0" variant="success" show>
        <h4 class="alert-heading">
          {{ $t('components.auth.otp.disable.confirmationMessageHeader') }}
        </h4>
        {{ $t('components.auth.otp.disable.confirmationMessage') }}
      </b-alert>

      <div v-if="!otp_enabled"></div>

      <template #modal-footer>
        <SpinButton
          ref="disableButton"
          :class="{ 'd-none': !otp_enabled }"
          :disabled="v$.$invalid"
          variant="danger"
          @click="disableOtp"
          >{{ $t('components.auth.otp.disable.button') }}</SpinButton
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
import { BAlert, BModal, BButton, BForm, BFormInput, BFormGroup } from 'bootstrap-vue';
import Required from '@/ux/form/Required.vue';
import SpinButton from '@/ux/SpinButton.vue';
import types from '@/store/types/authorization.js';
import { mapState } from 'vuex';
import { required } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

export default {
  name: 'DisableModal',
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    BAlert,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BModal,
    Required,
    SpinButton,
  },

  data() {
    return {
      error: null,
      password: '',
      otpDisabled: false,
    };
  },

  computed: {
    ...mapState({
      otp_enabled: (state) => state.authorization.otp_enabled,
    }),
  },

  validations: {
    password: {
      required,
    },
  },

  methods: {
    show() {
      this.error = null;
      this.password = '';
      this.$refs.disableModal.show();
    },

    hide() {
      this.v$.$reset();
      this.$refs.disableModal.hide();
    },

    async disableOtp() {
      try {
        await this.$store.dispatch(types.actions.DISABLE_OTP, {
          password: this.password,
        });
      } catch (error) {
        this.error = error.response.data.message;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cancel-close-button {
  width: 5rem;
}
</style>
