import types from '../../types/supplier.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_SUPPLIERS
   * @summary Updates the `suppliers` property.
   * @method
   * @param {Object} state Current state
   * @param {Supplier[]} suppliers The loaded Suppliers array
   */
  [m.SET_SUPPLIERS](state, suppliers) {
    state.suppliers = suppliers;
  },

  [m.SET_SUPPLIERS_STATUS](state, status) {
    state.suppliersState = status;
  },

  /**
   * @name m SET_SUPPLIERS_META
   * @summary Updates the `meta` property which holds the meta data for the `SUPPLIERS` collection.
   * For example, total rows, current page, number rows per page etc
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The meta data to store
   */
  [m.SET_SUPPLIERS_META](state, meta) {
    state.meta = meta;
  },

  /**
   * @name m SET_SUPPLIERS_FILTER
   * @summary Updates the `filter` property which holds the collection of filters currently active
   * on the Suppliers collection
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The filters to be applied
   */
  [m.SET_SUPPLIERS_FILTER](state, filter) {
    state.filter = filter;
  },

  /**
   * @name m SET_ACTIVE_SUPPLIER
   * @summary Updates the `activeSupplier` and resets the
   * `activeSupplierModifiedFields` & `activeSupplierErrors` properties
   * @method
   * @param {Object} state Current state
   * @param {Supplier} supplier The new Active {@link Supplier}
   */
  [m.SET_ACTIVE_SUPPLIER](state, supplier) {
    state.activeSupplier = supplier;
    state.activeSupplierModifiedFields = !supplier || supplier.id ? {} : { ...supplier };
    state.activeSupplierErrors = [];
  },

  /**
   * @name m SET_ACTIVE_SUPPLIER_STATE
   * @summary Updates the `activeSupplierState` property which holds the current status of the
   * based on the API enum
   * @method
   * @param {Object} state Current state
   * @param {enums/API} activeSupplierState The current activeSupplierState
   * (e.g. loading, success, failure)
   */
  [m.SET_ACTIVE_SUPPLIER_STATE](state, activeSupplierState) {
    state.activeSupplierState = activeSupplierState;
  },

  /**
   * @name m SET_ACTIVE_SUPPLIER_ERRORS
   * @summary Updates the `activeSupplierErrors` property which holds any
   * errors related to activeSupplier
   * @method
   * @param {Object} state Current state
   * @param {string[]} errors An array of error messages
   */
  [m.SET_ACTIVE_SUPPLIER_ERRORS](state, errors) {
    state.activeSupplierErrors = errors;
  },

  /**
   * @name m SET_ACTIVE_SUPPLIER_VALID
   * @summary Updates the `activeSupplierValid` property which holds
   * a boolean for the validation status of the `activeSupplier`.
   * @method
   * @param {Object} state Current state
   * @param {boolean} valid True if the `activeSupplier` is valid.
   */
  [m.SET_ACTIVE_SUPPLIER_VALID](state, valid) {
    state.activeSupplierValid = valid;
  },

  /**
   * @name m UPDATE_ACTIVE_SUPPLIER_MODIFIED_FIELDS
   * @summary Adds given field/value pair to the `activeSupplierModifiedFields` collection, which
   * will be sent as a PATCH to the API. If the given `value` equals the one on the `activeSupplier`
   * object (i.e. it hasn't actually changed, or has been reverted back to it's original) then we
   * remove that field from the modifiedFields collection
   * @method
   * @param {Object} state Current state
   * @param {object} payload Object containing a `field` and `value` properties.
   */
  [m.UPDATE_ACTIVE_SUPPLIER_MODIFIED_FIELDS](state, { field, value }) {
    // if the value matches the existing one then we remove the field from the modifiedFields obj
    if (state.activeSupplier && state.activeSupplier[field] === value) {
      delete state.activeSupplierModifiedFields[field];
    } else {
      state.activeSupplierModifiedFields[field] = value;
    }
  },

  /**
   * @name m CLEAR_ACTIVE_SUPPLIER_MODIFIED_FIELDS
   * @summary Empties the `activeSupplierModifiedFields` object. Used to ensure we're on a clean
   * slate after navigating to a Supplier Display screen,
   * @method
   * @param {Object} state Current state
   */
  [m.CLEAR_ACTIVE_SUPPLIER_MODIFIED_FIELDS](state) {
    state.activeSupplierModifiedFields = {};
  },

  /**
   * @name m REMOVE_LOCAL_SUPPLIER
   * @summary Removes the given supplier from the `suppliers` array. This is used to prevent a
   * full reload being needed after deleting a Supplier.
   * @method
   * @param {Object} state Current state
   * @param {object} supplier The Supplier to remove.
   */
  [m.REMOVE_LOCAL_SUPPLIER](state, supplier) {
    const index = state.suppliers.findIndex((u) => u.id === supplier.id);

    if (index >= 0) {
      state.suppliers.splice(index, 1);
    }
  },

  /**
   * @name m ADD_LOCAL_SUPPLIER
   * @summary Adds the given supplier to the `suppliers` array. This is used to prevent a
   * full reload being needed after adding a Supplier.
   * @method
   * @param {Object} state Current state
   * @param {object} supplier The Supplier to add.
   */
  [m.ADD_LOCAL_SUPPLIER](state, supplier) {
    state.suppliers.push(supplier);
  },

  /**
   * @name m UPDATE_LOCAL_SUPPLIER
   * @summary Updates the related supplier object in the `suppliers` array after an update.
   * @method
   * @param {Object} state Current state
   * @param {object} supplier The Supplier to update.
   */
  [m.UPDATE_LOCAL_SUPPLIER](state, supplier) {
    const localSupplier = state.suppliers.find((c) => c.id === supplier.id);

    if (localSupplier) {
      Object.assign(localSupplier, supplier);
    }
  },
};
