import * as Sentry from '@sentry/browser';
import APIError from '../../../errors/APIError.js';
import types from '../../types/workOrder.js';
import WorkOrderAPI from '../../../services/WorkOrderAPI.js';
import WorkOrderTypeAPI from '../../../services/WorkOrderTypeAPI.js';
import WorkOrderRequestTypeAPI from '../../../services/WorkOrderRequestTypeAPI.js';
import WorkOrderStateAPI from '../../../services/WorkOrderStateAPI.js';
import ApiStatusCreator from '../../../enums/API.js';
const API_STATUS = ApiStatusCreator();

const a = types.actions;
const m = types.mutations;

export default {
  /**
   * Loads specific WorkOrder details
   * @param {Object} context
   * @param {number} id The WorkOrder ID to load
   * @return {WorkOrder} A {@link WorkOrder} object.
   */
  async [a.GET_WORK_ORDER]({ commit, state }, { id }) {
    try {
      commit(m.SET_ACTIVE_WORK_ORDER, null);
      commit(m.SET_ACTIVE_WORK_ORDER_STATE, API_STATUS.LOADING);

      const workOrder = await WorkOrderAPI.getWorkOrder(id);

      workOrder.created_at = new Date(workOrder.created_at);
      workOrder.updated_at = new Date(workOrder.updated_at);

      commit(m.SET_ACTIVE_WORK_ORDER, workOrder);
      commit(m.SET_ACTIVE_WORK_ORDER_STATE, API_STATUS.SUCCESS);
    } catch (error) {
      let message = 'An error occurred while fetching this Work Order. Please try again.'
      commit(m.SET_ACTIVE_WORK_ORDER_STATE, API_STATUS.FAILURE);

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return state.workOrders;
  },

  /**
   * Creates a new WorkOrder
   * @param {Object} context
   * @param {object} workOrder A {@link WorkOrder} object
   * @return {WorkOrder} A {@link WorkOrder} object.
   */
  async [a.CREATE_WORK_ORDER]({ commit, state }, { workOrder }) {
    try {
      commit(m.SET_ACTIVE_WORK_ORDER_STATE, API_STATUS.SAVING);
      commit(m.SET_ACTIVE_WORK_ORDER_ERRORS, []);

      // Duplicate work order creation request debugging
      // accumulate errors globally so we can detect duplicates
      if (!window.recentWorkOrderCreateRequests) {
        window.recentWorkOrderCreateRequests = [];
      }
      window.recentWorkOrderCreateRequests.push(
        new Error('Simultaneous work order create requests detected'),
      );

      let workOrderResponse;
      // only allow a single API request to be submitted simultaneously
      if (!window.workOrderCreatePromise) {
        // report all duplicate requests
        window.setTimeout(() => {
          if (window.recentWorkOrderCreateRequests.length > 1) {
            window.recentWorkOrderCreateRequests.forEach((e) => {
              Sentry.captureException(e);
            });
          }
          window.recentWorkOrderCreateRequests = [];
        }, 1000);

        window.workOrderCreatePromise = WorkOrderAPI.createWorkOrder(workOrder);
        workOrderResponse = await window.workOrderCreatePromise;
        window.workOrderCreatePromise = undefined;
      } else {
        workOrderResponse = await window.workOrderCreatePromise;
      }

      commit(m.SET_ACTIVE_WORK_ORDER_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_WORK_ORDER, workOrderResponse);
    } catch (error) {
      window.workOrderCreatePromise = undefined;
      commit(m.SET_ACTIVE_WORK_ORDER_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while creating this Work Order. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeWorkOrder;
  },

  /**
   * Updates an existing WorkOrder
   * @param {Object} context
   * @param {object} workOrder The {@link WorkOrder} object to update
   * @param {object} modifiedFields An object with the key/values for the fields to update.
   * @return {WorkOrder} A {@link WorkOrder} object.
   */
  async [a.UPDATE_WORK_ORDER]({ commit, state }, { workOrder, modifiedFields }) {
    try {
      commit(m.SET_ACTIVE_WORK_ORDER_ERRORS, []);
      commit(m.SET_ACTIVE_WORK_ORDER_STATE, API_STATUS.SAVING);

      const workOrderResponse = await WorkOrderAPI.updateWorkOrder(workOrder.id, modifiedFields);

      commit(m.SET_ACTIVE_WORK_ORDER_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_WORK_ORDER, workOrderResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_WORK_ORDER_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while updating this Work Order. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeWorkOrder;
  },

  /**
   * Deletes a WorkOrder
   * @param {Object} context
   * @param {object} workOrder The {@link WorkOrder} object to delete
   * @return {WorkOrder} A {@link WorkOrder} object.
   */
  async [a.DELETE_WORK_ORDER]({ commit }, { workOrder }) {
    try {
      commit(m.SET_ACTIVE_WORK_ORDER_ERRORS, []);
      commit(m.SET_ACTIVE_WORK_ORDER_STATE, API_STATUS.DELETING);

      await WorkOrderAPI.deleteWorkOrder(workOrder.id);

      commit(m.SET_ACTIVE_WORK_ORDER_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_WORK_ORDER, null);
    } catch (error) {
      commit(m.SET_ACTIVE_WORK_ORDER_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while deleting this Work Order. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return true;
  },

  /**
   * Loads all Work Order Types
   * @param {Object} context
   * @param {string} sort The sort field
   * @param {string} direction The sort direction
   * @param {object} filter The filter criteria
   * @return {WorkOrderType[]} A {@link WorkOrderType} array.
   */
  async [a.GET_WORK_ORDER_TYPES]({ commit, state }, { sort, direction, filter }) {
    try {
      commit(m.SET_WORK_ORDER_TYPES, []);

      const workOrderTypes = await WorkOrderTypeAPI.getWorkOrderTypes(
        'all',
        sort,
        direction,
        filter,
      );

      commit(m.SET_WORK_ORDER_TYPES, workOrderTypes);
    } catch (error) {
      let message = 'An error occurred while fetching these Work Order Types. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return state.workOrderTypes;
  },

  /**
   * Loads all Work Order Request Types
   * @param {Object} context
   * @param {string} page The page field
   * @param {string} sort The sort field
   * @param {string} direction The sort direction
   * @param {object} filter The filter criteria
   * @return {WorkOrderType[]} A {@link WorkOrderRequestType} array.
   */
  async [a.GET_WORK_ORDER_REQUEST_TYPES]({ commit, state }, { page, sort, direction, filter }) {
    try {
      commit(m.SET_WORK_ORDER_REQUEST_TYPES, []);

      // eslint-disable-next-line max-len
      const workOrderRequestTypes = await WorkOrderRequestTypeAPI.getWorkOrderRequestTypes(
        page,
        sort,
        direction,
        filter,
      );

      commit(m.SET_WORK_ORDER_REQUEST_TYPES, workOrderRequestTypes);
    } catch (error) {
      let message = 'An error occurred while fetching these Work Order Request Types. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return state.workOrderRequestTypes;
  },

  /**
   * Loads all Work Order Request Types
   * @param {Object} context
   * @param {string} sort The sort field
   * @param {string} direction The sort direction
   * @param {object} filter The filter criteria
   * @return {WorkOrderType[]} A {@link WorkOrderRequestType} array.
   */
  async [a.GET_WORK_ORDER_STATES]({ commit, state }) {
    try {
      commit(m.SET_WORK_ORDER_STATES, []);

      // eslint-disable-next-line max-len
      const states = await WorkOrderStateAPI.getWorkOrderStates();

      commit(m.SET_WORK_ORDER_STATES, states);
    } catch (error) {
      let message = 'An error occurred while fetching these Work Order States. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return state.workOrderStates;
  },
};
