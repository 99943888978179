export default {
  path: '/cost-proposals',
  meta: {
    requiresAuth: true,
    layout: 'DefaultLayout',
    permission: 'CostProposalRequest_View',

    bcLinkText: 'Quotes',
    storeModules: ['costProposal'],
  },
  component: () => import('@/views/cost-proposals/CostProposals.vue'),
  children: [
    {
      path: ':page(\\d+)?',
      name: 'CostProposals',
      component: () => import('@/views/cost-proposals/CostProposalsHome.vue'),
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
      },
    },
  ],
};
