class Errors {
  static getErrors(error) {
    const errors = error.response?.data?.errors;

    // Handles 422 validation errors
    if(errors) {
      return errors?.map((error) => error.detail) ?? null;
    }
    // Handles 403 Authorisation error.
    else if (error.response?.data?.message) {
      return [error.response?.data?.message];
    }
    else {
      return null;
    }
  }
}

export default Errors;
