export default function getFileIcon(file) {
    if (!file) {
      return 'file-exclamation';
    }

    const mime = file.type || file.mime_type || '';
    const filename = file.file_name || file.name || '';
    const extension = filename.split('.').pop().toLowerCase();
    const mapMimes = {
      image: 'image',
      video: 'video',
    };
    const mapExtensions = {
      xls: 'file-excel',
      xlsx: 'file-excel',
      doc: 'file-word',
      docx: 'file-word',
      rtf: 'file-word',
      pdf: 'file-pdf',
    };

    let icon = mapMimes[mime.split('/')[0]];

    if (!icon) {
      icon = mapExtensions[extension];
    }

    if (!icon) {
      icon = 'file';
    }

    return icon;
}
