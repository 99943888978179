import axios from 'axios';
import createAPIParams from '@/util/createAPIParams.js';

/**
 * @typedef {Object} ContractorRate
 * @property {number} id -
 * @property {string} name
 * @property {int} contractor_id
 * @property {int} standard_hours_callout_amount
 * @property {int} standard_hours_labour_amount
 * @property {int} out_of_hours_callout_amount
 * @property {int} out_of_hours_labour_amount
 * @property {boolean} enabled
 *
 */

/**
 *
 * @class
 * @exports src/services/ContractorRatesAPI
 */
export default class ContractorRateAPI {
  static async getContractorRate(page, sort, direction, filter) {
    const response = await axios({
      method: 'GET',
      url: 'contractor_rate',
      responseType: 'json',
      params: createAPIParams(page || 'all', sort, direction, filter),
    });

    return response.data.data;
  }

  static async createContractorRate(rate) {
    const response = await axios({
      method: 'POST',
      url: 'contractor_rate',
      responseType: 'json',
      data: rate,
    });

    return response.data.data;
  }

  static async updateContractorRate(rateId, modifiedFields) {
    const response = await axios({
      method: 'PATCH',
      url: `contractor_rate/${rateId}`,
      responseType: 'json',
      data: modifiedFields,
    });

    return response.data.data;
  }

  static async deleteContractorRate(rateId) {
    await axios({
      method: 'DELETE',
      url: `contractor_rate/${rateId}`,
      responseType: 'json',
    });

    return true;
  }
}
