export default {
  state: {
    invoice: (data = {}) => ({
      id: null,
      work_order_id: null,
      reference: null,
      issue_date: null,
      description: null,
      due_date: null,
      lines: [],
      ...data,
    }),
  },

  getters: {},

  actions: {
    GET_COST_PROPOSAL: 'GET_COST_PROPOSAL',
  },

  mutations: {
    SET_ACTIVE_COST_PROPOSAL: 'SET_ACTIVE_COST_PROPOSAL',
    SET_ACTIVE_COST_PROPOSAL_STATE: 'SET_ACTIVE_COST_PROPOSAL_STATE',
    SET_ACTIVE_COST_PROPOSAL_ERRORS: 'SET_ACTIVE_COST_PROPOSAL_ERRORS',

    SET_INVOICES_FILTER: 'SET_INVOICES_FILTER',
    SET_INVOICES_SORT: 'SET_INVOICES_SORT',
  },

  types: ['QUOTE', 'ESTIMATE'],
};
