import axios from 'axios';
import createAPIParams from '@/util/createAPIParams.js';

/**
 * Interface to the WorkOrderType API endpoints
 * @class
 * @exports src/services/WorkOrderTypeAPI
 */
class WorkOrderTypeAPI {
  /**
   * Retrieves all WorkOrderTypes
   * @param {String} sort The field to sort on
   * @param {String} direction The sort direction. Either 'ASC' or 'DESC'. Defaults to ASC.
   * @param {Object} filter An object with key value pairs of filter values.
   * @return {Promise} Promise that resolves to the api response's data
   */
  static async getWorkOrderTypes(page, sort, direction, filter) {
    const response = await axios({
      method: 'GET',
      url: 'work_order_type',
      responseType: 'json',
      params: createAPIParams(page || 'all', sort, direction, filter),
    });

    return response.data.data;
  }

  /**
   * @summary Hits the create WorkOrderType endpoint.
   * @desc Sends the given workOrderType data to the create endpoint
   * @param  {WorkOrderType}  workOrderType A {@link WorkOrderType} object to be saved
   * @return {Promise}      Resolves to the returned full WorkOrderType object
   */
  static async createWorkOrderType(workOrderType) {
    const response = await axios({
      method: 'POST',
      url: 'work_order_type',
      responseType: 'json',
      data: workOrderType,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the PATCH WorkOrderType endpoint.
   * @desc Sends the specified _modified_ fields to the endpoint to be saved.
   * @param  {String|Number}  workOrderTypeId The WorkOrderType Id to save
   * @param  {object}  modifiedFields An object with a subset of the
   * {@link WorkOrderType} fields that have been modified and should be saved.
   * @return {Promise}      Resolves to the returned full WorkOrderType object
   */
  static async updateWorkOrderType(workOrderTypeId, modifiedFields) {
    const response = await axios({
      method: 'PATCH',
      url: `work_order_type/${workOrderTypeId}`,
      responseType: 'json',
      data: modifiedFields,
    });

    return response.data.data;
  }
}

export default WorkOrderTypeAPI;
