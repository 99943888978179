export default {
  /**
   * @name g isActiveTagPhantom
   * @desc Returns true if the activeCostCode is 'phantom' (i.e. has not been saved).
   * @param  {object}  state Current state
   * @return {Boolean}
   */
  isActiveTagPhantom(state) {
    return !(state.activeTag && state.activeTag.id);
  },
};
