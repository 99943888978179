export default {
  /**
   * @name g getActiveDashboard
   * @desc Returns the `activeDashboard` object
   * @param  {object}  state Current state
   * @return {object}
   */
  getActiveDashboard(state) {
    return state.activeDashboard;
  },
};
