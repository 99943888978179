<template>
  <b-modal
    v-bind="$attrs"
    ref="modal"
    dialog-class="expansive-modal"
    scrollable
    :hide-footer="false"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
    :no-enforce-focus="true"
  >
    <template v-slot:modal-header>
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="w-25">
        </div>

        <div class="flex-fill text-center text-bold">
          <slot name="title">
            <span :key="$t('ux.barcodeScanner.title')">
              {{ $t('ux.barcodeScanner.title') }}
            </span>
          </slot>
        </div>

        <div class="w-25 text-right">
          <b-button variant="none" @click="hide">
            <font-awesome-icon :icon="['far', 'times']"></font-awesome-icon>
          </b-button>
        </div>
      </div>
    </template>

    <div class="p-4">
      <b-alert v-if="error" variant="danger" show>
        <div class="font-weight-bold mb-2">{{ $t('ux.barcodeScanner.error') }}</div>
        <div class="mb-1">{{ $t('ux.barcodeScanner.errorHelp') }}</div>
        <ul class="mb-0">
          <li :key="$t('ux.barcodeScanner.errorHelpItems.camera')">
            {{ $t('ux.barcodeScanner.errorHelpItems.camera') }}
          </li>
          <li :key="$t('ux.barcodeScanner.errorHelpItems.permission')">
            {{ $t('ux.barcodeScanner.errorHelpItems.permission') }}
          </li>
          <li :key="$t('ux.barcodeScanner.errorHelpItems.inUse')">
            {{ $t('ux.barcodeScanner.errorHelpItems.inUse') }}
          </li>
        </ul>
      </b-alert>

      <div v-else-if="showCamera">
        <b-alert variant="info" class="text-center" show>
          {{ $t('ux.barcodeScanner.instructions') }}
        </b-alert>

        <div v-show="!cameraReady">
          <PrimeSkeleton height="10rem" />
        </div>

        <div v-show="cameraReady">
          <qrcode-stream
            :key="scannerKey"
            :track="paintBoundingBox"
            :formats="formats"
            :paused="paused"
            @detect="onDetect"
            @error="onError"
            @camera-on="onCameraOn"
          />
        </div>
      </div>

      <div v-else>
        <div class="mb-1">{{ $t('ux.barcodeScanner.barcodeFound') }}</div>
        <div class="d-flex align-items-stretch sidebar-field">
          <div class="flex-grow-1 align-items-center p-2 barcode-value">
            {{ barcode }}
          </div>
          <div class="d-flex barcode-button">
            <b-button variant="primary" class="barcode-button" @click="reset">
              <font-awesome-icon :icon="['far', 'barcode-read']" class="mx-2" />
            </b-button>
          </div>
        </div>
      </div>

    </div>

    <template v-if="requireConfirmation" v-slot:modal-footer>
      <div class="container-fluid">
        <div class="row">
          <div class="col">
            <b-button
              block
              variant="outline-secondary"
              @click="cancel"
            >
              {{ $t('ux.barcodeScanner.cancel') }}
            </b-button>
          </div>
          <div v-show="!error" class="col-6">
            <b-button
              :disabled="!barcode"
              variant="primary"
              block
              @click="confirm"
            >
              {{ $t('ux.barcodeScanner.use') }}
            </b-button>
          </div>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader';
import {BAlert, BButton, BModal} from "bootstrap-vue";
import PrimeSkeleton from "primevue/skeleton/Skeleton.vue";

export default {
  name: 'BarcodeScanner',

  components: {
    BButton,
    BAlert,
    BModal,
    QrcodeStream,
    PrimeSkeleton
  },

  props: {
    requireConfirmation: {
      type: Boolean,
      default: false
    },
  },

  emits: [
    'detected',
    'confirmed',
  ],

  data() {
    return {
      loading: false,
      error: [],
      paused: false,
      cameraReady: false,
      showCamera: true,
      barcode: null,
      scannerKey: 0,
      formats: [
        "aztec",
        "code_128",
        "code_39",
        "code_93",
        "codabar",
        "data_matrix",
        "ean_13",
        "ean_8",
        "itf",
        "pdf417",
        "qr_code",
        "upc_a",
        "upc_e",
        "unknown",
      ],
    };
  },

  methods: {
    reset() {
      this.barcode = null;
      this.error = null;
      this.paused = false;
      this.cameraReady = false;
      this.showCamera = true;
      this.scannerKey += 1;
    },

    show() {
      this.reset();
      this.$refs.modal.show();
    },

    hide() {
      this.$refs.modal.hide();
    },

    cancel() {
      this.hide();
    },

    confirm() {
      this.$emit('confirmed', this.barcode);
      this.hide();
    },

    paintBoundingBox(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const {
          boundingBox: { x, y, width, height }
        } = detectedCode;

        ctx.lineWidth = 2;
        ctx.strokeStyle = 'blue';
        ctx.strokeRect(x, y, width, height);
      }
    },

    async onDetect(detectedCodes) {
      this.barcode = detectedCodes[0].rawValue;
      this.paused = true;
      setTimeout(() => this.showCamera = false, 1000);

      this.$emit('detected', this.barcode);

      if (!this.requireConfirmation) {
        this.confirm();
      }
    },

    onCameraOn() {
      this.cameraReady = true;
    },

    onError() {
      this.error = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.barcode-value {
  border: 1px solid #ced4da;
  background-color: #e9ecef;
  border-radius: .25rem 0 0 .25rem;
  word-break: break-all;
}
.barcode-button {
  cursor: pointer;
  border-radius: 0 0.25rem 0.25rem 0;
}
</style>
