export default {
  sites: [],
  sitesStatus: null,

  filter: {},
  sort: 'name',
  sortDirection: 'ASC',

  activeSite: null,
  activeSiteState: null,
  activeSiteErrors: [],
  activeSiteValid: false,
  activeSiteModifiedFields: {},

  enabled: [
    { id: true, name: 'Yes' },
    { id: false, name: 'No' },
  ],

  test: [
    { id: 1, name: 'Yes' },
    { id: 0, name: 'No' },
  ],

  enabledFilter: [
    { id: null, name: 'All' },
    { id: true, name: 'Yes' },
    { id: false, name: 'No' },
  ],
};
