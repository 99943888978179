import Base from './Base.js';

export default function ApiStatusCreator() {
  class API extends Base {}

  API.initEnum({
    SUCCESS: {
      value: 'success',
      display: 'Loaded Successfully',
      busy: false,
    },
    FAILURE: {
      value: 'failure',
      display: 'Load Failed',
      busy: false,
    },
    LOADING: {
      value: 'loading',
      display: 'Loading',
      busy: true,
    },
    SAVING: {
      value: 'saving',
      display: 'Saving',
      busy: true,
    },
    DELETING: {
      value: 'deleting',
      display: 'Deleting',
      busy: true,
    },
  });

  return API;
}

