/**
 * Finds the node in the items tree matching the id.
 * Returns the found node or null if not found.
 * @param {object} node The node to check
 * @param  {Number} id The id to search
 * @param {object[]} path An array of nodes forming the path to the found 1
 * @param {object} opts Configuration options
 * @return {object} The found node
 */
function findNodeById(node, id, path, opts) {
  let result = null;
  const children =
    typeof opts.childrenField === 'function' ? opts.childrenField(node) : node[opts.childrenField];
  const isMatch =
    typeof opts.valueField === 'function'
      ? opts.valueField(node, id)
      : node[opts.valueField] === id;

  if (isMatch) {
    result = node;
  } else if (children != null) {
    for (let i = 0; result == null && i < children.length; i += 1) {
      result = findNodeById(children[i], id, path, opts);

      // push the parent of the found node on the path array (exclude root)
      if (result && !node.isRoot) {
        path.push(node);
      }
    }
  }

  return result;
}

/**
 * Finds the node in the items tree matching the id.
 * Returns an array of nodes forming the path to the desired one. The
 * desired one is last in the array
 * @param  {Number} id The id to search
 * @param  {Object} rootNode The node to start the search on
 * @param  {Object} opts Options for the search: `valueField` (id), `childrenField` (children)
 * @return {object[]}    An array of nodes
 */
export default function findNodePath(
  id,
  rootNode,
  opts = { valueField: 'id', childrenField: 'children' },
) {
  // path gets passed in and collects the nodes on the way to finding the right one
  const path = [];
  const node = findNodeById(rootNode, id, path, opts);

  // path nodes are in reverse order so we flip them
  path.reverse();

  // if we found a node we create an array of nodes leading to the found one
  // otherwise we return null.
  return node ? [...path, node] : null;
}
