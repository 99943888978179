import Vue from 'vue';
import types from '../../types/workOrder.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_WORK_ORDERS_FILTER
   * @summary Updates the `filter` property which holds the collection of filters currently active
   * on the WorkOrders collection
   * @method
   * @param {Object} state Current state
   * @param {Object} filter The filters to be applied
   */
  [m.SET_WORK_ORDERS_FILTER](state, filter) {
    state.filter = filter;
  },

  /**
   * @name m SET_WORK_ORDERS_SORT
   * @summary Updates the `sort` and `direction` properties
   * @method
   * @param {Object} state Current state
   * @param {Object} data The sort data (`sort` and `direction`)
   */
  [m.SET_WORK_ORDERS_SORT](state, { sort, direction }) {
    state.sort = sort;
    state.sortDirection = direction;
  },

  /**
   * @name m SET_ACTIVE_WORK_ORDER
   * @summary Updates the `activeWorkOrder` and resets the
   * `activeWorkOrderModifiedFields` & `activeWorkOrderErrors` properties
   * @method
   * @param {Object} state Current state
   * @param {WorkOrder} workOrder The new Active {@link WorkOrder}
   */
  [m.SET_ACTIVE_WORK_ORDER](state, workOrder) {
    state.activeWorkOrder = workOrder;
    state.activeWorkOrderModifiedFields = !workOrder || workOrder.id ? {} : { ...workOrder };
    state.activeWorkOrderErrors = [];
  },

  /**
   * @name m SET_ACTIVE_WORK_ORDER_STATE
   * @summary Updates the `activeWorkOrderState` property which holds the current status of the
   * based on the API enum
   * @method
   * @param {Object} state Current state
   * @param {enums/API} activeWorkOrderState The current activeWorkOrderState
   * (e.g. loading, success, failure)
   */
  [m.SET_ACTIVE_WORK_ORDER_STATE](state, activeWorkOrderState) {
    state.activeWorkOrderState = activeWorkOrderState;
  },

  /**
   * @name m SET_ACTIVE_WORK_ORDER_ERRORS
   * @summary Updates the `activeWorkOrderErrors` property which holds any errors
   * related to activeWorkOrder
   * @method
   * @param {Object} state Current state
   * @param {string[]} errors An array of error messages
   */
  [m.SET_ACTIVE_WORK_ORDER_ERRORS](state, errors) {
    state.activeWorkOrderErrors = errors;
  },

  /**
   * @name m SET_ACTIVE_WORK_ORDER_VALID
   * @summary Updates the `activeWorkOrderValid` property which holds a boolean
   * for the validation status of the `activeWorkOrder`.
   * @method
   * @param {Object} state Current state
   * @param {boolean} valid True if the `activeWorkOrder` is valid.
   */
  [m.SET_ACTIVE_WORK_ORDER_VALID](state, valid) {
    state.activeWorkOrderValid = valid;
  },

  [m.UPDATE_ACTIVE_WORK_ORDER_MODIFIED_FIELDS](state, { field, value }) {
    Vue.set(state.activeWorkOrderModifiedFields, field, value);
  },

  [m.CLEAR_ACTIVE_WORK_ORDER_MODIFIED_FIELDS](state) {
    state.activeWorkOrderModifiedFields = {};
  },

  /**
   * @name m SET_WORK_ORDER_TYPES
   * @summary Stores the loaded Work Order Types array.
   * @method
   * @param {Object} state Current state
   */
  [m.SET_WORK_ORDER_TYPES](state, workOrderTypes) {
    state.workOrderTypes = workOrderTypes || [];
  },

  /**
   * @name m SET_WORK_ORDER_REQUEST_TYPES
   * @summary Stores the loaded Work Order Request Types array.
   * @method
   * @param {Object} state Current state
   */
  [m.SET_WORK_ORDER_REQUEST_TYPES](state, workOrderRequestTypes) {
    state.workOrderRequestTypes = workOrderRequestTypes || [];
  },

  /**
   * @name m SET_WORK_ORDER_STATES
   * @summary Stores the loaded Work Order States array.
   * @method
   * @param {Object} state Current state
   */
  [m.SET_WORK_ORDER_STATES](state, workOrderStates) {
    state.workOrderStates = workOrderStates || [];
  },
};
