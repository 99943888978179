import axios from 'axios';
import createAPIParams from '@/util/createAPIParams.js';

/**
 * Interface to the User API endpoints
 * @class
 * @exports src/services/UserAPI
 */
class UserAPI {
  /**
   * Retrieves the specified User's details
   * @method
   * @static
   * @param  {number}  userId    The user id to load
   * @return {Promise}          Promise that resolves to the {@link User} details.
   */
  static async getUser(userId) {
    const response = await axios({
      method: 'GET',
      url: `user/${userId}`,
      responseType: 'json',
    });

    return response.data.data;
  }

  /**
   * Retrieves the current User's details
   * @method
   * @static
   * @return {Promise}          Promise that resolves to the {@link User} details.
   */
  static async getMe() {
    const response = await axios({
      method: 'GET',
      url: 'user/me',
      responseType: 'json',
    });

    return response.data.data;
  }

  /**
   * Retrieves all Users for the given page
   * @param {Number} page The page to load
   * @param {String} sort The field to sort on
   * @param {String} direction The sort direction. Either 'ASC' or 'DESC'. Defaults to ASC.
   * @param {Object} filter An object with key value pairs of filter values.
   * @return {Promise} Promise that resolves to the api response.
   * In format: { users: {object[]}, meta: {object}, links: {object} }
   */
  static async getUsers(page, sort, direction, filter) {
    const response = await axios({
      method: 'GET',
      url: 'user',
      responseType: 'json',
      params: createAPIParams(page, sort, direction, filter),
    });

    return response.data;
  }

  /**
   * @summary Hits the create User endpoint.
   * @desc Sends the given user data to the create endpoint
   * @param  {User}  user A {@link User} object to be saved
   * @return {Promise}      Resolves to the returned full User object
   */
  static async createUser(user) {
    const response = await axios({
      method: 'POST',
      url: 'user',
      responseType: 'json',
      data: user,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the PATCH User endpoint.
   * @desc Sends the specified _modified_ fields to the endpoint to be saved.
   * @param  {String|Number}  userId The User Id to save
   * @param  {object}  modifiedFields An object with a subset of the
   * {@link User} fields that have been modified and should be saved.
   * @return {Promise}      Resolves to the returned full User object
   */
  static async updateUser(userId, modifiedFields) {
    const response = await axios({
      method: 'PATCH',
      url: `user/${userId}`,
      responseType: 'json',
      data: modifiedFields,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the INVITE User endpoint.
   * @param  {Array}  userIds The User IDs to invite
   * @return {Promise}
   */
  static async inviteUsers(userIds) {
    await axios({
      method: 'POST',
      url: 'user/invite',
      responseType: 'json',
      data: { user_ids: userIds },
    });

    return true;
  }

  /**
   * @summary Hits the DELETE User endpoint.
   * @param  {String|Number}  userId The User Id to delete
   * @return {Promise}
   */
  static async deleteUser(userId) {
    await axios({
      method: 'DELETE',
      url: `user/${userId}`,
      responseType: 'json',
    });

    return true;
  }
}

export default UserAPI;
