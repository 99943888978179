export default {
  getters: {},

  actions: {
    GET_SERVICE_PRIORITIES: 'GET_SERVICE_PRIORITIES',
    UPDATE_SERVICE_PRIORITY: 'UPDATE_SERVICE_PRIORITY',
    CREATE_SERVICE_PRIORITY: 'CREATE_SERVICE_PRIORITY',
    DELETE_SERVICE_PRIORITY: 'DELETE_SERVICE_PRIORITY',
  },

  mutations: {
    SET_SERVICE_PRIORITIES: 'SET_SERVICE_PRIORITIES',
    SET_SERVICE_PRIORITIES_STATUS: 'SET_SERVICE_PRIORITIES_STATUS',
    SET_SERVICE_PRIORITIES_META: 'SET_SERVICE_PRIORITIES_META',
    SET_SERVICE_PRIORITIES_FILTER: 'SET_SERVICE_PRIORITIES_FILTER',

    REMOVE_LOCAL_SERVICE_PRIORITY: 'REMOVE_LOCAL_SERVICE_PRIORITY',
    ADD_LOCAL_SERVICE_PRIORITY: 'ADD_LOCAL_SERVICE_PRIORITY',
    UPDATE_LOCAL_SERVICE_PRIORITY: 'UPDATE_LOCAL_SERVICE_PRIORITY',

    SET_ACTIVE_SERVICE_PRIORITY: 'SET_ACTIVE_SERVICE_PRIORITY',
    SET_ACTIVE_SERVICE_PRIORITY_STATE: 'SET_ACTIVE_SERVICE_PRIORITY_STATE',
    SET_ACTIVE_SERVICE_PRIORITY_ERRORS: 'SET_ACTIVE_SERVICE_PRIORITY_ERRORS',
    SET_ACTIVE_SERVICE_PRIORITY_VALID: 'SET_ACTIVE_SERVICE_PRIORITY_VALID',
    UPDATE_ACTIVE_SERVICE_PRIORITY_MODIFIED_FIELDS:
      'UPDATE_ACTIVE_SERVICE_PRIORITY_MODIFIED_FIELDS',
    CLEAR_ACTIVE_SERVICE_PRIORITY_MODIFIED_FIELDS: 'CLEAR_ACTIVE_SERVICE_PRIORITY_MODIFIED_FIELDS',
  },
};
