import types from '../../types/viewEvents.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_SCROLL
   * @summary Updates the `scroll` property.
   * @method
   * @param {Object} scroll The scroll event data array
   */
  [m.SET_SCROLL](state, scroll) {
    state.scroll = scroll;
  },
};
