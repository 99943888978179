<template>
  <div class="expansive-tree" :class="{'shadowed': this.shadowed}" v-bind="$attrs">
    <div v-if="showButtons || showFilter" :class="{'px-3 pt-3': this.shadowed}">
      <div v-if="showButtons" class="d-flex flex-column">
        <div class="d-flex justify-content-between">
          <div>
            <b-button
                :disabled="tooManyNodes"
                variant="outline-secondary"
                size="sm"
                class="mr-2"
                @click="expandAll"
            >
              <span>{{ $t('common.forms.expandAll') }}</span>
            </b-button>
            <b-button
                :disabled="tooManyNodes"
                variant="outline-secondary"
                size="sm"
                @click="collapseAll"
            >
              <span>{{ $t('common.forms.collapseAll') }}</span>
            </b-button>
          </div>

          <div v-if="allowMultiple">
            <b-button :disabled="tooManyNodes" variant="outline-secondary" size="sm" class="mr-2" @click="clearSelection">
              Clear Selection
            </b-button>
            <slot name="selection-button-content" :too-many-nodes="tooManyNodes" />
          </div>
        </div>
      </div>

      <div :class="showFilter ? 'mt-3' : ''" style="display: flex; align-items: center;">
        <b-form-input ref="search" v-model="filter" :disabled="loading" placeholder="Search the tree" />
        <div
          v-if="showHeaderAction"
          class="icon action-icon fa-ellipsis-v ml-3 mr-1 bold"
          @click="(event) => $emit('header-action', event)">
        </div>
      </div>
    </div>

    <div ref="loading" class="position-relative p-3">
      <PrimeSkeleton height="10rem" />
      <div class="loading-message text-muted">{{ $t('common.searching') }}</div>
    </div>

    <ul v-show="!tooManyNodes" ref="tree" class="root"></ul>
    <div v-show="tooManyNodes && !loading">
      <div style="padding: 1rem; text-align: center;" class="text-muted">
        There are too many locations to display. <br />
        Please use the search bar above to find the location you are looking for.
      </div>
    </div>

    <div v-if="allowMultiple && !tooManyNodes" class="d-flex border-top">
      <div class="mt-2">
        <small class="text-muted">{{ selectionCount }} selected</small>
      </div>
    </div>
  </div>
</template>

<script>
import debounce from "lodash/debounce";
import "@fortawesome/fontawesome-pro/scss/light.scss";
import "@fortawesome/fontawesome-pro/scss/regular.scss";
import "@fortawesome/fontawesome-pro/scss/solid.scss";
import "@fortawesome/fontawesome-pro/css/all.min.css";
import {BButton, BFormInput} from "bootstrap-vue";
import {capitalize} from "lodash/string";
import {lowerCase, cloneDeep} from "lodash";
import PrimeSkeleton from "primevue/skeleton/Skeleton.vue";

export default {
  name: 'Tree',
  inject: ['mq'],
  components: {
    BButton,
    BFormInput,
    PrimeSkeleton
  },
  props: {
    noTranslateIf: {
      type: Function,
      default: (node) => false,
    },
    hiddenIf: {
      type: Function,
      default: (node) => false,
    },
    // array of objects with at least 'id', 'type'
    preSelected: {
      type: Array,
      default: () => [],
    },
    // array of objects with 'id', 'type' (see type below), 'label' (see labelField below)
    tree: {
      type: Array,
      default: () => [],
    },
    // specify the label field, e.g. 'name', 'title'
    labelField: {
      type: [String, Function],
      default: () => 'label',
    },
    // specify the type
    type: {
      type: Function,
      default: (node) => node.type,
    },
    // specify the icon name, e.g. 'house', 'building'
    icon: {
      type: Function,
      default: (node) => node.icon,
    },
    // provide an array of objects with 'type', 'icon'
    iconTypeMap: {
      type: Object,
      default: () => {},
    },
    headerActionIcon: {
      type: Boolean,
      default: false,
    },
    // provide an icon name to use for the action, e.g. 'edit', 'ellipsis'
    actionIcon: {
      type: String,
      default: null,
    },
    // provide an icon name to use for the action, e.g. 'edit', 'ellipsis'
    actionIf: {
      type: Function,
      default: (node) => true,
    },
    // expand the node as default if
    expandIf: {
      type: Function,
      default: (node) => false,
    },
    // make the node selectable if
    selectableIf: {
      type: Function,
      default: (node) => true,
    },
    // make the node selectable if
    stepThroughIf: {
      type: Function,
      default: (node) => false,
    },
    // make the node have disabled styling if (this doesn't affect being selectable)
    disabledStylingIf: {
      type: Function,
      default: (node) => false,
    },
    // single or multiple selection is supported, and icons adjust accordingly
    allowMultiple: {
      type: Boolean,
      default: true,
    },
    // expand all / collapse all buttons
    showButtons: {
      type: Boolean,
      default: true,
    },
    // show the filter / search bar
    showFilter: {
      type: Boolean,
      default: true,
    },
    // can be used to show a flag / badge next to the label to indicate the type
    // generally used without icons, but can be used together
    showTypeFlags: {
      type: Boolean,
      default: false,
    },
    // scroll to the selected item, only applied when allowMultiple = false
    scrollToSelected: {
      type: Boolean,
      default: true,
    },
    // removes any unselectable nodes from the tree, unless they have any selectable recursive children
    showSelectableNodesOnly: {
      type: Boolean,
      default: false,
    },
    // can be used to remove checkboxes to use the tree as a navigation panel
    showCheckboxes: {
      type: Boolean,
      default: true,
    },
    showHeaderAction: {
      type: Boolean,
      default: false,
    },
    shadowed: {
      type: Boolean,
      default: true
    }
  },
  emits: [
    'update',
    'node-select',
    'node-unselect',
    'node-action',
    'pre-node-expansion',
    'post-node-expansion'
  ],
  data() {
    return {
      loading: false,
      selected: [],
      filter: null,
      treeContainer: null,
      tooManyNodes: false,
      maxNodes: 3000,
    };
  },
  computed: {
    selectionCount() {
      return this.selected.length;
    },
    selectionMode() {
      return this.allowMultiple ? 'multiple' : 'single';
    },
  },
  watch: {
    tree: {
      handler() {
        this.rebuild();
      },
    },
    filter(value) {
      this.debouncedFilterWatch(value);
    },
  },
  created() {
    this.debouncedFilterWatch = debounce((value) => {
      this.onFilter(value);
    }, 500);
  },
  mounted() {
    this.treeContainer = this.$refs.tree;
    this.rebuild();

    this.$nextTick(() => {
      setTimeout(() => this.goScrollToSelected(), 700);
    });
  },
  methods: {
    async only(nodes) {
      this.setLoadingState();
      this.filter = '';

      setTimeout(async () => {
        this.tooManyNodes = nodes.length > this.maxNodes;

        if (this.tooManyNodes) {
          this.unsetLoadingState();
        }

        const containers = [...this.$refs.tree.querySelectorAll('li')]
          .map((container) => {
            return {
              id: container.dataset.id,
              type: container.dataset.type,
              node: container
            };
          });

        const hide = [];
        const show = [];
        const hideExpand = [];
        const showExpand = [];

        containers.forEach((container) => {
          const found = nodes.find((node) => node.id == container.id && node.type === container.type);

          if (found) {
            show.push(container);
            if (!found.children.length) {
              hideExpand.push(container);
            } else {
              if (container.node.querySelector('li:not(.filter-hidden)')) {
                showExpand.push(container);
              }
            }
          } else {
            hide.push(container);
          }
        });

        hide.forEach((container) => {
          setTimeout(() => {
            container.node.classList.add('externally-hidden');
          }, 0);
        });

        hideExpand.forEach((container) => {
          setTimeout(() => {
            container.node.classList.add('expand-hidden');
          }, 0);
        });

        show.forEach((container) => {
          setTimeout(() => {
            container.node.classList.remove('externally-hidden', 'filter-hidden');
          }, 0);
        });

        showExpand.forEach((container) => {
          setTimeout(() => {
            container.node.classList.remove('expand-hidden');
          }, 0);
        });

        setTimeout(() => {
          this.unsetLoadingState();
        }, 1);
      }, 1);
    },
    async all() {
      this.setLoadingState();
      this.filter = '';

      // count all the li elements
      const containers = [...this.$refs.tree.querySelectorAll('li')];

      this.tooManyNodes = containers.length > this.maxNodes;
      if (this.tooManyNodes) {
        this.unsetLoadingState();
      }

      this.$refs.tree.querySelectorAll('li.externally-hidden').forEach((nodeElement) => {
        nodeElement.className = nodeElement.className
          .replace('externally-hidden', '')
          .replace('expand-hidden', '')
          .replace('filter-hidden', '');
      });
      setTimeout(() => {
        this.unsetLoadingState();
      }, 1);
    },
    clearSelection() {
      const toIgnore = [...this.$refs.tree.querySelectorAll('li.filter-hidden, li.externally-hidden, li.path-only-node')]
        .map((container) => {
          return {
            id: container.dataset.id,
            type: container.dataset.type,
          };
        });

      const selectedContainers = [...this.$refs.tree.querySelectorAll('li.selected')]
        .map((container) => {
          return {
            id: container.dataset.id,
            type: container.dataset.type,
            node: container,
          };
        });

      this.selected = selectedContainers.filter((selected) => {
        return toIgnore.find((container) => {
          return container.id === selected.id
            && container.type === selected.type;
        });
      });

      this.$emit('update', this.selected);
      this.$emit('clear-selection');

      // remove toIgnore from selectedContainers
      const toDeselect = selectedContainers.filter((selected) => {
        return !toIgnore.find((container) => {
          return container.id === selected.id
            && container.type === selected.type;
        });
      });

      toDeselect.forEach((container) => {
        setTimeout(() => {
          // remove 'selectable' class from the containers className
          container.node.classList.remove('selected');
        }, 0);
      });
    },
    selectAll() {
      const containers = [
        ...this.$refs.tree.querySelectorAll(
          `li:not(.filter-hidden):not(.path-only-node):not(.externally-hidden)`
        )
      ];

      // we need to retain any selections previously

      this.selected = [
        ...this.selected,
        ...containers
          .map((container) => {
            return {
              id: container.dataset.id,
              type: container.dataset.type,
            };
          })
          .filter((selected) => {
            return !this.selected.find((selection) => {
              return selection.id == selected.id && selection.type === selected.type;
            });
          })
      ];

      this.$emit('update', this.selected);

      containers.forEach((container) => {
        setTimeout(() => {
          container.className += ' selected expanded';
        }, 0);
      });
    },
    selectAllOfType(type) {
      const containers = [
        ...this.$refs.tree.querySelectorAll(`li[data-type='${type}'].selectable`)
      ].map((container) => {
          return {
            id: container.dataset.id,
            type: container.dataset.type,
            node: container,
          };
        });

      const toIgnore = [
        ...this.$refs.tree.querySelectorAll('li.filter-hidden, li.externally-hidden, li.path-only-node, li.selected')
      ].map((container) => {
          return {
            id: container.dataset.id,
            type: container.dataset.type,
          };
        });

      // toSelect = containers - toIgnore
      const toSelect = containers.filter((container) => {
        return !toIgnore.find((ignore) => {
          return ignore.id === container.id && ignore.type === container.type;
        });
      });

      this.selected = [
        ...this.selected,
        ...toSelect.map((container) => {
          return {
            id: container.id,
            type: container.type,
          };
        })
      ];

      this.$emit('update', this.selected);

      toSelect.forEach((container) => {
        setTimeout(() => {
          container.node.className += ' selected';
          this._deepExpand(container.node);
        }, 0);
      });
    },
    getSteppedOnIds() {
      const sets = [];
      this.$refs.tree.querySelectorAll('.stepped-into').forEach((node) => {
        sets.push(node.dataset);
      });

      return sets;
    },
    setSteppedOnIds(steppedOnIds) {
      steppedOnIds.forEach((steppedOnId) => {
        const nodeContainer = this._getNodeContainerByIdType(steppedOnId.id, steppedOnId.type);
        if (nodeContainer) {
          if (!nodeContainer.classList.contains('stepped-into')) {
            nodeContainer.classList.add('stepped-into');
          }
        }
      });
    },
    getExpandedIds() {
      const sets = [];
      this.$refs.tree.querySelectorAll('.expanded').forEach((node) => {
        sets.push(node.dataset);
      });

      return sets;
    },
    setExpandedIds(expandedIds) {
      expandedIds.forEach((expandedId) => {
        const nodeContainer = this._getNodeContainerByIdType(expandedId.id, expandedId.type);
        if (nodeContainer) {
          if (!nodeContainer.classList.contains('expanded')) {
            nodeContainer.classList.add('expanded');
          }
        }
      });
    },
    setLoadingState() {
      this.loading = true;
      this.treeContainer.classList.add('d-none');
      this.$refs.loading.classList.remove('d-none');
    },
    unsetLoadingState() {
      this.loading = false;
      this.$refs.loading.classList.add('d-none');
      this.treeContainer.classList.remove('d-none');
    },
    async rebuild() {
      this.setLoadingState();

      this.treeContainer.classList.add(this.selectionMode);
      this.$refs.tree.innerHTML = '';

      let nodes = cloneDeep(this.tree);

      if (this.showSelectableNodesOnly) {
        // only keep nodes that are selectable or have selectable children of any depth
        // need a recursive function
        const _filterSelectable = function(nodes) {
          return nodes.filter((node) => {
            if (node.children?.length) {
              if (_filterSelectable(node.children).length) {
                return true;
              }

              node.children = [];
            }

            if (this.selectableIf(node)) {
              return true;
            }

            return this.preSelected.find((selected) => {
              return selected.id === String(node.id) && selected.type === this.type(node);
            });
          });
        }.bind(this);

        nodes = _filterSelectable(nodes);
      }

      // figure out how many nodes there are from the recursive nodes tree
      const _countNodes = function(nodes) {
        let count = 0;

        for (let node of nodes) {
          count++;

          if (node.children?.length) {
            count += _countNodes(node.children);
          }
        }

        return count;
      };

      let count = _countNodes(nodes);

      if (count > this.maxNodes) {
        this.tooManyNodes = true;
        this.unsetLoadingState();
      }

      setTimeout(() => {
        this._createNodeElements(this.treeContainer, nodes, [], 0);

        setTimeout(() => {
          this.unsetLoadingState();
        }, 0);
      }, 0);
    },
    // builds up the following markup for a single node of the tree
    //
    // <li class="expandable? expanded? selectable? selected? hidden? filter-hidden? disabled?" data-id="{{ id }}" data-type="{{ type }}" data-label="{{ label }}">
    //   <div class="node" data-id="{{ id }}">
    //     <div className="icon expanded-icon filter-hidden?" data-id="{{ id }}"></div>
    //     <div className="label-container" data-id="{{ id }}">
    //       ?<div className="icon label-icon" data-id="{{ id }}"></div>
    //       ?<div className="label-flag" data-id="{{ id }}"></div>
    //       <div class="label">{{label}}</div>
    //       ?<div className="icon select-icon" data-id="{{ id }}"></div>
    //       ?<div className="icon action-icon" data-id="{{ id }}"></div>
    //     </div>
    //   </div>
    //   ?<ul>{{ childNodes }}</ul>
    // </li>

    _createNodeElements(container, nodes, parentContainers) {
      for (let node of nodes) {
        if (!node?.id) {
          continue;
        }
        setTimeout(() => {
          // create the li
          const nodeContainer = document.createElement('li');
          nodeContainer.dataset.id = node.id;
          nodeContainer.dataset.label = node[this.labelField];
          nodeContainer.dataset.type = this.type(node);
          container.appendChild(nodeContainer);

          // create the node to handle flex
          const nodeElement = document.createElement('div');
          nodeElement.dataset.id = node.id;
          nodeElement.classList.add('node');
          nodeContainer.appendChild(nodeElement);

          this._createLabel(nodeElement, nodeContainer, node);
          this._createExpandIcon(nodeElement, nodeContainer, node);

          // disabled is just some styling, doesn't affect selectable
          if (this.disabledStylingIf(node)) {
            nodeContainer.classList.add('disabled');
          }

          // if the node is selected
          if (this.preSelected.find((selected) => String(selected.id) === String(node.id) && selected.type === this.type(node))) {
            nodeContainer.classList.add('selected');

            this.selected.push({
              id: node.id,
              type: this.type(node),
            });

            parentContainers.forEach((parentContainer) => {
              parentContainer.classList.add('expanded');
            });
          }

          if (node.children?.length) {
            // add the child container
            const childContainer = document.createElement('ul');
            nodeContainer.appendChild(childContainer);

            // add the child nodes to the child container
            this._createNodeElements(childContainer, node.children, [nodeContainer, ...parentContainers])

            // see if it needs to be expanded as default
            if (this.expandIf(node)) {
              this._deepExpand(nodeContainer);
            }
          }
        }, 0);
      }
    },
    _createLabel(nodeElement, nodeContainer, node) {
      const labelContainer = document.createElement('div');
      labelContainer.classList.add('label-container');
      labelContainer.dataset.id = node.id;

      // add the type flag
      if (this.showTypeFlags) {
        this._createTypeFlag(labelContainer, node);
      }

      // add the icon
      if (this.icon(node) || this.iconTypeMap) {
        this._createLabelIcon(labelContainer, node);
      }

      // add the label text
      const label = document.createElement('span');

      label.dataset.id = node.id;
      label.dataset.type = node.type;

      label.classList.add('label');

      if (this.noTranslateIf(node)) {
        label.classList.add('notranslate');
      }

      label.dataset.id = node.id;
      label.setAttribute('key', `${node.id}_${this.type(node)}`)
      label.innerHTML = node[this.labelField];

      labelContainer.appendChild(label);

      // add the action item
      if (this.actionIcon && this.actionIf(node)) {
        this._createActionIcon(labelContainer, node);
      }

      // add the selectable class and icon
      if (
        this.selectableIf(node)
        || this.selected.find((selected) => selected.id === String(node.id) && selected.type === this.type(node))
      ) {
        nodeContainer.classList.add('selectable');

        if (this.showCheckboxes) {
          this._createSelectIcon(labelContainer, node);
        }

        // add the event listener to handle changing selected state
        labelContainer.addEventListener('click', (event) => {
          if (event.target.classList.contains('action-icon')) {
            return;
          }
          this._toggleSelected(node.id, this.type(node));
        });
      }

      else if (this.stepThroughIf(node)) {
        nodeContainer.classList.add('step-through');

        labelContainer.addEventListener('click', (event) => {
          this._stepInto(node.id, this.type(node));
        });
      }

      // add the label container to the node
      nodeElement.appendChild(labelContainer);
    },
    _createTypeFlag(labelContainer, node) {
      const flag = document.createElement('div');
      flag.dataset.id = node.id;
      flag.classList.add('label-flag');
      flag.innerHTML = capitalize(this.type(node));

      labelContainer.prepend(flag);
    },
    _createLabelIcon(labelContainer, node) {
      const icon = document.createElement('div');
      icon.dataset.id = node.id;
      icon.classList.add('icon', 'label-icon');

      let iconCode = null;

      if (this.iconTypeMap) {
        const mappedIcon = this.iconTypeMap.find((iconType) => iconType.type === this.type(node));
        if (mappedIcon) {
          iconCode = mappedIcon.icon;
        }
      }

      if (!iconCode && this.icon(node)) {
        iconCode = this.icon(node);
      }

      icon.classList.add(`fa-${iconCode}`)

      labelContainer.prepend(icon);
    },
    _createActionIcon(labelContainer, node) {
      const action = document.createElement('div');
      action.dataset.id = node.id;
      action.className = 'icon action-icon fa-' + this.actionIcon;

      action.addEventListener('click', (event) => {
        this.$emit('node-action', node, event);
      });

      labelContainer.appendChild(action);
    },
    _createSelectIcon(labelContainer, node) {
      const icon = document.createElement('div');
      icon.dataset.id = node.id;
      icon.classList.add('icon', 'select-icon');
      icon.classList.add(this.allowMultiple ? 'fa-square' : 'fa-circle');

      labelContainer.appendChild(icon);
    },
    _createExpandIcon(nodeElement, nodeContainer, node) {
      // add an expand trigger to every node, which is hidden as default
      const icon = document.createElement('div');
      icon.dataset.id = node.id;
      icon.dataset.type = this.type(node);
      icon.classList.add('icon', 'expand-icon', 'fa-chevron-right');

      const expandable = node.children?.length;

      // only add the expandable class if it has children, which displays the icon
      if (expandable) {
        nodeContainer.classList.add('expandable');

        // add an event listener to handle changing expanded status
        icon.addEventListener('click', (event) => {
          this._toggleExpanded(nodeContainer)
        });
      }

      nodeElement.prepend(icon);
    },
    // expand this node, and move up through the chain of parent nodes to expand them as well
    _deepExpand(nodeContainer) {
      do {
        if (!nodeContainer.classList.contains('expanded')) {
          nodeContainer.classList.add('expanded');
        }

        // get the parent node element to continue up the chain
        nodeContainer = this._getParentNodeContainer(nodeContainer);
      } while(nodeContainer !== null);
    },
    _stepInto(id, type) {
      const nodeContainer = this._getNodeContainerByIdType(id, type);

      this._clearSteppedInto();

      nodeContainer.classList.add('stepped-into');

      if (nodeContainer.classList.contains('expanded')) {
        return;
      }

      this._toggleExpanded(nodeContainer);
    },
    _toggleExpanded(nodeElement) {
      this.$emit('pre-node-expansion');
      nodeElement.classList.toggle('expanded');
      this.$emit('post-node-expansion');
    },
    _toggleSelected(id, type, quietly = false, nodeContainer) {
      this._clearSteppedInto();

      if (!nodeContainer) {
        nodeContainer = this._getNodeContainerByIdType(id, type);
      }

      // if we can't find the node then bail out
      if (!nodeContainer) {
        return;
      }

      if (this.selectionMode === 'single') {
        this.selected = [];
        this._removeAllSelected();
      }

      const nodeData = {
        id: nodeContainer.dataset.id,
        label: nodeContainer.dataset.label,
        type: nodeContainer.dataset.type,
      };

      // remove it if it is already selected
      if (nodeContainer.classList.contains('selected')) {
        nodeContainer.classList.remove('selected');

        this.selected = this.selected
          .filter((node) => {
            return !(node.id == nodeData.id && node.type === nodeData.type)
          });

        if (!quietly) {
          this.$emit('node-unselect', nodeData);
        }

        return this.$emit('update', this.selected);
      }

      setTimeout(() => {
        nodeContainer.className += ' selected';
      }, 0);

      this.selected.push(nodeData);

      if (!quietly) {
        this.$emit('node-select', nodeData);
      }

      this.$emit('update', this.selected);
    },
    _removeAllSelected(type = null) {
      const selector = type
        ? `li.selected[data-type='${type}']:not(.filter-hidden):not(.externally-hidden):not(.path-only-node)`
        : 'li.selected:not(.filter-hidden):not(.externally-hidden):not(.path-only-node)';

      this.$refs.tree.querySelectorAll(selector).forEach((nodeElement) => {
        // get the id and type from the node element
        const id = nodeElement.dataset.id;
        const type = nodeElement.dataset.type;

        this.selected = this.selected.filter((node) =>
          !(node.id === String(id) && node.type === type)
        );

        nodeElement.classList.remove('selected');
      });
    },
    _clearSteppedInto() {
      this.$refs.tree.querySelectorAll('li.stepped-into').forEach((nodeElement) => {
        nodeElement.classList.remove('stepped-into');
      });
    },
    _getNodeContainerByIdType(id, type) {
      return this.treeContainer.querySelector(`li[data-id='${id}'][data-type='${type}']`);
    },
    _getParentNodeContainer(nodeContainer) {
      const containerElement = nodeContainer.closest('ul');
      return containerElement.closest('li');
    },
    async setSelected() {
      if (!this.preSelected.length) {
        return;
      }

      const preSelected = this.selectionMode === 'single'
        ? [this.preSelected[0]]
        : this.preSelected;

      preSelected.forEach((node) => {
        this._toggleSelected(node.id, node.type, true);

        const nodeContainer = this._getNodeContainerByIdType(node.id, node.type);

        if (nodeContainer) {
          const parentNodeContainer = this._getParentNodeContainer(nodeContainer);

          // the selected node might be the root level, so won't have a parent node
          if (parentNodeContainer) {
            this._deepExpand(parentNodeContainer);
          }
        }
      });
    },
    expandAll() {
      this.treeContainer.querySelectorAll('li.expandable:not(.expanded)').forEach((nodeContainer) => {
        setTimeout(() => {
          nodeContainer.classList.add('expanded');
        }, 0);
      });
    },
    collapseAll() {
      this.treeContainer.querySelectorAll('li.expandable.expanded').forEach((nodeContainer) => {
        setTimeout(() => {
          nodeContainer.className = nodeContainer.className.replace('expanded', '');
        })
      });
    },
    goScrollToSelected() {
      if (this.scrollToSelected && this.selectionMode === 'single') {
        const selectedNode =  this.treeContainer.querySelector('li.selected');

        if (selectedNode) {
          selectedNode.scrollIntoView({
            behavior: 'smooth',
            block: 'nearest'
          });
        }
      }
    },
    async onFilter(value) {
      this.setLoadingState();
      // having to force a timeout so that the loading skeleton is displayed
      setTimeout(async () => {
        await this.doFilter(value);

        // refocus the filter node
        setTimeout(async () => {
          this.$refs.search.$el?.focus();
        }, 1);
      }, 1);
    },
    async doFilter(value) {
      // if there's no filter value, remove any hidden classes across the tree
      if (value === '') {
        this.$refs.tree.querySelectorAll('.filter-hidden, .path-only-node, .expand-hidden').forEach((li) => {
          li.classList.remove('filter-hidden');

          // if the li has ANY children that are not externally hidden:
          if (li.querySelector('li:not(.externally-hidden)')) {
            li.classList.remove('expand-hidden');
          }

          li.classList.remove('path-only-node');
        });

        // see how many nodes there are now which are not externally hidden
        const visibleNodes = [...this.$refs.tree.querySelectorAll('li:not(.externally-hidden)')];
        this.tooManyNodes = visibleNodes.length > this.maxNodes;

        this.unsetLoadingState();
        return;
      }

      const flatTree = [];
      const _flatten = function(nodes, parent = null) {
        for (let node of nodes) {
          const nodeData = {
            id: String(node.id),
            type: node.type,
            name: node.name,
            parent: parent,
          };

          flatTree.push(nodeData);

          if (node.children?.length) {
            _flatten(node.children, nodeData);
          }
        }
      }.bind(this);

      _flatten(this.tree);

      const matched = [...this.$refs.tree.querySelectorAll('li:not(.externally-hidden) > .node .label')]
        .filter((label) => {
          return lowerCase(label.textContent).includes(lowerCase(value));
        })
        .map((label) => {
          return {
            id: label.dataset.id,
            type: label.dataset.type,
            label: label.textContent,
          };
        });

      const _addParents = function(nodes) {
        for (let node of nodes) {
          const parent = flatTree.find((flatNode) => flatNode.id === node.id && flatNode.type === node.type).parent;

          if (parent) {
            const parentData = {
              id: parent.id,
              type: parent.type,
              label: parent.name,
              pathOnly: true,
              hasChildren: true,
            };

            if (!matched.find((match) => match.id === parentData.id && match.type === parentData.type)) {
              matched.push(parentData);
            }

            _addParents([parentData]);
          }
        }
      };

      _addParents(matched);

      this.tooManyNodes = matched.length > this.maxNodes;

      if (this.tooManyNodes) {
        this.unsetLoadingState();
        return;
      }

      const containers = [...this.$refs.tree.querySelectorAll('li')];

      let i = 0;
      containers.forEach((li) => {
        setTimeout(() => {
          i++;

          const id = li.dataset.id;
          const type = li.dataset.type;
          const found = matched.find((node) => node.id === id && node.type === type);

          if (found) {
            li.classList.remove('filter-hidden');

            // if its a patch only node give it that class and expand it
            if (found.pathOnly) {
              li.classList.add('path-only-node');
              li.classList.add('expanded');
            }

            found.hasChildren
              ? li.classList.remove('expand-hidden')
              : li.classList.add('expand-hidden');
          } else {
            // hide the node
            li.classList.add('filter-hidden');
          }

          if (i === containers.length) {
            this.unsetLoadingState();
          }
        }, 0);
      });
    },
  }
}
</script>

<style lang="scss">
div.expansive-tree.shadowed {
  box-shadow: 0 0 15px -3px rgba(0, 0, 0, 0.1);
}

div.expansive-tree {
  background-color: #FFF;

  .header {
    padding: 1rem 1rem 0 1rem;
  }

  .loading-message {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .icon::before {
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    font-family: "Font Awesome 5 Pro", sans-serif;
    font-weight: 400;
    color: #6c757d;
  }

  .icon.bold::before {
    font-weight: 900;
    color: black;
    cursor: pointer;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;

    &.root {
      padding: 1rem;
    }
  }

  li {
    &.hidden {
      display: none;
    }

    &.externally-hidden {
      display: none;
    }

    &.filter-hidden {
      display: none;
    }

    &.path-only-node {
      > .node > .label-container {
        color: grey;
        pointer-events: none;

        > .select-icon::before {
          color: #9ec8f1 !important;
        }
      }
    }

    &.path-only-node:not(.selected) {
      > .node > .label-container > .select-icon {
        display: none;
      }
    }

    ul {
      padding-left: 1rem;
    }

    &:not(.expanded) {
      ul {
        display: none;

        > li {
          display: none;
        }
      }
    }

    // EXPAND ICON
    .expand-icon {
      display: inline-block;
      margin-right: 0.75rem;
      cursor: pointer;

      &:before {
        transform: rotate(0);
        transition: 0.2s transform;
      }

      &.filter-hidden,
      &.hidden {
        visibility: hidden;
      }
    }

    &.expand-hidden {
      > .node > .expand-icon {
        visibility: hidden;
      }
    }

    &:not(.expandable) {
      > .node > .expand-icon {
        visibility: hidden;
      }
    }

    &.expanded {
      > .node > .expand-icon::before {
        transform: rotate(90deg);
        transition: 0.2s transform;
      }
    }

    // NODE
    .node {
      padding: 0.3rem;
      display: flex;
      align-items: center;
    }

    // LABEL
    .label-container {
      display: flex;
      align-items: center;
      cursor: auto;
      color: #000;
      flex: 1;
      width: 100%;

      .label {
        flex: 1;
      }

      .label-icon {
        float: left;
        margin-right: 0.5rem;

        &:before {
          font-weight: 300;
        }
      }

      .label-flag {
        float: left;
        margin-right: 0.5rem;
        padding: .05rem .3rem;
        border-radius: 0.25rem;
        background-color: #e9ecef;
        color: #000;
        font-size: 75%;
      }
    }

    &.disabled {
      .label-container {
        color: #999;
      }
    }

    &:not(.selectable):not(.step-through) {
      > .node > .label-container {
        color: #999;
      }
    }

    &.selectable,
    &.step-through {
      > .node > .label-container {
        cursor: pointer;

        > .label-icon::before {
          font-weight: 400;
        }
      }
    }

    &.stepped-into {
      > .node > .label-container {
        font-weight: 600;

        .label-icon::before {
          font-weight: 900 !important;
        }
      }
    }

    &.selected {
      > .node > .label-container {
        color: $primary;
        font-weight: 600;

        .label-icon::before {
          color: $primary;
          font-weight: 900 !important;
        }

        .label-flag {
          background-color: $primary;
          color: #FFF;
        }
      }
    }

    // ACTION ICON
    .action-icon {
      visibility: visible;
      float: right;
      margin-left: 0.5rem;
      position: relative;
      cursor: pointer;

      &:before {
        font-weight: 900;
        color: inherit;
      }
    }

    &:not(.selected):not(.stepped-into) {
      > .node > .label-container > .action-icon {
        display: none;
      }
    }

    // SELECT ICON
    .select-icon {
      visibility: visible;
      float: right;
      margin-left: 0.5rem;

      &:before {
        color: #6c757d;
        font-weight: 300;
      }
    }

    &.selected {
      > .node > .label-container > .select-icon {
        visibility: visible;

        &:before {
          content: "\f14a"; // check-square
          color: $primary;
          font-weight: 900;
        }
      }
    }
  }

  ul.single {
    li.selected {
      > .node > .label-container > .select-icon::before {
        content: "\f058";
      }
    }
  }
}
</style>

