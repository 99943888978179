import axios from 'axios';
import createAPIParams from '@/util/createAPIParams.js';
import createFormData from '@/util/createFormData.js';
import FileExportAPI from '@/services/FileExportAPI.js';

/**
 * Interface to the Document API endpoints
 * @class
 * @exports src/services/DocumentAPI
 */
class DocumentAPI {
  /**
   * Retrieves all the Documents
   * @method
   * @static
   * @return {Promise} Promise that resolves to an array of {@link Documents} items.
   */
  static async getDocuments(page, sort, direction, filter) {
    const response = await axios({
      method: 'GET',
      url: 'document',
      responseType: 'json',
      params: createAPIParams(page, sort, direction, filter),
    });

    return response.data;
  }

  /**
   * @summary Hits the create Document endpoint.
   * @desc Sends the given document data to the create endpoint
   * @param  {Document}  document A {@link Document} object to be saved
   * @return {Promise}      Resolves to the returned full Document object
   */
  static async createDocument(document) {
    const response = await axios({
      method: 'POST',
      url: 'document',
      responseType: 'json',
      headers: {
        'content-type': 'multipart/form-data',
      },
      data: createFormData(document),
    });

    return response.data.data;
  }

  /**
   * @summary Hits the PATCH Document endpoint.
   * @desc Sends the specified _modified_ fields to the endpoint to be saved.
   * @param  {String|Number}  documentId The Document Id to save
   * @param  {object}  modifiedFields An object with a subset of the
   * {@link Document} fields that have been modified and should be saved.
   * @return {Promise}      Resolves to the returned full Document object
   */
  static async updateDocument(documentId, modifiedFields) {
    const { files, ...rest } = modifiedFields;
    const response = await axios({
      method: 'PATCH',
      url: `document/${documentId}`,
      responseType: 'json',
      data: rest,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the DELETE Document endpoint.
   * @param  {String|Number}  documentId The Document Id to delete
   * @return {Promise}
   */
  static async deleteDocument(documentId) {
    await axios({
      method: 'DELETE',
      url: `document/${documentId}`,
      responseType: 'json',
    });

    return true;
  }

  /**
   * Exports invoices with the give criteria
   * @param {String} fileType The fileType to load
   * @param {String} fileName The fileName to save
   * @param {String} sort The field to sort on
   * @param {String} direction The sort direction. Either 'ASC' or 'DESC'. Defaults to ASC.
   * @param {Object} filter An object with key value pairs of filter values.
   * @return {Promise} Promise that resolves to the api response.
   * In format: { sites: {object[]}, meta: {object}, links: {object} }
   */
  static async exportDocuments(fileType, fileName, sort, direction, filter) {
    const params = createAPIParams(null, sort, direction, filter);
    delete params.page;
    params.fileType = fileType;
    FileExportAPI.handleFileExport('document_export', params, fileType, fileName);
  }

  /**
   * Adds files to a Document
   * @method
   * @static
   * @param  {number}  documentId     The document to update
   * @param  {Array}  files     The files to add
   * @return {Promise}          Promise that resolves to the {@link Document} details.
   */
  static async addFiles(documentId, files) {
    const response = await axios({
      method: 'POST',
      url: `document/${documentId}/files`,
      responseType: 'json',
      data: createFormData({
        files: files.filter((f) => f instanceof File),
      }),
    });

    return response.data.data;
  }
}

export default DocumentAPI;
