export default {
  /**
   * @name g isActiveNotificationPhantom
   * @desc Returns true if the activeNotification is 'phantom' (i.e. has not been saved).
   * @param  {object}  state Current state
   * @return {Boolean}
   */
  isActiveNotificationPhantom(state) {
    return !(state.activeNotification && state.activeNotification.id);
  },
};
