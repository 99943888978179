import Base from './Base';

export default function RemedialActionStatusCreator(i18n) {
  class RemedialActionStatus extends Base {}

  RemedialActionStatus.initEnum({
    TO_DO: {
      value: 'TO_DO',
      display: 'To Do',
    },
    IN_PROGRESS: {
      value: 'IN_PROGRESS',
      display: 'In Progress',
    },
    DONE: {
      value: 'DONE',
      display: 'Done',
    },
    ABANDONED: {
      value: 'ABANDONED',
      display: 'Abandoned',
    },
  });

  return RemedialActionStatus;
}
