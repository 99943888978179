/**
 * A WorkOrderAvailableServices definition
 * @typedef {Object} WorkOrderAvailableServices
 * @property {Object} service -
 * @property {Object} service_provider -
 * @property {Object} contractor -
 */

export default {
  workOrderAvailableServices: [],
  workOrderAvailableServicesState: null,

  filter: {},
  meta: {},
};
