import Base from './Base.js';

export default function InvoiceStatusCreator(i18n) {
  class InvoiceStatus extends Base {}

  InvoiceStatus.initEnum({
    SUBMITTED: {
      value: 'SUBMITTED',
      display: i18n.global.t('enums.InvoiceStatus.submitted'),
    },
    APPROVED: {
      value: 'APPROVED',
      display: i18n.global.t('enums.InvoiceStatus.approved'),
    },
    REJECTED: {
      value: 'REJECTED',
      display: i18n.global.t('enums.InvoiceStatus.rejected'),
    },
    EXPORTED: {
      value: 'EXPORTED',
      display: i18n.global.t('enums.InvoiceStatus.exported'),
    },
    DRAFT: {
      value: 'DRAFT',
      display: i18n.global.t('enums.InvoiceStatus.draft'),
    },
    PAID: {
      value: 'PAID',
      display: i18n.global.t('enums.InvoiceStatus.paid'),
    },
  });

  return InvoiceStatus;
}
