export default {
  computed: {
    async logo() {
      if (
        !window.appConfig ||
        !window.appConfig.CLIENT_LOGO_URL ||
        !window.appConfig.CLIENT_LOGO_URL.value
      ) {
        try {
          return await import('@/assets/images/logo/logo-nav-light.png').then((result) => {
            return result.default;
          });
        } catch (err) {
          return null;
        }
      }

      return window.appConfig.CLIENT_LOGO_URL.value;
    },

    async expansiveLogo() {
      return await import('@/assets/images/logo/logo-nav-dark.png').then((result) => {
        return result.default;
      });
    },

    async darkLogo() {
      if (
        !window.appConfig ||
        !window.appConfig.CLIENT_LOGO_DARK_URL ||
        !window.appConfig.CLIENT_LOGO_DARK_URL.value
      ) {
        try {
          return await import('@/assets/images/logo/logo-nav-dark.png').then((result) => {
            return result.default;
          });
        } catch (err) {
          return null;
        }
      }

      return window.appConfig.CLIENT_LOGO_DARK_URL.value;
    },
  },
};
