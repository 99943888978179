export default {
  getters: {},

  actions: {
    GET_CONDITION_GRADES: 'GET_CONDITION_GRADES',
  },

  mutations: {
    SET_CONDITION_GRADES: 'SET_CONDITION_GRADES',
    SET_CONDITION_GRADES_STATUS: 'SET_CONDITION_GRADES_STATUS',
  },
};
