import types from '../../types/users.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_USERS_FILTER
   * @summary Updates the `filter` property which holds the collection of filters currently active
   * on the Users collection
   * @method
   * @param {Object} state Current state
   * @param {Object} filter The filters to be applied
   */
  [m.SET_USERS_FILTER](state, filter) {
    state.filter = filter;
  },

  /**
   * @name m SET_USERS_SORT
   * @summary Updates the `sort` and `direction` properties
   * @method
   * @param {Object} state Current state
   * @param {Object} data The sort data (`sort` and `direction`)
   */
  [m.SET_USERS_SORT](state, { sort, direction }) {
    state.sort = sort;
    state.sortDirection = direction;
  },

  /**
   * @name m SET_ACTIVE_USER
   * @summary Updates the `activeUser` and resets the
   * `activeUserModifiedFields` & `activeUserErrors` properties
   * @method
   * @param {Object} state Current state
   * @param {User} user The new Active {@link User}
   */
  [m.SET_ACTIVE_USER](state, user) {
    state.activeUser = user;
    state.activeUserModifiedFields = !user || user.id ? {} : { ...user };
    state.activeUserErrors = [];
  },

  /**
   * @name m SET_ACTIVE_USER_STATE
   * @summary Updates the `activeUserState` property which holds the current status of the
   * based on the API enum
   * @method
   * @param {Object} state Current state
   * @param {enums/API} activeUserState The current activeUserState (e.g. loading, success, failure)
   */
  [m.SET_ACTIVE_USER_STATE](state, activeUserState) {
    state.activeUserState = activeUserState;
  },

  /**
   * @name m SET_ACTIVE_USER_ERRORS
   * @summary Updates the `activeUserErrors` property which holds any errors related to activeUser
   * @method
   * @param {Object} state Current state
   * @param {string[]} errors An array of error messages
   */
  [m.SET_ACTIVE_USER_ERRORS](state, errors) {
    // eslint-disable-next-line no-unused-expressions
    Array.isArray(errors) ? (state.activeUserErrors = errors) : state.activeUserErrors.push(errors);
  },

  /**
   * @name m SET_ACTIVE_USER_VALID
   * @summary Updates the `activeUserValid` property which holds a boolean for the validation status
   * of the `activeUser`.
   * @method
   * @param {Object} state Current state
   * @param {boolean} valid True if the `activeUser` is valid.
   */
  [m.SET_ACTIVE_USER_VALID](state, valid) {
    state.activeUserValid = valid;
  },

  /**
   * @name m UPDATE_ACTIVE_USER_MODIFIED_FIELDS
   * @summary Adds the given field/value pair to the `activeUserModifiedFields` collection, which
   * will be sent as a PATCH to the API. If the given `value` equals the one on the `activeUser`
   * object (i.e. it hasn't actually changed, or has been reverted back to it's original) then we
   * remove that field from the modifiedFields collection
   * @method
   * @param {Object} state Current state
   * @param {object} payload Object containing a `field` and `value` properties.
   */
  [m.UPDATE_ACTIVE_USER_MODIFIED_FIELDS](state, { field, value }) {
    // if the value matches the existing one then we remove the field from the modifiedFields obj
    if (state.activeUser && state.activeUser[field] === value) {
      delete state.activeUserModifiedFields[field];
    } else {
      state.activeUserModifiedFields[field] = value;
    }
  },

  /**
   * @name m CLEAR_ACTIVE_USER_MODIFIED_FIELDS
   * @summary Empties the `activeUserModifiedFields` object. Used to ensure we're on a clean slate
   * after navigating to a User Display screen,
   * @method
   * @param {Object} state Current state
   */
  [m.CLEAR_ACTIVE_USER_MODIFIED_FIELDS](state) {
    state.activeUserModifiedFields = {};
  },
};
