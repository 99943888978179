<template>
  <div>
    <b-alert variant="success" show>
      <h4 class="alert-heading">
        {{ $t('components.auth.otp.setup.backupCodes.confirmationMessageHeader') }}
      </h4>
      {{ $t('components.auth.otp.setup.backupCodes.confirmationMessage') }}
    </b-alert>
    <p>
      {{ $t('components.auth.otp.setup.backupCodes.instruction') }}
    </p>

    <div class="text-center">
      <div v-for="backupCode in backupCodes" :key="backupCode">
        <pre :key="backupCode">{{ backupCode }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
import { BAlert } from 'bootstrap-vue';

export default {
  name: 'BackupCodes',

  components: {
    BAlert,
  },

  props: {
    backupCodes: {
      type: Array,
      default: () => [],
    },
  },
};
</script>
