import Base from './Base.js';

export default function StartWorkCheckStatusCreator(i18n) {
  class StartWorkCheckStatus extends Base {}

  StartWorkCheckStatus.initEnum({
    PASSED: {
      value: 'PASSED',
      display: 'Passed',
      color: 'success',
      colorCode: '#34c38f',
    },
    FAILED: {
      value: 'FAILED',
      display: 'Failed',
      color: 'danger',
      colorCode: '#f86c6b',
    },
    DRAFT: {
      value: 'DRAFT',
      display: 'Draft',
      color: 'warning',
      colorCode: '#f1b44c',
    },
  });

  return StartWorkCheckStatus;
}
