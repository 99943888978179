const releaseNotes = {
  path: '/releases',
  name: 'ReleaseNotes',
  meta: {
    layout: 'DefaultLayout',
    bcLinkText: 'Release Notes',
  },
  component: () => import('@/views/release-notes/ReleaseNotes.vue'),
};

export default releaseNotes;
