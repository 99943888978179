<template>
  <Toast position="bottom-right" group="serviceWorkerToast">
    <template v-slot:message="slotProps">
      <div v-if="slotProps.message">
        <div>
          <font-awesome-icon :icon="['far', 'exclamation-triangle']"></font-awesome-icon>
          <span :key="slotProps.message.title" class="ml-2">{{slotProps.message.title}}</span>
        </div>

        <div class="mt-2">
          <small>{{slotProps.message.summary}}</small>
        </div>

        <div>
          <b-button class="mt-3" @click="onReloadClick">Reload</b-button>
        </div>
      </div>
    </template>
  </Toast>
</template>

<script>
import { useRegisterSW } from 'virtual:pwa-register/vue';
import { registerSW } from 'virtual:pwa-register';
import { pwaInfo } from 'virtual:pwa-info';
import { BButton } from 'bootstrap-vue';
import Toast from 'primevue/toast';
import CONFIG from '@/config/config.js';

export default {
  name: 'ServiceWorker',
  components: {
    BButton,
    Toast
  },
  data() {
    return {
      updateSW: null
    };
  },
  created() {
    this.initializeServiceWorker();
  },
  methods: {
    async onReloadClick() {
      let posted = false;
      if (this.registration?.waiting?.postMessage) {
        this.registration?.waiting?.postMessage('SKIP_WAITING');
        posted = true;
      }

      const i = setInterval(() => {
        if (!posted && this.registration?.waiting?.postMessage) {
          this.registration?.waiting?.postMessage('SKIP_WAITING');
          posted = true;
        }

        if (posted && this.registration?.waiting === null) {
          window.location.reload();
          clearInterval(i);
        }
      }, 1000);
    },
    initializeServiceWorker() {
      this.updateSW = registerSW({
        immediate: true,
        onNeedRefresh: () => {
          const i = setInterval(() => {
            if (this.registration) {
              this.triggerPrompt(
                this.$t('common.serviceWorker.appNeedsRefreshTitle'),
                this.$t('common.serviceWorker.appNeedsRefreshDescription')
              );

              clearInterval(i);
            }
          }, 1000)
        },
        onOfflineReady: () => {
          const i = setInterval(() => {
            if (!this.registration) {
              this.triggerPrompt(
                this.$t('common.serviceWorker.appIsOfflineTitle'),
                this.$t('common.serviceWorker.appIsOfflineDescription')
              );

              clearInterval(i);
            }
          }, 1000)
        },
        onRegisteredSW: (swUrl, registration) => registration && setInterval(async () => {
          this.registration = registration;
          await registration.update();
        }, CONFIG.UPDATE_CHECK_INTERVAL),
      });
    },
    triggerPrompt(title, summary) {
      this.$nextTick(() => this.$toast.add({
        title: title,
        summary: summary,
        group: 'serviceWorkerToast',
        life: 30000,
      }));
    }
  }
}
</script>

<style>
.pwa-toast {
  position: fixed;
  right: 0;
  bottom: 0;
  margin: 16px;
  padding: 12px;
  border: 1px solid #8885;
  border-radius: 4px;
  z-index: 1;
  text-align: left;
  box-shadow: 3px 4px 5px 0px #8885;
}
.pwa-toast .message {
  margin-bottom: 8px;
}
.pwa-toast button {
  border: 1px solid #8885;
  outline: none;
  margin-right: 5px;
  border-radius: 2px;
  padding: 3px 10px;
}
</style>
