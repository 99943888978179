export default {
  path: '/contractor-profile',
  meta: {
    requiresAuth: true,
    layout: 'DefaultLayout',
  },
  children: [
    {
      path: '',
      name: 'ContractorProfile',
      component: () => import('@/views/contractor-profile/ContractorProfile.vue'),
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'ContractorProfile_Manage',
      },
    },
    {
      path: 'impersonate/:id',
      name: 'ContractorProfileImpersonate',
      component: () => import('@/views/contractor-profile/ContractorProfileImpersonate.vue'),
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'Contractor_Manage',
      },
    },
  ],
};
