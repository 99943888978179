export default {
  path: '/cost-requests',
  meta: {
    requiresAuth: true,
    layout: 'DefaultLayout',

    bcLinkText: 'Quote Requests',
    storeModules: ['costRequest'],
  },
  component: () => import('@/views/cost-requests/CostRequests.vue'),
  children: [
    {
      path: ':page(\\d+)?',
      name: 'CostRequests',
      component: () => import('@/views/cost-requests/CostRequestsHome.vue'),
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
      },
    },
    {
      path: 'view/:id/:pane?',
      name: 'CostRequestDisplay',
      component: () => import('@/views/cost-requests/CostRequestDisplay.vue'),
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        bcDynamic: true,
        bcGetter: 'getActiveCostRequest',
        bcLinkText: (value, $t) => {
          return $t('breadcrumbs.costRequests.costRequestForWorkOrder', {
            id: value.work_order.id
          });
        },
        bcLoadingText: 'Loading Quote Request...',
      },
    },
  ],
};
