import types from '@/store/types/conditionGrade.js';
import APIError from '@/errors/APIError.js';
import ConditionGradeAPI from '@/services/ConditionGradeAPI.js';
import ApiStatusCreator from '../../../enums/API.js';
const API_STATUS = ApiStatusCreator();

const a = types.actions;
const m = types.mutations;

export default {
  async [a.GET_CONDITION_GRADES]({ commit, state }) {
    commit(m.SET_CONDITION_GRADES_STATUS, API_STATUS.LOADING);

    try {
      const conditionGrades = await ConditionGradeAPI.getConditionGrades();

      commit(m.SET_CONDITION_GRADES_STATUS, API_STATUS.SUCCESS);
      commit(m.SET_CONDITION_GRADES, conditionGrades);
    } catch (error) {
      commit(m.SET_CONDITION_GRADES_STATUS, API_STATUS.FAILURE);

      let message = 'An error occurred while fetching these Condition Grades. Please try again'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.conditionGrades;
  },
};
