export default {
  getters: {},

  actions: {
    INIT_USER_SESSION: 'INIT_USER_SESSION',
    LOGIN_USER: 'LOGIN_USER',
    LOGIN_SSO: 'LOGIN_SSO',
    REFRESH_USER: 'REFRESH_USER',
    LOGOUT_USER: 'LOGOUT_USER',
    KILL_SESSION: 'KILL_SESSION',
    IMPERSONATE_USER: 'IMPERSONATE_USER',
    CONFIRM_OTP: 'CONFIRM_OTP',
    DISABLE_OTP: 'DISABLE_OTP',
  },

  mutations: {
    SET_SESSION: 'SET_SESSION',
    SET_SESSION_STATUS: 'SET_SESSION_STATUS',
    SET_FORCE_PASSWORD_UPDATE: 'SET_FORCE_PASSWORD_UPDATE',
    SET_USER: 'SET_USER',
    SET_SESSION_ERRORS: 'SET_SESSION_ERRORS',
    SET_SESSION_USER_ID: 'SET_SESSION_USER_ID',
    SET_SESSION_USER: 'SET_SESSION_USER',
    PATCH_SESSION_USER: 'PATCH_SESSION_USER',
    SET_OTP_ENABLED: 'SET_OTP_ENABLED',
  },
};
