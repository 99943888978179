import axios from 'axios';
import CONFIG from '@/config/config.js';
import SESSION from '@/enums/Session.js';
import authorizationTypes from '@/store/types/authorization.js';
import getCSRFToken from '@/util/getCSRFToken.js';

export function initAxios(store, router) {
  // Setup Axios default baseURL. This may be mutated at the component lvl
  axios.defaults.baseURL = CONFIG.API_URL;

  axios.defaults.timeout = 30000;

  // save the raw config so we can retry
  axios.interceptors.request.use((config) => ({ ...config, efmRawConfig: config }));

  axios.interceptors.response.use(undefined, async (err) => {
    if (err.response?.status === 419 && !err.config?.efmRetry) {
      await getCSRFToken(true);
      return axios.request({ ...err.config.efmRawConfig, efmRetry: true });
    }
    if (err && err.response && err.response.status === 401) {
      store.dispatch(authorizationTypes.actions.KILL_SESSION);
      store.commit(authorizationTypes.mutations.SET_SESSION_STATUS, SESSION.SESSION_EXPIRED);
      store.commit(authorizationTypes.mutations.SET_SESSION_ERRORS, [
        'Your session has expired. Please log in again',
        //i18n.t('common.session.sessionExpired'),
      ]);

      router.push({ name: 'login' });
    }

    return Promise.reject(err);
  });
}
