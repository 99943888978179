import types from '../../types/site.js';
import SiteAPI from '../../../services/SiteAPI.js';
import API from '../../../enums/API.js';
import APIError from '../../../errors/APIError.js';

const a = types.actions;
const m = types.mutations;

export default {

  /**
   * Loads one Site
   * @param {Object} context
   * @param {number} page The page to load. If not supplied will default to 1
   * @return {Site[]} An array of a {@link Site} object.
   */
  async [a.GET_SITE]({ commit, state }, { id }) {
    commit(m.SET_ACTIVE_SITE, null);
    commit(m.SET_ACTIVE_SITE_STATE, API.LOADING);

    try {
      const site = await SiteAPI.getSite(id);

      site.created_at = new Date(site.created_at);
      site.updated_at = new Date(site.updated_at);

      commit(m.SET_ACTIVE_SITE, site);
      commit(m.SET_ACTIVE_SITE_STATE, API.SUCCESS);
    } catch (error) {
      commit(m.SET_ACTIVE_SITE_STATE, API.FAILURE);

      let message = 'An error occurred while fetching this Site. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.sites;
  },

  /**
   * Creates a new Site
   * @param {Object} context
   * @param {object} site A {@link Site} object
   * @return {Site} A {@link Site} object.
   */
  async [a.CREATE_SITE]({ commit, state }, { site }) {
    try {
      commit(m.SET_ACTIVE_SITE_ERRORS, []);
      commit(m.SET_ACTIVE_SITE_STATE, API.SAVING);

      const siteResponse = await SiteAPI.createSite(site);

      commit(m.SET_ACTIVE_SITE_STATE, API.SUCCESS);
      commit(m.SET_ACTIVE_SITE, siteResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_SITE_STATE, API.FAILURE);

      let message = 'An error occurred while creating this Site. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeSite;
  },

  /**
   * Updates an existing Site
   * @param {Object} context
   * @param {object} site The {@link Site} object to update
   * @param {object} modifiedFields An object with the key/values for the fields to update.
   * @return {Site} A {@link Site} object.
   */
  async [a.UPDATE_SITE]({ commit, state }, { site, modifiedFields }) {
    try {
      commit(m.SET_ACTIVE_SITE_ERRORS, []);
      commit(m.SET_ACTIVE_SITE_STATE, API.SAVING);

      const siteResponse = await SiteAPI.updateSite(site.id, modifiedFields);

      commit(m.SET_ACTIVE_SITE_STATE, API.SUCCESS);
      commit(m.SET_ACTIVE_SITE, siteResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_SITE_STATE, API.FAILURE);

      let message = 'An error occurred while updating this Site. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeSite;
  },

  /**
   * Deletes a Site
   * @param {Object} context
   * @param {object} site The {@link Site} object to delete
   * @return {Site} A {@link Site} object.
   */
  async [a.DELETE_SITE]({ commit }, { site }) {
    try {
      commit(m.SET_ACTIVE_SITE_ERRORS, []);
      commit(m.SET_ACTIVE_SITE_STATE, API.DELETING);

      await SiteAPI.deleteSite(site.id);

      commit(m.SET_ACTIVE_SITE_STATE, API.SUCCESS);
      commit(m.SET_ACTIVE_SITE, null);
    } catch (error) {
      commit(m.SET_ACTIVE_SITE_STATE, API.FAILURE);

      let message = 'An error occurred while deleting this Site. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return true;
  },
};
