export default {
  /**
   * @name g getActiveRecurringWorkOrder
   * @desc Returns the `activeRecurringWorkOrder` object
   * @param  {object}  state Current state
   * @return {object}
   */
  getActiveRecurringWorkOrder(state) {
    return state.activeRecurringWorkOrder;
  },

  /**
   * @name g isActiveRecurringWorkOrderPhantom
   * @desc Returns true if the activeRecurringWorkOrder is 'phantom' (i.e. has not been saved).
   * @param  {object}  state Current state
   * @return {Boolean}
   */
  isActiveRecurringWorkOrderPhantom(state) {
    return !(state.activeRecurringWorkOrder && state.activeRecurringWorkOrder.id);
  },

  getActiveExpandedGroups(state) {
    return state.expandedGroups[state.activeGroup] || {};
  },

  // returns true if the groupId (and if supplied, rwoId) is present in the expandedGroups object, and therefore expanded
  isItemExpanded(state, getters) {
    return (groupId, rwoId) => {
      let leaf = getters.getActiveExpandedGroups[groupId];

      if (rwoId) {
        leaf = leaf?.[rwoId];
      }

      return !!leaf;
    };
  },

  // returns true if the groupId and rwoId is present and has the showArchived flag set to true
  isArchiveShown(state, getters) {
    return (groupId, rwoId) => getters.getActiveExpandedGroups[groupId]?.[rwoId]?.showArchived;
  },
};
