export default {
  /**
   * @name g isActiveCostCodePhantom
   * @desc Returns true if the activeCostCode is 'phantom' (i.e. has not been saved).
   * @param  {object}  state Current state
   * @return {Boolean}
   */
  isActiveLandlordPhantom(state) {
    return !(state.activeLandlord && state.activeLandlord.id);
  },
};
