export default {
  path: '/invoices',
  meta: {
    requiresAuth: true,
    layout: 'DefaultLayout',

    bcLinkText: 'Invoices',
    storeModules: ['invoice'],
  },
  component: () => import('@/views/invoices/Invoices.vue'),
  children: [
    {
      path: ':page(\\d+)?',
      name: 'Invoices',
      component: () => import('@/views/invoices/InvoicesHome.vue'),
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
      },
    },
    {
      path: 'view/:id',
      name: 'InvoiceDisplay',
      component: () => import('@/views/invoices/InvoiceDisplay.vue'),
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        bcLinkText: 'View Invoice',
      },
    },
  ],
};
