/**
 * A WorkOrderType definition
 * @typedef {Object} WorkOrderType
 * @property {number} id -
 * @property {string} name -
 * @property {string} code -
 * @property {string} symbol -
 */

export default {
  workOrderTypes: [],
  workOrderTypesState: null,

  filter: {},

  meta: {},

  activeWorkOrderType: null,
  activeWorkOrderTypeState: null,
  activeWorkOrderTypeErrors: [],
  activeWorkOrderTypeValid: false,
  activeWorkOrderTypeModifiedFields: {},
};
