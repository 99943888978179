import { Enum } from 'enumify';

export default class Base extends Enum {
  eql(state) {
    if (!state) {
      return false;
    }

    // `state` will _usually_ be an Enum instance but sometimes might be a string. if the `value`
    // prop is falsey then we just use it as is, probably a string containing the value.
    const value = state.value || state;

    return this.value === value;
  }
}
