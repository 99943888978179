import Base from './Base.js';

export default function StartWorkCheckTaskAnswerCreator(i18n) {
  class StartWorkCheckTaskAnswer extends Base {}

  StartWorkCheckTaskAnswer.initEnum({
    YES: {
      value: 'YES',
      display: 'Yes',
    },
    NO: {
      value: 'NO',
      display: 'No',
    },
  });

  return StartWorkCheckTaskAnswer;
}
