<template>
  <div id="app" :class="{'uat': isUat}">
    <metainfo>
      <template v-slot:title="{ content }">
        {{ content ? `${content} | Expansive FM` : 'Expansive FM' }}
      </template>
    </metainfo>
    <ServiceWorker/>
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<script>
import ServiceWorker from '@/components/serviceWorker/ServiceWorker.vue';
import CONFIG from '@/config/config.js';

export default {
  name: 'App',
  components: {
    ServiceWorker,
  },
  props: {},
  computed: {
    layout() {
      return this.$route.meta.layout || 'div';
    },
    isUat() {
      return window.location.hostname.includes('-uat')
        || window.location.hostname.startsWith('core-');
    },
  },
  mounted() {
    try {
      if (window.appConfig?.HOTJAR_SITE_ID?.value) {
        const tag = document.createElement('script');
        tag.setAttribute('src', 'scripts/hotjar.js');
        document.head.appendChild(tag);
      }
    } finally {
      // some wrap up
    }
  },
};
</script>

<style lang="scss">
.demo-mode {
  display: none;
}

.uat {
  .demo-mode {
    display: block;
    height: 2rem;
    background: rgb(254 214 99);
    border-bottom: 1px solid #eac457;
    color: #544b31;
    text-align: center;
    padding: 0.2rem;
    font-family: monospace;
  }
}

</style>
