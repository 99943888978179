export default {
  install(app) {
    app.config.globalProperties.$handleErrors = (error, defaultMessage) => {
      let msg = defaultMessage ?? '';

      if (error?.response?.data?.errors) {
        msg = error?.response?.data?.errors.flat();

        // v2 support
        if (Array.isArray(msg)) {
          msg = msg.map((error) => error.detail);
        }
      } else if (error?.response?.data?.message) {
        msg = error.response.data.message;
      } else if (error?.messages) {
        msg = error.messages;
      } else {
        msg = error?.message || msg;
      }

      if (import.meta.env.NODE_ENV !== 'production') {
        // eslint-disable-next-line no-console
        console.log(error);
      }

      return Array.isArray(msg) ? msg : [msg];
    };
  },
};
