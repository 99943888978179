export default {
  getters: {},

  actions: {
    GET_COST_CODES: 'GET_COST_CODES',
    UPDATE_COST_CODE: 'UPDATE_COST_CODE',
    CREATE_COST_CODE: 'CREATE_COST_CODE',
    DELETE_COST_CODE: 'DELETE_COST_CODE',
  },

  mutations: {
    SET_COST_CODES: 'SET_COST_CODES',
    SET_COST_CODES_STATUS: 'SET_COST_CODES_STATUS',
    SET_COST_CODES_META: 'SET_COST_CODES_META',
    SET_COST_CODES_FILTER: 'SET_COST_CODES_FILTER',

    REMOVE_LOCAL_COST_CODE: 'REMOVE_LOCAL_COST_CODE',
    ADD_LOCAL_COST_CODE: 'ADD_LOCAL_COST_CODE',
    UPDATE_LOCAL_COST_CODE: 'UPDATE_LOCAL_COST_CODE',

    SET_ACTIVE_COST_CODE: 'SET_ACTIVE_COST_CODE',
    SET_ACTIVE_COST_CODE_STATE: 'SET_ACTIVE_COST_CODE_STATE',
    SET_ACTIVE_COST_CODE_ERRORS: 'SET_ACTIVE_COST_CODE_ERRORS',
    SET_ACTIVE_COST_CODE_VALID: 'SET_ACTIVE_COST_CODE_VALID',
    UPDATE_ACTIVE_COST_CODE_MODIFIED_FIELDS: 'UPDATE_ACTIVE_COST_CODE_MODIFIED_FIELDS',
    CLEAR_ACTIVE_COST_CODE_MODIFIED_FIELDS: 'CLEAR_ACTIVE_COST_CODE_MODIFIED_FIELDS',
  },
};
