import Base from './Base.js';

export default function WorkOrderBillingStatusCreator(i18n) {
  class WorkOrderBillingStatus extends Base {}

  WorkOrderBillingStatus.initEnum({
    NON_BILLABLE: {
      value: 'NON_BILLABLE',
      display: i18n.global.t('enums.WorkOrderBillingStatus.nonBillable'),
      initial: true,
    },
    DRAFT: {
      value: 'DRAFT',
      display: i18n.global.t('enums.WorkOrderBillingStatus.draft'),
      initial: true,
    },
    SUBMITTED: {
      value: 'SUBMITTED',
      display: i18n.global.t('enums.WorkOrderBillingStatus.submitted'),
    },
    APPROVED: {
      value: 'APPROVED',
      display: i18n.global.t('enums.WorkOrderBillingStatus.approved'),
    },
    QUERIED: {
      value: 'QUERIED',
      display: i18n.global.t('enums.WorkOrderBillingStatus.queried'),
    },
    REJECTED: {
      value: 'REJECTED',
      display: i18n.global.t('enums.WorkOrderBillingStatus.rejected'),
    },
  });

  return WorkOrderBillingStatus;
}
