import types from '../../types/workOrderAvailableServices.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_WORK_ORDER_AVAILABLE_SERVICES
   * @summary Updates the `workOrderAvailableServices` property.
   * @method
   * @param {Object} state Current state
   // eslint-disable-next-line max-len
   * @param {WorkOrderAvailableServices[]} workOrderAvailableService
   */
  [m.SET_WORK_ORDER_AVAILABLE_SERVICES](state, workOrderAvailableServices) {
    state.workOrderAvailableServices = workOrderAvailableServices;
  },

  [m.SET_WORK_ORDER_AVAILABLE_SERVICES_STATUS](state, status) {
    state.workOrderAvailableServicesState = status;
  },
};
