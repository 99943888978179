import Base from './Base.js';

export default function WorkOrderClosureReasonsCreator(i18n) {
  class WorkOrderClosureReasons extends Base {}

  WorkOrderClosureReasons.initEnum({
    NO_FURTHER_ACTION: {
      value: 'NO_FURTHER_ACTION',
      display: i18n.global.t('views.work-orders.closureReasons.noFurtherAction'),
    },
    FOLLOW_ON_QUOTE_REQUIRED: {
      value: 'FOLLOW_ON_QUOTE_REQUIRED',
      display: i18n.global.t('views.work-orders.closureReasons.followOnQuoteRequired'),
    },
    ANOTHER_VISIT_REQUIRED: {
      value: 'ANOTHER_VISIT_REQUIRED',
      display: i18n.global.t('views.work-orders.closureReasons.anotherVisitRequired'),
    },
  });

  return WorkOrderClosureReasons;
}
