import axios from 'axios';

/**
 * Interface to the Audit API endpoints
 * @class
 * @exports src/services/AuditAPI
 */
class AuditAPI {
  /**
   * Retrieves the specified Audit's details
   * @method
   * @static
   * @param  {number}  auditId    The audit id to load
   * @return {Promise}          Promise that resolves to the {@link Audit} details.
   */
  static async getAudit(auditId) {
    const response = await axios({
      method: 'GET',
      url: `audit_log/${auditId}`,
      responseType: 'json',
    });

    return response.data.data;
  }

  /**
   * Retrieves all the Audits
   * @method
   * @static
   * @return {Promise}          Promise that resolves to an aray of {@link Audit} items.
   */
  static async getAudits(page, sort, direction, filter) {
    let directionSort = null;

    // if sort field isn't provided then don't set it
    if (sort) {
      directionSort = direction === 'DESC' ? `-${sort}` : sort;
    }

    const processedFilter = {};

    // if filter is falsey then don't process it
    if (filter) {
      Object.entries(filter).forEach(([key, value]) => {
        processedFilter[`filter[${key}]`] = Array.isArray(value) ? value.join(',') : value;
      });
    }

    // create params object
    const params = {
      page,
      ...processedFilter,
    };

    // add sort if present
    if (directionSort) {
      params.sort = directionSort;
    }

    const response = await axios({
      method: 'GET',
      url: 'audit_log',
      responseType: 'json',
      params,
    });

    return response.data;
  }

  /**
   * @summary Hits the create Audit endpoint.
   * @desc Sends the given audit data to the create endpoint
   * @param  {Audit}  audit A {@link Audit} object to be saved
   * @return {Promise}      Resolves to the returned full Audit object
   */
  static async createAudit(audit) {
    const response = await axios({
      method: 'POST',
      url: 'audit_log',
      responseType: 'json',
      data: audit,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the PATCH Audit endpoint.
   * @desc Sends the specified _modified_ fields to the endpoint to be saved.
   * @param  {String|Number}  auditId The Audit Id to save
   * @param  {object}  modifiedFields An object with a subset of the
   * {@link Audit} fields that have been modified and should be saved.
   * @return {Promise}      Resolves to the returned full Audit object
   */
  static async updateAudit(auditId, modifiedFields) {
    const response = await axios({
      method: 'PATCH',
      url: `audit_log/${auditId}`,
      responseType: 'json',
      data: modifiedFields,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the DELETE Audit endpoint.
   * @param  {String|Number}  auditId The Audit Id to delete
   * @return {Promise}
   */
  static async deleteAudit(auditId) {
    await axios({
      method: 'DELETE',
      url: `audit_log/${auditId}`,
      responseType: 'json',
    });

    return true;
  }
}

export default AuditAPI;
