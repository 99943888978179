import axios from 'axios';
import JsonApiResponseConverter from 'json-api-response-converter';
import MetaExtractor from "@/services/v2/MetaExtractor";
import IndexParameterBuilder from "@/services/v2/IndexParameterBuilder";

class IndexAPI {
  static async fetch(route, config) {
    const response = await axios({
      method: 'GET',
      url: `v2/${ route }`,
      responseType: 'json',
      params: IndexParameterBuilder.prepare(config),
    });

    return {
      data: new JsonApiResponseConverter(response.data).formattedResponse,
      meta: {
        ...response.data.meta,
        ...await MetaExtractor.extractMeta(response.data.data)
      },
    };
  }

  static getRelationshipDataFromMetaForId(meta, id) {
    return Object.values(meta).find((meta) => parseInt(meta.id) === parseInt(id))?.relationships;
  }
}

export default IndexAPI;
