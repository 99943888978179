import axios from 'axios';
import createAPIParams from '@/util/createAPIParams.js';

/**
 * Interface to the Organisation API endpoints
 * @class
 * @exports src/services/OrganisationAPI
 */
class OrganisationAPI {
  /**
   * Retrieves all the Organisations
   * @method
   * @static
   * @return {Promise}          Promise that resolves to an array of {@link Organisation} items.
   */
  static async getOrganisations(page, sort, direction, filter) {
    const response = await axios({
      method: 'GET',
      url: 'organisation',
      responseType: 'json',
      params: createAPIParams(page || 'all', sort, direction, filter),
    });

    return response.data.data;
  }

  /**
   * @summary Hits the create Organisation endpoint.
   * @desc Sends the given organisation data to the create endpoint
   * @param  {Organisation}  organisation A {@link Organisation} object to be saved
   * @return {Promise}      Resolves to the returned full Organisation object
   */
  static async createOrganisation(organisation) {
    const response = await axios({
      method: 'POST',
      url: 'organisation',
      responseType: 'json',
      data: organisation,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the PATCH Organisation endpoint.
   * @desc Sends the specified _modified_ fields to the endpoint to be saved.
   * @param  {String|Number}  organisationId The Organisation Id to save
   * @param  {object}  modifiedFields An object with a subset of the
   * {@link Organisation} fields that have been modified and should be saved.
   * @return {Promise}      Resolves to the returned full Organisation object
   */
  static async updateOrganisation(organisationId, modifiedFields) {
    const response = await axios({
      method: 'PATCH',
      url: `organisation/${organisationId}`,
      responseType: 'json',
      data: modifiedFields,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the DELETE Organisation endpoint.
   * @param  {String|Number}  organisationId The Organisation Id to delete
   * @return {Promise}
   */
  static async deleteOrganisation(organisationId) {
    await axios({
      method: 'DELETE',
      url: `organisation/${organisationId}`,
      responseType: 'json',
    });

    return true;
  }
}

export default OrganisationAPI;
