import Base from './Base.js';

export default function WorkOrderRequestForServiceStatusCreator(i18n) {
  class WorkOrderRequestForServiceStatus extends Base {}

  WorkOrderRequestForServiceStatus.initEnum({
    ACCEPTED: {
      value: 'ACCEPTED',
    },
    PENDING: {
      value: 'PENDING',
    },
  });

  return WorkOrderRequestForServiceStatus;
}
