export default {
  getters: {},

  actions: {
    GET_WORK_ORDER_TYPES: 'GET_WORK_ORDER_TYPES',
    UPDATE_WORK_ORDER_TYPE: 'UPDATE_WORK_ORDER_TYPE',
    CREATE_WORK_ORDER_TYPE: 'CREATE_WORK_ORDER_TYPE',
    DELETE_WORK_ORDER_TYPE: 'DELETE_WORK_ORDER_TYPE',
  },

  mutations: {
    SET_WORK_ORDER_TYPES: 'SET_WORK_ORDER_TYPES',
    SET_WORK_ORDER_TYPES_STATUS: 'SET_WORK_ORDER_TYPES_STATUS',
    SET_WORK_ORDER_TYPES_META: 'SET_WORK_ORDER_TYPES_META',
    SET_WORK_ORDER_TYPES_FILTER: 'SET_WORK_ORDER_TYPES_FILTER',

    REMOVE_LOCAL_WORK_ORDER_TYPE: 'REMOVE_LOCAL_WORK_ORDER_TYPE',
    ADD_LOCAL_WORK_ORDER_TYPE: 'ADD_LOCAL_WORK_ORDER_TYPE',
    UPDATE_LOCAL_WORK_ORDER_TYPE: 'UPDATE_LOCAL_WORK_ORDER_TYPE',

    SET_ACTIVE_WORK_ORDER_TYPE: 'SET_ACTIVE_WORK_ORDER_TYPE',
    SET_ACTIVE_WORK_ORDER_TYPE_STATE: 'SET_ACTIVE_WORK_ORDER_TYPE_STATE',
    SET_ACTIVE_WORK_ORDER_TYPE_ERRORS: 'SET_ACTIVE_WORK_ORDER_TYPE_ERRORS',
    SET_ACTIVE_WORK_ORDER_TYPE_VALID: 'SET_ACTIVE_WORK_ORDER_TYPE_VALID',
    UPDATE_ACTIVE_WORK_ORDER_TYPE_MODIFIED_FIELDS: 'UPDATE_ACTIVE_WORK_ORDER_TYPE_MODIFIED_FIELDS',
    CLEAR_ACTIVE_WORK_ORDER_TYPE_MODIFIED_FIELDS: 'CLEAR_ACTIVE_WORK_ORDER_TYPE_MODIFIED_FIELDS',
  },
};
