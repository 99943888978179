export default {
  filter: {},
  sort: 'id',
  sortDirection: 'DESC',

  meta: {},

  activeGroup: 'WorkOrderRequestType.id',

  /*
  An object with the structure as follows to track each level that is expanded

  {
    'WorkOrderREquestType.id': {
      1: { // groupId=1 is expanded simply by being present in the object
        22: { // rwoId=22 is expanded, simply by being present
          showArchived: true // the archived grid is shown based on this flag.
        },
        33: { // rwoId=33 is also expanded
          showArchived: false // archived grid not visible.
        }
      }
    },
    'Site.id': {
      ...
    }
  }

  */
  expandedGroups: {},

  activeRecurringWorkOrder: null,
  activeRecurringWorkOrderState: null,
  activeRecurringWorkOrderErrors: [],
  activeRecurringWorkOrderValid: false,
  activeRecurringWorkOrderModifiedFields: {},
};
