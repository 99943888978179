export default {
  state: {
    /**
     * A Country definition
     * @typedef {Object} Country
     * @property {number} id -
     * @property {string} name -
     */
    country: () => ({
      id: null,
      name: null,
    }),
  },

  getters: {},

  actions: {
    GET_COUNTRIES: 'GET_COUNTRIES',
  },

  mutations: {
    SET_COUNTRIES: 'SET_COUNTRIES',
    SET_COUNTRIES_STATE: 'SET_COUNTRIES_STATE',
  },
};
