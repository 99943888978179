<template>
  <ul class="list-card">
    <ListItem
      v-if="shouldShowThisLevel"
      :node="{
        name: $t(`ux.form.list.ListCard.${levelName}`),
        isThisLevel: true,
      }"
      icon="flag-checkered"
      @nodeclicked="onNodeClick"
    />

    <ListItem
      v-for="(n, index) in getChildren(node)"
      v-bind="$attrs"
      :key="index"
      :children-field="childrenField"
      :node="n"
      @nodeclicked="onNodeClick"
    />
  </ul>
</template>

<script>
import ListItem from '@/ux/form/list/ListItem.vue';

/**
 * This component creates a list of ListItem components and bounces the nodeclicked event.
 * @exports src/ux/list/ListCard
 * @property node {object} The root node of the tree
 * @property {boolean} parentSelect If true this allows users to select a 'folder' (via
 * a 'This Level' list option), as well as selecting a leaf. If false, only a leaf can be selected.
 */
export default {
  name: 'ListCard',

  components: {
    ListItem,
  },

  props: {
    node: {
      type: Object,
      default: () => ({ children: [] }),
    },

    parentSelect: {
      type: Boolean,
      default: true,
    },

    rootSelect: {
      type: Boolean,
      default: true,
    },

    childrenField: {
      type: [String, Function],
      default: () => 'children',
    },

    level: {
      type: Number,
      default: () => 1,
    },
  },

  computed: {
    levelName() {
      return this.level === 1 ? 'topLevel' : 'thisLevel';
    },

    shouldShowThisLevel() {
      if (this.node.read_only) {
        return false;
      }

      return this.parentSelect && (this.rootSelect || this.level > 1);
    },
  },

  methods: {
    onNodeClick(node) {
      let fireNode = node;
      let select = this.getChildren(node).length === 0;

      // if the click is on the 'This Level' item, then we use the card's node and indicate
      // that the item should be selected
      if (node.isThisLevel) {
        fireNode = this.node;
        select = true;
      }

      this.$emit('nodeclicked', fireNode, select);
    },

    getChildren(node) {
      return (
        (typeof this.childrenField === 'function'
          ? this.childrenField(node)
          : node[this.childrenField || 'children']) || []
      );
    },
  },
};
</script>

<style lang="scss" scoped>
ul {
  padding: 0;
  margin: 0;
  background: #fff;
}
</style>
<style lang="scss"></style>
