<template>
  <div class="margin-small-device">
    <b-card v-if="showDisabledToggle" class="enabled-switch-toolbar">
      <b-form-checkbox v-model="toggled" name="check-button" switch @change="toggleEnabled">
        {{ $t('ux.gridToolbar.enabledSwitch') }}
      </b-form-checkbox>
    </b-card>

    <Tree
      v-if="maintainableEntitiesTree && maintainableEntitiesTree.length > 0"
      id="maintainable-entity-tree"
      :pre-selected="treeValue"
      :tree="filteredTreeItems"
      label-field="name"
      :allow-multiple="selectionMode !== 'single'"
      :icon-type-map="storeMaintainableEntityIcons"
      :expand-if="expandIf"
      :selectable-if="selectableIf"
      :disabled-styling-if="disabledStylingIf"
      :show-buttons="showExpansionButtons"
      :scroll-to-selected="scrollToSelected"
      :action-icon="canAddMaintainableEntity ? 'ellipsis-v' : null"
      :show-checkboxes="selectionMode !== 'single'"
      @preNodeExpansion="() => $emit('pre-node-expansion')"
      @postNodeExpansion="() => $emit('post-node-expansion')"
      @update="emitTreeValue"
      @node-action="onNodeAction"
    />

    <!-- Boundary puts this hover menu onto the active node on the tree -->
    <b-dropdown
      ref="actions"
      :boundary="activeTreeNodeTarget"
      variant="none"
      class="action-menu"
      toggle-class="action-menu-button"
      block
      no-caret
      lazy
    >
      <!-- Dummy button which is hidden via the action-menu-button toggle class  -->
      <template #button-content>
        <div>&nbsp;</div>
      </template>
      <b-dropdown-item-button
        v-if="activeTreeNode.type === 'SITE'"
        @click.stop="$emit('on-add-entity-context-menu-click', 'BUILDING')"
      >
        <font-awesome-icon :icon="['far', 'building']" class="fa-fw mr-2" />&nbsp;<!--
              -->{{ $t('views.maintainableEntities.MaintainableEntityDetail.tree.contextMenu.addBuilding') }}
      </b-dropdown-item-button>

      <b-dropdown-item-button
        v-if="activeTreeNode.type === 'SITE' || activeTreeNode.type === 'BUILDING'"
        @click.stop="$emit('on-add-entity-context-menu-click', 'FLOOR')"
      >
        <font-awesome-icon :icon="['far', 'layer-group']" class="fa-fw mr-2" />&nbsp;<!--
              -->{{ $t('views.maintainableEntities.MaintainableEntityDetail.tree.contextMenu.addFloor') }}
      </b-dropdown-item-button>

      <b-dropdown-item-button
        v-if="['FLOOR', 'SITE', 'BUILDING', 'SPACE'].includes(activeTreeNode.type)"
        @click.stop="$emit('on-add-entity-context-menu-click', 'SPACE')"
      >
        <font-awesome-icon :icon="['far', 'map-marker-alt']" class="fa-fw mr-2" />&nbsp;<!--
              -->{{ $t('views.maintainableEntities.MaintainableEntityDetail.tree.contextMenu.addSpace') }}
      </b-dropdown-item-button>

      <b-dropdown-item-button
        v-if="activeTreeNode.type !== 'SYSTEM' && activeTreeNode.type !== 'ASSET'"
        @click.stop="$emit('on-add-entity-context-menu-click', 'SYSTEM')"
      >
        <font-awesome-icon :icon="['far', 'project-diagram']" class="fa-fw mr-2" />&nbsp;<!--
              -->{{ $t('views.maintainableEntities.MaintainableEntityDetail.tree.contextMenu.addSystem') }}
      </b-dropdown-item-button>

      <b-dropdown-item-button
        v-if="hasPermission('Asset_Create')"
        @click.stop="$emit('on-add-entity-context-menu-click', 'ASSET')"
      >
        <font-awesome-icon :icon="['far', 'box']" class="fa-fw mr-2" />&nbsp;<!--
              -->{{ $t('views.maintainableEntities.MaintainableEntityDetail.tree.contextMenu.addAsset') }}
      </b-dropdown-item-button>
    </b-dropdown>
  </div>
</template>

<script>
import {
  BDropdown,
  BDropdownItemButton,
  BFormCheckbox,
  BCard,
} from 'bootstrap-vue';
import flattenTree from '@/util/flattenTree.js';
import Tree from '@/ux/Tree.vue';
import cloneDeep from "lodash/cloneDeep";
import {mapState} from "pinia";
import {useMaintainableEntityStore} from "@/stores/maintainableEntity";
import {mapGetters} from "vuex";

export default {
  name: 'TreeView',
  inject: ["mq"],
  components: {
    BDropdown,
    BFormCheckbox,
    BDropdownItemButton,
    BCard,
    Tree,
  },
  props: {
    scrollToSelected: {
      type: Boolean,
      default: true,
    },
    assetSelectionOnly: {
      type: Boolean,
      default: false,
    },
    showExpansionButtons: {
      type: Boolean,
      default: false,
    },
    selectionMode: {
      type: String,
      default: 'single',
    },
    showDisabledToggle: {
      type: Boolean,
      default: false
    },
    maintainableEntitiesTree: {
      type: Array,
      default: () => [],
    },
    assets: {
      type: Array,
      default: () => [],
    },
    activeItems: {
      type: Array,
      default: () => [],
    },
    canAddMaintainableEntity: {
      type: Boolean,
      default: true,
    },
    location: {
      type: Number,
      default: () => -1,
    },
    preSelectedKeys: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      filteredTreeItems: [],
      selectedFilter: {
        label: this.$t('views.maintainableEntities.MaintainableEntityDetail.tree.filter.all'),
        value: 'SITE',
      },
      treeKey: 0,
      filtersList: {
        site: {
          label: this.$t('views.maintainableEntities.MaintainableEntityDetail.tree.filter.all'),
          value: 'SITE',
        },
        building: {
          label: this.$t(
            'views.maintainableEntities.MaintainableEntityDetail.tree.filter.buildings',
          ),
          value: 'BUILDING',
        },
        floor: {
          label: this.$t('views.maintainableEntities.MaintainableEntityDetail.tree.filter.floors'),
          value: 'FLOOR',
        },
        space: {
          label: this.$t('views.maintainableEntities.MaintainableEntityDetail.tree.filter.spaces'),
          value: 'SPACE',
        },
        system: {
          label: this.$t('views.maintainableEntities.MaintainableEntityDetail.tree.filter.systems'),
          value: 'SYSTEM',
        },
        asset: {
          label: this.$t('views.maintainableEntities.MaintainableEntityDetail.tree.filter.assets'),
          value: 'ASSET',
        },
      },
      toggled: false,
      treeValue: [],
      selectedTreeValue: [],
      activeTreeNode: null,
      activeTreeNodeTarget: null,
    };
  },

  computed: {
    ...mapState(useMaintainableEntityStore, {
      storeMaintainableEntityIcons: 'icons',
    }),

    ...mapGetters(['hasPermission']),

    flatTree() {
      return flattenTree(this.tree);
    },
    expandToLevel() {
      return this.selectedFilter.value === 'SITE' ? 3 : 0;
    },
  },

  watch: {
    maintainableEntitiesTree: {
      handler(before, after) {
        this.setFilteredTreeItems();
      },
      deep: true,
    },

    selectedFilter: {
      handler() {
        this.setFilteredTreeItems();
      },
    }
  },

  async mounted() {
    await this.setTreeValue();
    this.setFilteredTreeItems();
  },

  methods: {
    update() {
      this.setFilteredTreeItems();
    },
    async setTreeValue() {
      this.treeValue = this.preSelectedKeys;
    },
    setFilteredTreeItems() {
      const tree = cloneDeep(this.maintainableEntitiesTree);

      let filtered = [];

      const map = function(nodes) {
        nodes.forEach((node) => {
          if (this.assetSelectionOnly) {
            node.selectable = false;
          }

          map(node.children ?? []);
          const assets = this.assets.filter((a) => a.maintainableEntity.id === node.id);
          const preparedAssets = assets.map((a) => ({
            name: a.name,
            type: "ASSET",
            id: a.id,
            children: [],
            enabled: true,
            selectable: true,
          }));

          if (this.selectedFilter.value !== 'SITE') {
            if (this.selectedFilter.value === 'ASSET') {
              filtered = [
                ...filtered,
                ...preparedAssets
              ];
            }

            else if (this.selectedFilter.value === node.type) {
              node.children = [];
              return filtered.push(node);
            }
          }

          node.children = [
            ...node.children,
            ...preparedAssets
          ];
        })
      }.bind(this);

      map(tree);

      const strip = function(node, parent) {
        node.children.forEach((child) => {
          strip(child, node);
        });

        if (node.type === 'ASSET') {
          return;
        }

        if (node.children.length === 0 || node.children.every((child) => child === null)) {
          if (!parent.children) {
            return;
          }

          const index = parent.children.findIndex((child) => {
            return child?.id === node.id;
          })

          parent.children[index] = null;
        }

        node.children = node.children.filter((child) => child !== null);
      };

      if (filtered.length) {
        if (this.assetSelectionOnly) {
          const parent = {
            children: filtered
          };

          filtered.forEach((node) => {
            strip(node, parent);
          });

          filtered = parent.children;
        }
        this.filteredTreeItems = filtered;
        return;
      }

      if (this.assetSelectionOnly) {
        tree.forEach((node) => {
          strip(node, tree);
        });
      }

      this.filteredTreeItems = tree;
    },
    toggleEnabled(state) {
      this.$emit('toggle', state);
    },
    emitTreeValue(newTreeValue) {
      if (newTreeValue) {
        this.$emit('tree-value-updated', newTreeValue);
      }
    },
    expandIf(node) {
      return this.selectedFilter.value === 'SITE'
        ? ['SITE', 'BUILDING'].includes(node.type)
        : false;
    },
    selectableIf(node) {
      return this.assetSelectionOnly
        ? node.selectable && node.type === 'ASSET'
        : true;
    },
    disabledStylingIf(node) {
      return !node.enabled;
    },
    onNodeAction(node, event) {
      this.activeTreeNodeTarget = event.target;
      this.activeTreeNode = node;
      this.$refs.actions.show();
    },
  },
};
</script>

<style lang="scss" scoped>
:deep(.dropdown-menu) {
  box-shadow: $shadow-dark;
  border-color: $gray-600;
  border-radius: 0.25rem;
  margin-left: 0.5rem;
  font-size: .9rem;
  padding: 0.2rem 0;

  @include media-breakpoint-down(md) {
    width: 200px !important;
    left: -220px !important;
  }
}

:deep(.action-menu-button) {
  visibility: hidden;
  display: block;
}

:deep(.dropdown-item) {
  background-color: transparent;
  color: #212529;
  padding: 0.3rem 0.75rem;

  &:hover {
    background-color: #eeeeee;
    color: #212529;
  }
}
</style>
