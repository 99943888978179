/**
 * A Notification definition
 * @typedef {Object} Notification
 * @property {number} id -
 * @property {string} name -
 * @property {string[]} permissions -
 * @property {string} created_at -
 * @property {string} updated_at -
 */

export default {
  notifications: [],
  notificationsStatus: null,

  filter: {},

  meta: {},

  activeNotification: null,
  activeNotificationState: null,
  activeNotificationErrors: [],
  activeNotificationValid: false,
  activeNotificationModifiedFields: {},
};
