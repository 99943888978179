import axios from 'axios';
import createAPIParams from '@/util/createAPIParams.js';

/**
 * A Tag definition
 * @typedef {Object} Tag
 * @property {number} id -
 * @property {string} name
 * @property {int} tagType
 * @property {string} color
 */

/**
 * Interface to the Tag API endpoints
 * @class
 * @exports src/services/TagAPI
 */
class TagAPI {
  /**
   * Retrieves all the Tags
   * @method
   * @static
   * @return {Promise}          Promise that resolves to an aray of {@link Tag} items.
   */
  static async getTags(page, sort, direction, filter) {
    const response = await axios({
      method: 'GET',
      url: 'tag',
      responseType: 'json',
      params: createAPIParams(page || 'all', sort, direction, filter),
    });
    return response.data.data;
  }

  /**
   * Retrieves a single tag type
   * @method
   * @static
   * @return {Promise}          Promise that resolves to an array of {@link tag} items.
   */
  static async getTagType(id) {
    const response = await axios({
      method: 'GET',
      url: `tag_type/${id}`,
      responseType: 'json',
    });

    return response.data.data;
  }

  /**
   * Retrieves a single the tag
   * @method
   * @static
   * @return {Promise}          Promise that resolves to an array of {@link tag} items.
   */
  static async getTag(id) {
    const response = await axios({
      method: 'GET',
      url: `tag/${id}`,
      responseType: 'json',
    });

    return response.data.data;
  }

  /**
   * @summary Hits the create Tag endpoint.
   * @desc Sends the given tag data to the create endpoint
   * @param  {Tag}  tag A {@link Tag} object to be saved
   * @return {Promise}      Resolves to the returned full Tag object
   */
  static async createTag(tag) {
    const response = await axios({
      method: 'POST',
      url: 'tag',
      responseType: 'json',
      data: tag,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the PATCH Tag endpoint.
   * @desc Sends the specified _modified_ fields to the endpoint to be saved.
   * @param  {String|Number}  tagId The Tag Id to save
   * @param  {object}  modifiedFields An object with a subset of the
   * {@link Tag} fields that have been modified and should be saved.
   * @return {Promise}      Resolves to the returned full Tag object
   */
  static async updateTag(tagId, modifiedFields) {
    const response = await axios({
      method: 'PATCH',
      url: `tag/${tagId}`,
      responseType: 'json',
      data: modifiedFields,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the DELETE Tag endpoint.
   * @param  {String|Number}  tagId The Tag Id to delete
   * @return {Promise}
   */
  static async deleteTag(tagId) {
    await axios({
      method: 'DELETE',
      url: `tag/${tagId}`,
      responseType: 'json',
    });

    return true;
  }
}

export default TagAPI;
