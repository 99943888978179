import types from '../../types/landlord.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_LANDLORDS
   * @summary Updates the `Landlords` property.
   * @method
   * @param {Object} state Current state
   * @param {Landlords[]} landlord The loaded Landlords array
   */
  [m.SET_LANDLORDS](state, landlords) {
    state.landlords = landlords;
  },

  [m.SET_LANDLORDS_STATUS](state, status) {
    state.landlordsState = status;
  },

  /**
   * @name m SET_LANDLORDS_META
   * @summary Updates the `meta` property which holds the meta data for the `LANDLORDS` collection.
   * For example, total rows, current page, number rows per page etc
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The meta data to store
   */
  [m.SET_LANDLORDS_META](state, meta) {
    state.meta = meta;
  },

  /**
   * @name m SET_LANDLORDS_FILTER
   * @summary Updates the `filter` property which holds the collection of filters currently active
   * on the Landlords collection
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The filters to be applied
   */
  [m.SET_LANDLORDS_FILTER](state, filter) {
    state.filter = filter;
  },

  /**
   * @name m SET_ACTIVE_LANDLORD
   * @summary Updates the `activeLandlord` and resets the
   * `activeLandlordModifiedFields` & `activeLandlordErrors` properties
   * @method
   * @param {Object} state Current state
   * @param {Landlord} Landlord The new Active {@link Landlord}
   */
  [m.SET_ACTIVE_LANDLORD](state, Landlord) {
    state.activeLandlord = Landlord;
    state.activeLandlordModifiedFields = !Landlord || Landlord.id ? {} : { ...Landlord };
    state.activeLandlordErrors = [];
  },

  /**
   * @name m SET_ACTIVE_LANDLORD_STATE
   * @summary Updates the `activeLandlordState` property which holds the current status of the
   * based on the API enum
   * @method
   * @param {Object} state Current state
   * @param {enums/API} activeLandlordState The current activeLandlordState
   * (e.g. loading, success, failure)
   */
  [m.SET_ACTIVE_LANDLORD_STATE](state, activeLandlordState) {
    state.activeLandlordState = activeLandlordState;
  },

  /**
   * @name m SET_ACTIVE_LANDLORD_ERRORS
   * @summary Updates the `activeLandlordErrors` property which holds any
   * errors related to activeLandlord
   * @method
   * @param {Object} state Current state
   * @param {string[]} errors An array of error messages
   */
  [m.SET_ACTIVE_LANDLORD_ERRORS](state, errors) {
    state.activeLandlordErrors = errors;
  },

  /**
   * @name m SET_ACTIVE_LANDLORD_VALID
   * @summary Updates the `activeLandlordValid` property which holds
   * a boolean for the validation status of the `activeLandlord`.
   * @method
   * @param {Object} state Current state
   * @param {boolean} valid True if the `activeLandlord` is valid.
   */
  [m.SET_ACTIVE_LANDLORD_VALID](state, valid) {
    state.activeLandlordValid = valid;
  },

  /**
   * @name m UPDATE_ACTIVE_LANDLORD_MODIFIED_FIELDS
   * @summary Adds given field/value pair to the `activeLandlordModifiedFields` collection, which
   * will be sent as a PATCH to the API. If the given `value` equals the one on the `activeLandlord`
   * object (i.e. it hasn't actually changed, or has been reverted back to it's original) then we
   * remove that field from the modifiedFields collection
   * @method
   * @param {Object} state Current state
   * @param {object} payload Object containing a `field` and `value` properties.
   */
  [m.UPDATE_ACTIVE_LANDLORD_MODIFIED_FIELDS](state, { field, value }) {
    // if the value matches the existing one then we remove the field from the modifiedFields obj
    if (state.activeLandlord && state.activeLandlord[field] === value) {
      delete state.activeLandlordModifiedFields[field];
    } else {
      state.activeLandlordModifiedFields[field] = value;
    }
  },

  /**
   * @name m CLEAR_ACTIVE_LANDLORD_MODIFIED_FIELDS
   * @summary Empties the `activeLandlordModifiedFields` object. Used to ensure we're on a clean
   * slate after navigating to a Landlord Display screen,
   * @method
   * @param {Object} state Current state
   */
  [m.CLEAR_ACTIVE_LANDLORD_MODIFIED_FIELDS](state) {
    state.activeLandlordModifiedFields = {};
  },

  /**
   * @name m REMOVE_LOCAL_LANDLORD
   * @summary Removes the given Landlord from the `Landlords` array. This is used to prevent a
   * full reload being needed after deleting a Landlord.
   * @method
   * @param {Object} state Current state
   * @param {object} landlord The Landlord to remove.
   */
  [m.REMOVE_LOCAL_LANDLORD](state, landlord) {
    const index = state.landlords.findIndex((u) => u.id === landlord.id);

    if (index >= 0) {
      state.landlords.splice(index, 1);
    }
  },

  /**
   * @name m ADD_LOCAL_LANDLORD
   * @summary Adds the given Landlord to the `landlords` array. This is used to prevent a
   * full reload being needed after adding a Landlord.
   * @method
   * @param {Object} state Current state
   * @param {object} landlord The Landlord to add.
   */
  [m.ADD_LOCAL_LANDLORD](state, landlord) {
    state.landlords.push(landlord);
  },

  /**
   * @name m UPDATE_LOCAL_LANDLORD
   * @summary Updates the related Landlord object in the `landlords` array after an update.
   * @method
   * @param {Object} state Current state
   * @param {object} landlord The Landlord to update.
   */
  [m.UPDATE_LOCAL_LANDLORD](state, landlord) {
    const localLandlord = state.landlords.find((c) => c.id === landlord.id);

    if (localLandlord) {
      Object.assign(localLandlord, landlord);
    }
  },
};
