export default {
  getters: {},

  actions: {
    GET_WORK_ORDER_AVAILABLE_SERVICES: 'GET_WORK_ORDER_AVAILABLE_SERVICES',
  },

  mutations: {
    SET_WORK_ORDER_AVAILABLE_SERVICES: 'SET_WORK_ORDER_AVAILABLE_SERVICES',
    SET_WORK_ORDER_AVAILABLE_SERVICES_STATUS: 'SET_WORK_ORDER_AVAILABLE_SERVICES_STATUS',
    SET_WORK_ORDER_AVAILABLE_SERVICES_META: 'SET_WORK_ORDER_AVAILABLE_SERVICES_META',
    SET_WORK_ORDER_AVAILABLE_SERVICES_FILTER: 'SET_WORK_ORDER_AVAILABLE_SERVICES_FILTER',
  },
};
