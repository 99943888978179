export default {
  filter: {},
  sort: 'created_at',
  sortDirection: 'DESC',

  meta: {},

  activeRequestForService: null,
  activeRequestForServiceState: null,
  activeRequestForServiceErrors: [],
  activeRequestForServiceValid: false,
  activeRequestForServiceModifiedFields: {},

  enabled: [
    { id: 1, name: 'Yes' },
    { id: 0, name: 'No' },
  ],
  enabledFilter: [
    { id: null, name: 'All' },
    { id: 1, name: 'Yes' },
    { id: 0, name: 'No' },
  ],
  status: [
    { value: 'ACCEPTED', text: 'ACCEPTED' },
    { value: 'PENDING', text: 'PENDING' },
    { value: 'REJECTED', text: 'REJECTED' },
    { value: 'CANCELLED', text: 'CANCELLED' },
  ],
};
