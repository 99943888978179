import Base from './Base.js';

export default function StartWorkCheckTaskTypeCreator(i18n) {
  class StartWorkCheckTaskType extends Base {}

  StartWorkCheckTaskType.initEnum({
    INDUCTION: {
      value: 'INDUCTION',
      display: 'Induction',
    },
    HAZARD: {
      value: 'HAZARD',
      display: 'Hazard',
    },
    SAFE_TO_PROCEED: {
      value: 'SAFE_TO_PROCEED',
      display: 'Safe to Proceed',
    },
  });

  return StartWorkCheckTaskType;
}
