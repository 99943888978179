<template>
  <ul class="bread-crumbs">
    <li v-for="(route, index) in filteredRoutes" :key="index">
      <BreadcrumbItem
        :route="route"
        :is-last="index === filteredRoutes.length - 1 || !filteredRoutes[index + 1].meta.bcLinkText"
        :entity="entity"
        :default-query="defaultQuery"
      />
    </li>
  </ul>
</template>

<script>
import BreadcrumbItem from '@/ux/breadcrumbs/BreadcrumbItem.vue';

export default {
  components: {
    BreadcrumbItem,
  },
  props: {
    routes: {
      type: Array,
      default: () => [],
    },
    defaultQuery: {
      type: Object,
      default: () => {},
    },
    entity: {
      type: Object,
      default: () => null,
    },
    allowDuplicate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    filteredRoutes() {
      if (this.allowDuplicate) {
        return this.routes;
      }
      return this.removeDuplicates(this.routes, 'path');
    },
  },
  methods: {
    removeDuplicates(array, property) {
      const seenValues = new Set();
      const result = [];

      for (const obj of array) {
        const value = obj[property].toString();

        if (!seenValues.has(value)) {
          seenValues.add(value);
          result.push(obj);
        }
      }
      return result;
    }
  },
};
</script>

<style lang="scss" scoped>
.bread-crumbs {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;

  li {
    list-style: none;
  }
}

@include media-breakpoint-down(xs) {
  .bread-crumbs {
    li {
      min-width: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;

      &:last-child {
        flex: 1;
      }
    }
  }
}
</style>
