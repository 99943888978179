export default {
  path: '/work-order',
  meta: {
    requiresAuth: true,
    layout: 'DefaultLayout',
    bcLinkText: 'Work Orders',
    storeModules: [
      'workOrder',
      'serviceType',
      'site',
      'workOrderAvailableServices',
      'documentType',
      'document',
      'maintainableEntity',
      'filter',
    ],
  },
  component: () => import('@/views/work-orders/WorkOrderHome.vue'),
  children: [
    {
      path: ':page(\\d+)?',
      name: 'WorkOrders',
      component: () => import('@/views/work-orders/WorkOrderList.vue'),
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
      },
    },
    {
      path: 'view/:id/:pane?',
      name: 'WorkOrderDisplay',
      component: () => import('@/views/work-orders/WorkOrderDisplay.vue'),
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        bcDynamic: true,
        bcLinkText: (wo) => wo && `${wo?.work_order_type?.name}`,
        bcLinkPathOverride: (wo) => ({
          name: 'WorkOrders',
          query: {
            'WorkOrderType.id': wo?.work_order_type?.id,
          }
        }),
        bcLoadingText: 'Loading Work Order...',
      },
    },
    {
      path: 'create',
      name: 'WorkOrderCreate',
      component: () => import('@/views/work-orders/WorkOrderCreate.vue'),
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'WorkOrder_Create',
        bcLinkText: 'Create',
      },
    },
  ],
};
