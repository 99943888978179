import types from '../../types/country.js';
import CountryAPI from '../../../services/CountryAPI.js';
import ApiStatusCreator from '../../../enums/API.js';
const API_STATUS = ApiStatusCreator();

const a = types.actions;
const m = types.mutations;

export default {
  /**
   * Loads all countries for the given page.
   * @param {Object} context
   * @return {Country[]} An array of {@link Country} objects.
   */
  async [a.GET_COUNTRIES]({ commit, state }) {
    try {
      commit(m.SET_COUNTRIES_STATE, API_STATUS.LOADING);

      const data = await CountryAPI.getCountries();

      commit(m.SET_COUNTRIES_STATE, API_STATUS.SUCCESS);
      commit(m.SET_COUNTRIES, data);
    } catch (error) {
      commit(m.SET_COUNTRIES_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while fetching these Countries. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return state.countries;
  },
};
