export default {
  state: {
    /**
     * A Work Order Request for Service definition
     * @typedef {Object} WorkOrderRequestForService
     * @property {number} id -
     * @property {number} work_order_id -
     * @property {number} contractor_id -
     * @property {string} status -
     * @property {string} message -
     * @property {string} response_reason -
     * @property {date} created_at -
     * @property {date} updated_at -
     */
    requestForService: () => ({
      id: null,
      work_order_id: null,
      contractor_id: null,
      status: null,
      message: null,
      response_reason: null,
    }),
  },

  getters: {},

  actions: {
    GET_REQUEST_FOR_SERVICE: 'GET_REQUEST_FOR_SERVICE',
    UPDATE_REQUEST_FOR_SERVICE: 'UPDATE_REQUEST_FOR_SERVICE',
    CREATE_REQUEST_FOR_SERVICE: 'CREATE_REQUEST_FOR_SERVICE',
    DELETE_REQUEST_FOR_SERVICE: 'DELETE_REQUEST_FOR_SERVICE',
  },

  mutations: {
    SET_ACTIVE_REQUEST_FOR_SERVICE: 'SET_ACTIVE_REQUEST_FOR_SERVICE',
    SET_ACTIVE_REQUEST_FOR_SERVICE_STATE: 'SET_ACTIVE_REQUEST_FOR_SERVICE_STATE',
    SET_ACTIVE_REQUEST_FOR_SERVICE_ERRORS: 'SET_ACTIVE_REQUEST_FOR_SERVICE_ERRORS',
    SET_ACTIVE_REQUEST_FOR_SERVICE_VALID: 'SET_ACTIVE_REQUEST_FOR_SERVICE_VALID',
    UPDATE_ACTIVE_REQUEST_FOR_SERVICE_MODIFIED_FIELDS:
      'UPDATE_ACTIVE_REQUEST_FOR_SERVICE_MODIFIED_FIELDS',
    CLEAR_ACTIVE_REQUEST_FOR_SERVICE_MODIFIED_FIELDS:
      'CLEAR_ACTIVE_REQUEST_FOR_SERVICE_MODIFIED_FIELDS',

    SET_REQUEST_FOR_SERVICES_FILTER: 'SET_REQUEST_FOR_SERVICES_FILTER',
    SET_REQUEST_FOR_SERVICES_SORT: 'SET_REQUEST_FOR_SERVICES_SORT',
  },
};
