export default {
  /**
   * @name g isActiveSupplierPhantom
   * @desc Returns true if the activeSupplier is 'phantom' (i.e. has not been saved).
   * @param  {object}  state Current state
   * @return {Boolean}
   */
  isActiveSupplierPhantom(state) {
    return !(state.activeSupplier && state.activeSupplier.id);
  },
};
