<template>
  <div>
    <b-button class="button-color" @click="goBack">
      <font-awesome-icon :icon="['far', 'chevron-left']" size="sm" />
    </b-button>
  </div>
</template>
<script>
import { BButton } from 'bootstrap-vue';

export default {
  components: { BButton },
  props: {
    backButtonRoute: {
      type: String,
      default: '',
    },
  },
  methods: {
    goBack() {
      if (this.backButtonRoute) {
        this.$router.push(this.backButtonRoute);
      } else {
        this.$router.go(-1);
      }
    },
  },
};
</script>
<style scoped lang="scss">
.button-color {
  background-color: gray !important;
  border: none !important;
}
</style>
