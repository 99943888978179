export default {
  /**
   * @name g isActiveServicePriorityPhantom
   * @desc Returns true if the activeServicePriority is 'phantom' (i.e. has not been saved).
   * @param  {object}  state Current state
   * @return {Boolean}
   */
  isActiveServicePriorityPhantom(state) {
    return !(state.activeServicePriority && state.activeServicePriority.id);
  },
};
