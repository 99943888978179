/**
 * Extracts and returns a list of errors from an Error object - preferentially using API error fields.
 * @param {Error} error
 * @param {String} defaultMessage A default error message to use if none were found on error
 * @returns {Array} A list of error messages
 */
export default function getAPIErrors(error, defaultMessage) {
  const message = error.response.data.message ?? error.message ?? defaultMessage;
  return Array.isArray(message) ? message : [message];
}
