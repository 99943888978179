export default {
  path: '/bulk-cost-approval',
  meta: {
    requiresAuth: true,
    layout: 'DefaultLayout',
    bcLinkText: 'Bulk Cost Approval',
  },
  name: 'BulkCostApproval',
  component: () => import('@/views/bulk-cost-approval/BulkCostApproval.vue'),
};
