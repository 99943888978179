import Base from './Base.js';

export default function WorkOrderSlasCreator(i18n) {
  class WorkOrderSlas extends Base {}

  WorkOrderSlas.initEnum({
    ATRISK: {
      variant: 'warning',
      display: i18n.global.t('views.work-orders.WorkOrderDisplay.time.serviceLevels.states.atRisk'),
    },
    MISS: {
      variant: 'danger',
      display: i18n.global.t('views.work-orders.WorkOrderDisplay.time.serviceLevels.states.miss'),
    },
    HIT: {
      variant: 'success',
      display: i18n.global.t('views.work-orders.WorkOrderDisplay.time.serviceLevels.states.hit'),
    },
    PENDING: {
      variant: 'secondary',
      display: i18n.global.t('views.work-orders.WorkOrderDisplay.time.serviceLevels.states.pending'),
    },
  });

  return WorkOrderSlas;
}
