import types from '../../types/audit.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_AUDITS
   * @summary Updates the `audits` property.
   * @method
   * @param {Object} state Current state
   * @param {Audit[]} audits The loaded Audits array
   */
  [m.SET_AUDITS](state, audits) {
    state.audits = audits;
  },

  [m.SET_AUDITS_STATUS](state, status) {
    state.auditsState = status;
  },

  /**
   * @name m SET_AUDITS_META
   * @summary Updates the `meta` property which holds the meta data for the `AUDITS` collection.
   * For example, total rows, current page, number rows per page etc
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The meta data to store
   */
  [m.SET_AUDITS_META](state, meta) {
    state.meta = meta;
  },

  /**
   * @name m SET_AUDITS_FILTER
   * @summary Updates the `filter` property which holds the collection of filters currently active
   * on the Audits collection
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The filters to be applied
   */
  [m.SET_AUDITS_FILTER](state, filter) {
    state.filter = filter;
  },

  /**
   * @name m SET_AUDITS_SORT
   * @summary Updates the `sort` and `direction` properties
   * @method
   * @param {Object} state Current state
   * @param {Object} data The sort data (`sort` and `direction`)
   */
  [m.SET_AUDITS_SORT](state, { sort, direction }) {
    state.sort = sort;
    state.sortDirection = direction;
  },
};
