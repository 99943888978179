export default {
  getters: {},

  actions: {
    GET_ORGANISATIONS: 'GET_ORGANISATIONS',
    UPDATE_ORGANISATION: 'UPDATE_ORGANISATION',
    CREATE_ORGANISATION: 'CREATE_ORGANISATION',
    DELETE_ORGANISATION: 'DELETE_ORGANISATION',
  },

  mutations: {
    SET_ORGANISATIONS: 'SET_ORGANISATIONS',
    SET_ORGANISATIONS_STATUS: 'SET_ORGANISATIONS_STATUS',
    SET_ORGANISATIONS_META: 'SET_ORGANISATIONS_META',
    SET_ORGANISATIONS_FILTER: 'SET_ORGANISATIONS_FILTER',

    REMOVE_LOCAL_ORGANISATION: 'REMOVE_LOCAL_ORGANISATION',
    ADD_LOCAL_ORGANISATION: 'ADD_LOCAL_ORGANISATION',
    UPDATE_LOCAL_ORGANISATION: 'UPDATE_LOCAL_ORGANISATION',

    SET_ACTIVE_ORGANISATION: 'SET_ACTIVE_ORGANISATION',
    SET_ACTIVE_ORGANISATION_STATE: 'SET_ACTIVE_ORGANISATION_STATE',
    SET_ACTIVE_ORGANISATION_ERRORS: 'SET_ACTIVE_ORGANISATION_ERRORS',
    SET_ACTIVE_ORGANISATION_VALID: 'SET_ACTIVE_ORGANISATION_VALID',
    UPDATE_ACTIVE_ORGANISATION_MODIFIED_FIELDS: 'UPDATE_ACTIVE_ORGANISATION_MODIFIED_FIELDS',
    CLEAR_ACTIVE_ORGANISATION_MODIFIED_FIELDS: 'CLEAR_ACTIVE_ORGANISATION_MODIFIED_FIELDS',
  },
};
