import APIError from '../../../errors/APIError.js';
import types from '../../types/landlord.js';
import LandlLordAPI from '../../../services/LandlordAPI.js';
import ApiStatusCreator from '../../../enums/API.js';
const API_STATUS = ApiStatusCreator();

const a = types.actions;
const m = types.mutations;

export default {
  /**
   * Loads all Landlords
   * @param {Object} context
   * @return {Landlords[]} An array of {@link Landlord} objects.
   */
  async [a.GET_LANDLORDS]({ commit, state }, { page, sort, direction, filter }) {
    commit(m.SET_LANDLORDS_STATUS, API_STATUS.LOADING);

    try {
      const landlords = await LandlLordAPI.getLandlords(page, sort, direction, filter);

      commit(m.SET_LANDLORDS_STATUS, API_STATUS.SUCCESS);
      commit(m.SET_LANDLORDS, landlords);
    } catch (error) {
      commit(m.SET_LANDLORDS_STATUS, API_STATUS.FAILURE);

      let message = 'An error occurred while fetching these Landlords. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.landlords;
  },

  /**
   * Creates a new landlord
   * @param {Object} context
   * @param {object} landlord A {@link landlord} object
   * @return {Landlord} A {@link Landlord} object.
   */
  async [a.CREATE_LANDLORD]({ commit, state }, { landlord }) {
    try {
      commit(m.SET_ACTIVE_LANDLORD_ERRORS, []);
      commit(m.SET_ACTIVE_LANDLORD_STATE, API_STATUS.SAVING);

      const landlordResponse = await LandlLordAPI.createLandlord(landlord);

      commit(m.SET_ACTIVE_LANDLORD_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_LANDLORD, landlordResponse);
      commit(m.ADD_LOCAL_LANDLORD, landlordResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_LANDLORD_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while creating this Landlord. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeLandlord;
  },

  /**
   * Updates an existing Landlord
   * @param {Object} context
   * @param {object} landlord The {@link Landlord} object to update
   * @param {object} modifiedFields An object with the key/values for the fields to update.
   * @return {Landlord} A {@link Landlord} object.
   */
  async [a.UPDATE_LANDLORD]({ commit, state }, { landlord, modifiedFields }) {
    try {
      commit(m.SET_ACTIVE_LANDLORD_ERRORS, []);
      commit(m.SET_ACTIVE_LANDLORD_STATE, API_STATUS.SAVING);

      const landlordResponse = await LandlLordAPI.updateLandlord(landlord.id, modifiedFields);

      commit(m.SET_ACTIVE_LANDLORD_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_LANDLORD, landlordResponse);
      commit(m.UPDATE_LOCAL_LANDLORD, landlordResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_LANDLORD_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while updating this Landlord. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeLandlord;
  },

  /**
   * Deletes a Landlord
   * @param {Object} context
   * @param {Landlord} landlord The {@link Landlord} object to delete
   * @return {Landlord} A {@link Landlord} object.
   */
  async [a.DELETE_LANDLORD]({ commit }, { landlord }) {
    try {
      commit(m.SET_ACTIVE_LANDLORD_ERRORS, []);
      commit(m.SET_ACTIVE_LANDLORD_STATE, API_STATUS.DELETING);

      await LandlLordAPI.deleteLandlord(landlord.id);

      commit(m.SET_ACTIVE_LANDLORD_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_LANDLORD, null);

      commit(m.REMOVE_LOCAL_LANDLORD, landlord);
    } catch (error) {
      commit(m.SET_ACTIVE_LANDLORD_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while deleting this Landlord. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return true;
  },
};
