export default {
  getters: {},

  actions: {
    GET_TAGS: 'GET_TAGS',
    UPDATE_TAG: 'UPDATE_TAG',
    CREATE_TAG: 'CREATE_TAG',
    DELETE_TAG: 'DELETE_TAG',
  },

  mutations: {
    SET_TAGS: 'SET_TAGS',
    SET_TAGS_STATUS: 'SET_TAGS_STATUS',
    SET_TAGS_META: 'SET_TAGS_META',
    SET_TAGS_FILTER: 'SET_TAGS_FILTER',

    REMOVE_LOCAL_TAG: 'REMOVE_LOCAL_TAG',
    ADD_LOCAL_TAG: 'ADD_LOCAL_TAG',
    UPDATE_LOCAL_TAG: 'UPDATE_LOCAL_TAG',

    SET_ACTIVE_TAG: 'SET_ACTIVE_TAG',
    SET_ACTIVE_TAG_STATE: 'SET_ACTIVE_TAG_STATE',
    SET_ACTIVE_TAG_ERRORS: 'SET_ACTIVE_TAG_ERRORS',
    SET_ACTIVE_TAG_VALID: 'SET_ACTIVE_TAG_VALID',
    UPDATE_ACTIVE_TAG_MODIFIED_FIELDS: 'UPDATE_ACTIVE_TAG_MODIFIED_FIELDS',
    CLEAR_ACTIVE_TAG_MODIFIED_FIELDS: 'CLEAR_ACTIVE_TAG_MODIFIED_FIELDS',
  },
};
