export default {
  getters: {},

  actions: {
    GET_ROLES: 'GET_ROLES',
    UPDATE_ROLE: 'UPDATE_ROLE',
    CREATE_ROLE: 'CREATE_ROLE',
    DELETE_ROLE: 'DELETE_ROLE',
  },

  mutations: {
    SET_ROLES: 'SET_ROLES',
    SET_ROLES_STATUS: 'SET_ROLES_STATUS',
    SET_ROLES_META: 'SET_ROLES_META',
    SET_ROLES_FILTER: 'SET_ROLES_FILTER',

    REMOVE_LOCAL_ROLE: 'REMOVE_LOCAL_ROLE',
    ADD_LOCAL_ROLE: 'ADD_LOCAL_ROLE',
    UPDATE_LOCAL_ROLE: 'UPDATE_LOCAL_ROLE',

    SET_ACTIVE_ROLE: 'SET_ACTIVE_ROLE',
    SET_ACTIVE_ROLE_STATE: 'SET_ACTIVE_ROLE_STATE',
    SET_ACTIVE_ROLE_ERRORS: 'SET_ACTIVE_ROLE_ERRORS',
    SET_ACTIVE_ROLE_VALID: 'SET_ACTIVE_ROLE_VALID',
    UPDATE_ACTIVE_ROLE_MODIFIED_FIELDS: 'UPDATE_ACTIVE_ROLE_MODIFIED_FIELDS',
    CLEAR_ACTIVE_ROLE_MODIFIED_FIELDS: 'CLEAR_ACTIVE_ROLE_MODIFIED_FIELDS',
  },
};
