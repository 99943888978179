import keyBy from 'lodash/keyBy';
import types from '@/store/types/filter.js';

const m = types.mutations;

export default {
  [m.REGISTER_LOCATION_FILTER_OPTIONS_REQUESTED](state, { ids }) {
    state.locationFilterOptionsRequested = {
      ...state.locationFilterOptionsRequested,
      ...Object.fromEntries(ids.map((id) => [id, true])),
    };
  },

  [m.REGISTER_LOCATION_FILTER_OPTIONS](state, { site, options }) {
    const withSite = options.map((o) => ({ ...o, site }));
    const unique = {
      ...keyBy(state.locationFilterOptions, 'id'),
      ...keyBy(withSite, 'id'),
    };
    state.locationFilterOptions = Object.values(unique);
  },
};
