import types from '@/store/types/document.js';
import DocumentAPI from '@/services/DocumentAPI.js';
import API from '@/enums/API.js';
import APIError from '@/errors/APIError.js';

const a = types.actions;
const m = types.mutations;

export default {
  /**
   * Loads all Documents
   * @param {Object} context
   * @param {number} page The page to load. If not supplied will default to 1
   * @param {string} sort
   * @param {string} direction
   * @param {object} filter
   * @return {Document[]} An array of {@link Document} objects.
   */
  async [a.GET_DOCUMENTS](
    { commit, state },
    { page = 1, sort = 'name', direction = 'asc', filter = {} },
  ) {
    commit(m.SET_DOCUMENTS_STATUS, API.LOADING);

    try {
      const { data, meta = {} } = await DocumentAPI.getDocuments(page, sort, direction, filter);

      commit(m.SET_DOCUMENTS_STATUS, API.SUCCESS);
      commit(m.SET_DOCUMENTS, data);
      commit(m.SET_DOCUMENTS_META, meta);
    } catch (error) {
      commit(m.SET_DOCUMENTS_STATUS, API.FAILURE);

      let message = 'An error occurred while fetching these Documents. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return state.documents;
  },

  /**
   * Creates a new Document
   * @param {Object} context
   * @param {object} document A {@link Document} object
   * @return {Document} A {@link Document} object.
   */
  async [a.CREATE_DOCUMENT]({ commit, state }, { document }) {
    try {
      commit(m.SET_ACTIVE_DOCUMENT_ERRORS, []);
      commit(m.SET_ACTIVE_DOCUMENT_STATE, API.SAVING);

      const finalDocument = { ...document };
      if (document.restrict_by_role === false) {
        finalDocument.role_ids = [];
      }

      const documentResponse = await DocumentAPI.createDocument(finalDocument);
      commit(m.SET_ACTIVE_DOCUMENT_STATE, API.SUCCESS);
      commit(m.SET_ACTIVE_DOCUMENT, documentResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_DOCUMENT_STATE, API.FAILURE);

      let message = 'An error occurred while creating this Document. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeDocument;
  },

  /**
   * Updates an existing Document
   * @param {Object} context
   * @param {object} document The {@link Document} object to update
   * @param {object} modifiedFields An object with the key/values for the fields to update.
   * @return {Document} A {@link Document} object.
   */
  async [a.UPDATE_DOCUMENT]({ commit, state }, { document, modifiedFields }) {
    try {
      commit(m.SET_ACTIVE_DOCUMENT_ERRORS, []);
      commit(m.SET_ACTIVE_DOCUMENT_STATE, API.SAVING);

      const finalDocument = { ...modifiedFields };
      if (modifiedFields.restrict_by_role === false) {
        finalDocument.role_ids = [];
      }

      const documentResponse = await DocumentAPI.updateDocument(document.id, finalDocument);

      commit(m.SET_ACTIVE_DOCUMENT_STATE, API.SUCCESS);
      commit(m.SET_ACTIVE_DOCUMENT, documentResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_DOCUMENT_STATE, API.FAILURE);

      let message = 'An error occurred while updating this Document. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeDocument;
  },

  /**
   * Deletes a Document
   * @param {Object} context
   * @param {object} document The {@link Document} object to delete
   * @return {Document} A {@link Document} object.
   */
  async [a.DELETE_DOCUMENT]({ commit }, { document }) {
    try {
      commit(m.SET_ACTIVE_DOCUMENT_ERRORS, []);
      commit(m.SET_ACTIVE_DOCUMENT_STATE, API.DELETING);

      await DocumentAPI.deleteDocument(document.id);

      commit(m.SET_ACTIVE_DOCUMENT_STATE, API.SUCCESS);
      commit(m.SET_ACTIVE_DOCUMENT, null);
    } catch (error) {
      commit(m.SET_ACTIVE_DOCUMENT_STATE, API.FAILURE);

      let message = 'An error occurred while deleting this Document. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return true;
  },
};
