export default {
  path: '/service-requests',
  meta: {
    requiresAuth: true,
    layout: 'DefaultLayout',
    bcLinkText: 'Service Requests',
    storeModules: ['requestForService'],
  },
  component: () => import('@/views/request-for-services/RequestForServices.vue'),
  children: [
    {
      path: ':page(\\d+)?',
      name: 'RequestForServices',
      component: () => import('@/views/request-for-services/RequestForServicesHome.vue'),
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'WorkOrderRequestForService_View',
      },
    },
    {
      path: 'view/:id/:pane?',
      name: 'RequestForServiceDisplay',
      component: () => import('@/views/request-for-services/RequestForServiceDisplay.vue'),
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'WorkOrderRequestForService_View',

        bcDynamic: true,
        bcGetter: 'getActiveRequestForService',
        bcLinkText: (value, $t) => {
          return $t('breadcrumbs.requestForService.rfsForWorkOrder', {
            id: value.work_order.id,
          });
        },
        bcLoadingText: 'Loading Request for Service...',
      },
    },
    {
      path: 'create',
      name: 'RequestForServiceCreate',
      component: () => import('@/views/request-for-services/RequestForServiceCreate.vue'),
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'WorkOrderRequestForService_View',
        bcLinkText: 'Create',
      },
    },
  ],
};
