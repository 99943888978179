<template>
  <div>
    <b-form-file
      :id="`fileUpload${_uid}`"
      ref="file"
      data-ref="file-uploader"
      :accept="accept"
      :multiple="true"
      style="display: none"
      :disabled="saving"
      @input="emitUploadEvent"
    />

    <b-button
      :disabled="saving"
      variant="outline-secondary"
      class="upload-button"
      @click="$refs.file.$refs.input.click()"
    >
      <font-awesome-icon class="mr-2" :icon="['far', 'camera']" />
      <span :key="addLabel">{{ addLabel }}</span>
      <span class="sr-only">
        {{ $t('views.work-orders.WorkOrderDisplay.files.screenReader.buttonUpload') }}</span
      >
    </b-button>

    <div id="file-drag-drop">
      <div
        :ref="`fileform${_uid}`"
        class="drop-zone"
        @dragover="applyDragColour"
        @dragleave="removeDragColour"
        @drop="removeDragColour"
      >
        <slot>
          <label :for="`fileUpload${_uid}`">
            <div class="info-container">
              <span class="icon-size">
                <i class="far fa-file-upload" />
              </span>
              <h6>
                {{ $t('views.work-orders.WorkOrderDisplay.files.dragUpload') }}
              </h6>
              <p :key="maxFileSizeMb + maxUploadSizeUnit" class="small-font">
                {{ computedInstructions }}. Max size per file is {{ maxFileSizeMb }} {{ maxUploadSizeUnit }}.
              </p>
            </div>
          </label>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { BFormFile, BButton } from 'bootstrap-vue';
import bytesToFormattedSize from '@/util/bytesToFormattedSize.js';

export default {
  name: 'FileDropbox',
  components: { BFormFile, BButton },
  props: {
    saving: {
      default: false,
      type: Boolean,
    },
    addLabel: {
      default() {
      return this.$t('components.attachment.AttachmentList.uploadPhotoOrVideo');
      },
      type: String,
    },
    instructions: {
      type: String,
      default: null,
    },
    accept: {
      type: String,
      default: [
        'image/*',
        'video/*',
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/csv',
      ].join(),
    },
  },
  computed: {
    maxFileSizeKb() {
      return window.appConfig?.MAX_FILE_UPLOAD_SIZE.value ??
        window.appConfig?.MAX_FILE_UPLOAD_SIZE.default_value ?? 104857600;
    },
    maxFileSizeMb() {
      return bytesToFormattedSize(this.maxFileSizeKb, 0).size;
    },
    maxUploadSizeUnit() {
      return bytesToFormattedSize(this.maxFileSizeKb).unit;
    },
    computedInstructions() {
      return this.instructions ?? 'You can upload PDF, Word & Excel documents and images & videos';
    },
  },
  mounted() {
    this.dragAndDropCapable = this.determineDragAndDropCapable();
    if (this.dragAndDropCapable) {
      ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach(
        (evt) => {
          this.$refs[`fileform${this._uid}`].addEventListener(
            evt,
            (e) => {
              e.preventDefault();
              e.stopPropagation();
            },
            false,
          );
        },
      );

      this.$refs[`fileform${this._uid}`].addEventListener('drop', (e) => {
        this.emitUploadEvent(e.dataTransfer.files);
      });
    }
  },
  methods: {
    applyDragColour() {
      const ref = this.$refs[`fileform${this._uid}`];
      ref.style.backgroundColor = 'lightyellow';
    },
    emitUploadEvent(files) {
      this.$emit('upload-files', files);
    },
    removeDragColour() {
      const ref = this.$refs[`fileform${this._uid}`];
      ref.style.backgroundColor = null;
    },
    determineDragAndDropCapable() {
      const div = document.createElement('div');
      return (
        ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) &&
        'FormData' in window &&
        'FileReader' in window
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.drop-zone {
  height: 10rem;
  display: flex;
  justify-content: center;
  width: 100%;
  border-style: dashed;
  border-width: 0.1rem;
  border-color: lightgrey;
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-align: center;
  border-radius: 0.5rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  align-items: center;
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.upload-button {
  width: 100%;
  @include media-breakpoint-up(lg) {
    display: none;
  }
}

.icon-size {
  font-size: 3rem;
}

.small-font {
  font-size: 0.75rem;
}

.info-container {
  color: grey;
  margin-top: 1rem;
}
</style>
