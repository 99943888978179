export default {
  path: '/contractor',
  meta: {
    requiresAuth: true,
    layout: 'DefaultLayout',
    permission: 'Contractor_Manage',
    bcLinkText: 'Contractors',
    storeModules: [
      'contractor',
      'country',
      'site',
      'workOrderType',
      'servicePriority',
      'serviceType',
      'document',
      'documentType',
    ],
  },
  component: () => import('@/views/contractors/Contractors.vue'),
  children: [
    {
      path: ':page(\\d+)?',
      name: 'Contractors',
      component: () => import('@/views/contractors/ContractorsHome.vue'),
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'Contractor_Manage',
      },
    },
    {
      path: 'view/:id/:pane?',
      name: 'ContractorDisplay',
      component: () => import('@/views/contractors/ContractorDisplay.vue'),
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'Contractor_Manage',
        bcDynamic: true,
        bcGetter: 'getActiveContractor',
        bcLinkText: (contractor) => contractor?.name,
        bcLoadingText: 'Loading Contractor...',
        translate: false,
      },
    },
    {
      path: 'create',
      name: 'ContractorCreate',
      component: () => import('@/views/contractors/ContractorCreate.vue'),
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        permission: 'Contractor_Manage',
        bcLinkText: 'Create',
      },
    },
  ],
};
