import types from '../../types/dashboard.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_DASHBOARDS_SORT
   * @summary Updates the `sort` and `direction` properties
   * @method
   * @param {Object} state Current state
   * @param {Object} data The sort data (`sort` and `direction`)
   */
  [m.SET_DASHBOARDS_SORT](state, { sort, direction }) {
    state.sort = sort;
    state.sortDirection = direction;
  },

  /**
   * @name m SET_DASHBOARDS_FILTER
   * @summary Updates the `filter` property which holds the collection of filters currently active
   * on the Dashboards collection
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The filters to be applied
   */
  [m.SET_DASHBOARDS_FILTER](state, filter) {
    state.filter = filter;
  },

  /**
   * @name m SET_ACTIVE_DASHBOARD
   * @summary Updates the `activeDashboard` and resets the
   * `activeDashboardModifiedFields` & `activeDashboardErrors` properties
   * @method
   * @param {Object} state Current state
   * @param {Dashboard} dashboard The new Active {@link Dashboard}
   */
  [m.SET_ACTIVE_DASHBOARD](state, dashboard) {
    state.activeDashboard = dashboard;
    state.activeDashboardModifiedFields = !dashboard || dashboard.id ? {} : { ...dashboard };
    state.activeDashboardErrors = [];
  },
  /**
   * /**
   * @name m SET_EDIT_DASHBOARD
   * @summary Updates the `editDashboard` and resets the
   * `activeDashboardModifiedFields` & `activeDashboardErrors` properties
   * @method
   * @param {Object} state Current state
   * @param {Dashboard} dashboard The new Active {@link Dashboard}
   */
  [m.SET_EDIT_DASHBOARD](state, dashboard) {
    state.editDashboard = dashboard;
    state.activeDashboardModifiedFields = !dashboard || dashboard.id ? {} : { ...dashboard };
    state.activeDashboardErrors = [];
  },
  /**
   * @name m SET_ACTIVE_DASHBOARD_STATE
   * @summary Updates the `activeDashboardState` property which holds the current loading status of
   * the active dashboard based on the API enum
   * @method
   * @param {Object} state Current state
   * @param {enums/API} activeDashboardState The current state (e.g. loading, success, failure)
   */
  [m.SET_ACTIVE_DASHBOARD_STATE](state, activeDashboardState) {
    state.activeDashboardState = activeDashboardState;
  },

  /**
   * @name m SET_ACTIVE_DASHBOARD_ERRORS
   * @summary Updates the `activeDashboardErrors` property which holds any errors related to
   * activeDashboard
   * @method
   * @param {Object} state Current state
   * @param {string[]} errors An array of error messages
   */
  [m.SET_ACTIVE_DASHBOARD_ERRORS](state, errors) {
    state.activeDashboardErrors = errors;
  },

  /**
   * @name m SET_ACTIVE_DASHBOARD_VALID
   * @summary Updates the `activeDashboardValid` property which holds a boolean for the validation
   * status of the `activeDashboard`.
   * @method
   * @param {Object} state Current state
   * @param {boolean} valid True if the `activeDashboard` is valid.
   */
  [m.SET_ACTIVE_DASHBOARD_VALID](state, valid) {
    state.activeDashboardValid = valid;
  },

  /**
   * @name m UPDATE_ACTIVE_DASHBOARD_MODIFIED_FIELDS
   * @summary Adds the given field/value pair to the `activeDashboardModifiedFields` collection,
   * which will be sent as a PATCH to the API. If the given `value` equals the one on the
   * `activeDashboard` object (i.e. it hasn't actually changed, or has been reverted back to it's
   * original) then we remove that field from the modifiedFields collection
   * @method
   * @param {Object} state Current state
   * @param {object} payload Object containing a `field` and `value` properties.
   */
  [m.UPDATE_ACTIVE_DASHBOARD_MODIFIED_FIELDS](state, { field, value }) {
    const unchanged =
      !state.activeDashboard ||
      state.activeDashboard[field] === value ||
      (state.activeDashboard[field] === null && value === '');
    // if the value matches the existing one then we remove the field from the modifiedFields obj
    if (unchanged) {
      delete state.activeDashboardModifiedFields[field];
    } else {
      state.activeDashboardModifiedFields[field] = value;
    }
  },

  /**
   * @name m CLEAR_ACTIVE_DASHBOARD_MODIFIED_FIELDS
   * @summary Empties the `activeDashboardModifiedFields` object. Used to ensure we're on a clean
   * slate after navigating to a Dashboard Display screen,
   * @method
   * @param {Object} state Current state
   */
  [m.CLEAR_ACTIVE_DASHBOARD_MODIFIED_FIELDS](state) {
    state.activeDashboardModifiedFields = {};
  },

  /**
   * @name m CLEAR_EDIT_DASHBOARD_STATE
   * @summary Clears the edit dashboard state so changes don't persist once we press cancel
   * @method
   * @param {Object} state Current state
   */
  [m.CLEAR_EDIT_DASHBOARD_STATE](state) {
    state.editDashboard = {};
  },
};
