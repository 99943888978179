<template>
  <div :class="{ 'list-wrapper': true, searchable: allowSearch }">
    <div v-if="allowSearch" class="bg-light p-3">
      <b-form-group
        :label="$t('common.forms.search', { label: $t('common.forms.all') })"
        :label-sr-only="true"
        :label-for="`list-search-input`"
        class="m-0"
      >
        <b-input-group>
          <b-form-input
            :id="`list-search-input`"
            v-model.lazy="search"
            :placeholder="$t('common.searchPlaceholder')"
            trim
          />
          <b-input-group-append>
            <b-button variant="secondary" @click="search = ''">
              <font-awesome-icon :icon="['far', 'times']" />&nbsp;<!--
              --><span class="sr-only">{{ $t('common.forms.clear') }}</span>
            </b-button>
          </b-input-group-append>
        </b-input-group>
      </b-form-group>
    </div>

    <div v-if="hasSearch" class="list">
      <ListSearchCard
        :items="searchItems"
        display-field="searchDisplayName"
        @nodeclicked="onNodeClick"
      />
    </div>

    <div v-if="!hasSearch">
      <TransitionSlide
        :from-right="direction === 'forward' && enteringList === 'listOne'"
        :to-right="direction === 'back' && enteringList !== 'listOne'"
        :from-left="direction === 'back' && enteringList === 'listOne'"
        :to-left="direction == 'forward' && enteringList !== 'listOne'"
        @afterenter="onAfterEnter('listTwo')"
        @afterleave="onAfterLeave('listOne')"
      >
        <div v-if="enteringList === 'listOne'" class="list">
          <ListCard
            :node="listOne"
            :parent-select="parentSelect"
            :root-select="rootSelect"
            :children-field="childrenField"
            :level="path.length + 1"
            @nodeclicked="onNodeClick"
          />
        </div>
      </TransitionSlide>
      <TransitionSlide
        :from-right="direction === 'forward' && enteringList === 'listTwo'"
        :to-right="direction === 'back' && enteringList !== 'listTwo'"
        :from-left="direction === 'back' && enteringList === 'listTwo'"
        :to-left="direction == 'forward' && enteringList !== 'listTwo'"
        @afterenter="onAfterEnter('listOne')"
        @afterleave="onAfterLeave('listTwo')"
      >
        <div v-if="enteringList === 'listTwo'" class="list">
          <ListCard
            :node="listTwo"
            :parent-select="parentSelect"
            :root-select="rootSelect"
            :children-field="childrenField"
            :level="path.length + 1"
            @nodeclicked="onNodeClick"
          />
        </div>
      </TransitionSlide>
    </div>
  </div>
</template>

<script>
import { BFormGroup, BFormInput, BInputGroup, BButton, BInputGroupAppend } from 'bootstrap-vue';
import TransitionSlide from '@/ux/animations/TransitionSlide.vue';
import ListCard from '@/ux/form/list/ListCard.vue';
import ListSearchCard from '@/ux/form/list/ListSearchCard.vue';

/**
 * This component is a 'drilldown list' collection where the tree of items
 * can be navigated and a leaf node selected.
 * It works by holding two lists which are populated as a navigation event happens. They alternate
 * being the entering list (i.e. if list one is visible then list two is populated
 * and shown and vice versa). The direction of the animation is determined by the `direction`
 * property and the list that is entering.
 * @exports src/ux/list/List
 * @property node {object} The root node of the tree
 * @property {string} valueField The field of the items to use as the value. Defaults to `id`
 * @property {string} displayField The field of the items to use for display
 * @property {boolean} parentSelect If true this allows users to select a 'folder' (via
 * a 'This Level' list option), as well as selecting a leaf. If false, only a leaf can be selected.
 */
export default {
  name: 'List',

  components: {
    'b-form-group': BFormGroup,
    'b-form-input': BFormInput,
    'b-input-group': BInputGroup,
    'b-button': BButton,
    'b-input-group-append': BInputGroupAppend,

    TransitionSlide,
    ListCard,
    ListSearchCard,
  },

  props: {
    node: {
      type: Object,
      default: () => ({ children: [] }),
    },

    valueField: {
      type: String,
      default: () => 'id',
    },

    displayField: {
      type: String,
      default: () => 'name',
    },

    parentSelect: {
      type: Boolean,
      default: false,
    },

    rootSelect: {
      type: Boolean,
      default: false,
    },

    childrenField: {
      type: [String, Function],
      default: () => 'children',
    },

    allowSearch: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      // holds the item node that each list is currently displaying
      listOne: null,
      listTwo: null,

      // nodes an array of nodes we've clicked through to reach the active one
      path: [],

      // indicates the direction we're moving. used to set animations correctly
      direction: '',

      // indicates the node that is _entering_
      enteringList: 'listOne',

      search: '',
      searchItems: [],
    };
  },

  computed: {
    hasSearch() {
      return !!this.search;
    },
  },

  watch: {
    listOne(val) {
      if (val) {
        this.$emit('nodechange', val);
      }
    },
    listTwo(val) {
      if (val) {
        this.$emit('nodechange', val);
      }
    },
    search(search) {
      let matches = [];

      // if there's a search term then we do a search
      if (this.hasSearch) {
        // returns an array of arrays, each with all nodes that lead to the leaf
        matches = this.findNodeMatches(this.node, search);

        // build the combined name for each match, and create a new object based on the leaf node's data
        matches = matches
          .map((match) => ({
            ...match[match.length - 1],
            searchDisplayName: match.map((node) => node[this.displayField]).join(' / '),
          }))
          .sort((a, b) => a.searchDisplayName.localeCompare(b.searchDisplayName));
      }

      this.searchItems = matches;

      this.$emit('search-change', this.hasSearch, this.searchItems.length);
    },

    // if allowSearch is turned off then we reset the search
    allowSearch(value) {
      if (!value) {
        this.seach = '';
      }
    },
  },

  created() {
    this.listOne = this.node;
  },

  methods: {
    /**
     * @summary [PUBLIC] This resets the list back to its neutral setup.
     * @desc Reverts back to the original `node` prop.
     * @method
     */
    resetList() {
      this.enteringList = 'listOne';
      this.listOne = this.node;
      this.listTwo = null;
      this.path = [];
      this.direction = '';
    },

    /**
     * @summary [PUBLIC] Move the list back a step.
     * @desc
     * @method
     * @return {boolean} True if a back action was done, or false it is at the root.
     */
    back() {
      if (this.path.length > 0) {
        this.direction = 'back';

        // if listOne is populated then it means it's on screen, so we use listTwo to hold the
        // entering list
        const enteringList = this.listOne ? 'listTwo' : 'listOne';

        this[enteringList] = this.path.pop();
        this.enteringList = enteringList;

        return true;
      }

      return false;
    },

    /**
     * @summary [PUBLIC] Reset the list - cancels search
     * @desc
     * @method
     */
    reset() {
      this.search = '';
    },

    /**
     * Handler for a click on a node with children.
     * This will populate the unused list with the new data and show it.
     * @param  {object} node The node to show
     */
    onNodeClick(node, select) {
      if (this.getChildren(node).length > 0 && !select) {
        this.direction = 'forward';

        const enteringList = this.listOne ? 'listTwo' : 'listOne';
        const leavingList = this.listOne || this.listTwo;

        this.path.push(leavingList);

        this[enteringList] = node;
        this.enteringList = enteringList;

        this.$emit('nodeclicked', node);
      } else {
        this.onNodeSelect(node);
      }
    },

    getChildren(node) {
      return (
        (typeof this.childrenField === 'function'
          ? this.childrenField(node)
          : node[this.childrenField || 'children']) || []
      );
    },

    /**
     * Handler for when a leaf node is clicked so we can update the underlying value
     * @param  {object} node The node to select
     */
    onNodeSelect(node) {
      this.innerValue = node[this.valueField];

      this.$emit('nodeselected', node);
    },

    /**
     * Handler for an afterenter animation event, we use it to empty the exiting
     * list so it can be reused.
     * @param  {string} list Name of the list to empty
     */
    onAfterEnter(list) {
      this[list] = null;
    },

    /**
     * Handler for an afterleave animation event, we use it to empty the exiting
     * list so it can be reused.
     * @param  {string} list Name of the list to empty
     */
    onAfterLeave(list) {
      this[list] = null;
    },

    /**
     * Recursively looks down the tree for nodes matching the search term passed in.
     * @param  {Object} node         The node to check for a match, and to recurse through children
     * @param  {String} search       The search string to match
     * @param  {Boolean} forceInclude If a parent node matches the search then we want to include all
     * it's children in the resultset, so we use this boolean to force them in, even if their name's don't match.
     * @return {Array[]}              The results will be an array of arrays, with the path of nodes to the matching leaf included.
     */
    findNodeMatches(node, search, forceInclude) {
      const children = this.getChildren(node);
      let matches = [];

      // does the search string match the node's display field
      const isMatch =
        (node[this.displayField] || '').toLowerCase().indexOf(search.toLowerCase()) >= 0;

      // if we're at a leaf node (or have parentSelect on) and it's a match, or we want to force the node into the results
      // then we add it to the result set.
      if ((children.length === 0 || this.parentSelect) && (isMatch || forceInclude) && !node.read_only) {
        // we add it as an array because we want to collect all the nodes that lead to this one in the tree structure
        matches.push([node]);
      }

      // loop through all children and try to find more matches
      for (let i = 0; i < children.length; i += 1) {
        // we recurse down into each child node. we pass `isMatch` in to force any children to
        // also be included in the result set.
        const childMatches = this.findNodeMatches(children[i], search, isMatch);

        // we want to exclude the root node from the 'path' as it is just a placeholder
        if (node !== this.node) {
          // add the current node (i.e. the match's parent) to the start of the match's path array
          childMatches.forEach((match) => {
            match.unshift(node);
          });
        }

        // combine with other matches
        matches = matches.concat(childMatches);
      }

      return matches;
    },
  },
};
</script>

<style lang="scss" scoped>
.list-wrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-x: hidden;

  .list {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }

  &.searchable {
    .list {
      top: 70px;
      border-top: 1px solid #dee2e6;
    }
  }
}
</style>
<style lang="scss"></style>
