import axios from 'axios';
import createAPIParams from '@/util/createAPIParams.js';
import createFormData from '@/util/createFormData.js';

/**
 * Interface to the WorkOrderRequestType API endpoints
 * @class
 * @exports src/services/WorkOrderRequestTypeAPI
 */
class WorkOrderRequestTypeAPI {
  /**
   * Retrieves WorkOrderRequestTypes
   * @param {Number} page The page to load
   * @param {String} sort The field to sort on
   * @param {String} direction The sort direction. Either 'ASC' or 'DESC'. Defaults to ASC.
   * @param {Object} filter An object with key value pairs of filter values.
   * @return {Promise} Promise that resolves to the api response's data
   */
  static async getWorkOrderRequestTypes(page, sort, direction, filter) {
    const response = await axios({
      method: 'GET',
      url: 'work_order_request_type',
      responseType: 'json',
      params: createAPIParams(page, sort, direction, filter),
    });

    return response.data.data;
  }

  /**
   * @summary Hits the create WO Request Type endpoint.
   * @desc Sends the given work order request type data to the create endpoint
   * @param  {WorkOrderRequestType}  requestType A {@link WorkOrderRequestType} object to be saved
   * @return {Promise}      Resolves to the returned full WorkOrderRequestType object
   */
  static async createWorkOrderRequestType(requestType) {
    const response = await axios({
      method: 'POST',
      url: 'work_order_request_type',
      responseType: 'json',
      data: createFormData(requestType),
    });

    return response.data.data;
  }

  /**
   * @summary Hits the PATCH WorkOrderRequestType endpoint.
   * @desc Sends the specified _modified_ fields to the endpoint to be saved.
   * @param  {String|Number}  workOrderRequestTypeId The WorkOrderRequestType Id to save
   * @param  {object}  modifiedFields An object with a subset of the
   * {@link WorkOrderRequestType} fields that have been modified and should be saved.
   * @return {Promise}      Resolves to the returned full WorkOrderRequestType object
   */
  static async updateWorkOrderRequestType(workOrderRequestTypeId, modifiedFields) {
    // eslint-disable-line max-len
    const { media, ...noMedia } = modifiedFields;
    const response = await axios({
      method: 'PATCH',
      url: `work_order_request_type/${workOrderRequestTypeId}`,
      responseType: 'json',
      data: noMedia,
    });

    if (media.length) {
      return this.addMedia(workOrderRequestTypeId, media);
    }

    return response.data.data;
  }

  /**
   * Adds media to a WorkOrderRequestType 
   * @method
   * @static
   * @param  {number}  workOrderRequestTypeId The work order request to update
   * @param  {number}  media The media to upload
   * @return {Promise} Promise that resolves to the {@link WorkOrderRequestType} details.
   */
  static async addMedia(workOrderRequestTypeId, media) {
    // eslint-disable-line max-len
    const response = await axios({
      method: 'POST',
      url: `work_order_request_type/${workOrderRequestTypeId}/media`,
      responseType: 'json',
      data: createFormData({
        media: media.filter((f) => f instanceof File),
      }),
    });

    return response.data.data;
  }

  /**
   * @summary Hits the DELETE WorkOrderRequestType endpoint.
   * @param  {String|Number}  workOrderRequestTypeId The WorkOrderRequestType Id to delete
   * @return {Promise}
   */
  static async deleteWorkOrderRequestType(workOrderRequestTypeId) {
    await axios({
      method: 'DELETE',
      url: `work_order_request_type/${workOrderRequestTypeId}`,
      responseType: 'json',
    });

    return true;
  }
}

export default WorkOrderRequestTypeAPI;
