import Base from './Base.js';

export default function CustomFieldFieldTypeCreator(i18n = null) {
  class CustomFieldFieldType extends Base {}

  CustomFieldFieldType.initEnum({
    TEXT: {
      value: 'TEXT',
      display: 'Text',
    },
    NUMBER: {
      value: 'NUMBER',
      display: 'Number',
    },
    DATE: {
      value: 'DATE',
      display: 'Date',
    },
    SELECT: {
      value: 'SELECT',
      display: 'Select',
    },
  });

  return CustomFieldFieldType;
}
