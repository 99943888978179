/**
 * A Role definition
 * @typedef {Object} Role
 * @property {number} id -
 * @property {string} name -
 * @property {string[]} permissions -
 * @property {string} created_at -
 * @property {string} updated_at -
 */

export default {
  roles: [],
  rolesStatus: null,

  filter: {},

  meta: {},

  activeRole: null,
  activeRoleState: null,
  activeRoleErrors: [],
  activeRoleValid: false,
  activeRoleModifiedFields: {},
};
