import axios from 'axios';

/**
 * A ConditionGrade definition
 * @typedef {Object} ConditionGrade
 * @property {number} id -
 * @property {string} name -
 */

/**
 * Interface to the ConditionGrade API endpoints
 * @class
 * @exports src/services/ConditionGradeAPI
 */
class ConditionGradeAPI {
  /**
   * Retrieves all the Countries
   * @method
   * @static
   * @return {Promise}          Promise that resolves to an aray of {@link ConditionGrade} items.
   */
  static async getConditionGrades() {
    const response = await axios({
      method: 'GET',
      url: 'condition_grade',
      responseType: 'json',
    });

    return response.data.data;
  }
}

export default ConditionGradeAPI;
