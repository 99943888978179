/**
 * A Tag definition
 * @typedef {Object} Tag
 * @property {number} id -
 * @property {string} name
 * @property {int} tagType
 * @property {string} color
 */

export default {
  tags: [],
  tagsState: null,

  filter: {},

  meta: {},

  activeTag: null,
  activeTagState: null,
  activeTagErrors: [],
  activeTagValid: false,
  activeTagModifiedFields: {},
};
