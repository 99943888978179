import APIError from '../../../errors/APIError.js';
import types from '../../types/recurringWorkOrders.js';
import RecurringWorkOrderAPI from '../../../services/RecurringWorkOrderAPI.js';
import ApiStatusCreator from '../../../enums/API.js';
const API_STATUS = ApiStatusCreator();

const a = types.actions;
const m = types.mutations;

export default {
  /**
   * Loads specific RecurringWorkOrder details
   * @param {Object} context
   * @param {number} id The RecurringWorkOrder ID to load
   * @return {RecurringWorkOrder} A {@link RecurringWorkOrder} object.
   */
  async [a.GET_RECURRING_WORK_ORDER]({ commit, state }, { id }) {
    try {
      commit(m.SET_ACTIVE_RECURRING_WORK_ORDER, null);
      commit(m.SET_ACTIVE_RECURRING_WORK_ORDER_STATE, API_STATUS.LOADING);

      const recurringWorkOrder = await RecurringWorkOrderAPI.getRecurringWorkOrder(id);

      recurringWorkOrder.created_at = new Date(recurringWorkOrder.created_at);
      recurringWorkOrder.updated_at = new Date(recurringWorkOrder.updated_at);

      commit(m.SET_ACTIVE_RECURRING_WORK_ORDER, recurringWorkOrder);
      commit(m.SET_ACTIVE_RECURRING_WORK_ORDER_STATE, API_STATUS.SUCCESS);
    } catch (error) {
      commit(m.SET_ACTIVE_RECURRING_WORK_ORDER_STATE, API_STATUS.FAILURE);
      let message = 'An error occurred while fetching this Recurring Work Order. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return state.recurringWorkOrders;
  },

  /**
   * Creates a new RecurringWorkOrder
   * @param {Object} context
   * @param {object} recurringWorkOrder A {@link RecurringWorkOrder} object
   * @return {RecurringWorkOrder} A {@link RecurringWorkOrder} object.
   */
  async [a.CREATE_RECURRING_WORK_ORDER]({ commit, state }, { recurringWorkOrder }) {
    try {
      commit(m.SET_ACTIVE_RECURRING_WORK_ORDER_ERRORS, []);
      commit(m.SET_ACTIVE_RECURRING_WORK_ORDER_STATE, API_STATUS.SAVING);

      const recurringWorkOrderResponse = await RecurringWorkOrderAPI.createRecurringWorkOrder(
        recurringWorkOrder,
      );

      commit(m.SET_ACTIVE_RECURRING_WORK_ORDER_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_RECURRING_WORK_ORDER, recurringWorkOrderResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_RECURRING_WORK_ORDER_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while creating this Recurring Work Order. Please try again.'

      if (error?.response?.data?.errors) {
        message = error.response.data.errors;
      } else if (error?.response?.data?.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message ?? message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeRecurringWorkOrder;
  },

  /**
   * Updates an existing RecurringWorkOrder
   * @param {Object} context
   * @param {object} recurringWorkOrder The {@link RecurringWorkOrder} object to update
   * @param {object} modifiedFields An object with the key/values for the fields to update.
   * @return {RecurringWorkOrder} A {@link RecurringWorkOrder} object.
   */
  async [a.UPDATE_RECURRING_WORK_ORDER]({ commit, state }, { recurringWorkOrder, modifiedFields }) {
    try {
      commit(m.SET_ACTIVE_RECURRING_WORK_ORDER_ERRORS, []);
      commit(m.SET_ACTIVE_RECURRING_WORK_ORDER_STATE, API_STATUS.SAVING);

      const recurringWorkOrderResponse = await RecurringWorkOrderAPI.updateRecurringWorkOrder(
        recurringWorkOrder.id,
        modifiedFields,
      );

      commit(m.SET_ACTIVE_RECURRING_WORK_ORDER_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_RECURRING_WORK_ORDER, recurringWorkOrderResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_RECURRING_WORK_ORDER_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while updating this Recurring Work Order. Please try again.'

      if (error.response && error.response.data && error.response.data.errors) {
        message = error.response.data.errors;
      } else if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeRecurringWorkOrder;
  },

  /**
   * Deletes a RecurringWorkOrder
   * @param {Object} context
   * @param {object} recurringWorkOrder The {@link RecurringWorkOrder} object to delete
   * @return {RecurringWorkOrder} A {@link RecurringWorkOrder} object.
   */
  async [a.DELETE_RECURRING_WORK_ORDER]({ commit }, { recurringWorkOrder }) {
    try {
      commit(m.SET_ACTIVE_RECURRING_WORK_ORDER_ERRORS, []);
      commit(m.SET_ACTIVE_RECURRING_WORK_ORDER_STATE, API_STATUS.DELETING);

      await RecurringWorkOrderAPI.deleteRecurringWorkOrder(recurringWorkOrder.id);

      commit(m.SET_ACTIVE_RECURRING_WORK_ORDER_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_RECURRING_WORK_ORDER, null);
    } catch (error) {
      commit(m.SET_ACTIVE_RECURRING_WORK_ORDER_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while deleting this Recurring Work Order. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return true;
  },
};
