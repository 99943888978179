import Base from './Base.js';

export default function CostItemTypeCreator(i18n) {
  class CostItemType extends Base {}

  CostItemType.initEnum({
    CALLOUT: {
      value: 'Callout',
      display: i18n.global.t('enums.CostItemType.callout'),
    },
    LABOUR: {
      value: 'Labour',
      display: i18n.global.t('enums.CostItemType.labour'),
    },
    PARTS: {
      value: 'Parts',
      display: i18n.global.t('enums.CostItemType.parts'),
    },
    TRAVEL: {
      value: 'Travel',
      display: i18n.global.t('enums.CostItemType.travel'),
    },
    OTHER: {
      value: 'Other',
      display: i18n.global.t('enums.CostItemType.other'),
    },
  });

  return CostItemType;
}
