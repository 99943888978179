import types from '../../types/costRequest.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_COST_REQUESTS_FILTER
   * @summary Updates the `filter` property which holds the collection of filters currently active
   * on the COST_REQUESTS collection
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The filters to be applied
   */
  [m.SET_COST_REQUESTS_FILTER](state, filter) {
    state.filter = filter;
  },

  /**
   * @name m SET_COST_REQUESTS_SORT
   * @summary Updates the `sort` and `direction` properties
   * @method
   * @param {Object} state Current state
   * @param {Object} data The sort data (`sort` and `direction`)
   */
  [m.SET_COST_REQUESTS_SORT](state, { sort, direction }) {
    state.sort = sort;
    state.sortDirection = direction;
  },

  /**
   * @name m SET_ACTIVE_COST_REQUEST
   * @summary Updates the `activeCostRequest` and resets the
   * `activeCostRequestModifiedFields` & `activeCostRequestErrors` properties
   * @method
   * @param {Object} state Current state
   * @param {CostRequest} costRequest The new Active {@link CostRequest}
   */
  [m.SET_ACTIVE_COST_REQUEST](state, costRequest) {
    state.activeCostRequest = costRequest;
    state.activeCostRequestModifiedFields =
      !costRequest || costRequest.id ? {} : { ...costRequest };
    state.activeCostRequestErrors = [];
  },

  /**
   * @name m SET_ACTIVE_COST_REQUEST_STATE
   * @summary Updates the `activeCostRequestState` property
   * which holds the current status of the
   * based on the API enum
   * @method
   * @param {Object} state Current state
   * @param {enums/API} activeCostRequestState The current activeCostRequestState
   * (e.g. loading, success, failure)
   */
  [m.SET_ACTIVE_COST_REQUEST_STATE](state, activeCostRequestState) {
    state.activeCostRequestState = activeCostRequestState;
  },

  /**
   * @name m SET_ACTIVE_COST_REQUEST_ERRORS
   * @summary Updates the `activeCostRequestErrors` property which holds any errors
   * related to activeCostRequest
   * @method
   * @param {Object} state Current state
   * @param {string[]} errors An array of error messages
   */
  [m.SET_ACTIVE_COST_REQUEST_ERRORS](state, errors) {
    if (Array.isArray(errors)) {
      state.activeCostRequestErrors = errors;
    } else {
      state.activeCostRequestErrors.push(errors);
    }
  },

  /**
   * @name m SET_ACTIVE_COST_REQUEST_VALID
   * @summary Updates the `activeCostRequestValid` property which holds a boolean
   * for the validation status of the `activeCostRequest`.
   * @method
   * @param {Object} state Current state
   * @param {boolean} valid True if the `activeCostRequest` is valid.
   */
  [m.SET_ACTIVE_COST_REQUEST_VALID](state, valid) {
    state.activeCostRequestValid = valid;
  },

  /**
   * @name m UPDATE_ACTIVE_COST_REQUEST_MODIFIED_FIELDS
   * @summary Adds the given field/value pair to the
   * `activeCostRequestModifiedFields` collection, which
   * will be sent as a PATCH to the API. If the given `value` equals the one on the
   * `activeCostRequest`
   * object (i.e. it hasn't actually changed, or has been reverted back to it's original) then we
   * remove that field from the modifiedFields collection
   * @method
   * @param {Object} state Current state
   * @param {object} payload Object containing a `field` and `value` properties.
   */
  [m.UPDATE_ACTIVE_COST_REQUEST_MODIFIED_FIELDS](state, { field, value }) {
    // if the value matches the existing one then we remove the field from the modifiedFields obj
    if (state.activeCostRequest && state.activeCostRequest[field] === value) {
      delete state.activeCostRequestModifiedFields[field];
    } else {
      state.activeCostRequestModifiedFields[field] = value;
    }
  },

  /**
   * @name m CLEAR_ACTIVE_COST_REQUEST_MODIFIED_FIELDS
   * @summary Empties the `activeCostRequestModifiedFields` object.
   * Used to ensure we're on a clean slate
   * after navigating to a CostRequest Display screen,
   * @method
   * @param {Object} state Current state
   */
  [m.CLEAR_ACTIVE_COST_REQUEST_MODIFIED_FIELDS](state) {
    state.activeCostRequestModifiedFields = {};
  },
};
