import types from '@/store/types/conditionGrade.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_CONDITION_GRADES
   * @summary Updates the `conditionGrades` property.
   * @method
   * @param {Object} state Current state
   * @param {ConditionGrade[]} conditionGrades The loaded Service Types array
   */
  [m.SET_CONDITION_GRADES](state, conditionGrades) {
    state.conditionGrades = conditionGrades;
  },

  [m.SET_CONDITION_GRADES_STATUS](state, status) {
    state.conditionGradesState = status;
  },
};
