import types from '../../types/document.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_DOCUMENTS
   * @summary Updates the `documents` property.
   * @method
   * @param {Object} state Current state
   * @param {Document[]} documents The loaded Documents array
   */
  [m.SET_DOCUMENTS](state, documents) {
    state.documents = documents;
  },

  [m.SET_DOCUMENTS_STATUS](state, status) {
    state.documentsStatus = status;
  },

  /**
   * @name m SET_DOCUMENTS_META
   * @summary Updates the `meta` property which holds the meta data for the `DOCUMENTS` collection
   * For example, total rows, current page, number rows per page etc
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The meta data to store
   */
  [m.SET_DOCUMENTS_META](state, meta) {
    state.meta = meta;
  },

  /**
   * @name m SET_DOCUMENTS_FILTER
   * @summary Updates the `filter` property which holds the collection of filters currently active
   * on the Currencies collection
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The filters to be applied
   */
  [m.SET_DOCUMENTS_FILTER](state, filter) {
    state.filter = filter;
  },

  /**
   * @name m SET_ACTIVE_DOCUMENT
   * @summary Updates the `activeDocument` and resets the
   * `activeDocumentModifiedFields` & `activeDocumentErrors` properties
   * @method
   * @param {Object} state Current state
   * @param {Document} document The new Active {@link Document}
   */
  [m.SET_ACTIVE_DOCUMENT](state, document) {
    state.activeDocument = document;
    state.activeDocumentModifiedFields = !document || document.id ? {} : { ...document };
    state.activeDocumentErrors = [];
  },

  /**
   * @name m SET_ACTIVE_DOCUMENT_STATE
   * @summary Updates the `activeDocumentState` property which holds the current status of the
   * based on the API enum
   * @method
   * @param {Object} state Current state
   * @param {enums/API} activeDocumentState The current activeDocumentState
   * (e.g. loading, success, failure)
   */
  [m.SET_ACTIVE_DOCUMENT_STATE](state, activeDocumentState) {
    state.activeDocumentState = activeDocumentState;
  },

  /**
   * @name m SET_ACTIVE_DOCUMENT_ERRORS
   * @summary Updates the `activeDocumentErrors` property which holds any
   * errors related to activeDocument
   * @method
   * @param {Object} state Current state
   * @param {string[]} errors An array of error messages
   */
  [m.SET_ACTIVE_DOCUMENT_ERRORS](state, errors) {
    state.activeDocumentErrors = errors;
  },

  /**
   * @name m SET_ACTIVE_DOCUMENT_VALID
   * @summary Updates the `activeDocumentValid` property which holds
   * a boolean for the validation status of the `activeDocument`.
   * @method
   * @param {Object} state Current state
   * @param {boolean} valid True if the `activeDocument` is valid.
   */
  [m.SET_ACTIVE_DOCUMENT_VALID](state, valid) {
    state.activeDocumentValid = valid;
  },

  /**
   * @name m UPDATE_ACTIVE_DOCUMENT_MODIFIED_FIELDS
   * @summary Adds given field/value pair to the `activeDocumentModifiedFields` collection, which
   * will be sent as a PATCH to the API. If the given `value` equals the one on the
   * `activeDocument` object (i.e. it hasn't actually changed, or has been reverted back to it's
   * original) then we remove that field from the modifiedFields collection
   * @method
   * @param {Object} state Current state
   * @param {object} payload Object containing a `field` and `value` properties.
   */
  [m.UPDATE_ACTIVE_DOCUMENT_MODIFIED_FIELDS](state, { field, value }) {
    // if the value matches the existing one then we remove the field from the modifiedFields obj
    if (state.activeDocument && state.activeDocument[field] === value) {
      delete state.activeDocumentModifiedFields[field];
    } else {
      state.activeDocumentModifiedFields[field] = value;
    }
  },

  /**
   * @name m CLEAR_ACTIVE_DOCUMENT_MODIFIED_FIELDS
   * @summary Empties the `activeDocumentModifiedFields` object. Used to ensure we're on a clean
   * slate after navigating to a Document Display screen,
   * @method
   * @param {Object} state Current state
   */
  [m.CLEAR_ACTIVE_DOCUMENT_MODIFIED_FIELDS](state) {
    state.activeDocumentModifiedFields = {};
  },

  /**
   * @name m SET_DOCUMENTS_SORT
   * @summary Updates the `sort` and `direction` properties
   * @method
   * @param {Object} state Current state
   * @param {Object} data The sort data (`sort` and `direction`)
   */
  [m.SET_DOCUMENTS_SORT](state, { sort, direction }) {
    state.sort = sort;
    state.sortDirection = direction;
  },
};
