import APIError from '../../../errors/APIError.js';
import types from '../../types/audit.js';
import AuditAPI from '../../../services/AuditAPI.js';
import ApiStatusCreator from '../../../enums/API.js';
const API_STATUS = ApiStatusCreator();

const a = types.actions;
const m = types.mutations;

export default {
  async [a.GET_AUDITS]({ commit, state }) {
    commit(m.SET_AUDITS_STATUS, API_STATUS.LOADING);

    try {
      const audits = await AuditAPI.getAudits();

      commit(m.SET_AUDITS_STATUS, API_STATUS.SUCCESS);
      commit(m.SET_AUDITS, audits);
      commit(m.SET_AUDITS_META, audits.meta);
    } catch (error) {
      commit(m.SET_AUDITS_STATUS, API_STATUS.FAILURE);

      let message = 'An error occurred while fetching these Audits. Please try again';

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message;
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.audits;
  },
};
