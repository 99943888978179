/**
 * A Service Type definition
 * @typedef {Object} ServiceType
 * @property {number} id
 * @property {string} name
 * @property {object[]} children
 * @property {number} parent_id
 * @property {string} created_at
 * @property {string} updated_at
 */

export default {
  serviceTypes: [],
  serviceTypesState: null,

  activeServiceType: null,
  activeServiceTypeState: null,
  activeServiceTypeErrors: [],
  activeServiceTypeValid: false,
  activeServiceTypeModifiedFields: {},
};
