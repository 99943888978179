import Base from './Base.js';

export default function ActionCreator(i18n) {
  class Action extends Base {}

  Action.initEnum({
    AbandonWorkOrder: {
      value: 'AbandonWorkOrder',
      display: 'AbandonWorkOrder',
    },
    AcceptPartsOrder: {
      value: 'AcceptPartsOrder',
      display: 'AcceptPartsOrder',
    },
    AcceptRequestForService: {
      value: 'AcceptRequestForService',
      display: 'AcceptRequestForService',
    },
    AddComplianceDocument: {
      value: 'AddComplianceDocument',
      display: 'AddComplianceDocument',
    },
    AddMessage: {
      value: 'AddMessage',
      display: 'AddMessage',
    },
    AddRams: {
      value: 'AddRams',
      display: 'AddRams',
    },
    AddFollowOnQuote: {
      value: 'AddFollowOnQuote',
      display: 'AddFollowOnQuote',
    },
    AddFollowOnWorkOrder: {
      value: 'AddFollowOnWorkOrder',
      display: 'AddFollowOnWorkOrder',
    },
    AddTimeLog: {
      value: 'AddTimeLog',
      display: 'AddTimeLog',
    },
    AmendPartOrder: {
      value: 'AmendPartOrder',
      display: 'AmendPartOrder',
    },
    ApproveCostProposal: {
      value: 'ApproveCostProposal',
      display: 'ApproveCostProposal',
    },
    AssignContractor: {
      value: 'AssignContractor',
      display: 'AssignContractor',
    },
    AssignEngineer: {
      value: 'AssignEngineer',
      display: 'AssignEngineer',
    },
    AssignOwner: {
      value: 'AssignOwner',
      display: 'AssignOwner',
    },
    AssignService: {
      value: 'AssignService',
      display: 'AssignService',
    },
    AssignServiceType: {
      value: 'AssignServiceType',
      display: 'AssignServiceType',
    },
    AssignWorkflowAndService: {
      value: 'AssignWorkflowAndService',
      display: 'AssignWorkflowAndService',
    },
    CancelPartOrderAmendment: {
      value: 'CancelPartOrderAmendment',
      display: 'CancelPartOrderAmendment',
    },
    CancelRequestForService: {
      value: 'CancelRequestForService',
      display: 'CancelRequestForService',
    },
    CheckoutEngineers: {
      value: 'CheckoutEngineers',
      display: 'CheckoutEngineers',
    },
    CreateCostProposalRequest: {
      value: 'CreateCostProposalRequest',
      display: 'CreateCostProposalRequest',
    },
    CreateRequestForService: {
      value: 'CreateRequestForService',
      display: 'CreateRequestForService',
    },
    CreateStartWorkCheck: {
      value: 'CreateStartWorkCheck',
      display: 'CreateStartWorkCheck',
    },
    ConfirmCostTreatment: {
      value: 'ConfirmCostTreatment',
      display: 'ConfirmCostTreatment',
    },
    ConfirmRemedialActions: {
      value: 'ConfirmRemedialActions',
      display: 'ConfirmRemedialActions',
    },
    DispatchOrder: {
      value: 'DispatchOrder',
      display: 'DispatchOrder',
    },
    IssueWorkPermit: {
      value: 'IssueWorkPermit',
      display: 'IssueWorkPermit',
    },
    MarkDelivered: {
      value: 'MarkDelivered',
      display: 'MarkDelivered',
    },
    PopulateFields: {
      value: 'PopulateFields',
      display: 'PopulateFields',
    },
    ReleasePendingPartOrder: {
      value: 'ReleasePendingPartOrder',
      display: 'ReleasePendingPartOrder',
    },
    RejectPartsOrder: {
      value: 'RejectPartsOrder',
      display: 'RejectPartsOrder',
    },
    RejectRequestForService: {
      value: 'RejectRequestForService',
      display: 'RejectRequestForService',
    },
    RetireSlaLogs: {
      value: 'RetireSlaLogs',
      display: 'RetireSlaLogs',
    },
    ReviewAssetServiceDetails: {
      value: 'ReviewAssetServiceDetails',
      display: 'ReviewAssetServiceDetails',
    },
    SetCompliantDate: {
      value: 'SetCompliantDate',
      display: 'SetCompliantDate',
    },
    ShowDialog: {
      value: 'ShowDialog',
      display: 'ShowDialog',
    },
    SubmitAssetServiceDetails: {
      value: 'SubmitAssetServiceDetails',
      display: 'SubmitAssetServiceDetails',
    },
    WorkOrderPartsReflectStatusChange: {
      value: 'WorkOrderPartsReflectStatusChange',
      display: 'WorkOrderPartsReflectStatusChange',
    },

  });

  return Action;
}
