/**
 * A Condition Grade definition
 * @typedef {Object} ConditionGrade
 * @property {number} id
 * @property {string} name
 * @property {string} created_at
 * @property {string} updated_at
 */

export default {
  conditionGrades: [],
  conditionGradesState: null,

  activeConditionGrade: null,
  activeConditionGradeState: null,
  activeConditionGradeErrors: [],
  activeConditionGradeValid: false,
  activeConditionGradeModifiedFields: {},
};
