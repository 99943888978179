<template>
  <SidePanel
    ref="sidePanel"
  >
    <template #header>
      <div class="d-block py-2">
        <h5 class="text-muted mb-0">{{ title }}</h5>
      </div>
    </template>

    <slot />

    <div class="row mt-3">
      <div class="col-md-6 mt-2 order-md-last">
        <b-button block variant="primary" @click="onConfirm">
          {{ confirmLabel }}
        </b-button>
      </div>
      <div class="col-md-6 mt-2">
        <b-button block variant="secondary" @click="onCancel">
          {{ cancelLabel }}
        </b-button>
      </div>
    </div>
  </SidePanel>
</template>

<script>
import { BButton } from 'bootstrap-vue';
import SidePanel from "@/ux/SidePanel.vue";

export default {
  name: 'ConfirmationSidePanel',
  components: {
    SidePanel,
    BButton,
  },
  props: {
    title: {
      type: String,
      default: 'Confirmation Required',
    },
    cancelLabel: {
      type: String,
      default: 'Cancel',
    },
    confirmLabel: {
      type: String,
      default: 'Continue',
    },
  },
  methods: {
    show() {
      this.$refs.sidePanel.show();
    },
    hide() {
      this.$refs.sidePanel.hide();
    },
    onConfirm() {
      this.$emit('confirm');
      this.$refs.sidePanel.hide();
    },
    onCancel() {
      this.$emit('cancel');
      this.$refs.sidePanel.hide();
    },
  },
};
</script>
