import APIError from '../../../errors/APIError.js';
import types from '../../types/supplier.js';
import SupplierAPI from '../../../services/SupplierAPI.js';
import ApiStatusCreator from '../../../enums/API.js';
const API_STATUS = ApiStatusCreator();

const a = types.actions;
const m = types.mutations;

export default {
  /**
   * Loads all Suppliers
   * @param {Object} context
   * @param {number} page The page to load. If not supplied will default to 1
   * @return {Supplier[]} An array of {@link Supplier} objects.
   */
  async [a.GET_SUPPLIERS]({ commit, state }, { sort, direction, filter }) {
    commit(m.SET_SUPPLIERS_STATUS, API_STATUS.LOADING);

    try {
      const suppliers = await SupplierAPI.getSuppliers(sort, direction, filter);

      commit(m.SET_SUPPLIERS_STATUS, API_STATUS.SUCCESS);
      commit(m.SET_SUPPLIERS, suppliers);
      // commit(m.SET_SUPPLIERS_META, suppliers.meta);
    } catch (error) {
      commit(m.SET_SUPPLIERS_STATUS, API_STATUS.FAILURE);

      let message = 'An error occurred while fetching these Suppliers. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.suppliers;
  },

  /**
   * Creates a new Supplier
   * @param {Object} context
   * @param {object} supplier A {@link Supplier} object
   * @return {Supplier} A {@link Supplier} object.
   */
  async [a.CREATE_SUPPLIER]({ commit, state }, { supplier }) {
    try {
      commit(m.SET_ACTIVE_SUPPLIER_ERRORS, []);
      commit(m.SET_ACTIVE_SUPPLIER_STATE, API_STATUS.SAVING);

      const supplierResponse = await SupplierAPI.createSupplier(supplier);

      commit(m.SET_ACTIVE_SUPPLIER_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_SUPPLIER, supplierResponse);
      commit(m.ADD_LOCAL_SUPPLIER, supplierResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_SUPPLIER_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while creating this Supplier. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeSupplier;
  },

  /**
   * Updates an existing Supplier
   * @param {Object} context
   * @param {object} supplier The {@link Supplier} object to update
   * @param {object} modifiedFields An object with the key/values for the fields to update.
   * @return {Supplier} A {@link Supplier} object.
   */
  async [a.UPDATE_SUPPLIER]({ commit, state }, { supplier, modifiedFields }) {
    try {
      commit(m.SET_ACTIVE_SUPPLIER_ERRORS, []);
      commit(m.SET_ACTIVE_SUPPLIER_STATE, API_STATUS.SAVING);

      const supplierResponse = await SupplierAPI.updateSupplier(supplier.id, modifiedFields);

      commit(m.SET_ACTIVE_SUPPLIER_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_SUPPLIER, supplierResponse);
      commit(m.UPDATE_LOCAL_SUPPLIER, supplierResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_SUPPLIER_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while updating this Supplier. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeSupplier;
  },

  /**
   * Deletes a Supplier
   * @param {Object} context
   * @param {Supplier} supplier The {@link Supplier} object to delete
   * @return {Supplier} A {@link Supplier} object.
   */
  async [a.DELETE_SUPPLIER]({ commit }, { supplier }) {
    try {
      commit(m.SET_ACTIVE_SUPPLIER_ERRORS, []);
      commit(m.SET_ACTIVE_SUPPLIER_STATE, API_STATUS.DELETING);

      await SupplierAPI.deleteSupplier(supplier.id);

      commit(m.SET_ACTIVE_SUPPLIER_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_SUPPLIER, null);

      commit(m.REMOVE_LOCAL_SUPPLIER, supplier);
    } catch (error) {
      commit(m.SET_ACTIVE_SUPPLIER_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while deleting this Supplier. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return true;
  },
};
