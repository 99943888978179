import APIError from '@/errors/APIError.js';
import types from '../../types/costRequest.js';
import CostRequestAPI from '../../../services/CostRequestAPI.js';
import errorTypes from '../../types/error.js';
import ApiStatusCreator from '../../../enums/API.js';
const API_STATUS = ApiStatusCreator();

const a = types.actions;
const m = types.mutations;
const me = errorTypes.mutations;

export default {
  /**
   * Loads specific CostRequest details
   * @param {Object} context
   * @param {number} id The CostRequest ID to load
   * @return {CostRequest} A {@link CostRequest} object.
   */
  async [a.GET_COST_REQUEST]({ commit, state }, { id }) {
    try {
      commit(m.SET_ACTIVE_COST_REQUEST, null);
      commit(m.SET_ACTIVE_COST_REQUEST_STATE, API_STATUS.LOADING);

      const costRequest = await CostRequestAPI.getCostRequest(id);

      costRequest.created_at = new Date(costRequest.created_at);
      costRequest.updated_at = new Date(costRequest.updated_at);

      commit(m.SET_ACTIVE_COST_REQUEST, costRequest);
      commit(m.SET_ACTIVE_COST_REQUEST_STATE, API_STATUS.SUCCESS);
    } catch (error) {
      commit(m.SET_ACTIVE_COST_REQUEST_STATE, API_STATUS.FAILURE);
      let message = 'An error occurred while fetching this Cost Request. Please try again.'
      let noPermissionOrNotFoundError = '';
      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
        if (error.response.status === 404 || error.response.status === 403) {
          noPermissionOrNotFoundError = error.response.data.message;
        }
      } else {
        message = error.message || message;
      }

      commit(m.SET_ACTIVE_COST_REQUEST_ERRORS, message);
      commit(me.SET_NOT_FOUND_OR_PERMISSION_ERRORS, noPermissionOrNotFoundError);
    }

    return state.activeCostRequest;
  },

  async [a.UPDATE_COST_REQUEST]({ commit, state }, { costRequest, modifiedFields }) {
    try {
      commit(m.SET_ACTIVE_COST_REQUEST_ERRORS, []);
      commit(m.SET_ACTIVE_COST_REQUEST_STATE, API_STATUS.SAVING);

      const costRequestResponse = await CostRequestAPI.updateCostRequest(
        costRequest.id,
        modifiedFields,
      );

      commit(m.SET_ACTIVE_COST_REQUEST_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_COST_REQUEST, costRequestResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_COST_REQUEST_STATE, API_STATUS.FAILURE);

      let message ='An error occurred while updating this Cost Request. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeCostRequest;
  },
};
