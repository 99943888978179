import axios from 'axios';
import createAPIParams from '@/util/createAPIParams.js';

/**
 * Interface to the RecurringWorkOrder API endpoints
 * @class
 * @exports src/services/RecurringWorkOrderAPI
 */
class RecurringWorkOrderAPI {
  /**
   * Retrieves the specified Recurring WorkOrder's details
   * @method
   * @static
   * @param  {number}  recurringWorkOrderId    The recurringWorkOrder id to load
   * @return {Promise}          Promise that resolves to the {@link RecurringWorkOrder} details.
   */
  static async getRecurringWorkOrder(recurringWorkOrderId) {
    const response = await axios({
      method: 'GET',
      url: `recurring_work_order/${recurringWorkOrderId}`,
      responseType: 'json',
    });

    return response.data.data;
  }

  static async getRealignedDueDate(date, rwoId) {
    const response = await axios({
      method: 'GET',
      url: `recurring_work_order/${rwoId}/realigned_due_date`,
      responseType: 'json',
      params: {
        compliant_date: date,
      },
    });
    return response.data;
  }

  /**
   * Retrieves all RecurringWorkOrders for the given page
   * @param {Number} page The page to load
   * @param {String} sort The field to sort on
   * @param {String} direction The sort direction. Either 'ASC' or 'DESC'. Defaults to ASC.
   * @param {Object} filter An object with key value pairs of filter values.
   * @param {Object} additionalQueryStringParams An object with key value pairs of query string parameters.
   * @return {Promise} Promise that resolves to the api response.
   * In format: { workOrder: {object[]}, meta: {object}, links: {object} }
   */
  static async getRecurringWorkOrders(page, sort, direction, filter, additionalQueryStringParams) {
    const response = await axios({
      method: 'GET',
      url: 'recurring_work_order',
      responseType: 'json',
      params: createAPIParams(page, sort, direction, filter, additionalQueryStringParams),
    });

    return response.data;
  }

  /**
   * @summary Hits the create RecurringWorkOrder endpoint.
   * @desc Sends the given recurringWorkOrder data to the create endpoint
   * @param  {RecurringWorkOrder}  recurringWorkOrder A {@link RecurringWorkOrder} object to be saved
   * @return {Promise}      Resolves to the returned full RecurringWorkOrder object
   */
  static async createRecurringWorkOrder(recurringWorkOrder) {
    const response = await axios({
      method: 'POST',
      url: 'recurring_work_order',
      responseType: 'json',
      data: recurringWorkOrder,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the PATCH RecurringWorkOrder endpoint.
   * @desc Sends the specified _modified_ fields to the endpoint to be saved.
   * @param  {String|Number}  recurringWorkOrderId The RecurringWorkOrder Id to save
   * @param  {object}  modifiedFields An object with a subset of the
   * {@link RecurringWorkOrder} fields that have been modified and should be saved.
   * @return {Promise}      Resolves to the returned full RecurringWorkOrder object
   */
  static async updateRecurringWorkOrder(recurringWorkOrderId, modifiedFields) {
    const response = await axios({
      method: 'PATCH',
      url: `recurring_work_order/${recurringWorkOrderId}`,
      responseType: 'json',
      data: modifiedFields,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the DELETE RecurringWorkOrder endpoint.
   * @param  {String|Number}  recurringWorkOrderId The RecurringWorkOrder Id to delete
   * @return {Promise}
   */
  static async deleteRecurringWorkOrder(recurringWorkOrderId) {
    await axios({
      method: 'DELETE',
      url: `recurring_work_order/${recurringWorkOrderId}`,
      responseType: 'json',
    });

    return true;
  }

  static async getRecurringWorkOrderAuditLogs(recurringWorkOrderId, page, sort, direction) {
    const response = await axios({
      method: 'GET',
      url: 'recurring_work_order/log',
      responseType: 'json',
      params: createAPIParams(page, sort, direction, recurringWorkOrderId),
    });

    return response.data;
  }

  /**
   * Retrieves the Stats for for the given group
   * @param {String} groupBy The property to group by
   * @param {Number} page The page to load
   * @param {String} sort The field to sort on
   * @param {String} direction The sort direction. Either 'ASC' or 'DESC'. Defaults to ASC.
   * @param {Object} filter An object with key value pairs of filter values.
   * @return {Promise} Promise that resolves to the api response.
   * In format: { data: {object[]}, meta: {object}, links: {object} }
   */
  static async getRecurringWorkOrderStats(groupBy, page, sort, direction, filter) {
    // some fields had to be contracted to remove the dots so they work with frontend mapping (see hydration code below)
    // so we have to map some back for compatibility with the backend
    const sortMap = {
      'ServiceContractor.name': 'Service.Contractor.name',
    };

    const sortField = sortMap[sort] || sort;

    const params = createAPIParams(page, sortField, direction, filter);

    if (groupBy !== 'all') {
      params.group_by = groupBy;
    }

    params.metrics = ['count', 'open_work_order_count|sum', 'overdue_work_order_count|sum'].join(
      ',',
    );
    params.include_related = true;

    const response = await axios({
      method: 'GET',
      url: 'recurring_work_order/stats',
      responseType: 'json',
      params,
    });

    const { data } = response;

    // try to hydrate these fields with their full data object. assign it back to the item
    // with the field name minus the '.id'. e.g. 'Site.id' => 'Site'
    const hydrateFields = ['WorkOrderRequestType.calendar_alias', 'Service.Contractor.id', 'Site.id'];

    // hydrate related objects
    data.data.forEach((item) => {
      hydrateFields.forEach((field) => {
        const id = item[field];

        if (id) {
          const key = field
            .split('.')
            .slice(0, -1) // remove the last part
            .join(''); // join without dots so it can be mapped in the UI (dot-notation is used to look into subobjects so we can't have it as a key)

          // we also need the actual column to take from the field we're hydrating so we can match it later...
          const targetColumn = field
            .split('.')
            .pop();

          // eslint-disable-next-line no-param-reassign
          item[key] = Object.values(data.related[field]).find((r) => r[targetColumn] === id);
        }
      });
    });

    return response.data;
  }
}

export default RecurringWorkOrderAPI;
