<template>
  <span :key="$currency(this.value, this.code)" translate="no">{{ $currency(this.value, this.code) }}</span>
</template>

<script>

export default {
  name: 'Currency',
  props: {
    value: {
      type: null,
      required: true,
    },
    code: {
      type: null,
      default: null,
    },
  },
};

</script>
