export default {
  getters: {},

  actions: {
    UPDATE_FINANCIAL_PERIOD: 'UPDATE_FINANCIAL_PERIOD',
    CREATE_FINANCIAL_PERIOD: 'CREATE_FINANCIAL_PERIOD',
    DELETE_FINANCIAL_PERIOD: 'DELETE_FINANCIAL_PERIOD',
    GET_FINANCIAL_PERIODS: 'GET_FINANCIAL_PERIODS',
  },

  mutations: {
    SET_FINANCIAL_PERIODS: 'SET_FINANCIAL_PERIODS',
    SET_FINANCIAL_PERIODS_STATUS: 'SET_FINANCIAL_PERIODS_STATUS',
    SET_FINANCIAL_PERIODS_META: 'SET_FINANCIAL_PERIODS_META',
    SET_FINANCIAL_PERIODS_FILTER: 'SET_FINANCIAL_PERIODS_FILTER',

    REMOVE_LOCAL_FINANCIAL_PERIOD: 'REMOVE_LOCAL_FINANCIAL_PERIOD',
    ADD_LOCAL_FINANCIAL_PERIOD: 'ADD_LOCAL_FINANCIAL_PERIOD',
    UPDATE_LOCAL_FINANCIAL_PERIOD: 'UPDATE_LOCAL_FINANCIAL_PERIOD',

    SET_ACTIVE_FINANCIAL_PERIOD: 'SET_ACTIVE_FINANCIAL_PERIOD',
    SET_ACTIVE_FINANCIAL_PERIOD_STATE: 'SET_ACTIVE_FINANCIAL_PERIOD_STATE',
    SET_ACTIVE_FINANCIAL_PERIOD_ERRORS: 'SET_ACTIVE_FINANCIAL_PERIOD_ERRORS',
    SET_ACTIVE_FINANCIAL_PERIOD_VALID: 'SET_ACTIVE_FINANCIAL_PERIOD_VALID',
    UPDATE_ACTIVE_FINANCIAL_PERIOD_MODIFIED_FIELDS:
      'UPDATE_ACTIVE_FINANCIAL_PERIOD_MODIFIED_FIELDS',
    CLEAR_ACTIVE_FINANCIAL_PERIOD_MODIFIED_FIELDS: 'CLEAR_ACTIVE_FINANCIAL_PERIOD_MODIFIED_FIELDS',
  },
};
