import Base from './Base.js';

export default function AssetServiceDetailStatusCreator() {
  class AssetServiceDetailStatus extends Base {}

  AssetServiceDetailStatus.initEnum({
    DRAFT: {
      value: 'DRAFT',
      display: 'Draft',
    },
    SUBMITTED: {
      value: 'SUBMITTED',
      display: 'Submitted',
    },
    APPROVED: {
      value: 'APPROVED',
      display: 'Approved',
    },
    REJECTED: {
      value: 'REJECTED',
      display: 'Rejected',
    },
  });

  return AssetServiceDetailStatus;
}
