export default {
  state: {
    /**
     * A User definition
     * @typedef {Object} User
     * @property {number} id -
     * @property {string} name -
     * @property {string} email -
     * @property {string} password -
     * @property {string} user_type -
     * @property {string} site_access -
     * @property {number[]} sites -
     * @property {number[]} site_tag_ids -
     * @property {number} receive_emails -
     * @property {number} receive_push_notifications -
     * @property {string} language -
     * @property {string} locale -
     * @property {string} timezone -
     * @property {date} created_at -
     * @property {date} updated_at -
     */
    user: () => ({
      id: null,
      name: null,
      email: null,
      password: null,
      user_type: null,
      site_access: null,
      site_ids: [],
      site_tag_ids: [],
      receive_emails: false,
      receive_push_notifications: false,
      internal_owner: false,
      enabled: false,
      force_password_change: false,
      language: 'en',
      locale: 'en-GB',
      timezone: 'Europe/London',
      contractor_id: null,
      engineer: false,
      engineer_can_view_other_work_orders: false,
      invitation_processing: false,
      created_at: null,
      updated_at: null,
    }),
  },

  getters: {},

  actions: {
    GET_USER: 'GET_USER',
    UPDATE_USER: 'UPDATE_USER',
    CREATE_USER: 'CREATE_USER',
    INVITE_USER: 'INVITE_USER',
  },

  mutations: {
    SET_ACTIVE_USER: 'SET_ACTIVE_USER',
    SET_ACTIVE_USER_STATE: 'SET_ACTIVE_USER_STATE',
    SET_ACTIVE_USER_ERRORS: 'SET_ACTIVE_USER_ERRORS',
    SET_ACTIVE_USER_VALID: 'SET_ACTIVE_USER_VALID',
    UPDATE_ACTIVE_USER_MODIFIED_FIELDS: 'UPDATE_ACTIVE_USER_MODIFIED_FIELDS',
    CLEAR_ACTIVE_USER_MODIFIED_FIELDS: 'CLEAR_ACTIVE_USER_MODIFIED_FIELDS',

    SET_USERS_FILTER: 'SET_USERS_FILTER',
    SET_USERS_SORT: 'SET_USERS_SORT',
  },
};
