import axios from 'axios';
import dayjs from 'dayjs';
import * as Sentry from '@sentry/browser';
import types from '../../types/authorization.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_SESSION_STATUS
   * @summary Updates the `session_status` property.
   * @method
   * @param {Object} state Current state
   * @param {enums/Session} status The new Session Status enum
   */
  [m.SET_SESSION_STATUS](state, status) {
    state.session_status = status;
  },

  [m.SET_FORCE_PASSWORD_UPDATE](state, force) {
    state.force_password_change = force;
  },

  /**
   * @name m SET_SESSION
   * @summary Updates the `session` details and sets the axios default headers.
   * @method
   * @param {Object} state Current state
   * @param {Session} session The new {@link Session} details
   */
  [m.SET_SESSION](state, session) {
    state.session = session;
    let headers = {};

    if (session) {
      headers = {
        Authorization: `Bearer ${session.access_token}`,
      };
    }

    // update the axios headers to include the access_token
    axios.defaults.headers.common = headers;
  },

  /**
   * @name m SET_SESSION_ERRORS
   * @summary Updates the `session_errors` state with the given errors
   * @method
   * @param {Object} state Current state
   * @param {string[]} errors An array of error messages
   */
  [m.SET_SESSION_ERRORS](state, errors) {
    state.session_errors = errors;
  },

  /**
   * @name m SET_SESSION_USER_ID
   * @summary Updates the logged in user id
   * @method
   * @param {Object} state Current state
   * @param {number} userId The new userId
   */
  [m.SET_SESSION_USER_ID](state, userId) {
    state.user_id = userId;
  },

  [m.SET_SESSION_USER](state, user) {
    state.user = user;

    // set the dayjs locale
    if (user) {
      const locale = user.locale || 'en-GB';
      dayjs.locale(locale.toLowerCase());
    }

    // set user on Sentry
    if (user) {
      Sentry.setUser({
        id: `${user.id}@${window.location.hostname}`,
      });
    }
  },

  [m.PATCH_SESSION_USER](state, { modifiedFields, updatedUserId }) {
    if (updatedUserId === state.user.id) {
      Object.assign(state.user, modifiedFields);
    }
  },

  [m.SET_OTP_ENABLED](state, enabled) {
    state.otp_enabled = enabled;
  },
};
