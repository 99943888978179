const changePassword = {
  path: '/passwordChange',
  name: 'ForcedPasswordChange',
  meta: {
    requiresAuth: true,
    storeModules: ['authorization'],
  },
  component: () => import('@/views/ForcedPasswordChange.vue'),
};

const resetPassword = {
  path: '/request-password-reset/:token',
  name: 'ResetPassword',
  component: () => import('@/views/auth/ResetPassword.vue'),
};

export { resetPassword, changePassword };
