import types from '../../types/country.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_COUNTRIES
   * @summary Updates the `countries` property.
   * @method
   * @param {Object} state Current state
   * @param {Country[]} countries The loaded Countries array
   */
  [m.SET_COUNTRIES](state, countries) {
    state.countries = countries;
  },

  /**
   * @name m SET_COUNTRIES_STATE
   * @summary Updates the `countriesState` property which holds the current loading status of the
   * countries based on the API enum
   * @method
   * @param {Object} state Current state
   * @param {enums/API} countriestate The current countriesState (e.g. loading, success, failure)
   */
  [m.SET_COUNTRIES_STATE](state, countriestate) {
    state.countriesState = countriestate;
  },
};
