import axios from 'axios';
import createAPIParams from '@/util/createAPIParams.js';

/**
 * Interface to the CostRequest API endpoints
 * @class
 * @exports src/services/CostRequestAPI
 */
class CostRequestAPI {
  /**
   * Creates a request for service for a WorkOrder and moves to new transition state.
   * @method
   * @static
   * @param  {number}  workOrderId      The workOrder id to transition
   * @param  {number}  transitionId     The Id of the transition to move to
   * @param  {string}  contractorIds    The IDs of the contractors
   * @param  {string}  type             The type of the CPR
   * @param  {string}  message          The message to attach
   * @param  {boolean} shareAttachments Whether attachments should be shared with the contractor
   * @param  {Date}    dueAt            The due date
   * @return {Promise}          Promise that resolves to the {@link WorkOrder} details.
   */
  static async createCostRequests(
    workOrderId,
    transitionId,
    contractorIds,
    type,
    message,
    shareAttachments,
    dueAt,
  ) {
    const response = await axios({
      method: 'POST',
      url: 'cost_proposal_request',
      responseType: 'json',
      data: {
        work_order_id: workOrderId,
        transition_id: transitionId,
        contractor_ids: contractorIds,
        type,
        message,
        share_attachments: shareAttachments,
        due_at: dueAt,
      },
    });
    return response.data.data;
  }

  /**
   * Retrieves the specified CostRequest's details
   * @method
   * @static
   * @param  {number}  costRequestId    The costRequest id to load
   * @return {Promise}          Promise that resolves to the {@link CostRequest} details.
   */
  static async getCostRequest(costRequestId) {
    const response = await axios({
      method: 'GET',
      url: `cost_proposal_request/${costRequestId}`,
      responseType: 'json',
    });

    return response.data.data;
  }

  /**
   * Retrieves all CostRequests for the given page
   * @param {Number} page The page to load
   * @param {String} sort The field to sort on
   * @param {String} direction The sort direction. Either 'ASC' or 'DESC'. Defaults to ASC.
   * @param {Object} filter An object with key value pairs of filter values.
   * @return {Promise} Promise that resolves to the api response.
   * In format: { costRequests: {object[]}, meta: {object}, links: {object} }
   */
  static async getCostRequests(page, sort, direction, filter) {
    const response = await axios({
      method: 'GET',
      url: 'cost_proposal_request',
      responseType: 'json',
      params: createAPIParams(page, sort, direction, filter),
    });

    return response.data;
  }

  static async getWorkOrderCostRequest(workOrderId) {
    const data = await CostRequestAPI.getCostRequests('all', 'created_at', 'asc', {
      'WorkOrder.id': workOrderId,
    });

    return data.data;
  }

  /**
   * Updates a cost proposal request for a WorkOrder
   * @method
   * @static
   * @param  {number}  costProposalRequestId     The cost proposal request to update
   * @param  {number}  data               The data to pass
   * @return {Promise}          Promise that resolves to the {@link WorkOrder} details.
   */
  static async updateCostRequest(costProposalRequestId, data) {
    // eslint-disable-line max-len
    const response = await axios({
      method: 'PATCH',
      url: `cost_proposal_request/${costProposalRequestId}`,
      responseType: 'json',
      data,
    });

    return response.data.data;
  }
}

export default CostRequestAPI;
