/**
 * A User definition
 * @typedef {Object} User
 * @property {number} id - The user's id
 * @property {string} name - The full name of the user
 * @property {string} email - The email of the user
 * @property {boolean} receive_emails - Whether the user wants to receive emails
 * @property {boolean} receive_push - Whether the user wants to receive push notifications
 * @property {string} language - The language the user wants to use
 * @property {string} locale - The locale the user wants to use
 * @property {string} timezone - The timezone the user is located in
 * @property {string} created_at - The time that the user was created at
 * @property {string} updated_at - The time that the user was last updated at
 */

export default {
  force_password_change: false,

  session_status: null,
  session_errors: null,

  user_id: null,
  user: null,

  impersonatorUser: null,
  otp_enabled: false,
};
