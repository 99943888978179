/**
 * A DocumentType definition
 * @typedef {Object} DocumentType
 * @property {number} id -
 * @property {string} name -
 * @property {string} created_at -
 * @property {string} updated_at -
 */

export default {
  documentTypes: [],
  documentTypesStatus: null,
  filter: {},
  sort: 'name',
  sortDirection: 'ASC',
  meta: {},
  activeDocumentType: null,
  activeDocumentTypeState: null,
  activeDocumentTypeErrors: [],
  activeDocumentTypeValid: false,
  activeDocumentTypeModifiedFields: {},
};
