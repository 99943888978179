import Base from './Base.js';

export default function LinkTypeCreator(i18n) {
  class LinkType extends Base {}

  LinkType.initEnum({
    RELATES_TO: {
      value: 'RELATES_TO',
      display: i18n.global.t('enums.LinkType.relates_to'),
      displayRev: i18n.global.t('enums.LinkType.relates_to'),
      directional: false,
    },
    BLOCKS: {
      value: 'BLOCKS',
      display: i18n.global.t('enums.LinkType.blocks'),
      displayRev: i18n.global.t('enums.LinkType.blocked_by'),
      directional: true,
    },
    DUPLICATED_BY: {
      value: 'DUPLICATED_BY',
      display: i18n.global.t('enums.LinkType.duplicates'),
      displayRev: i18n.global.t('enums.LinkType.duplicated_by'),
      directional: true,
    },
  });

  return LinkType;
}
