import APIError from '../../../errors/APIError.js';
import types from '../../types/workOrderType.js';
import WorkOrderTypeAPI from '../../../services/WorkOrderTypeAPI.js';
import ApiStatusCreator from '../../../enums/API.js';
const API_STATUS = ApiStatusCreator();

const a = types.actions;
const m = types.mutations;

export default {
  /**
   * Loads all WorkOrderTypes
   * @param {Object} context
   * @param {number} page The page to load. If not supplied will default to 1
   * @return {WorkOrderType[]} An array of {@link WorkOrderType} objects.
   */
  async [a.GET_WORK_ORDER_TYPES]({ commit, state }, { page, sort, direction, filter }) {
    commit(m.SET_WORK_ORDER_TYPES_STATUS, API_STATUS.LOADING);

    try {
      const workOrderTypes = await WorkOrderTypeAPI.getWorkOrderTypes(
        page,
        sort,
        direction,
        filter,
      );

      commit(m.SET_WORK_ORDER_TYPES_STATUS, API_STATUS.SUCCESS);
      commit(m.SET_WORK_ORDER_TYPES, workOrderTypes);
      // commit(m.SET_WORK_ORDER_TYPES_META, workOrderTypes.meta);
    } catch (error) {
      commit(m.SET_WORK_ORDER_TYPES_STATUS, API_STATUS.FAILURE);

      let message = 'An error occurred while fetching these Work Order Types. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.workOrderTypes;
  },

  /**
   * Creates a new WorkOrderType
   * @param {Object} context
   * @param {object} workOrderType A {@link WorkOrderType} object
   * @return {WorkOrderType} A {@link WorkOrderType} object.
   */
  async [a.CREATE_WORK_ORDER_TYPE]({ commit, state }, { workOrderType }) {
    try {
      commit(m.SET_ACTIVE_WORK_ORDER_TYPE_ERRORS, []);
      commit(m.SET_ACTIVE_WORK_ORDER_TYPE_STATE, API_STATUS.SAVING);

      const workOrderTypeResponse = await WorkOrderTypeAPI.createWorkOrderType(workOrderType);

      commit(m.SET_ACTIVE_WORK_ORDER_TYPE_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_WORK_ORDER_TYPE, workOrderTypeResponse);
      commit(m.ADD_LOCAL_WORK_ORDER_TYPE, workOrderTypeResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_WORK_ORDER_TYPE_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while creating this Work Order Type. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeWorkOrderType;
  },

  /**
   * Updates an existing WorkOrderType
   * @param {Object} context
   * @param {object} workOrderType The {@link WorkOrderType} object to update
   * @param {object} modifiedFields An object with the key/values for the fields to update.
   * @return {WorkOrderType} A {@link WorkOrderType} object.
   */
  async [a.UPDATE_WORK_ORDER_TYPE]({ commit, state }, { workOrderType, modifiedFields }) {
    try {
      commit(m.SET_ACTIVE_WORK_ORDER_TYPE_ERRORS, []);
      commit(m.SET_ACTIVE_WORK_ORDER_TYPE_STATE, API_STATUS.SAVING);

      const workOrderTypeResponse = await WorkOrderTypeAPI.updateWorkOrderType(
        workOrderType.id,
        modifiedFields,
      );

      commit(m.SET_ACTIVE_WORK_ORDER_TYPE_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_WORK_ORDER_TYPE, workOrderTypeResponse);
      commit(m.UPDATE_LOCAL_WORK_ORDER_TYPE, workOrderTypeResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_WORK_ORDER_TYPE_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while updating this Work Order Type. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeWorkOrderType;
  },

  /**
   * Deletes a WorkOrderType
   * @param {Object} context
   * @param {WorkOrderType} workOrderType The {@link WorkOrderType} object to delete
   * @return {WorkOrderType} A {@link WorkOrderType} object.
   */
  async [a.DELETE_WORK_ORDER_TYPE]({ commit }, { workOrderType }) {
    try {
      commit(m.SET_ACTIVE_WORK_ORDER_TYPE_ERRORS, []);
      commit(m.SET_ACTIVE_WORK_ORDER_TYPE_STATE, API_STATUS.DELETING);

      await WorkOrderTypeAPI.deleteWorkOrderType(workOrderType.id);

      commit(m.SET_ACTIVE_WORK_ORDER_TYPE_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_WORK_ORDER_TYPE, null);

      commit(m.REMOVE_LOCAL_WORK_ORDER_TYPE, workOrderType);
    } catch (error) {
      commit(m.SET_ACTIVE_WORK_ORDER_TYPE_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while deleting this Work Order Type. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return true;
  },
};
