/**
 * A Landlord definition
 * @typedef {Object} Landlord
 * @property {number} id -
 * @property {string} address
 * @property {string} created_at
 * @property {string} email
 * @property {string} name
 * @property {string} notes
 * @property {string} telephone
 * @property {string} updated_at
 */

export default {
  landlords: [],
  landlordsState: null,

  filter: {},

  meta: {},

  activeLandlord: null,
  activeLandlordState: null,
  activeLandlordErrors: [],
  activeLandlordValid: false,
  activeLandlordModifiedFields: {},
};
