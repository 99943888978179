<template>
  <div>
    <div class="form-group">
    <b-input-group>
      <b-form-input
        id="search"
        v-model="query"
        :placeholder="$t('views.work-orders.WorkOrderEdit.assetSearchPlaceholder')"
        @input="searchList"
      />
      <b-input-group-append>
        <b-button variant="primary" class="input-group-button px-3" @click="showBarcodeScanner">
          <font-awesome-icon :icon="['far', 'barcode-read']" />
        </b-button>
      </b-input-group-append>
    </b-input-group>
    </div>

    <label v-if="query.length" class="mt-2" for="assets">
      <span>
        {{ $t('views.work-orders.WorkOrderEdit.assetSearchResult', {count: searchResults.length}) }}
      </span>
    </label>

    <BarcodeScanner
      ref="barcodeScanner"
      @confirmed="onBarcodeScannerConfirmed"
    />

    <b-list-group id="assets">
      <SingleAsset
        v-for="asset in searchResults"
        :key="asset.id"
        :flat-tree="flatTree"
        :selected-values="selectedValues"
        :asset="asset"
        @check-item="onCheckItem($event, asset)"
      />
    </b-list-group>
  </div>
</template>

<script>
import flattenTree from '@/util/flattenTree.js';
import { BButton, BFormInput, BInputGroup, BListGroup } from 'bootstrap-vue';
import BarcodeScanner from '@/ux/BarcodeScanner.vue';

export default {
  name: 'AssetSearch',
  components: {
    BarcodeScanner,
    BButton,
    BFormInput,
    BListGroup,
    BInputGroup,
  },
  props: {
    assets: {
      type: Array,
      default: () => [],
    },
    maintainableEntitiesTree: {
      type: Array,
      default: () => [],
    },
    selectedValues: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      searchResults: [],
      query: '',
    };
  },
  computed: {
    flatTree() {
      return [...flattenTree(this.maintainableEntitiesTree)];
    },
  },
  methods: {
    searchList(query) {
      if (query.length) {
        this.searchResults = this.assets.filter(
          (asset) =>
            asset.assetModel?.name?.toLowerCase().includes(query.trim().toLowerCase()) ||
            asset.label?.toLowerCase().includes(query.trim().toLowerCase()),
        );
      } else {
        this.searchResults = [];
      }
    },

    onCheckItem(e, item) {
      if (e === true) {
        this.$emit('addAsset', item);
      } else {
        this.$emit('removeAsset', item);
      }
    },

    showBarcodeScanner() {
      this.$refs.barcodeScanner.show();
    },

    async onBarcodeScannerConfirmed(barcode) {
      this.query = barcode;
      this.searchList(this.query)
    },
  },
};
</script>

<style lang="scss">
.small-font {
  font-size: 0.8rem;
}

.list-group > div {
  display: flex;
}

</style>
