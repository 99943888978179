<template>
  <div class="layout default-layout">
    <Navigation :expanded="menuOpen" @close="menuOpen = false" />

    <div ref="mainArea" class="main">
      <MobileHeader v-if="isMobile" class="d-print-none" @open="menuOpen = true" />
      <DesktopHeader class="d-print-none" />

      <div class="demo-mode">
        {{ $t('common.demoMode') }}
      </div>

      <div ref="scrollArea" class="section">
        <slot />
      </div>
    </div>

    <GlobalComponents />
  </div>
</template>

<script>
import types from '@/store/types/viewEvents.js';
import Navigation from '@/components/app/Navigation.vue';
import MobileHeader from '@/components/app/MobileHeader.vue';
import DesktopHeader from '@/components/app/DesktopHeader.vue';
import GlobalComponents from '@/components/app/GlobalComponents.vue';
import store from '@/store/index.js';

export default {
  name: 'DefaultLayout',
  inject: ["mq"],
  components: {
    Navigation,
    MobileHeader,
    DesktopHeader,
    GlobalComponents,
  },
  data() {
    return {
      menuOpen: false,
      scrollTrigger: 24,
      lastScrollTop: 0,
      hasIntercom: window.appConfig?.HAS_LIVECHAT.value ?? window.appConfig?.HAS_LIVECHAT.default_value ?? false,
    };
  },
  computed: {
    isMobile() {
      return ['xs', 'sm', 'md'].includes(this.mq.current);
    },
  },
  watch: {
    $route() {
      this.menuOpen = false;
    },
  },
  mounted() {
    const { scrollArea } = this.$refs;

    if (scrollArea) {
      scrollArea.addEventListener('scroll', this.handleScroll);
    }

    const loggedIn = store.getters.isLoggedIn;
    if (loggedIn && this.hasIntercom) {
      const intercom = document.createElement('script');
      intercom.setAttribute('id', 'intercom');
      intercom.setAttribute('src', '/scripts/intercom.js');
      document.body.appendChild(intercom);
    }
  },
  unmounted() {
    const { scrollArea } = this.$refs;

    if (scrollArea) {
      scrollArea.removeEventListener('scroll', this.handleScroll);
    }

    // eslint-disable-next-line no-undef
    if (this.hasIntercom && typeof Intercom !== 'undefined') {
      Intercom('shutdown');
      document.getElementById('intercom').remove();
    }
  },
  methods: {
    handleScroll() {
      const { scrollArea, mainArea } = this.$refs;
      const { scrollTop } = scrollArea;

      clearTimeout(this.scrollTimer);
      this.scrollTimer = setTimeout(() => {
        if (scrollTop > this.scrollTrigger && scrollTop > this.lastScrollTop) {
          this.$store.commit(types.mutations.SET_SCROLL, 'DOWN');
          mainArea.classList.add('scrolling-down');
        } else {
          this.$store.commit(types.mutations.SET_SCROLL, 'UP');
          mainArea.classList.remove('scrolling-down');
        }
        this.lastScrollTop = scrollTop;
      }, 0);
    },
  }
};
</script>

<style lang="scss" scoped>
.default-layout {
  display: flex;
  background: $light;

  .main {
    position: relative;
    display: flex;
    padding-top: 3.5rem;
    max-width: 100%;
    flex: 1;
    flex-direction: column;
    overflow: hidden;

    @include media-breakpoint-up(lg) {
      padding-top: 4.375rem;
    }
    .header,
    .mobile-header {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: 9;
    }
    .mobile-header {
      position: fixed;
    }

    .section {
      overflow: auto;
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-bottom: 2rem;
      height: 100vh;
      justify-content: space-between;
      @include media-breakpoint-up(lg) {
        padding: 0 2rem 2rem 2rem;
      }
    }
  }
}
</style>
