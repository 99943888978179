import axios from 'axios';
import { processFilterParameters } from '@/util/createAPIParams.js';

/**
 * Interface to the ConfigVar API endpoints
 * @class
 * @exports src/services/ConfigVarAPI
 */
class ConfigVarAPI {
  /**
   * Retrieves all the ConfigVars
   * @method
   * @static
   * @return {Promise}          Promise that resolves to an array of {@link ConfigVar} items.
   */
  static async getConfigVars(filter) {
    const response = await axios({
      method: 'GET',
      url: 'config_var',
      responseType: 'json',
      params: processFilterParameters(filter),
    });

    return response.data.data;
  }

  static async update(key, value) {
    const response = await axios({
      method: 'POST',
      url: `config_var/${key}`,
      responseType: 'json',
      data: { value },
    });

    return response.data.data;
  }
}

export default ConfigVarAPI;
