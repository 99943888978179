import Base from './Base.js';

export default function ServiceTypesServedOptionsCreator() {
  class ServiceTypesServedOptions extends Base {}

  ServiceTypesServedOptions.initEnum({
    NONE: {
      value: 'NONE',
      display: 'None',
    },
    ALL: {
      value: 'ALL',
      display: 'All',
    },
    SELECTED: {
      value: 'SELECTED',
      display: 'Selected',
    },
  });

  return ServiceTypesServedOptions;
}
