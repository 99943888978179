import APIError from '@/errors/APIError.js';
import types from '@/store/types/dashboard.js';
import DashboardAPI from '@/services/DashboardAPI.js';
import ApiStatusCreator from '../../../enums/API.js';
const API_STATUS = ApiStatusCreator();

const a = types.actions;
const m = types.mutations;

export default {
  /**
   * Loads specific Dashboard details
   * @param {Object} context
   * @param {number} id The Dashboard ID to load
   * @return {Dashboard} A {@link Dashboard} object.
   */
  async [a.GET_DASHBOARD]({ commit, state }, { id }) {
    try {
      commit(m.SET_ACTIVE_DASHBOARD, null);
      commit(m.SET_ACTIVE_DASHBOARD_STATE, API_STATUS.LOADING);

      const dashboard = await DashboardAPI.getDashboard(id);

      dashboard.created_at = new Date(dashboard.created_at);
      dashboard.updated_at = new Date(dashboard.updated_at);

      commit(m.SET_ACTIVE_DASHBOARD, dashboard);
      commit(m.SET_ACTIVE_DASHBOARD_STATE, API_STATUS.SUCCESS);
    } catch (error) {
      commit(m.SET_ACTIVE_DASHBOARD_STATE, API_STATUS.FAILURE);
      let message = 'An error occurred while fetching this Dashboard. Please try again.';

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return state.dashboards;
  },

  /**
   * Creates a new Dashboard
   * @param {Object} context
   * @param {object} dashboard A {@link Dashboard} object
   * @return {Dashboard} A {@link Dashboard} object.
   */
  async [a.CREATE_DASHBOARD]({ commit, state }, { dashboard }) {
    try {
      commit(m.SET_ACTIVE_DASHBOARD_ERRORS, []);
      commit(m.SET_ACTIVE_DASHBOARD_STATE, API_STATUS.SAVING);

      await DashboardAPI.createDashboard(dashboard);

      commit(m.SET_ACTIVE_DASHBOARD_STATE, API_STATUS.SUCCESS);
    } catch (error) {
      commit(m.SET_ACTIVE_DASHBOARD_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while creating this Dashboard. Please try again.';

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeDashboard;
  },

  /**
   * Updates an existing Dashboard
   * @param {Object} context
   * @param {object} dashboard The {@link Dashboard} object to update
   * @param {object} modifiedFields An object with the key/values for the fields to update.
   * @return {Dashboard} A {@link Dashboard} object.
   */
  async [a.UPDATE_DASHBOARD]({ commit, state }, { dashboard, modifiedFields }) {
    try {
      commit(m.SET_ACTIVE_DASHBOARD_ERRORS, []);
      commit(m.SET_ACTIVE_DASHBOARD_STATE, API_STATUS.SAVING);

      const copy = { ...modifiedFields };

      const dashboardResponse = await DashboardAPI.updateDashboard(dashboard.id, copy);

      commit(m.SET_ACTIVE_DASHBOARD_STATE, API_STATUS.SUCCESS);

      // If the current active dashboard is the same as the dashboard we are editing
      // then we should update the active dashboard state to reflect the changes
      if (state.activeDashboard?.id === state.editDashboard?.id) {
        commit(m.SET_ACTIVE_DASHBOARD, {
          ...dashboardResponse,
        });
      }
    } catch (error) {
      commit(m.SET_ACTIVE_DASHBOARD_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while updating this Dashboard. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeDashboard;
  },

  /**
   * Deletes a Dashboard
   * @param {Object} context
   * @param {object} dashboard The {@link Dashboard} object to delete
   * @return {Dashboard} A {@link Dashboard} object.
   */
  async [a.DELETE_DASHBOARD]({ commit }, { dashboard }) {
    try {
      commit(m.SET_ACTIVE_DASHBOARD_ERRORS, []);
      commit(m.SET_ACTIVE_DASHBOARD_STATE, API_STATUS.DELETING);

      await DashboardAPI.deleteDashboard(dashboard.id);

      commit(m.SET_ACTIVE_DASHBOARD_STATE, API_STATUS.SUCCESS);
    } catch (error) {
      commit(m.SET_ACTIVE_DASHBOARD_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while deleting this Dashboard. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return true;
  },
};
