export default {
  filter: {},
  page: 1,
  sort: 'title',
  sortDirection: 'ASC',
  enabled: [
    { id: true, name: 'Yes' },
    { id: false, name: 'No' },
  ],
  enabledFilter: [
    { id: null, name: 'All' },
    { id: true, name: 'Yes' },
    { id: false, name: 'No' },
  ],
  meta: {},
  activeDashboard: null,
  editDashboard: {},
  activeDashboardState: null,
  activeDashboardErrors: [],
  activeDashboardValid: false,
  activeDashboardModifiedFields: {},
};
