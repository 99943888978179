export default {
  path: '/maintainable-entity',
  meta: {
    requiresAuth: true,
    layout: 'DefaultLayout',
    bcLinkText: 'Assets',
    storeModules: [
      'maintainableEntity',
      'serviceType',
      'siteType',
      'conditionGrade',
      'buildingType',
      'documentType',
      'document',
    ],
  },
  component: () => import('@/views/maintainable-entities/MaintainableEntities.vue'),
  children: [
    {
      path: 'view/:maintainableEntityType?/:id/:pane?',
      name: 'MaintainableEntityDisplay',
      component: () => import('@/views/maintainable-entities/MaintainableEntityDisplay.vue'),
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        bcDynamic: true,
        bcGetter: 'getActiveMaintainableEntity',
        bcLinkText: (maintainableEntity) => maintainableEntity?.name,
        bcLoadingText: 'Loading Location/Asset...',
      },
    },
  ],
};
