import APIError from '../../../errors/APIError.js';
import types from '../../types/costCode.js';
import CostCodeAPI from '../../../services/CostCodeAPI.js';
import ApiStatusCreator from '../../../enums/API.js';
const API_STATUS = ApiStatusCreator();

const a = types.actions;
const m = types.mutations;

export default {
  /**
   * Loads all CostCodes
   * @param {Object} context
   * @return {CostCode[]} An array of {@link CostCode} objects.
   * @param page
   * @param sort
   * @param direction
   * @param filter
   */
  async [a.GET_COST_CODES]({ commit, state }, { page, sort, direction, filter }) {
    commit(m.SET_COST_CODES_STATUS, API_STATUS.LOADING);

    try {
      const costCodes = await CostCodeAPI.getCostCodes(page, sort, direction, filter);

      commit(m.SET_COST_CODES_STATUS, API_STATUS.SUCCESS);
      commit(m.SET_COST_CODES, costCodes.data);
      if (costCodes.meta) {
        commit(m.SET_COST_CODES_META, costCodes.meta);
      }
    } catch (error) {
      commit(m.SET_COST_CODES_STATUS, API_STATUS.FAILURE);

      let message = 'An error occurred while fetching these Cost Codes. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.costCodes;
  },

  /**
   * Creates a new CostCode
   * @param {Object} context
   * @param {object} costCode A {@link CostCode} object
   * @return {CostCode} A {@link CostCode} object.
   */
  async [a.CREATE_COST_CODE]({ commit, state }, { costCode }) {
    try {
      commit(m.SET_ACTIVE_COST_CODE_ERRORS, []);
      commit(m.SET_ACTIVE_COST_CODE_STATE, API_STATUS.SAVING);

      const costCodeResponse = await CostCodeAPI.createCostCode(costCode);

      commit(m.SET_ACTIVE_COST_CODE_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_COST_CODE, costCodeResponse);
      commit(m.ADD_LOCAL_COST_CODE, costCodeResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_COST_CODE_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while creating this Cost Code. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeCostCode;
  },

  /**
   * Updates an existing CostCode
   * @param {Object} context
   * @param {object} costCode The {@link CostCode} object to update
   * @param {object} modifiedFields An object with the key/values for the fields to update.
   * @return {CostCode} A {@link CostCode} object.
   */
  async [a.UPDATE_COST_CODE]({ commit, state }, { costCode, modifiedFields }) {
    try {
      commit(m.SET_ACTIVE_COST_CODE_ERRORS, []);
      commit(m.SET_ACTIVE_COST_CODE_STATE, API_STATUS.SAVING);

      const costCodeResponse = await CostCodeAPI.updateCostCode(costCode.id, modifiedFields);

      commit(m.SET_ACTIVE_COST_CODE_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_COST_CODE, costCodeResponse);
      commit(m.UPDATE_LOCAL_COST_CODE, costCodeResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_COST_CODE_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while updating this Cost Code. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeCostCode;
  },

  /**
   * Deletes a CostCode
   * @param {Object} context
   * @param {CostCode} costCode The {@link CostCode} object to delete
   * @return {CostCode} A {@link CostCode} object.
   */
  async [a.DELETE_COST_CODE]({ commit }, { costCode }) {
    try {
      commit(m.SET_ACTIVE_COST_CODE_ERRORS, []);
      commit(m.SET_ACTIVE_COST_CODE_STATE, API_STATUS.DELETING);

      await CostCodeAPI.deleteCostCode(costCode.id);

      commit(m.SET_ACTIVE_COST_CODE_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_COST_CODE, null);

      commit(m.REMOVE_LOCAL_COST_CODE, costCode);
    } catch (error) {
      commit(m.SET_ACTIVE_COST_CODE_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while deleting this Cost Code. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return true;
  },
};
