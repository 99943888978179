import Base from './Base.js';

export default function SessionCreator() {
  class Session extends Base {}

  Session.initEnum({
    LOGGED_OUT: {
      value: 'logged_out',
      display: 'Logged Out',
    },
    LOGGING_IN: {
      value: 'logging_in',
      display: 'Logging In',
    },
    LOGGED_IN: {
      value: 'logged_in',
      display: 'Logged In',
    },
    LOGIN_FAILED: {
      value: 'login_failed',
      display: 'Login Failed',
    },
    LOGGING_OUT: {
      value: 'logging_out',
      display: 'Logging Out',
    },
    LOGOUT_FAILED: {
      value: 'logout_failed',
      display: 'Logout Failed',
    },

    SESSION_EXPIRED: {
      value: 'session_expired',
      display: 'Session Expired',
    },

    PASSWORD_CHANGE: {
      value: 'force_password_change',
      display: 'Password change required',
    },
  });

  return Session;
}
