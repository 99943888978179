import Base from './Base.js';

export default function EngineerTypeCreator(i18n) {
  class EngineerType extends Base {}

  EngineerType.initEnum({
    MAIN: {
      value: 'MAIN',
      display: i18n.global.t('enums.EngineerType.main'),
    },
    ASSISTANT: {
      value: 'ASSISTANT',
      display: i18n.global.t('enums.EngineerType.assistant'),
    },
  });

  return EngineerType;
}
