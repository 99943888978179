import SessionCreator from '../../../enums/Session.js';
import UserTypeCreator from '../../../enums/UserType.js';

const SESSION_STATUS = SessionCreator();
const USER_TYPE = UserTypeCreator();

export default {
  isError(state) {
    return !![
      SESSION_STATUS.LOGIN_FAILED,
      SESSION_STATUS.LOGOUT_FAILED,
      SESSION_STATUS.SESSION_EXPIRED,
    ].find((status) => status.eql(state.session_status));
  },

  isLoggedIn(state) {
    return !!(
      SESSION_STATUS.LOGGED_IN.eql(state.session_status) ||
      SESSION_STATUS.PASSWORD_CHANGE.eql(state.session_status)
    );
  },

  isForcedToChangePassword(state) {
    return !!SESSION_STATUS.PASSWORD_CHANGE.eql(state.session_status);
  },

  isContractor(state) {
    return state.user && USER_TYPE.CONTRACTOR.eql(state.user.user_type);
  },

  isContractorOnboarding(state) {
    return state.user
      && state.user.isContractorOnboarding;
  },

  /**
   * Returns true if the given permission(s) are ALL present in the current user's `permission`
   * array. If an empty/blank permission is passed in, then it will always return true.
   * @param  {string|string[]}  permission The permission to check (e.g. User_Manage)
   * @return {Boolean}
   */
  hasPermission(state, getters) {
    return (permission) => {
      const perms = getters.hasPermissionAll(permission);

      // check each permission and combine the result
      return perms.reduce((out, p) => out && p, true);
    };
  },

  /**
   * Returns true if ONE of the given permission(s) are present in the current user's `permission`
   * array. If an empty/blank permission is passed in, then it will always return true.
   * @param  {string|string[]}  permission The permission to check (e.g. User_Manage)
   * @return {Boolean}
   */
  hasPermissionOr(state, getters) {
    return (permission) => {
      const perms = getters.hasPermissionAll(permission);

      // check each permission and combine the result
      return perms.reduce((out, p) => out || p, false);
    };
  },

  /**
   * Returns an array of booleans for each of the permissions being queried.
   * @param  {string|string[]}  permission The perm to check (e.g. User_Manage or [User_Manage])
   * @return {Boolean[]}
   */
  hasPermissionAll(state) {
    return (permission) => {
      if (!permission || permission.length === 0) {
        return [true];
      }

      const permissions = (state.user && state.user.permissions) || [];

      // turn the perm we're checking for into an array if it isn't
      const perm = Array.isArray(permission) ? permission : [permission];

      return perm.map((p) => permissions.indexOf(p) >= 0);
    };
  },

  getTimezone: (state) => state?.user?.timezone,

  getLocale: (state) => state?.user?.locale,

  // eslint-disable-next-line camelcase
  isImpersonating: (state) => !!state.user?.meta?.impersonator_user_id,
};
