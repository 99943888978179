/* eslint-disable */

// Load intersection-observer polyfill
const fillIntersectionOberserver = () => new Promise((resolve) => {
  if ('IntersectionObserver' in window) return resolve();

  require.ensure([], () => {
    require('intersection-observer');

    resolve();
  }, 'IntersectionObserver');
});

export default fillIntersectionOberserver;
