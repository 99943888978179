import types from '../../types/servicePriority.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_SERVICE_PRIORITIES
   * @summary Updates the `servicePriorities` property.
   * @method
   * @param {Object} state Current state
   * @param {ServicePriority[]} servicePriorities The loaded ServicePriorities array
   */
  [m.SET_SERVICE_PRIORITIES](state, servicePriorities) {
    state.servicePriorities = servicePriorities;
  },

  [m.SET_SERVICE_PRIORITIES_STATUS](state, status) {
    state.servicePrioritiesState = status;
  },

  /**
   * @name m SET_SERVICE_PRIORITIES_META
   * @summary Updates the `meta` property which holds the meta data for the
   * `SERVICE_PRIORITIES` collection.
   * For example, total rows, current page, number rows per page etc
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The meta data to store
   */
  [m.SET_SERVICE_PRIORITIES_META](state, meta) {
    state.meta = meta;
  },

  /**
   * @name m SET_SERVICE_PRIORITIES_FILTER
   * @summary Updates the `filter` property which holds the collection of filters currently active
   * on the ServicePriorities collection
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The filters to be applied
   */
  [m.SET_SERVICE_PRIORITIES_FILTER](state, filter) {
    state.filter = filter;
  },

  /**
   * @name m SET_ACTIVE_SERVICE_PRIORITY
   * @summary Updates the `activeServicePriority` and resets the
   * `activeServicePriorityModifiedFields` & `activeServicePriorityErrors` properties
   * @method
   * @param {Object} state Current state
   * @param {ServicePriority} servicePriority The new Active {@link ServicePriority}
   */
  [m.SET_ACTIVE_SERVICE_PRIORITY](state, servicePriority) {
    state.activeServicePriority = servicePriority;
    state.activeServicePriorityModifiedFields =
      !servicePriority || servicePriority.id ? {} : { ...servicePriority };
    state.activeServicePriorityErrors = [];
  },

  /**
   * @name m SET_ACTIVE_SERVICE_PRIORITY_STATE
   * @summary Updates the `activeServicePriorityState`
   * property which holds the current status of the
   * based on the API enum
   * @method
   * @param {Object} state Current state
   * @param {enums/API} activeServicePriorityState The current activeServicePriorityState
   * (e.g. loading, success, failure)
   */
  [m.SET_ACTIVE_SERVICE_PRIORITY_STATE](state, activeServicePriorityState) {
    state.activeServicePriorityState = activeServicePriorityState;
  },

  /**
   * @name m SET_ACTIVE_SERVICE_PRIORITY_ERRORS
   * @summary Updates the `activeServicePriorityErrors` property which holds any
   * errors related to activeServicePriority
   * @method
   * @param {Object} state Current state
   * @param {string[]} errors An array of error messages
   */
  [m.SET_ACTIVE_SERVICE_PRIORITY_ERRORS](state, errors) {
    state.activeServicePriorityErrors = errors;
  },

  /**
   * @name m SET_ACTIVE_SERVICE_PRIORITY_VALID
   * @summary Updates the `activeServicePriorityValid` property which holds
   * a boolean for the validation status of the `activeServicePriority`.
   * @method
   * @param {Object} state Current state
   * @param {boolean} valid True if the `activeServicePriority` is valid.
   */
  [m.SET_ACTIVE_SERVICE_PRIORITY_VALID](state, valid) {
    state.activeServicePriorityValid = valid;
  },

  /**
   * @name m UPDATE_ACTIVE_SERVICE_PRIORITY_MODIFIED_FIELDS
   * @summary Adds given field/value pair to the
   * `activeServicePriorityModifiedFields` collection, which
   * will be sent as a PATCH to the API. If the given `value`
   * equals the one on the `activeServicePriority`
   * object (i.e. it hasn't actually changed, or has been reverted back to it's original) then we
   * remove that field from the modifiedFields collection
   * @method
   * @param {Object} state Current state
   * @param {object} payload Object containing a `field` and `value` properties.
   */
  [m.UPDATE_ACTIVE_SERVICE_PRIORITY_MODIFIED_FIELDS](state, { field, value }) {
    // if the value matches the existing one then we remove the field from the modifiedFields obj
    if (state.activeServicePriority && state.activeServicePriority[field] === value) {
      delete state.activeServicePriorityModifiedFields[field];
    } else {
      state.activeServicePriorityModifiedFields[field] = value;
    }
  },

  /**
   * @name m CLEAR_ACTIVE_SERVICE_PRIORITY_MODIFIED_FIELDS
   * @summary Empties the `activeServicePriorityModifiedFields` object.
   * Used to ensure we're on a clean
   * slate after navigating to a ServicePriority Display screen,
   * @method
   * @param {Object} state Current state
   */
  [m.CLEAR_ACTIVE_SERVICE_PRIORITY_MODIFIED_FIELDS](state) {
    state.activeServicePriorityModifiedFields = {};
  },

  /**
   * @name m REMOVE_LOCAL_SERVICE_PRIORITY
   * @summary Removes the given servicePriority from the `servicePriorities` array.
   * This is used to prevent a
   * full reload being needed after deleting a ServicePriority.
   * @method
   * @param {Object} state Current state
   * @param {object} servicePriority The ServicePriority to remove.
   */
  [m.REMOVE_LOCAL_SERVICE_PRIORITY](state, servicePriority) {
    const index = state.servicePriorities.findIndex((u) => u.id === servicePriority.id);

    if (index >= 0) {
      state.servicePriorities.splice(index, 1);
    }
  },

  /**
   * @name m ADD_LOCAL_SERVICE_PRIORITY
   * @summary Adds the given servicePriority to the `servicePriorities` array.
   * This is used to prevent a
   * full reload being needed after adding a ServicePriority.
   * @method
   * @param {Object} state Current state
   * @param {object} servicePriority The ServicePriority to add.
   */
  [m.ADD_LOCAL_SERVICE_PRIORITY](state, servicePriority) {
    state.servicePriorities.push(servicePriority);
  },

  /**
   * @name m UPDATE_LOCAL_SERVICE_PRIORITY
   * @summary Updates the related servicePriority object in the `servicePriorities`
   * array after an update.
   * @method
   * @param {Object} state Current state
   * @param {object} servicePriority The ServicePriority to update.
   */
  [m.UPDATE_LOCAL_SERVICE_PRIORITY](state, servicePriority) {
    const localServicePriority = state.servicePriorities.find((c) => c.id === servicePriority.id);

    if (localServicePriority) {
      Object.assign(localServicePriority, servicePriority);
    }
  },
};
