export default {
  state: {
    /**
     * A Site definition
     * @typedef {Object} Site
     * @property {number} id
     * @property {string} reference
     * @property {string} name
     * @property {string} formatted_name
     * @property {string} address
     * @property {string} city
     * @property {string} county
     * @property {string} postcode
     * @property {array} tag_ids
     * @property {object} country (id, name)
     * @property {string} latitude
     * @property {string} longitude
     * @property {string} telephone
     * @property {string} notes
     * @property {string} permit_instructions
     * @property {object} landlord (id, name, address, telephone, email,
     * notes, created_at, updated_at)
     * @property {string} site_type
     * @property {string} access_times
     * @property {number} enabled
     * @property {string} created_at
     * @property {string} updated_at
     */
    site: () => ({
      attributes: {
        id: null,
        name: null,
        reference: null,
        address: null,
        city: null,
        county: null,
        postcode: null,
        organisation_id: null,
        tag_ids: [],
        country_id: null,
        latitude: null,
        longitude: null,
        telephone: null,
        notes: null,
        permit_instructions: null,
        landlord_id: null,
        site_type_id: null,
        access_times: null,
        test: false,
        created_at: null,
        updated_at: null,
        timezone: null,
        built_date: null,
        lease_end_date: null,
        total_space: null,
        usable_space: null,
        number_of_floors: null,
        number_of_parking_spaces: null,
        opening_times: null,
        disabled_access: null,
        number_of_lifts: null,
        hazards: null,
        number_of_meeting_rooms: null,
        number_of_employees: null,
        capacity: null,
      },
    }),
  },

  getters: {},

  actions: {
    GET_SITE: 'GET_SITE',
    UPDATE_SITE: 'UPDATE_SITE',
    CREATE_SITE: 'CREATE_SITE',
    DELETE_SITE: 'DELETE_SITE',
  },

  mutations: {
    SET_SITES: 'SET_SITES',
    SET_SITES_STATUS: 'SET_SITES_STATUS',

    SET_ACTIVE_SITE: 'SET_ACTIVE_SITE',
    SET_ACTIVE_SITE_STATE: 'SET_ACTIVE_SITE_STATE',
    SET_ACTIVE_SITE_ERRORS: 'SET_ACTIVE_SITE_ERRORS',
    SET_ACTIVE_SITE_VALID: 'SET_ACTIVE_SITE_VALID',
    UPDATE_ACTIVE_SITE_MODIFIED_FIELDS: 'UPDATE_ACTIVE_SITE_MODIFIED_FIELDS',
    CLEAR_ACTIVE_SITE_MODIFIED_FIELDS: 'CLEAR_ACTIVE_SITE_MODIFIED_FIELDS',

    SET_SITES_FILTER: 'SET_SITES_FILTER',
    SET_SITES_SORT: 'SET_SITES_SORT',
  },
};
