import types from '../../types/documentType.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_DOCUMENT_TYPES
   * @summary Updates the `documentTypes` property.
   * @method
   * @param {Object} state Current state
   * @param {DocumentType[]} documentTypes The loaded DocumentTypes array
   */
  [m.SET_DOCUMENT_TYPES](state, documentTypes) {
    state.documentTypes = documentTypes;
  },

  [m.SET_DOCUMENT_TYPES_STATUS](state, status) {
    state.documentTypesStatus = status;
  },

  /**
   * @name m SET_DOCUMENT_TYPES_META
   * @summary Updates the `meta` property which holds the meta data for the `DOCUMENT_TYPES` collection
   * For example, total rows, current page, number rows per page etc
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The meta data to store
   */
  [m.SET_DOCUMENT_TYPES_META](state, meta) {
    state.meta = meta;
  },

  /**
   * @name m SET_DOCUMENT_TYPES_FILTER
   * @summary Updates the `filter` property which holds the collection of filters currently active
   * on the Currencies collection
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The filters to be applied
   */
  [m.SET_DOCUMENT_TYPES_FILTER](state, filter) {
    state.filter = filter;
  },

  /**
   * @name m SET_ACTIVE_DOCUMENT_TYPE
   * @summary Updates the `activeDocumentType` and resets the
   * `activeDocumentTypeModifiedFields` & `activeDocumentTypeErrors` properties
   * @method
   * @param {Object} state Current state
   * @param {DocumentType} documentType The new Active {@link DocumentType}
   */
  [m.SET_ACTIVE_DOCUMENT_TYPE](state, documentType) {
    state.activeDocumentType = documentType;
    state.activeDocumentTypeModifiedFields =
      !documentType || documentType.id ? {} : { ...documentType };
    state.activeDocumentTypeErrors = [];
  },

  /**
   * @name m SET_ACTIVE_DOCUMENT_TYPE_STATE
   * @summary Updates the `activeDocumentTypeState` property which holds the current status of the
   * based on the API enum
   * @method
   * @param {Object} state Current state
   * @param {enums/API} activeDocumentTypeState The current activeDocumentTypeState
   * (e.g. loading, success, failure)
   */
  [m.SET_ACTIVE_DOCUMENT_TYPE_STATE](state, activeDocumentTypeState) {
    state.activeDocumentTypeState = activeDocumentTypeState;
  },

  /**
   * @name m SET_ACTIVE_DOCUMENT_TYPE_ERRORS
   * @summary Updates the `activeDocumentTypeErrors` property which holds any
   * errors related to activeDocumentType
   * @method
   * @param {Object} state Current state
   * @param {string[]} errors An array of error messages
   */
  [m.SET_ACTIVE_DOCUMENT_TYPE_ERRORS](state, errors) {
    state.activeDocumentTypeErrors = errors;
  },

  /**
   * @name m SET_ACTIVE_DOCUMENT_TYPE_VALID
   * @summary Updates the `activeDocumentTypeValid` property which holds
   * a boolean for the validation status of the `activeDocumentType`.
   * @method
   * @param {Object} state Current state
   * @param {boolean} valid True if the `activeDocumentType` is valid.
   */
  [m.SET_ACTIVE_DOCUMENT_TYPE_VALID](state, valid) {
    state.activeDocumentTypeValid = valid;
  },

  /**
   * @name m UPDATE_ACTIVE_DOCUMENT_TYPE_MODIFIED_FIELDS
   * @summary Adds given field/value pair to the `activeDocumentTypeModifiedFields` collection, which
   * will be sent as a PATCH to the API. If the given `value` equals the one on the
   * `activeDocumentType` object (i.e. it hasn't actually changed, or has been reverted back to it's
   * original) then we remove that field from the modifiedFields collection
   * @method
   * @param {Object} state Current state
   * @param {object} payload Object containing a `field` and `value` properties.
   */
  [m.UPDATE_ACTIVE_DOCUMENT_TYPE_MODIFIED_FIELDS](state, { field, value }) {
    // if the value matches the existing one then we remove the field from the modifiedFields obj
    if (state.activeDocumentType && state.activeDocumentType[field] === value) {
      delete state.activeDocumentTypeModifiedFields[field];
    } else {
      state.activeDocumentTypeModifiedFields[field] = value;
    }
  },

  /**
   * @name m CLEAR_ACTIVE_DOCUMENT_TYPE_MODIFIED_FIELDS
   * @summary Empties the `activeDocumentTypeModifiedFields` object. Used to ensure we're on a clean
   * slate after navigating to a DocumentType Display screen,
   * @method
   * @param {Object} state Current state
   */
  [m.CLEAR_ACTIVE_DOCUMENT_TYPE_MODIFIED_FIELDS](state) {
    state.activeDocumentTypeModifiedFields = {};
  },

  /**
   * @name m REMOVE_LOCAL_DOCUMENT_TYPE
   * @summary Removes the given documentType from the `documentTypes` array. This is used to prevent a
   * full reload being needed after deleting a DocumentType.
   * @method
   * @param {Object} state Current state
   * @param {object} documentType The DocumentType to remove.
   */
  [m.REMOVE_LOCAL_DOCUMENT_TYPE](state, documentType) {
    const index = state.documentTypes.findIndex((u) => u.id === documentType.id);

    if (index >= 0) {
      state.documentTypes.splice(index, 1);
    }
  },

  /**
   * @name m ADD_LOCAL_DOCUMENT_TYPE
   * @summary Adds the given documentType to the `documentTypes` array. This is used to prevent a
   * full reload being needed after adding a DocumentType.
   * @method
   * @param {Object} state Current state
   * @param {object} documentType The DocumentType to add.
   */
  [m.ADD_LOCAL_DOCUMENT_TYPE](state, documentType) {
    state.documentTypes.push(documentType);
  },

  /**
   * @name m UPDATE_LOCAL_DOCUMENT_TYPE
   * @summary Updates the related documentType object in the `documentTypes` array after an update.
   * @method
   * @param {Object} state Current state
   * @param {object} documentType The DocumentType to update.
   */
  [m.UPDATE_LOCAL_DOCUMENT_TYPE](state, documentType) {
    const localDocumentType = state.documentTypes.find((c) => c.id === documentType.id);

    if (localDocumentType) {
      Object.assign(localDocumentType, documentType);
    }
  },
};
