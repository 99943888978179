<template>
  <div translate="yes">
    <div class="view-header d-flex align-items-center">
      <div class="flex-1 min-width-0 pr-2">
        <Breadcrumbs
          v-if="!hideOnMobile"
          :key="JSON.stringify(entity)"
          :routes="getRoutes"
          :entity="entity"
          :allow-duplicate="allowDuplicate"
        />
        <BackButton v-else :back-button-route="backButtonRoute" />
        <slot name='belowBreadcrumbs'/>
      </div>
      <slot />
    </div>
    <slot name="tags"></slot>
    <slot name="information"></slot>
  </div>
</template>

<script>
import Breadcrumbs from '@/ux/breadcrumbs/Breadcrumbs.vue';
import BackButton from '@/ux/breadcrumbs/BackButton.vue';

export default {
  name: 'ViewHeader',
  inject: ["mq"],
  components: {
    Breadcrumbs,
    BackButton,
  },
  props: {
    entity: {
      type: Object,
      default: () => null,
    },
    view: {
      type: String,
      default: '',
    },
    routes: {
      type: Array,
      default: () => null,
    },
    backButtonRoute: {
      type: String,
      default: '',
    },
    allowDuplicate: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getRoutes() {
      if (this.routes) {
        return this.routes;
      }
      return this.$route.matched;
    },
    hideOnMobile() {
      if (this.view && this.view === 'workOrder' && this.mobile) {
        return true;
      }
      return false;
    },
    mobile() {
      if (this.mq.current === 'sm' || this.mq.current === 'md' || this.mq.current === 'xs') {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.view-header {
  width: 100%;
  margin: 0;
  padding: 1rem;

  @include media-breakpoint-up(lg) {
    padding: 1.5rem 0;
  }
}
</style>
