import axios from 'axios';
import createAPIParams from '@/util/createAPIParams.js';

/**
 * Interface to the WorkOrderAvailableServices API endpoints
 * @class
 * @exports src/services/WorkOrderAvailableServicesAPI
 */
class WorkOrderAvailableServicesAPI {
  /**
   * Retrieves available work order services
   * @return {Promise} Promise that resolves to the api response.
   */
  static async getWorkOrderAvailableServices(page, sort, direction, filter) {
    const response = await axios({
      method: 'GET',
      url: 'service/available',
      responseType: 'json',
      params: createAPIParams(page, sort, direction, filter),
    });

    return response.data.data;
  }
}

export default WorkOrderAvailableServicesAPI;
