import types from '../../types/tag.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_TAGS
   * @summary Updates the `Tags` property.
   * @method
   * @param {Object} state Current state
   * @param {Tags[]} tag The loaded Tags array
   */
  [m.SET_TAGS](state, tags) {
    state.tags = tags;
  },

  [m.SET_TAGS_STATUS](state, status) {
    state.tagsState = status;
  },

  /**
   * @name m SET_TAGS_META
   * @summary Updates the `meta` property which holds the meta data for the `TAGS` collection.
   * For example, total rows, current page, number rows per page etc
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The meta data to store
   */
  [m.SET_TAGS_META](state, meta) {
    state.meta = meta;
  },

  /**
   * @name m SET_TAGS_FILTER
   * @summary Updates the `filter` property which holds the collection of filters currently active
   * on the Tags collection
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The filters to be applied
   */
  [m.SET_TAGS_FILTER](state, filter) {
    state.filter = filter;
  },

  /**
   * @name m SET_ACTIVE_TAG
   * @summary Updates the `activeTag` and resets the
   * `activeTagModifiedFields` & `activeTagErrors` properties
   * @method
   * @param {Object} state Current state
   * @param {Tag} Tag The new Active {@link Tag}
   */
  [m.SET_ACTIVE_TAG](state, Tag) {
    state.activeTag = Tag;
    state.activeTagModifiedFields = !Tag || Tag.id ? {} : { ...Tag };
    state.activeTagErrors = [];
  },

  /**
   * @name m SET_ACTIVE_TAG_STATE
   * @summary Updates the `activeTagState` property which holds the current status of the
   * based on the API enum
   * @method
   * @param {Object} state Current state
   * @param {enums/API} activeTagState The current activeTagState
   * (e.g. loading, success, failure)
   */
  [m.SET_ACTIVE_TAG_STATE](state, activeTagState) {
    state.activeTagState = activeTagState;
  },

  /**
   * @name m SET_ACTIVE_TAG_ERRORS
   * @summary Updates the `activeTagErrors` property which holds any
   * errors related to activeTag
   * @method
   * @param {Object} state Current state
   * @param {string[]} errors An array of error messages
   */
  [m.SET_ACTIVE_TAG_ERRORS](state, errors) {
    state.activeTagErrors = errors;
  },

  /**
   * @name m SET_ACTIVE_TAG_VALID
   * @summary Updates the `activeTagValid` property which holds
   * a boolean for the validation status of the `activeTag`.
   * @method
   * @param {Object} state Current state
   * @param {boolean} valid True if the `activeTag` is valid.
   */
  [m.SET_ACTIVE_TAG_VALID](state, valid) {
    state.activeTagValid = valid;
  },

  /**
   * @name m UPDATE_ACTIVE_TAG_MODIFIED_FIELDS
   * @summary Adds given field/value pair to the `activeTagModifiedFields` collection, which
   * will be sent as a PATCH to the API. If the given `value` equals the one on the `activeTag`
   * object (i.e. it hasn't actually changed, or has been reverted back to it's original) then we
   * remove that field from the modifiedFields collection
   * @method
   * @param {Object} state Current state
   * @param {object} payload Object containing a `field` and `value` properties.
   */
  [m.UPDATE_ACTIVE_TAG_MODIFIED_FIELDS](state, { field, value }) {
    // if the value matches the existing one then we remove the field from the modifiedFields obj
    if (state.activeTag && state.activeTag[field] === value) {
      delete state.activeTagModifiedFields[field];
    } else {
      state.activeTagModifiedFields[field] = value;
    }
  },

  /**
   * @name m CLEAR_ACTIVE_TAG_MODIFIED_FIELDS
   * @summary Empties the `activeTagModifiedFields` object. Used to ensure we're on a clean
   * slate after navigating to a Tag Display screen,
   * @method
   * @param {Object} state Current state
   */
  [m.CLEAR_ACTIVE_TAG_MODIFIED_FIELDS](state) {
    state.activeTagModifiedFields = {};
  },

  /**
   * @name m REMOVE_LOCAL_TAG
   * @summary Removes the given Tag from the `Tags` array. This is used to prevent a
   * full reload being needed after deleting a Tag.
   * @method
   * @param {Object} state Current state
   * @param {object} tag The Tag to remove.
   */
  [m.REMOVE_LOCAL_TAG](state, tag) {
    const index = state.tags.findIndex((u) => u.id === tag.id);

    if (index >= 0) {
      state.tags.splice(index, 1);
    }
  },

  /**
   * @name m ADD_LOCAL_TAG
   * @summary Adds the given Tag to the `tags` array. This is used to prevent a
   * full reload being needed after adding a Tag.
   * @method
   * @param {Object} state Current state
   * @param {object} tag The Tag to add.
   */
  [m.ADD_LOCAL_TAG](state, tag) {
    state.tags.push(tag);
  },

  /**
   * @name m UPDATE_LOCAL_TAG
   * @summary Updates the related Tag object in the `tags` array after an update.
   * @method
   * @param {Object} state Current state
   * @param {object} tag The Tag to update.
   */
  [m.UPDATE_LOCAL_TAG](state, tag) {
    const localTag = state.tags.find((c) => c.id === tag.id);

    if (localTag) {
      Object.assign(localTag, tag);
    }
  },
};
