export default {
  getters: {},

  actions: {
    GET_LANDLORDS: 'GET_LANDLORDS',
    UPDATE_LANDLORD: 'UPDATE_LANDLORD',
    CREATE_LANDLORD: 'CREATE_LANDLORD',
    DELETE_LANDLORD: 'DELETE_LANDLORD',
  },

  mutations: {
    SET_LANDLORDS: 'SET_LANDLORDS',
    SET_LANDLORDS_STATUS: 'SET_LANDLORDS_STATUS',
    SET_LANDLORDS_META: 'SET_LANDLORDS_META',
    SET_LANDLORDS_FILTER: 'SET_LANDLORDS_FILTER',

    REMOVE_LOCAL_LANDLORD: 'REMOVE_LOCAL_LANDLORD',
    ADD_LOCAL_LANDLORD: 'ADD_LOCAL_LANDLORD',
    UPDATE_LOCAL_LANDLORD: 'UPDATE_LOCAL_LANDLORD',

    SET_ACTIVE_LANDLORD: 'SET_ACTIVE_LANDLORD',
    SET_ACTIVE_LANDLORD_STATE: 'SET_ACTIVE_LANDLORD_STATE',
    SET_ACTIVE_LANDLORD_ERRORS: 'SET_ACTIVE_LANDLORD_ERRORS',
    SET_ACTIVE_LANDLORD_VALID: 'SET_ACTIVE_LANDLORD_VALID',
    UPDATE_ACTIVE_LANDLORD_MODIFIED_FIELDS: 'UPDATE_ACTIVE_LANDLORD_MODIFIED_FIELDS',
    CLEAR_ACTIVE_LANDLORD_MODIFIED_FIELDS: 'CLEAR_ACTIVE_LANDLORD_MODIFIED_FIELDS',
  },
};
