export default {
  /**
   * @name g getActiveWorkOrder
   * @desc Returns the `activeWorkOrder` object
   * @param  {object}  state Current state
   * @return {object}
   */
  getActiveWorkOrder(state) {
    return state.activeWorkOrder;
  },

  /**
   * @name g isActiveWorkOrderPhantom
   * @desc Returns true if the activeWorkOrder is 'phantom' (i.e. has not been saved).
   * @param  {object}  state Current state
   * @return {Boolean}
   */
  isActiveWorkOrderPhantom(state) {
    return !(state.activeWorkOrder && state.activeWorkOrder.id);
  },

  getWorkOrderStatusById(state) {
    return (id) => state.workOrderStates.find((s) => s.id === id) || {};
  },

  getWorkOrderStatus() {
    return (wo) => {
      if (!wo.id) {
        return 'planned';
      }

      if (wo.cancelled_at) {
        return 'cancelled';
      }

      if (wo.completed_at && (!wo.compliance || wo.compliant_date)) {
        return 'complete';
      }

      // TODO: FIXME: $dayjs / app context is not available here.
      // Swapped for native Date functionality
      if (new Date(wo.due_date) < new Date()) {
        return 'overdue';
      }

      return 'scheduled';
    };
  },
};
