<template>
  <b-modal
    ref="modal"
    :visible="true"
    dialog-class="wo-create expansive-modal"
    scrollable
    size="lg"
    :hide-footer="true"
    :no-close-on-backdrop="true"
    :no-close-on-esc="true"
  >
    <template v-slot:modal-header>
      <div class="d-flex w-100 align-items-center">
        <b-button variant="none" @click="onCancelAdd">
          {{ $t('common.forms.cancel') }}
        </b-button>
        <span v-if="workOrder && workOrder.parent_work_order_id" class="flex-fill text-center">
          {{ $t('views.work-orders.WorkOrderCreate.linkedTitle') }}
        </span>
        <span v-else class="flex-fill text-center">
          {{ $t('views.work-orders.WorkOrderCreate.title') }}
        </span>
        <div class="btn-placeholder" />
      </div>
    </template>

    <div v-if="$enums.API_STATUS.SUCCESS.eql(status)" class="wrapper">
      <WorkOrderEdit
        :work-order="activeWorkOrder"
        :phantom="isActiveWorkOrderPhantom"
        :status="activeWorkOrderState"
        :errors="activeWorkOrderErrors"
        @input="onWorkOrderDetailChange"
        @formvalidate="onWorkOrderDetailValidate"
      />

      <SpinButton
        v-if="showSubmit"
        id="create-work-order-btn"
        variant="primary"
        class="w-100"
        :loading-msg="$t('common.forms.saving')"
        :loading="$enums.API_STATUS.SAVING.eql(activeWorkOrderState)"
        :disabled="$enums.API_STATUS.LOADING.eql(activeWorkOrderState) || !activeWorkOrderValid"
        @click="onSaveWorkOrder"
      >
        {{ $t('views.work-orders.WorkOrderCreate.buttons.create') }}
      </SpinButton>
    </div>

    <div v-else class="mt-5 mb-5 text-center">
      <b-spinner variant="primary" :label="$t('common.forms.loading')" />
    </div>
  </b-modal>
</template>

<script>
import { BModal, VBModal, BSpinner, BButton } from 'bootstrap-vue';
import loadStoreModules from '@/store/loader.js';
import serviceTypeTypes from '@/store/types/serviceType.js';
import workOrderTypes from '@/store/types/workOrder.js';
import { mapState, mapGetters } from 'vuex';
import SpinButton from '@/ux/SpinButton.vue';
import WorkOrderEdit from '@/views/work-orders/WorkOrderEdit.vue';

export default {
  name: 'WorkOrderCreateModal',
  components: {
    WorkOrderEdit,
    SpinButton,
    BModal,
    BSpinner,
    BButton,
  },
  directives: {
    'b-modal': VBModal,
  },
  props: {
    workOrder: {
      type: [Object],
      default: () => ({}),
    },
  },
  data() {
    return {
      status: null,

      // the Vuex store modules required for this component.
      // usually these would be configured in `router.js` but since this is a global
      // modal we have to do it here to avoid pre-loading them unnecessarily
      storeModules: [
        'workOrder',
        'serviceType',
        'site',
        'workOrderAvailableServices',
        'documentType',
        'document',
        'maintainableEntity',
      ],
      hotkeysEnabled:
        window.appConfig?.DEMO_HOTKEYS?.value ??
        window.appConfig?.DEMO_HOTKEYS?.default_value ??
        false,
    };
  },
  computed: {
    ...mapState({
      userId: (state) => state.authorization.user_id,
      activeWorkOrder: (state) => state.workOrder.activeWorkOrder,
      activeWorkOrderState: (state) => state.workOrder.activeWorkOrderState,
      activeWorkOrderErrors: (state) => state.workOrder.activeWorkOrderErrors,
      activeWorkOrderValid: (state) => state.workOrder.activeWorkOrderValid,
      activeWorkOrderModifiedFields: (state) => state.workOrder.activeWorkOrderModifiedFields,
      class() {
        return 'text-primary';
      },
    }),

    ...mapGetters(['hasPermission', 'isActiveWorkOrderPhantom']),

    showSubmit() {
      if (!this.activeWorkOrderModifiedFields.emergency) {
        return true;
      }

      if (this.hasPermission('WorkOrder_OverrideEmergencyProcedures')) {
        return true;
      }

      return (
        this.activeWorkOrderModifiedFields.requested_service_id &&
        this.hasPermission('WorkOrder_CreateEmergency')
      );
    },
  },
  watch: {},
  mounted() {
    if (this.hotkeysEnabled) {
      window.addEventListener('keydown', this.onKeyBind, true);
    }
  },
  unmounted() {
    window.removeEventListener('keydown', this.onKeyBind, true);
  },
  async created() {
    this.status = this.$enums.API_STATUS.LOADING;

    await loadStoreModules(this.storeModules);
    await this.loadRelatedData();
    this.initWorkOrder(this.workOrder);
    this.status = this.$enums.API_STATUS.SUCCESS;
  },
  methods: {
    onKeyBind(e) {
      if (e.key === '`') {
        this.onSaveWorkOrder();
      }
    },
    initWorkOrder(workOrder) {
      const baseWorkOrder = workOrder || {};
      // create the empty WO
      this.$store.commit(workOrderTypes.mutations.SET_ACTIVE_WORK_ORDER, {
        ...workOrderTypes.state.workOrder(),
        ...baseWorkOrder,
      });
    },
    onWorkOrderDetailChange(field, value) {
      const values = [];

      values.push({ field, value });

      // eslint-disable-next-line no-shadow
      values.forEach(({ field, value }) => {
        this.$store.commit(workOrderTypes.mutations.UPDATE_ACTIVE_WORK_ORDER_MODIFIED_FIELDS, {
          field,
          value,
        });
      });
    },
    onWorkOrderDetailValidate(valid) {
      this.$store.commit(workOrderTypes.mutations.SET_ACTIVE_WORK_ORDER_VALID, valid);
    },
    onCancelAdd() {
      this.hideAndEmit('global-work-order-create-cancel');
    },
    async onSaveWorkOrder() {
      const showToast = !this.activeWorkOrder.parent_work_order_id;
      try {
        const createdWorkOrder = await this.createWorkOrder();
        this.hideAndEmit('global-work-order-create-success', createdWorkOrder);

        if (showToast) {
          this.showToast(createdWorkOrder);
        }
      } catch (e) {
        this.$store.commit(workOrderTypes.mutations.SET_ACTIVE_WORK_ORDER_ERRORS, e.messages);
      }
    },
    hideAndEmit(evt, ...evtArgs) {
      this.$refs.modal.hide();

      setTimeout(() => {
        this.$globalEmit.emit(evt, ...evtArgs);
      }, 250);
    },
    async createWorkOrder() {
      this.correctPrimarylocationID();
      const workOrder = { ...this.activeWorkOrderModifiedFields };
      return this.$store.dispatch(workOrderTypes.actions.CREATE_WORK_ORDER, {
        workOrder,
      });
    },
    correctPrimarylocationID() {
      let newId = null;
      newId =
        this.activeWorkOrderModifiedFields.primary_location_id === -1
          ? this.activeWorkOrderModifiedFields.site_id
          : this.activeWorkOrderModifiedFields.primary_location_id;
      this.$store.commit(workOrderTypes.mutations.UPDATE_ACTIVE_WORK_ORDER_MODIFIED_FIELDS, {
        field: 'primary_location_id',
        value: newId,
      });
    },
    showToast(workOrder) {
      return this.$globalEmit.emit('toast', {
        severity: 'success',
        summary: this.$t('components.app.toast.workOrder.createdTitle'),
        detail: this.$t('components.app.toast.workOrder.createdMsg'),
        link: {
          text: workOrder.display_reference,
          route: {
            name: 'WorkOrderDisplay',
            params: {id: workOrder.id}
          }
        },
        icon: ['fas', 'check-circle'],
      });
    },
    async loadRelatedData() {
      try {
        await Promise.all([
          this.$store.dispatch(serviceTypeTypes.actions.GET_SERVICE_TYPES),
          this.$store.dispatch(workOrderTypes.actions.GET_WORK_ORDER_STATES, {}),
          this.$store.dispatch(workOrderTypes.actions.GET_WORK_ORDER_REQUEST_TYPES, {
            page: 'all',
            filter: { recurring: false },
          }),
        ]);
      } catch (e) {
        // eslint-disable no-empty-block
        // todo handle errors: refactor to centralise lookups
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  padding: 1.5rem 1.5rem 2.5rem 1.5rem;
}
</style>
