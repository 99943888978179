<template>
  <transition
    v-bind="$attrs"
    tag="div"
    name="slide"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave"
    @after-leave="afterLeave"
  >
    <slot />
  </transition>
</template>

<script>
export default {
  name: 'TransitionSlide',

  props: {
    fromRight: {
      type: Boolean,
      default: () => false,
    },
    toRight: {
      type: Boolean,
      default: () => false,
    },
    fromLeft: {
      type: Boolean,
      default: () => false,
    },
    toLeft: {
      type: Boolean,
      default: () => false,
    },
    disabled: {
      type: Boolean,
      default: () => false,
    },
    animateEnter: {
      type: Boolean,
      default: () => true,
    },
    animateExit: {
      type: Boolean,
      default: () => true,
    },
  },

  methods: {
    enter(el) {
      if (this.fromRight) {
        el.classList.add('from-right');
      }
      if (this.fromLeft) {
        el.classList.add('from-left');
      }
    },

    afterEnter(el) {
      el.classList.remove('from-right');
      el.classList.remove('from-left');

      this.$emit('afterenter');
    },

    leave(el) {
      if (this.toRight) {
        el.classList.add('to-right');
      }
      if (this.toLeft) {
        el.classList.add('to-left');
      }
    },

    afterLeave(el) {
      el.classList.remove('to-right');
      el.classList.remove('to-left');

      this.$emit('afterleave');
    },
  },
};
</script>

<style scoped>
* {
  will-change: transform;
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;
}
</style>

<style lang="scss">
.slide-enter-active,
.slide-leave-active {
  transition: transform 0.5s ease-in-out;
  overflow: hidden;
}

.from-left {
  &.slide-enter {
    transform: translate(-800px);
  }
}
.to-left {
  &.slide-leave-to {
    transform: translate(-800px);
  }
}

.from-right {
  &.slide-enter {
    transform: translate(800px);
  }
}

.to-right {
  &.slide-leave-to {
    transform: translate(800px);
  }
}

@include media-breakpoint-down(md) {
  .from-left {
    &.slide-enter {
      transform: translate(-100vw);
    }
  }
  .to-left {
    &.slide-leave-to {
      transform: translate(-100vw);
    }
  }

  .from-right {
    &.slide-enter {
      transform: translate(100vw);
    }
  }

  .to-right {
    &.slide-leave-to {
      transform: translate(100vw);
    }
  }
}

.v-enter-to {
  transform: translate(0);
}
</style>
