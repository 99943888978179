import axios from 'axios';
import findNodePath from '@/util/findNodePath.js';
import { processFilterParameters } from '@/util/createAPIParams.js';
import WorkOrderRequestTypeAPI from './WorkOrderRequestTypeAPI.js';
/**
 * Interface to the ServiceType API endpoints
 * @class
 * @exports src/services/ServiceTypeAPI
 */
class ServiceTypeAPI {
  /**
   * Retrieves all the Service Types
   * @method
   * @static
   * @return {Promise}          Promise that resolves to an aray of {@link ServiceType} items.
   */
  static async getServiceTypes(filter) {
    const response = await axios({
      method: 'GET',
      url: 'service_type',
      responseType: 'json',
      params: {
        ...processFilterParameters(filter),
      },
    });

    return response.data.data;
  }

  /**
   * Retrieves all the Service Types and Request Types and then maps them together.
   * @method
   * @static
   * @return {Promise}          Promise that resolves to an aray of {@link ServiceType} items.
   */
  static async getServiceTypesWithRequestTypes(serviceTypeFilters, workOrderRequestTypeFilters) {
    const result = await Promise.all([
      ServiceTypeAPI.getServiceTypes(serviceTypeFilters),
      WorkOrderRequestTypeAPI.getWorkOrderRequestTypes(
        'all',
        'name',
        'asc',
        workOrderRequestTypeFilters,
      ),
    ]);

    const serviceTypes = result[0];
    const requestTypes = result[1];

    requestTypes.forEach((r) => {
      // get the path to the matching Service Type - we ensure to ignore ID matching Request Types
      // that have already been matched
      const path = findNodePath(
        r.service_type_id,
        {
          id: -1,
          children: serviceTypes,
        },
        {
          valueField: (node, id) => node.id === id && !node.service_type_id,
          childrenField: 'children',
        },
      );

      // if we haven't found a match (length of 1 would mean a root match which we ignore)
      if (path && path.length > 1) {
        const ag = path[path.length - 1];

        ag.children = ag.children || [];
        ag.children.push(r);
      }
    });

    return serviceTypes;
  }

  /**
   * @summary Hits the create ServiceType endpoint.
   * @desc Sends the given service type data to the create endpoint
   * @param  {ServiceType}  serviceType A {@link ServiceType} object to be saved
   * @return {Promise}      Resolves to the returned full ServiceType object
   */
  static async createServiceType(serviceType) {
    const response = await axios({
      method: 'POST',
      url: 'service_type',
      responseType: 'json',
      data: serviceType,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the PATCH ServiceType endpoint.
   * @desc Sends the specified _modified_ fields to the endpoint to be saved.
   * @param  {String|Number}  serviceTypeId The ServiceType Id to save
   * @param  {object}  modifiedFields An object with a subset of the
   * {@link ServiceType} fields that have been modified and should be saved.
   * @return {Promise}      Resolves to the returned full ServiceType object
   */
  static async updateServiceType(serviceTypeId, modifiedFields) {
    const response = await axios({
      method: 'PATCH',
      url: `service_type/${serviceTypeId}`,
      responseType: 'json',
      data: modifiedFields,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the DELETE ServiceType endpoint.
   * @param  {String|Number}  serviceTypeId The ServiceType Id to delete
   * @return {Promise}
   */
  static async deleteServiceType(serviceTypeId) {
    await axios({
      method: 'DELETE',
      url: `service_type/${serviceTypeId}`,
      responseType: 'json',
    });

    return true;
  }
}

export default ServiceTypeAPI;
