import types from '../../types/contractor.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_CONTRACTORS_SORT
   * @summary Updates the `sort` and `direction` properties
   * @method
   * @param {Object} state Current state
   * @param {Object} data The sort data (`sort` and `direction`)
   */
  [m.SET_CONTRACTORS_SORT](state, { sort, direction }) {
    state.sort = sort;
    state.sortDirection = direction;
  },

  /**
   * @name m SET_CONTRACTORS_FILTER
   * @summary Updates the `filter` property which holds the collection of filters currently active
   * on the Contractors collection
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The filters to be applied
   */
  [m.SET_CONTRACTORS_FILTER](state, filter) {
    state.filter = filter;
  },

  /**
   * @name m SET_ACTIVE_CONTRACTOR
   * @summary Updates the `activeContractor` and resets the
   * `activeContractorModifiedFields` & `activeContractorErrors` properties
   * @method
   * @param {Object} state Current state
   * @param {Contractor} contractor The new Active {@link Contractor}
   */
  [m.SET_ACTIVE_CONTRACTOR](state, contractor) {
    state.activeContractor = contractor;
    state.activeContractorModifiedFields = !contractor || contractor.id ? {} : { ...contractor };
    state.activeContractorErrors = [];
  },

  /**
   * @name m SET_ACTIVE_CONTRACTOR_STATE
   * @summary Updates the `activeContractorState` property which holds the current loading status of
   * the active contractor based on the API enum
   * @method
   * @param {Object} state Current state
   * @param {enums/API} activeContractorState The current state (e.g. loading, success, failure)
   */
  [m.SET_ACTIVE_CONTRACTOR_STATE](state, activeContractorState) {
    state.activeContractorState = activeContractorState;
  },

  /**
   * @name m SET_ACTIVE_CONTRACTOR_ERRORS
   * @summary Updates the `activeContractorErrors` property which holds any errors related to
   * activeContractor
   * @method
   * @param {Object} state Current state
   * @param {string[]} errors An array of error messages
   */
  [m.SET_ACTIVE_CONTRACTOR_ERRORS](state, errors) {
    // eslint-disable-next-line no-unused-expressions
    Array.isArray(errors)
      ? (state.activeContractorErrors = errors)
      : state.activeContractorErrors.push(errors);
  },

  /**
   * @name m SET_ACTIVE_CONTRACTOR_VALID
   * @summary Updates the `activeContractorValid` property which holds a boolean for the validation
   * status of the `activeContractor`.
   * @method
   * @param {Object} state Current state
   * @param {boolean} valid True if the `activeContractor` is valid.
   */
  [m.SET_ACTIVE_CONTRACTOR_VALID](state, valid) {
    state.activeContractorValid = valid;
  },

  /**
   * @name m UPDATE_ACTIVE_CONTRACTOR_MODIFIED_FIELDS
   * @summary Adds the given field/value pair to the `activeContractorModifiedFields` collection,
   * which will be sent as a PATCH to the API. If the given `value` equals the one on the
   * `activeContractor` object (i.e. it hasn't actually changed, or has been reverted back to it's
   * original) then we remove that field from the modifiedFields collection
   * @method
   * @param {Object} state Current state
   * @param {object} payload Object containing a `field` and `value` properties.
   */
  [m.UPDATE_ACTIVE_CONTRACTOR_MODIFIED_FIELDS](state, { field, value }) {
    const unchanged =
      !state.activeContractor ||
      state.activeContractor[field] === value ||
      (state.activeContractor[field] === null && value === '');
    // if the value matches the existing one then we remove the field from the modifiedFields obj
    if (unchanged) {
      delete state.activeContractorModifiedFields[field];
    } else {
      state.activeContractorModifiedFields[field] = value;
    }
  },

  /**
   * @name m CLEAR_ACTIVE_CONTRACTOR_MODIFIED_FIELDS
   * @summary Empties the `activeContractorModifiedFields` object. Used to ensure we're on a clean
   * slate after navigating to a Contractor Display screen,
   * @method
   * @param {Object} state Current state
   */
  [m.CLEAR_ACTIVE_CONTRACTOR_MODIFIED_FIELDS](state) {
    state.activeContractorModifiedFields = {};
  },
};
