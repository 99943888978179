export default {
  /**
   * @name g isActiveOrganisationPhantom
   * @desc Returns true if the activeOrganisation is 'phantom' (i.e. has not been saved).
   * @param  {object}  state Current state
   * @return {Boolean}
   */
  isActiveOrganisationPhantom(state) {
    return !(state.activeOrganisation && state.activeOrganisation.id);
  },
};
