import Base from './Base';

export default function WorkOrderResolutionCodesCreator(i18n) {
  class WorkOrderResolutionCodes extends Base {}

  WorkOrderResolutionCodes.initEnum({
    FIRST_TIME_FIX: {
      value: 'FIRST_TIME_FIX',
      display: i18n.global.t('views.work-orders.resolutionCodes.firstTimeFix'),
      icon: 'medal',
    },
    FIXED: {
      value: 'FIXED',
      display: i18n.global.t('views.work-orders.resolutionCodes.fixed'),
      icon: 'check',
    },
    QUOTE_REQUIRED: {
      value: 'QUOTE_REQUIRED',
      display: i18n.global.t('views.work-orders.resolutionCodes.quoteRequired'),
      icon: 'file-alt',
    },
  });

  return WorkOrderResolutionCodes;
}
