export default {
  state: {
    /**
     * A RecurringWorkOrder definition
     * @typedef {Object} RecurringWorkOrder
     * @property {number} id
     * @property {number} site_id
     * @property {number} service_type_id
     * @property {number} work_order_request_type_id
     * @property {number} service_id
     * @property {string} maintainable_entity_type
     * @property {string} description
     * @property {string} point_of_contact
     * @property {string} next_unspawned_due_date
     * @property {string} interval
     * @property {string} end_date
     * @property {number} view_period
     * @property {number} action_period
     * @property {boolean} compliance
     * @property {boolean} enabled
     */

    recurringWorkOrder: () => ({
      id: null,
      summary: null,
      primary_location_id: null,
      auto_submit_costs: false,
      asset_ids: [],
      service_type_id: null,
      work_order_request_type_id: null,
      service_id: null,
      skip_rfs: false,
      engineer_id: null,
      description: null,
      point_of_contact: null,
      compliance: false,
      po_number: null,
      budget_id: null,
      spend_limit: null,
      cost_code_id: null,
      next_unspawned_due_date: null,
      interval: null,
      end_date: null,
      view_period: 30,
      action_period: 30,
      enabled: null,
      owner_id: null,
      watcher_ids: [],
      cost_lines: [],
      rams_ids: [],
    }),
  },

  getters: {},

  actions: {
    GET_RECURRING_WORK_ORDER: 'GET_RECURRING_WORK_ORDER',
    UPDATE_RECURRING_WORK_ORDER: 'UPDATE_RECURRING_WORK_ORDER',
    CREATE_RECURRING_WORK_ORDER: 'CREATE_RECURRING_WORK_ORDER',
    DELETE_RECURRING_WORK_ORDER: 'DELETE_RECURRING_WORK_ORDER',
  },

  mutations: {
    SET_ACTIVE_RECURRING_WORK_ORDER: 'SET_ACTIVE_RECURRING_WORK_ORDER',
    SET_ACTIVE_RECURRING_WORK_ORDER_STATE: 'SET_ACTIVE_RECURRING_WORK_ORDER_STATE',
    SET_ACTIVE_RECURRING_WORK_ORDER_ERRORS: 'SET_ACTIVE_RECURRING_WORK_ORDER_ERRORS',
    SET_ACTIVE_RECURRING_WORK_ORDER_VALID: 'SET_ACTIVE_RECURRING_WORK_ORDER_VALID',
    UPDATE_ACTIVE_RECURRING_WORK_ORDER_MODIFIED_FIELDS:
      'UPDATE_ACTIVE_RECURRING_WORK_ORDER_MODIFIED_FIELDS',
    CLEAR_ACTIVE_RECURRING_WORK_ORDER_MODIFIED_FIELDS:
      'CLEAR_ACTIVE_RECURRING_WORK_ORDER_MODIFIED_FIELDS',

    SET_RECURRING_WORK_ORDERS_FILTER: 'SET_RECURRING_WORK_ORDERS_FILTER',
    SET_RECURRING_WORK_ORDERS_SORT: 'SET_RECURRING_WORK_ORDERS_SORT',

    SET_ACTIVE_GROUP: 'SET_ACTIVE_GROUP',
    SET_EXPANDED_GROUPS: 'SET_EXPANDED_GROUPS',
  },
};
