import APIError from '../../../errors/APIError.js';
import types from '../../types/financialPeriod.js';
import FinancialPeriodAPI from '../../../services/FinancialPeriodAPI.js';
import ApiStatusCreator from '../../../enums/API.js';
const API_STATUS = ApiStatusCreator();

const a = types.actions;
const m = types.mutations;

export default {
  /**
   * Creates a new financialPeriod
   * @param {Object} context
   * @param {object} financialPeriod A {@link financialPeriod} object
   * @return {FinancialPeriod} A {@link FinancialPeriod} object.
   */
  async [a.CREATE_FINANCIAL_PERIOD]({ commit, state }, { financialPeriod }) {
    try {
      commit(m.SET_ACTIVE_FINANCIAL_PERIOD_ERRORS, []);
      commit(m.SET_ACTIVE_FINANCIAL_PERIOD_STATE, API_STATUS.SAVING);

      const financialPeriodResponse = await FinancialPeriodAPI.createFinancialPeriod(
        financialPeriod,
      );

      commit(m.SET_ACTIVE_FINANCIAL_PERIOD_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_FINANCIAL_PERIOD, financialPeriodResponse);
      commit(m.ADD_LOCAL_FINANCIAL_PERIOD, financialPeriodResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_FINANCIAL_PERIOD_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while creating this Financial Period. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeFinancialPeriod;
  },

  /**
   * Updates an existing FinancialPeriod
   * @param {Object} context
   * @param {object} financialPeriod The {@link FinancialPeriod} object to update
   * @param {object} modifiedFields An object with the key/values for the fields to update.
   * @return {FinancialPeriod} A {@link FinancialPeriod} object.
   */
  async [a.UPDATE_FINANCIAL_PERIOD]({ commit, state }, { financialPeriod, modifiedFields }) {
    try {
      commit(m.SET_ACTIVE_FINANCIAL_PERIOD_ERRORS, []);
      commit(m.SET_ACTIVE_FINANCIAL_PERIOD_STATE, API_STATUS.SAVING);

      const financialPeriodResponse = await FinancialPeriodAPI.updateFinancialPeriod(
        financialPeriod.id,
        modifiedFields,
      );

      commit(m.SET_ACTIVE_FINANCIAL_PERIOD_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_FINANCIAL_PERIOD, financialPeriodResponse);
      commit(m.UPDATE_LOCAL_FINANCIAL_PERIOD, financialPeriodResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_FINANCIAL_PERIOD_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while creating this Financial Period. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeFinancialPeriod;
  },

  /**
   * Deletes a FinancialPeriod
   * @param {Object} context
   * @param {FinancialPeriod} financialPeriod The {@link FinancialPeriod} object to delete
   * @return {FinancialPeriod} A {@link FinancialPeriod} object.
   */
  async [a.DELETE_FINANCIAL_PERIOD]({ commit }, { financialPeriod }) {
    try {
      commit(m.SET_ACTIVE_FINANCIAL_PERIOD_ERRORS, []);
      commit(m.SET_ACTIVE_FINANCIAL_PERIOD_STATE, API_STATUS.DELETING);

      await FinancialPeriodAPI.deleteFinancialPeriod(financialPeriod.id);

      commit(m.SET_ACTIVE_FINANCIAL_PERIOD_STATE, API_STATUS.SUCCESS);
      commit(m.SET_ACTIVE_FINANCIAL_PERIOD, null);

      commit(m.REMOVE_LOCAL_FINANCIAL_PERIOD, financialPeriod);
    } catch (error) {
      commit(m.SET_ACTIVE_FINANCIAL_PERIOD_STATE, API_STATUS.FAILURE);

      let message = 'An error occurred while deleting this Financial Period. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return true;
  },

  async[a.GET_FINANCIAL_PERIODS](
    { commit, state },
    { page = 'all', sort = 'start_date', direction = 'asc', filter = {} },
  ) {
    try {
      commit(m.SET_FINANCIAL_PERIODS_STATUS, API_STATUS.LOADING);

      const data = await FinancialPeriodAPI.getFinancialPeriods(page, sort, direction, filter);

      commit(m.SET_FINANCIAL_PERIODS_STATUS, API_STATUS.SUCCESS);
      commit(m.SET_FINANCIAL_PERIODS, data);
    } catch (error) {
      commit(m.SET_FINANCIAL_PERIODS_STATUS, API_STATUS.FAILURE);

      let message = 'An error occurred while fetching these Financial Periods. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return state.financialPeriods;
  },
};
