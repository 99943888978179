/**
 * A Financial Period definition
 * @typedef {Object} FinancialPeriod
 * @property {number} id -
 * @property {string} name
 * @property {string} start_date Format YYYY-MM-DD
 * @property {string} end_date Format YYYY-MM-DD
 * @property {string} divisions
 */

export default {
  financialPeriods: [],
  financialPeriodsState: null,

  meta: {},

  activeFinancialPeriod: null,
  activeFinancialPeriodState: null,
  activeFinancialPeriodErrors: [],
  activeFinancialPeriodValid: false,
  activeFinancialPeriodModifiedFields: {},
};
