import types from '../../types/expense.js';

const m = types.mutations;

export default {
  /**
   * @name m SET_EXPENSES_FILTER
   * @summary Updates the `filter` property which holds the collection of filters currently active
   * on the EXPENSES collection
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The filters to be applied
   */
  [m.SET_EXPENSES_FILTER](state, filter) {
    state.filter = filter;
  },

  /**
   * @name m SET_EXPENSES_SORT
   * @summary Updates the `sort` and `direction` properties
   * @method
   * @param {Object} state Current state
   * @param {Object} data The sort data (`sort` and `direction`)
   */
  [m.SET_EXPENSES_SORT](state, { sort, direction }) {
    state.sort = sort;
    state.sortDirection = direction;
  },

  /**
   * @name m SET_EXPENSES
   * @summary Updates the `expenses` property.
   * @method
   * @param {Object} state Current state
   * @param {Expense[]} expenses The loaded Expenses array
   */
  [m.SET_EXPENSES](state, expenses) {
    state.expenses = expenses;
  },

  [m.SET_EXPENSES_STATUS](state, status) {
    state.expensesState = status;
  },

  /**
   * @name m SET_EXPENSES_META
   * @summary Updates the `meta` property which holds the meta data for the `EXPENSES` collection.
   * For example, total rows, current page, number rows per page etc
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The meta data to store
   */
  [m.SET_EXPENSES_META](state, meta) {
    state.meta = meta;
  },

  /**
   * @name m SET_EXPENSES_FILTER
   * @summary Updates the `filter` property which holds the collection of filters currently active
   * on the Expenses collection
   * @method
   * @param {Object} state Current state
   * @param {Object} meta The filters to be applied
   */
  [m.SET_EXPENSES_FILTER](state, filter) {
    state.filter = filter;
  },

  /**
   * @name m SET_ACTIVE_EXPENSE
   * @summary Updates the `activeExpense` and resets the
   * `activeExpenseModifiedFields` & `activeExpenseErrors` properties
   * @method
   * @param {Object} state Current state
   * @param {Expense} expense The new Active {@link Expense}
   */
  [m.SET_ACTIVE_EXPENSE](state, expense) {
    state.activeExpense = expense;
    state.activeExpenseModifiedFields = !expense || expense.id ? {} : { ...expense };
    state.activeExpenseErrors = [];
  },

  /**
   * @name m SET_ACTIVE_EXPENSE_STATE
   * @summary Updates the `activeExpenseState` property which holds the current status of the
   * based on the API enum
   * @method
   * @param {Object} state Current state
   * @param {enums/API} activeExpenseState The current activeExpenseState
   * (e.g. loading, success, failure)
   */
  [m.SET_ACTIVE_EXPENSE_STATE](state, activeExpenseState) {
    state.activeExpenseState = activeExpenseState;
  },

  /**
   * @name m SET_ACTIVE_EXPENSE_ERRORS
   * @summary Updates the `activeExpenseErrors` property which holds any
   * errors related to activeExpense
   * @method
   * @param {Object} state Current state
   * @param {string[]} errors An array of error messages
   */
  [m.SET_ACTIVE_EXPENSE_ERRORS](state, errors) {
    state.activeExpenseErrors = errors;
  },

  /**
   * @name m SET_ACTIVE_EXPENSE_VALID
   * @summary Updates the `activeExpenseValid` property which holds
   * a boolean for the validation status of the `activeExpense`.
   * @method
   * @param {Object} state Current state
   * @param {boolean} valid True if the `activeExpense` is valid.
   */
  [m.SET_ACTIVE_EXPENSE_VALID](state, valid) {
    state.activeExpenseValid = valid;
  },

  /**
   * @name m UPDATE_ACTIVE_EXPENSE_MODIFIED_FIELDS
   * @summary Adds given field/value pair to the `activeExpenseModifiedFields` collection, which
   * will be sent as a PATCH to the API. If the given `value` equals the one on the `activeExpense`
   * object (i.e. it hasn't actually changed, or has been reverted back to it's original) then we
   * remove that field from the modifiedFields collection
   * @method
   * @param {Object} state Current state
   * @param {object} payload Object containing a `field` and `value` properties.
   */
  [m.UPDATE_ACTIVE_EXPENSE_MODIFIED_FIELDS](state, { field, value }) {
    // if the value matches the existing one then we remove the field from the modifiedFields obj
    if (state.activeExpense && state.activeExpense[field] === value) {
      delete state.activeExpenseModifiedFields[field];
    } else {
      state.activeExpenseModifiedFields[field] = value;
    }
  },

  /**
   * @name m CLEAR_ACTIVE_EXPENSE_MODIFIED_FIELDS
   * @summary Empties the `activeExpenseModifiedFields` object. Used to ensure we're on a clean
   * slate after navigating to a Expense Display screen,
   * @method
   * @param {Object} state Current state
   */
  [m.CLEAR_ACTIVE_EXPENSE_MODIFIED_FIELDS](state) {
    state.activeExpenseModifiedFields = {};
  },

  /**
   * @name m REMOVE_LOCAL_EXPENSE
   * @summary Removes the given expense from the `expenses` array. This is used to prevent a
   * full reload being needed after deleting a Expense.
   * @method
   * @param {Object} state Current state
   * @param {object} expense The Expense to remove.
   */
  [m.REMOVE_LOCAL_EXPENSE](state, expense) {
    const index = state.expenses.findIndex((u) => u.id === expense.id);

    if (index >= 0) {
      state.expenses.splice(index, 1);
    }
  },

  /**
   * @name m ADD_LOCAL_EXPENSE
   * @summary Adds the given expense to the `expenses` array. This is used to prevent a
   * full reload being needed after adding a Expense.
   * @method
   * @param {Object} state Current state
   * @param {object} expense The Expense to add.
   */
  [m.ADD_LOCAL_EXPENSE](state, expense) {
    state.expenses.push(expense);
  },

  /**
   * @name m UPDATE_LOCAL_EXPENSE
   * @summary Updates the related expense object in the `expenses` array after an update.
   * @method
   * @param {Object} state Current state
   * @param {object} expense The Expense to update.
   */
  [m.UPDATE_LOCAL_EXPENSE](state, expense) {
    const localExpense = state.expenses.find((c) => c.id === expense.id);

    if (localExpense) {
      Object.assign(localExpense, expense);
    }
  },
};
