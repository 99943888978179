<template>
  <div class="global-components">
    <GlobalErrors />

    <AssetScanModal ref="assetScanModal" :key="assetScanKey" />
    <WorkOrderCreateModal v-if="showWorkOrderModal" :work-order="workOrderConfig" />

    <v-google-translate v-if="hasTranslate" v-show="false"></v-google-translate>

    <Toast
      position="bottom-right"
      group="globalToast"
      :pt="{root: { style: 'width:auto; max-width:25rem; margin-left:20px' }}"
    >
      <template v-slot:message="slotProps">
        <div v-if="slotProps.message">
          <div style="margin-top: 0.2rem;">
            <font-awesome-icon v-if="slotProps.message.icon" :icon="slotProps.message.icon"></font-awesome-icon> <!-- -->&nbsp;
            <span :key="slotProps.message.summary">{{slotProps.message.summary}}</span>
          </div>

          <div v-if="slotProps.message.detail || slotProps.message.link" class="mt-2">
            <small>
              {{slotProps.message.detail}}
              <span v-if="slotProps.message.link">
                <b-link @click="onToastClick(slotProps.message.link.route)">{{slotProps.message.link.text}}</b-link>
              </span>
            </small>
          </div>
        </div>
      </template>
    </Toast>
  </div>
</template>

<script>
import GlobalErrors from '@/components/error/GlobalErrors.vue';
import WorkOrderCreateModal from '@/views/work-orders/WorkOrderCreateModal.vue';
import dayjs from 'dayjs';
import vGoogleTranslate from '@/v-google-translate/index.vue';
import Toast from 'primevue/toast';
import {BLink} from 'bootstrap-vue';
import AssetScanModal from "@/views/assets/AssetScanModal.vue";

export default {
  name: 'GlobalComponents',
  inject: ['mq'],
  components: {
    GlobalErrors,
    WorkOrderCreateModal,
    vGoogleTranslate,
    Toast,
    BLink,
    AssetScanModal,
  },
  data() {
    return {
      workOrderCreateSuccessCallback: null,
      assetScanKey: 0,
      showWorkOrderModal: false,
      workOrderConfig: null,
      hotkeysEnabled:
        window.appConfig?.DEMO_HOTKEYS?.value ??
        window.appConfig?.DEMO_HOTKEYS?.default_value ??
        false,
      hasTranslate: window.appConfig?.HAS_TRANSLATE.value ?? window.appConfig?.HAS_TRANSLATE.default_value ?? false,
    };
  },
  mounted() {
    if (this.hotkeysEnabled) {
      document.addEventListener('keydown', this.onKeyBind, true);
    }

    this.$globalEmit.on('global-asset-scan-open', this.openAssetScanModal);

    this.$globalEmit.on('global-work-order-create-open', this.openWorkOrderCreateModal);
    this.$globalEmit.on('global-work-order-create-cancel', this.hideWorkOrderCreateModal);
    this.$globalEmit.on('global-work-order-create-success', this.onWorkOrderCreateSuccess);

    this.$globalEmit.on('toast', this.onToast);
  },
  unmounted() {
    document.removeEventListener('keydown', this.onKeyBind, true);

    this.$globalEmit.off('global-asset-scan-open', this.openAssetScanModal);

    this.$globalEmit.off('global-work-order-create-open', this.openWorkOrderCreateModal);
    this.$globalEmit.off('global-work-order-create-cancel', this.hideWorkOrderCreateModal);
    this.$globalEmit.off('global-work-order-create-success', this.onWorkOrderCreateSuccess);

    this.$globalEmit.off('toast', this.onToast);
  },
  methods: {
    onKeyBind(e) {
      if (e.key === '§' && (e.ctrlKey || e.metaKey)) {
        e.preventDefault();
        this.openWorkOrderCreateModal({
          summary: `Test WO (${dayjs().format('YYYY-MM-DD HH:mm:ss')})`,
          description: 'This is a test work order.',
          primary_location_id: -1,
          site_id: 1,
          service_type_id: 2,
          work_order_request_type_id: 2,
          point_of_contact: 'Test',
          sla_type_id: 'NONE',
        });
      }
    },
    openWorkOrderCreateModal(config) {
      this.workOrderConfig = config || {};
      this.showWorkOrderModal = true;
      this.workOrderCreateSuccessCallback = config.successCallback;
    },
    onWorkOrderCreateSuccess(createdWorkOrder) {
      this.hideWorkOrderCreateModal();
      if (this.workOrderCreateSuccessCallback) {
        this.workOrderCreateSuccessCallback(createdWorkOrder);
      }
    },
    hideWorkOrderCreateModal() {
      this.workOrderConfig = null;
      this.showWorkOrderModal = false;
    },
    onToast(event) {
      const {severity, summary, detail, link, icon} = event;
      this.$nextTick(() => this.$toast.add({
        severity: severity,
        summary: summary,
        detail: detail,
        link: link,
        icon: icon,
        group: 'globalToast',
        life: 4000,
      }));
    },
    onToastClick(routeConfig) {
      this.$router.push({ name: routeConfig.name, params: routeConfig.params });
      this.$toast.removeGroup('globalToast');
    },
    openAssetScanModal() {
      this.scannerKey += 1;
      this.$refs.assetScanModal.show();
    },
  },
};
</script>

<style lang="scss" scoped></style>
