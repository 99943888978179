import Base from './Base.js';

export default function UserTypeCreator() {
  class UserType extends Base {}

  UserType.initEnum({
    ADMIN: {
      value: 'ADMIN',
      display: 'Admin',
    },
    CONTRACTOR: {
      value: 'CONTRACTOR',
      display: 'Contractor',
    },
  });

  return UserType;
}
