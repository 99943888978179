export default {
  /**
   * @name g getActiveUser
   * @desc Returns the `activeUser` object
   * @param  {object}  state Current state
   * @return {object}
   */
  getActiveUser(state) {
    return state.activeUser;
  },

  /**
   * @name g isActiveUserPhantom
   * @desc Returns true if the activeUser is 'phantom' (i.e. has not been saved).
   * @param  {object}  state Current state
   * @return {Boolean}
   */
  isActiveUserPhantom(state) {
    return !(state.activeUser && state.activeUser.id);
  },
};
