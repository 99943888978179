export default {
  getters: {},

  actions: {
    GET_DOCUMENT_TYPES: 'GET_DOCUMENT_TYPES',
    UPDATE_DOCUMENT_TYPE: 'UPDATE_DOCUMENT_TYPE',
    CREATE_DOCUMENT_TYPE: 'CREATE_DOCUMENT_TYPE',
    DELETE_DOCUMENT_TYPE: 'DELETE_DOCUMENT_TYPE',
  },

  mutations: {
    SET_DOCUMENT_TYPES: 'SET_DOCUMENT_TYPES',
    SET_DOCUMENT_TYPES_STATUS: 'SET_DOCUMENT_TYPES_STATUS',
    SET_DOCUMENT_TYPES_META: 'SET_DOCUMENT_TYPES_META',
    SET_DOCUMENT_TYPES_FILTER: 'SET_DOCUMENT_TYPES_FILTER',

    REMOVE_LOCAL_DOCUMENT_TYPE: 'REMOVE_LOCAL_DOCUMENT_TYPE',
    ADD_LOCAL_DOCUMENT_TYPE: 'ADD_LOCAL_DOCUMENT_TYPE',
    UPDATE_LOCAL_DOCUMENT_TYPE: 'UPDATE_LOCAL_DOCUMENT_TYPE',

    SET_ACTIVE_DOCUMENT_TYPE: 'SET_ACTIVE_DOCUMENT_TYPE',
    SET_ACTIVE_DOCUMENT_TYPE_STATE: 'SET_ACTIVE_DOCUMENT_TYPE_STATE',
    SET_ACTIVE_DOCUMENT_TYPE_ERRORS: 'SET_ACTIVE_DOCUMENT_TYPE_ERRORS',
    SET_ACTIVE_DOCUMENT_TYPE_VALID: 'SET_ACTIVE_DOCUMENT_TYPE_VALID',
    UPDATE_ACTIVE_DOCUMENT_TYPE_MODIFIED_FIELDS: 'UPDATE_ACTIVE_DOCUMENT_TYPE_MODIFIED_FIELDS',
    CLEAR_ACTIVE_DOCUMENT_TYPE_MODIFIED_FIELDS: 'CLEAR_ACTIVE_DOCUMENT_TYPE_MODIFIED_FIELDS',
  },
};
