<template>
  <div class="external-layout">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'ExternalLayout',
  components: {},

  data() {
    return {};
  },

  watch: {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.external-layout {
  height: 100%;
  overflow: auto;
}
</style>
