<template>
  <div>
    <p class="text-center">
      {{ $t('components.auth.otp.setup.form.instruction') }}
    </p>

    <b-form>
      <div class="otp-field-container" @keydown="keydown">
        <b-form-input
          v-for="(n, i) in otpComponents"
          :key="i"
          v-model="otpComponents[i]"
        ></b-form-input>
      </div>
    </b-form>
  </div>
</template>

<script>
import { BForm, BFormInput } from 'bootstrap-vue';
import { required, numeric } from '@vuelidate/validators';
import useVuelidate from '@vuelidate/core';

const OTP_LENGTH = 6;
const OTP_FIELD_DEFAULT_VALUE = '';

export default {
  name: 'Form',
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    BForm,
    BFormInput,
  },

  data() {
    return {
      otpComponents: Array(OTP_LENGTH).fill(OTP_FIELD_DEFAULT_VALUE),
    };
  },

  computed: {
    changeData() {
      return this.otpComponents;
    },
  },

  watch: {
    changeData(value) {
      this.$emit('updated', {
        valid: !this.v$.$invalid,
        value: value.join(''),
      });
    },
  },

  mounted() {
    this.$el.querySelector('input').focus();
  },

  validations: {
    otpComponents: {
      $each: {
        required,
        numeric,
      },
    },
  },

  methods: {
    keydown(e) {
      if (!e.metaKey && !e.shiftKey && !e.ctrlKey) {
        if (e.key.length === 1) {
          e.preventDefault();
        }

        if (!Number.isNaN(Number(e.key))) {
          e.target.value = e.key;
          e.target.blur();
          if (e.target.nextElementSibling) {
            e.target.nextElementSibling.focus();
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.otp-field-container {
  justify-content: center;
  display: flex;
  margin-bottom: 1rem;

  > input {
    height: 3rem;
    margin: 0 0.1rem;
    text-align: center;
    cursor: pointer;
    font-size: 1.5rem !important;
  }
}
</style>
