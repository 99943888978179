export default {
  install(app, store) {
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$currencyPrefix = (currency) => {
      if (!currency) {
        return '';
      }

      return (0)
        .toLocaleString((store.getters.getLocale || 'en-GB').toLowerCase(), {
          style: 'currency',
          currency,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })
        .replace(/\d/g, '')
        .trim();
    };
  },
};
