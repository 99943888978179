export default {
  getters: {},

  actions: {
    GET_AUDITS: 'GET_AUDITS',
  },

  mutations: {
    SET_AUDITS: 'SET_AUDITS',
    SET_AUDITS_STATUS: 'SET_AUDITS_STATUS',
    SET_AUDITS_META: 'SET_AUDITS_META',
    SET_AUDITS_FILTER: 'SET_AUDITS_FILTER',
    SET_AUDITS_SORT: 'SET_AUDITS_SORT',
  },
};
