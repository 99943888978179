export default {
  path: '/work-order-calendar',
  meta: {
    requiresAuth: true,
    layout: 'DefaultLayout',
    bcLinkText: 'Work Order Calendar',
  },
  component: () => import('@/views/work-orders/WorkOrderHome.vue'),
  children: [
    {
      name: 'WorkOrderCalendar',
      path: '',
      meta: {
        requiresAuth: true,
        layout: 'DefaultLayout',
        storeModules: ['workOrder', 'serviceType', 'site'],
      },
      component: () => import('@/views/work-orders/WorkOrderPPM.vue'),
    },
  ],
};
