<template>
  <div>
    <b-alert
      v-for="(error, k) in globalErrors"
      :key="k"
      variant="danger"
      show
      dismissible
      class="position-fixed fixed-bottom m-1 rounded-1"
      @dismissed="clearError(k)"
    >
      <div v-for="(text, n) in error.errors" :key="n">
        <span :key="text">{{ text }}</span>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import errorTypes from '@/store/types/error.js';
import { BAlert } from 'bootstrap-vue';

export default {
  name: 'GlobalErrors',
  components: {
    'b-alert': BAlert,
  },

  computed: {
    ...mapState({
      globalErrors: (state) => state.error.errors,
    }),
  },

  methods: {
    clearError(id) {
      this.$store.commit(errorTypes.mutations.EXPIRE_ERROR, id);
    },
  },
};
</script>
