/**
 * A Supplier definition
 * @typedef {Object} Supplier
 * @property {number} id -
 * @property {string} name -
 * @property {string} code -
 * @property {string} symbol -
 */

export default {
  suppliers: [],
  suppliersState: null,

  filter: {},

  meta: {},

  activeSupplier: null,
  activeSupplierState: null,
  activeSupplierErrors: [],
  activeSupplierValid: false,
  activeSupplierModifiedFields: {},
};
