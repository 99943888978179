import types from '../../types/notification.js';
import NotificationAPI from '../../../services/NotificationAPI.js';
import API from '../../../enums/API.js';
import APIError from '../../../errors/APIError.js';

const a = types.actions;
const m = types.mutations;

export default {
  /**
   * Loads all Notifications
   * @param {Object} context
   * @param {number} page The page to load. If not supplied will default to 1
   * @return {User[]} An array of {@link Notification} objects.
   */
  async [a.GET_NOTIFICATIONS]({ commit, state }, { sort = 'name', direction }) {
    commit(m.SET_NOTIFICATIONS_STATUS, API.LOADING);

    try {
      const notifications = await NotificationAPI.getNotifications('all', sort, direction);

      commit(m.SET_NOTIFICATIONS_STATUS, API.SUCCESS);
      commit(m.SET_NOTIFICATIONS, notifications);
    } catch (error) {
      commit(m.SET_NOTIFICATIONS_STATUS, API.FAILURE);

      let message = 'An error occurred while fetching these Notifications. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return state.notifications;
  },

  /**
   * Creates a new Notification
   * @param {Object} context
   * @param {object} notification A {@link Notification} object
   * @return {Notification} A {@link Notification} object.
   */
  async [a.CREATE_NOTIFICATION]({ commit, state }, { notification }) {
    try {
      commit(m.SET_ACTIVE_NOTIFICATION_ERRORS, []);
      commit(m.SET_ACTIVE_NOTIFICATION_STATE, API.SAVING);

      const notificationResponse = await NotificationAPI.createNotification(notification);

      commit(m.SET_ACTIVE_NOTIFICATION_STATE, API.SUCCESS);
      commit(m.SET_ACTIVE_NOTIFICATION, notificationResponse);
      commit(m.ADD_LOCAL_NOTIFICATION, notificationResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_NOTIFICATION_STATE, API.FAILURE);

      let message = 'An error occurred while creating this Notification. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeNotification;
  },

  /**
   * Updates an existing Notification
   * @param {Object} context
   * @param {object} notification The {@link Notification} object to update
   * @param {object} modifiedFields An object with the key/values for the fields to update.
   * @return {Notification} A {@link Notification} object.
   */
  async [a.UPDATE_NOTIFICATION]({ commit, state }, { notification, modifiedFields }) {
    try {
      commit(m.SET_ACTIVE_NOTIFICATION_ERRORS, []);
      commit(m.SET_ACTIVE_NOTIFICATION_STATE, API.SAVING);

      // eslint-disable-next-line max-len
      const notificationResponse = await NotificationAPI.updateNotification(
        notification.id,
        modifiedFields,
      );

      commit(m.SET_ACTIVE_NOTIFICATION_STATE, API.SUCCESS);
      commit(m.SET_ACTIVE_NOTIFICATION, notificationResponse);
      commit(m.UPDATE_LOCAL_NOTIFICATION, notificationResponse);
    } catch (error) {
      commit(m.SET_ACTIVE_NOTIFICATION_STATE, API.FAILURE);

      let message = 'An error occurred while updating this Notification. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      message = Array.isArray(message) ? message : [message];

      throw new APIError(message);
    }

    return state.activeNotification;
  },

  /**
   * Deletes a Notification
   * @param {Object} context
   * @param {object} notification The {@link Notification} object to delete
   * @return {Notification} A {@link Notification} object.
   */
  async [a.DELETE_NOTIFICATION]({ commit }, { notification }) {
    try {
      commit(m.SET_ACTIVE_NOTIFICATION_ERRORS, []);
      commit(m.SET_ACTIVE_NOTIFICATION_STATE, API.DELETING);

      await NotificationAPI.deleteNotification(notification.id);

      commit(m.SET_ACTIVE_NOTIFICATION_STATE, API.SUCCESS);
      commit(m.SET_ACTIVE_NOTIFICATION, null);

      commit(m.REMOVE_LOCAL_NOTIFICATION, notification);
    } catch (error) {
      commit(m.SET_ACTIVE_NOTIFICATION_STATE, API.FAILURE);

      let message = 'An error occurred while deleting this Notification. Please try again.'

      if (error.response && error.response.data && error.response.data.message) {
        message = error.response.data.message; // eslint-disable-line prefer-destructuring
      } else {
        message = error.message || message;
      }

      throw new Error(message);
    }

    return true;
  },
};
