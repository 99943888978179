export default {
  mutations: {
    EXPIRE_ERROR: 'EXPIRE_ERROR',
    PUSH_ERROR: 'PUSH_ERROR',
    SET_NOT_FOUND_OR_PERMISSION_ERRORS: 'SET_NOT_FOUND_OR_PERMISSION_ERRORS',
  },
  actions: {
    RAISE_ERROR: 'RAISE_ERROR',
  },
};
