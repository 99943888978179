/**
 * @summary Takes an object and extracts the items at the given key
 * @param  {Object} obj The object to pluck from
 * @param  {string} childKey The key to extract
 * @return {object} A new object, mapping from original key to extracted value
 */
export default function objectPluck(obj, childKey) {
  const out = {};
  const safe = obj || {};

  // extract the `childKey` property and reassign
  Object.entries(safe).forEach(([key, value]) => {
    out[key] = value[childKey];
  });
  return out;
}
