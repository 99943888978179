import axios from 'axios';

class EmergencyWorkOrderAPI {
  static async getProcedure(siteId, requestedServiceId, noneSuitable) {
    const response = await axios({
      method: 'GET',
      url: 'work_order/emergency_procedure',
      responseType: 'json',
      params: {
        site_id: siteId,
        requested_service_id: requestedServiceId,
        none_suitable: noneSuitable ? 1 : 0,
      },
    });

    return response.data.data;
  }
}

export default EmergencyWorkOrderAPI;
