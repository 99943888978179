import axios from 'axios';

function getValueFromCookie() {
  const cookie = document.cookie.split(';').find((c) => c.startsWith('XSRF-TOKEN='));
  if (cookie === undefined) {
    return undefined;
  }

  return cookie.split('=')[1];
}

let csrfRequest = null;
async function refreshCSRFFromApi() {
  if (csrfRequest) {
    return csrfRequest;
  }
  csrfRequest = await axios.get('/csrf-cookie').then(() => {
    csrfRequest = null;
  });

  return csrfRequest;
}

/**
 * Returns a CSRF token, either from a cookie or by hitting the relevant endpoint
 * @returns {Array} A CSRF token
 */
export default async function getCSRFToken(force = false) {
  let existing = force ? undefined : getValueFromCookie();
  if (existing === undefined) {
    await refreshCSRFFromApi();
    existing = getValueFromCookie();
  }
  return existing;
}
