import axios from 'axios';
import createAPIParams from '@/util/createAPIParams.js';

/**
 * Interface to the CostCode API endpoints
 * @class
 * @exports src/services/CostCodeAPI
 */
class CostCodeAPI {
  /**
   * Retrieves all the CostCodes
   * @method
   * @static
   * @return {Promise}          Promise that resolves to an aray of {@link CostCode} items.
   */
  static async getCostCodes(page, sort, direction, filter) {
    const response = await axios({
      method: 'GET',
      url: 'cost_code',
      responseType: 'json',
      params: createAPIParams(page, sort, direction, filter),
    });

    // add a `formattedName` to the data so it is available everywhere
    const costCodes = response.data?.data || [];

    costCodes.forEach((cc) => {
      // eslint-disable-next-line no-param-reassign
      cc.formattedName = `${cc.name} (${cc.code})`;
    });

    return { data: costCodes };
  }

  /**
   * @summary Hits the create CostCode endpoint.
   * @desc Sends the given costCode data to the create endpoint
   * @param  {CostCode}  costCode A {@link CostCode} object to be saved
   * @return {Promise}      Resolves to the returned full CostCode object
   */
  static async createCostCode(costCode) {
    const response = await axios({
      method: 'POST',
      url: 'cost_code',
      responseType: 'json',
      data: costCode,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the PATCH CostCode endpoint.
   * @desc Sends the specified _modified_ fields to the endpoint to be saved.
   * @param  {String|Number}  costCodeId The CostCode Id to save
   * @param  {object}  modifiedFields An object with a subset of the
   * {@link CostCode} fields that have been modified and should be saved.
   * @return {Promise}      Resolves to the returned full CostCode object
   */
  static async updateCostCode(costCodeId, modifiedFields) {
    const response = await axios({
      method: 'PATCH',
      url: `cost_code/${costCodeId}`,
      responseType: 'json',
      data: modifiedFields,
    });

    return response.data.data;
  }

  /**
   * @summary Hits the DELETE CostCode endpoint.
   * @param  {String|Number}  costCodeId The CostCode Id to delete
   * @return {Promise}
   */
  static async deleteCostCode(costCodeId) {
    await axios({
      method: 'DELETE',
      url: `cost_code/${costCodeId}`,
      responseType: 'json',
    });

    return true;
  }
}

export default CostCodeAPI;
