export default {
  filter: {},
  sort: 'created_at',
  sortDirection: 'DESC',

  meta: {},

  activeWorkOrder: null,
  activeWorkOrderState: null,
  activeWorkOrderErrors: [],
  activeWorkOrderValid: false,
  activeWorkOrderModifiedFields: {},

  workOrderTypes: [],
  workOrderRequestTypes: [],
  workOrderStates: [],

  enabled: [
    { id: 1, name: 'Yes' },
    { id: 0, name: 'No' },
  ],
  enabledFilter: [
    { id: null, name: 'All' },
    { id: 1, name: 'Yes' },
    { id: 0, name: 'No' },
  ],
};
