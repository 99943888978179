import Base from './Base.js';

export default function ContractorBillingRoundingCreator(i18n) {
  class ContractorBillingRounding extends Base {}

  ContractorBillingRounding.initEnum({
    UP_1_MINUTE: {
      value: 'UP_1_MINUTE',
      display: i18n.global.t('enums.ContractorBillingRounding.roundUpToTheNearestXMinutes', {count: 1}),
    },
    UP_15_MINUTE: {
      value: 'UP_15_MINUTE',
      display: i18n.global.t('enums.ContractorBillingRounding.roundUpToTheNearestXMinutes_plural', {count: 15}),
    },
    UP_30_MINUTE: {
      value: 'UP_30_MINUTE',
      display: i18n.global.t('enums.ContractorBillingRounding.roundUpToTheNearestXMinutes_plural', {count: 30}),
    },
    UP_60_MINUTE: {
      value: 'UP_60_MINUTE',
      display: i18n.global.t('enums.ContractorBillingRounding.roundUpToTheNearestHour'),
    },
  });

  return ContractorBillingRounding;
}
